// This class is used in 
// handleAddToCartItemList method of products-display.js file
export class Product {
    constructor(
    productId,
    name,
    nameMarathi,
    information,
    mrp,
    finalPrice,
    quantity,   // multipleOf is passsed here
    instock,
    unitId
  ) {
    this.productId = productId;
    this.name = name;
    this.nameMarathi = nameMarathi;
    this.information = information;
    this.mrp = mrp;
    this.finalPrice=finalPrice;
    this.quantity=quantity;
    this.instock = instock;
    this.unitId = unitId;
  }
}
export default Product;