import React, { Component } from "react";
import { Link } from "react-router-dom";

class AdminSpecialOfferList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
    };
  }
  handleEditSpecialOffer = (specialOffer) => {
    this.props.onEditSpecialOffer(specialOffer);
  };
  handleDeleteSpecialOffer = (specialOffer) => {
    this.props.onDeleteSpecialOffer(specialOffer);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  render() {
    let { error } = this.state;
    let { searchText } = this.props;
    let { filteredSpecialOffers } = this.props;
    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        <div className="form-heading column col-12">LIST OF PROMO CODES ({filteredSpecialOffers.length})</div>
        {/* row starts */}
        <div className="row">
          <div className="col-9">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={this.handleSearchTextBoxKeyUp}
              onChange={this.handleSearchTextChange} // This is contolled by parent
              placeholder="Search Special Offer"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-striped text-center">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link to="#" onClick={this.handleHeaderClick} id="name">
                        Special Offer
                      </Link>
                    </th>
                  </tr>
                </thead>
                {filteredSpecialOffers.map((specialOffer, index) => (
                  <tbody key={specialOffer.specialOfferId}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{specialOffer.name}</td>
                      <td>
                        <Link
                          to="#"
                          onClick={() =>
                            this.handleEditSpecialOffer(specialOffer)
                          }
                        >
                          {/* Edit */}
                          <i className="fas fa-edit admin-icons"></i>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                          to="#"
                          onClick={() => {
                            if (
                              window.confirm(
                                "You really want to delete " +
                                  specialOffer.name +
                                  " Special Offer  ?"
                              )
                            )
                              this.handleDeleteSpecialOffer(specialOffer);
                          }}
                        >
                          {/* Delete */}
                          <i className="far fa-trash-alt admin-icons"></i>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving Special Offers </div>;
    } else {
      return (
            <div className="col-md-12">{content}</div>
      );
    }
  }
}
export default AdminSpecialOfferList;
