import React, { Component } from "react";
import OwnerShopList from "./owner-shop-list";
import OwnerShopAdd from "./owner-shop-add";
import { BeatLoader } from "react-spinners";
import { ShopDB } from "../ShopDB";
import axios from "axios";

class OwnerShop extends Component {
  // shopTypeList = [];
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      shop: null,
      filteredShops: [],
      shopList:[],
      shopTypeList: [],
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getShopListFromDatabase = () => {
    let { shopTypeList } = this.state;
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/shop/shops")
      .then((res) => {
        let shopList = res.data;
        let updatedShops = [...shopList];

        // Do not show masterlist shop, it may get removed.
        
        updatedShops.map((shop, index) => {
          // get shopType (string) from shopId
          for (let i = 0; i < updatedShops.length; i++) {
            if (shop.shopTypeId === shopTypeList[i].shopTypeId) {
              updatedShops[index].shopType = shopTypeList[i].name;
              break;
            } //if
          } //for
        });
        shopList = updatedShops;
        this.setState({
          filteredShops: shopList,
          shopdb: new ShopDB(shopList),
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    this.setState({
      flagLoading: true,
    });
    axios
      .all([
        axios.get(window.routerPrefix + "/shop/shops"),
        axios.get(window.routerPrefix + "/shopType/shopTypes"),
      ])
      .then(
        axios.spread((res1, res2) => {
          let shopList = res1.data;
          let shopTypeList = res2.data;
          let updatedShops = [...shopList];
          updatedShops=updatedShops.filter((shop,index)=>
            shop.shopUrl!="masterlist"
          );
          updatedShops.map((shop, index) => {
            // get shopType (string) from shopId
            for (let i = 0; i < updatedShops.length; i++) {
              if (shop.shopTypeId === shopTypeList[i].shopTypeId) {
                updatedShops[index].shopType = shopTypeList[i].name;
                break;
              } //if
            } //for
          });
          shopList = updatedShops;
          this.setState({
            filteredShops: shopList,
            shopdb: new ShopDB(shopList),
            shopTypeList: shopTypeList,
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false });
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedShops = [];
    searchedShops = this.state.shopdb.filterByShopUrl(query);
    this.setState({
      filteredShops: searchedShops,
    });
  };
  handleFormCancelClick=()=>
  {
    this.props.onFormCancelClick();
  }
  updateShopList = (shop) => {
    let shopList = [...this.state.shopList];
    for (let i = 0; i < shopList.length; i++) {
      if (shopList[i].shopId == shop.shopId) {
        shopList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        shopList.unshift(shop);
        break;
      }
    } //for
    // remove from filteredshop also
    let filteredShops = [...this.state.filteredShops];
    for (let i = 0; i < filteredShops.length; i++) {
      if (filteredShops[i].shopId == shop.shopId) {
        filteredShops.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        filteredShops.unshift(shop);
        break;
      }
    } //for
    //sorting
    this.setState({
      filteredShops: filteredShops,
      shopList: shopList,
    });
  };
  onFormSubmit(shop) {
    let message;
    let { action } = this.props;
    if (action === "UPDATE") {
      //EDIT / PUT
      this.setState({
        flagLoading: true,
      });
      axios
        .put(window.routerPrefix + "/shop/updateShopByOwner/", shop)
        .then((res) => {
          this.setState({
          flagLoading: false,
          });
          let message;
          if (!res.data ) {
            // error updating shop
            message = "Error... could not update the shop";
          } else {
            this.updateShopList(shop);
            message = shop.shopUrl + " updated successfully";
          }
          this.props.onCrudAction(message, "LIST", "Add a Shop");
        })
        .catch((err) => {
          this.setState({
            flagLoading: false,
          });
          console.log(err);
        });
    } else if (action === "ADD") {
      //ADD / POST
      let userName = shop.mobile; // this will be the username
      axios
        // .post("/shops", data, {
        .post(window.routerPrefix + "/shop/addShop", shop, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .then((res) => {
          let response = res.data;
          this.setState({
            response: res.data,
          });
          message = shop.shopUrl + " added successfully.";
          this.props.onCrudAction(message, "ADD");
          this.addNewAdmin(shop.shopUrl, userName);
          // this.getShopListFromDatabase();  2023
          // this.createTables(shop.shopUrl); 2023
        })
        .catch((err) => {
          console.log("error!");
        });
    } //else-if (action==="ADD")
  } //onFormSubmit
  createTables = (shopUrl) => {
    axios
      .get(window.routerPrefix + "/tables/" + shopUrl)
      .then((res) => {
        this.setState({
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false });
      });
  };

  addNewAdmin = (shopUrl, userName) => {
    // Now create a temporary password to be entered first time
    // by the shopkeeper
    let password = this.makePassword(6); // of length =6
    window.alert(
      "Note down username and password of " +
        shopUrl +
        ". Username is " +
        userName +
        " and password is " +
        password
    );
    let data = new Object();
    data.userName = userName;
    data.password = password;
    data.shopUrl = shopUrl;
    data.flagFirstTimeLogin = 0;
    // ADD / POST
    axios
      // .post("/admins", data, {
      .post(window.routerPrefix + "/admin/addAdmin", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => {
        let response = res.data;
        this.setState({
          response: res.data,
        });
      })
      .catch((err) => {
        console.log("error!");
      });
  };
  makePassword = (length) => {
    var result = "";
    var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  handleEditShop = (shop) => {
    this.setState({
      shop: shop,
      searchText: "",
    });
    this.props.onEditClick();
  };
  handleTheDeleteShop = (shop, result) => {
    let message;
    if (result == 0) {
      //Error... deleting shop
      message = "Error....could not delete the shop";
      this.props.onCrudAction(message, "LIST");
      return;
    }
    let shopList = [...this.state.shopList];
    for (let i = 0; i < shopList.length; i++) {
      if (shopList[i].shopId == shop.shopTypeId) {
        shopList.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    // remove from filteredshopTypes also
    let filteredShops = [...this.state.filteredShops];
    for (let i = 0; i < filteredShops.length; i++) {
      if (filteredShops[i].shopId == shop.shopId) {
        filteredShops.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    this.setState({
      shopdb: new ShopDB(shopList),
      filteredShops: filteredShops,
      shopList: shopList,
    });
    message = shop.shopUrl + " deleted successfully";
    this.props.onCrudAction(message, "LIST");
  };
  handleDeleteShop = (shop) => {
    let message;
    window.scroll(0, 0);
    let { shopId } = shop;
    let { shopUrl } = shop;
    this.setState({
      flagLoading: true,
      searchText: "",
    });
    // Now call for delete api
    axios
      // .delete("/shops/" + shopId)
      .delete(window.routerPrefix + "/shop/delete/" + shop.shopUrl)
      .then((res) => {
        this.setState({
          flagLoading: false,
        });
        this.handleTheDeleteShop(shop, res.data);  //  res.data is 1 or 0  ... success/error
      })
      .catch((err) => {
        this.setState({
          flagLoading: false,
        });
        console.log(err);
      });
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;
    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredShops } = this.state;
    if (direction == false) {
      //in ascending order
      filteredShops = filteredShops.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredShops = filteredShops.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({ filteredShops: filteredShops, sortedField: field });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };

  render() {
    let { action } = this.props;
    let { shop } = this.state;
    let { shopTypeList } = this.state;
    let { flagLoading } = this.state;
    let content;
    if (flagLoading)
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"orange"} flagLoading />
        </div>
      );
    else if (action === "LIST") {
      // First show list
      content = (
        <div>
          <OwnerShopList
            onEditShop={this.handleEditShop}
            onDeleteShop={this.handleDeleteShop}
            onSearchTextChange={this.handleSearchTextChange}
            filteredShops={this.state.filteredShops}
            searchText={this.state.searchText}
            onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
            onHeaderClick={this.handleHeaderClick}
            onSectionNumberClick={this.handleSectionNumberClick}
            sectionNumber={this.state.sectionNumber}
          />
        </div>
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <OwnerShopAdd
            onFormSubmit={this.onFormSubmit}
            onFormCancelClick={this.handleFormCancelClick}
            shop={shop}
            action={action}
            shopTypeList={shopTypeList}
            // brandList={brandList}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-list-table p-0">{content}</div>
    );
  }
}
export default OwnerShop;
