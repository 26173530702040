import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { Link } from "react-router-dom";
function AdminProductListSingleImageQuick(props) {
  
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [mrp, setMrp] = useState(props.product.mrp);
  let [finalPrice, setfFinalPrice] = useState(props.product.finalPrice);

  useEffect(() => {}, []);
  function handleSaveProductPrice() {
    let { product } = props;
    let { shopUrl } = props;
    // save this product
    setFlagLoad(true);
    axios
      .put(window.routerPrefix + "/product/update/" + shopUrl, product)
      .then((res) => {
        setFlagLoad(false);
        props.onSaveProductPrice(product);
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
  }
  function handleChange(event) {
    let userInput = event.target.checked;
    let { product } = props;
    props.onSamePriceCheckBoxChange(product, userInput);
  }
  function handleMrpChange(event) {
    let newMrp = event.target.value;
    let { product } = props;
    props.onMrpChange(product, newMrp);
  }
  function handleFPriceChange(event) {
    let { product } = props;
    let value = event.target.value;
    props.onFinalPriceChange(product, value);
  }
  function handleMrpFocus() {
    setMrp(props.product.mrp);
  }
  function handleFPriceFocus() {
    setfFinalPrice(props.product.finalPrice);
  }
  let { product } = props;
  let { index } = props;
  let { languageChoice } = props;
  let { colorScheme } = props;
  
  if (error) {
    return <div>Error retriving products </div>;
  }
  return (
    // This is enclosed in container-list-table
    <>
      <div className="col-12  text-thick mb-1">
        <div className="">
          {index + 1}. &nbsp;
          {product.name}
          {product.information ? "-" + product.information : null}
          {languageChoice ? "/" + product.nameMarathi : null}
          &nbsp;
          {product.flagPriceChange &&
            !product.flagPriceUpdate &&
            !flagLoad && (
              <Link
                to="#"
                className="btn btn-danger"
                onClick={() => handleSaveProductPrice()}
              >
                <span className="bigger-text">Save</span>
              </Link>
            )}
          {product.flagPriceUpdate && !flagLoad && (
            <span className="text-mygreen">(Updated..)</span>
          )}
          {flagLoad && <BeatLoader size={8} color={"blue"} flagLoad />}
        </div>
      </div>
      {/* <div className="col-4 text-center">{product.mrp}</div> */}
      <div className="col-2 text-right mb-1">MRP</div>
      <div className="col-3 px-0 input-group">
       
        <input
          type="text"
          className="form-control"
          name="mrp"
          value={product.mrp}
          onChange={handleMrpChange}
          onFocus={handleMrpFocus}
        />
      </div>
      <div className="col-3 text-right ">Final Price</div>
      <div className="col-3 px-0 input-group">
       
        <input
          type="text"
          className="form-control"
          name="finalPrice"
          value={product.finalPrice}
          onChange={handleFPriceChange}
          onFocus={handleFPriceFocus}
        />
      </div>
      <div className="col-12 text-center column mb-1">
        Keep both prices same ?
        <input
          className="form-control"
          type="checkbox"
          name="flagPriceSame"
          value=""
          onChange={handleChange}
          checked={product.flagSamePrice}
          // checked={product.mrp==product.finalPrice}
        />
      </div>
      <div className={"col-12 my-2 border-my"+colorScheme}></div>
    </>
  );
}
export default AdminProductListSingleImageQuick;
