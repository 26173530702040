import React, { useEffect } from "react";
import FinalYaadiProduct from "./final-yaadi-product";
import FinalYaadiHeader from "./final-yaadi-header";
import "./css/final-yaadi.css";
import { Link } from "react-router-dom";
function FinalYaadiWhatsApp(props) {
  function handleBackClick() {
    props.onBackClick();
  }
  function handleWhatsappButtonClick() {
    props.onWhatsappButtonClick();
  }
  function handlePrintBillClick() {
    props.onPrintBillClick();
  }
  function handleWhatsappBillClick() {
    props.onWhatsappButtonClick();
  }
  useEffect(() => {
    //Runs only on the first render
    window.scroll(0, 0);
  }, []);
  let { cartItems } = props;
  let { customer } = props;
  let { shopDetails } = props;
  let { settings } = props;
  let { totalAmount } = props;
  let { yaadiUrl } = props;
  let { numberOfCartProducts } = props;
  let { languageChoice } = props;
  let { dummyPrice } = props;
  let { colorScheme } = props;
  let { isBilling } = props;

  let amountToPay = totalAmount;
  if (
    totalAmount < settings.deliveryChargesBelow &&
    settings.deliveryCharges > 0
  ) {
    amountToPay = amountToPay + Number(settings.deliveryCharges);
  }
  let i = 0;
  let { shopTypeId } = props;
  let whatsAppMessage;
  let yaadiOrOrder = " order";
  if (shopTypeId != 8) {
    yaadiOrOrder = " yaadi";
  }
  if (shopTypeId != 8) {
    whatsAppMessage = `Yaadi contains ${numberOfCartProducts} items. Total amount
    is ${totalAmount}. Link for yaadi is www.meriyaadi.com/yaadilist/${yaadiUrl}`;
  } else {
    whatsAppMessage = `The Order contains ${numberOfCartProducts} items. Total amount
    is ${totalAmount}. Link for the order is www.meriyaadi.com/yaadilist/${yaadiUrl}`;
  }
  let navBar;
  let textWhatsAppInfo = `1. Clicking above button will open WhatsApp in your mobile. 
  2. In WhatsApp, just tap the Enter-button to forward this ${yaadiOrOrder} to ${shopDetails.name}`;
  navBar = (
    // Here
    <div
      className={
        "container-fluid container-md fixed-top column py-2 bg-my" +
        colorScheme +
        "-dark"
      }
    >
      {/* row starts */}
      <div className="row justify-content-center align-items-center py-1">
        <div className="col-12 col-md-2 p-0 column text-center my-1">
          <button
            to="#"
            onClick={handleBackClick}
            className={"btn-my" + colorScheme}
          >
            {!isBilling ? "Back to Shopping" : "Back"}
          </button>
        </div>
        {!isBilling && (
          <div className="col-12 col-md-3 p-0  text-center py-2">
            {/* to open whatsapp */}
            <a
              className="btn btn-success text-myblack column  "
              onClick={handleWhatsappButtonClick}
            >
              Open WhatsApp to forward the {yaadiOrOrder}
            </a>
          </div>
        )}
        {isBilling && (
          <div className="col-6 col-md-3 p-0  text-center py-2">
            <Link
              className="btn btn-success text-myblack column  "
              onClick={handlePrintBillClick}
            >
              Print Bill
            </Link>
          </div>
        )}
        {/* {isBilling && (
          <div className="col-6 col-md-3 p-0  text-center py-2">
            <Link
              className="btn btn-success text-myblack column  "
              onClick={handleWhatsappBillClick}
            >
              WhatsApp to Customer
            </Link>
          </div>
        )} */}
      </div>
      {!isBilling && (
        <div className="row justify-content-center bg-white">
          <div className="col-12 col-md-10 whatsApp-button-info  text-center">
            <div className="div-center ">{textWhatsAppInfo}</div>
          </div>
        </div>
      )}
    </div>
  );
  let content;
  content = (
    <>
      <div
        className="container-fluid container-md bg-white container-final-yaadi-whatsapp text-center justify-content-center"
        id="final-yaadi"
      >
        {/* row starts */}
        <FinalYaadiHeader
          customer={customer}
          shopDetails={shopDetails}
          settings={settings}
          yaadiDateTime={new Date()}
          isBilling={isBilling}
          colorScheme={colorScheme}
        />
        {/* row ends */}
        {/* row starts */}
        <div className="text-thick">Yaadi</div>
        <div className="row column justify-content-center text-thick text-center mb-2 border-top border-bottom text-danger">
          <div className="col-5 col-md-4 column">Description</div>
          <div className="col-3 col-md-2 column">Qty</div>
          <div className="col-2 col-md-2 column">
            {!dummyPrice ? "Rate" : ""}
          </div>
          <div className="col-2 col-md-2 column text-right">
            {!dummyPrice ? "Total" : ""}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        {cartItems.map((product, index) =>
          product.quantity > 0 && product != null ? (
            <FinalYaadiProduct
              product={product}
              key={product.productId}
              srNo={++i}
              languageChoice={languageChoice}
              dummyPrice={props.dummyPrice}
            />
          ) : null
        )}
        {/* row ends */}
        {/* row starts */}
        {!dummyPrice && (
          <div className="row justify-content-center border-top border-bottom p-0 text-danger">
            <div className="col-5 col-md-4 p-0 column">
              Total {numberOfCartProducts} products
            </div>
            <div className="col-7 col-md-6 p-0 column text-right text-thick">
              Grand Total = <i className="fas fa-rupee-sign"></i>
              &nbsp; {totalAmount.toFixed(2)}
            </div>
            {totalAmount < settings.deliveryChargesBelow &&
              settings.deliveryCharges > 0 && (
                <div className="offset-5 col-7 offset-md-4 col-md-6 p-0 column text-right text-thick">
                  Delivery Charges = <i className="fas fa-rupee-sign"></i>
                  &nbsp; {Number(settings.deliveryCharges).toFixed(2)}
                </div>
              )}
            {totalAmount > settings.deliveryChargesBelow && (
              <div className="offset-5 col-7 offset-md-4 col-md-6 p-0 column text-right text-thick">
                Delivery Charges = <i className="fas fa-rupee-sign"></i>
                &nbsp; 00.00
              </div>
            )}
            {
              <div className="col-12 col-md-10 p-0 column text-right text-thick">
                Net payable amount = <i className="fas fa-rupee-sign"></i>
                &nbsp; {Number(amountToPay).toFixed(2)}
              </div>
            }
          </div>
        )}
        {/* row ends */}
        {/* container ends */}
      </div>
      <div className="mb-5">&nbsp;&nbsp;</div>
      <div className="mb-5">&nbsp;&nbsp;</div>
    </>
  );
  return (
    <>
      {navBar}
      {content}
    </>
  );
}
export default FinalYaadiWhatsApp;
