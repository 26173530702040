import React from "react";
import { Link } from "react-router-dom";

function ListCategory(props) {
  function handleCategoryClick(event) {
    props.onCategoryClick(event);
  }
  let { categoryList } = props;
  let {colorScheme } = props;
  return (
    <>
      {categoryList.map((category, index) =>
        category.rating != 1 ? (
          <div
            key={index}
            className={"col-6 col-md-4 bg-white  bigger-text text-center py-2 "}
          >
            <Link // showCategory replaced by rating
              // className="btn  text-thick text-left p-0"
              className={"btn-block btn-my" + colorScheme }
              to="#"
              onClick={handleCategoryClick}
              id={category.categoryId}
            >
              {category.name}
            </Link>
          </div>
        ) : null
      )}
    </>
  );
}
export default ListCategory;
