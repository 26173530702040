import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import  fieldValidate from "./Formvalidations";
function AdminProductAdd(props) {
  let [product, setProduct] = useState({
    productId: "",
    name: "",
    nameMarathi: "",
    unit: "",
    information: "",
    mrp: "",
    finalPrice: "",
    category: "",
    brand: "",
    searchWords: "",
    imageName: null,
    unitId: -1,
    categoryId: -1,
    brandId: -1,
    instock: 1,
    rating: 5,
  });
  let [errorProduct, setErrorProduct] = useState({
    name: { message: "", mxLen: 80, mnLen: 1, onlyDigits: false },
    nameMarathi: { message: "", mxLen: 40, mnLen: 1, onlyDigits: false },
    information: { message: "", onlyDigits: false },
    mrp: {
      message: "",
      mxLen: 30,
      mnLen: 1,
      onlyDigits: true,
      dependent: "finalPrice",
    },
    finalPrice: { message: "", mxLen: 30, mnLen: 1, onlyDigits: true },
    unit: { message: "" },
    category: { message: "" },
    brand: { message: "" },
    searchWords: { message: "", mxLen: 40, mnLen: 0, onlyDigits: false },
    unitId: { message: "" },
    categoryId: { message: "" },
    brandId: { message: "" },
  });
  let [file, setFile] = useState("");
  let [fileName, setFileName] = useState("");
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let emptyProduct;
  useEffect(() => {
    window.scroll(0, 0);
    init();
  }, [props]);
  function init() {
    emptyProduct = product;
    let { action } = props;
    if (action === "ADD") {
      setFlagFormInvalid(true);
      emptyProduct.category = props.categoryToRetain;
      emptyProduct.categoryId = props.categoryIdToRetain;
      emptyProduct.brand = props.brandToRetain;
      emptyProduct.brandId = props.brandIdToRetain;
      emptyProduct.unit = props.unitToRetain;
      emptyProduct.unitId = props.unitIdToRetain;
      setProduct(emptyProduct);
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      setFlagFormInvalid(false);
      setProduct(props.product);
    }
  }
  
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      let value;
      if (userInput) {
        value = 1;
      } else {
        value = 0;
      }
      setProduct({ ...product, [name]: value });
      return;
    }
    if (inputType == "file") {
      let file = event.target.files[0];
      if (!file) {
        return;
      }
      let fileType = file.type.substring(0, file.type.indexOf("/"));
      let errProduct = { ...errorProduct };
      let fileName = file.name;

      if (errorProduct[name]["imageType"] != fileType) {
        errorProduct[`${name}`].message = "Check type of file";
        setErrorProduct(errProduct);
      } else {
        errorProduct[`${name}`].message = "";
        setErrorProduct(errProduct);
        setProduct({ ...product, [name]: event.target.value });
        setFile(file);
        setFileName(fileName);
      }
      return;
    }
    setProduct({ ...product, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorProduct[`${name}`].mnLen;
      mxLen = errorProduct[`${name}`].mxLen;
      onlyDigits = errorProduct[`${name}`].onlyDigits;
      allDigits = errorProduct[`${name}`].allDigits;
      noSymbols = errorProduct[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      // else if (!allDigits) {
      //   for (i = value.length - 1, flag = false; i >= 0; i--) {
      //     const d = value.charCodeAt(i);
      //     if (d < 48 || d > 57) {
      //       message = "";
      //       flag = true;
      //       break;
      //     } //if
      //   } //for
      //   if (flag == false) {
      //     message = "Only digits are not allowed, use some alphabets also";
      //   }
      // } //else if... allDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errProduct = { ...errorProduct };
    errorProduct[`${name}`].message = message;
    setErrorProduct(errProduct);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorProduct) {
      if (errorProduct[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleRatingClick(value) {
    setProduct({ ...product, rating: value });
    // setState({
    //   product: { ...state.product, rating: value },
    // });
  }
  function handleSelectUnitChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedUnitId = optionElement.getAttribute("id");
    let unit = event.target.value;
    let unitId = selectedUnitId;
    setProduct({ ...product, unit: unit, unitId: unitId });
  }
  function handleSelectCategoryChange(event) {
    let index = event.target.selectedIndex; // get selected index, instead of selected value
    var optionElement = event.target.childNodes[index];
    var selectedCategoryId = optionElement.getAttribute("id");
    let category = event.target.value.trim();
    let categoryId = selectedCategoryId;
    setProduct({ ...product, category: category, categoryId: categoryId });
  }
  function handleSelectBrandChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedBrandId = optionElement.getAttribute("id");
    let brand = event.target.value.trim();
    let brandId = selectedBrandId;
    setProduct({ ...product, brand: brand, brandId: brandId });
  }

  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    //https://medium.com/@everdimension/how-to-handle-forms-with-just-react-ac066c48bd4f
    // Hushh... almost 3 days wasted for following single statement
    // got on above website
    // const formData = new FormData(event.target)
    // const body = {}
    // formData.forEach((value, property) => body[property] = value)
    //here you can update, remove, add values/properties in
    //the body object this is specially usefull if any custom process must be done to check, encrypt data or wherever you want.
    // data.append('categoryId',state.categoryId);
    // let category = state.product.category;
    // let categoryId = state.product.categoryId;
    // let brand = state.product.brand;
    // let brandId = state.product.brandId;
    // emptyProduct.category = category;
    // emptyProduct.categoryId = categoryId;
    // emptyProduct.brand = brand;
    // emptyProduct.brandId = brandId;
    // // TESTING
    // emptyProduct.name = state.product.name;
    props.onFormSubmit(product);
    // Following 2 required as those are select (i.e. list) components
    // setState({ product: emptyProduct });
    // Retain previous  category and brand
    // setState({ product: emptyProduct });
  }
  function handleCancelClick() {
    props.onCancelClick();
  }
  let { categoryList } = props;
  let { brandList } = props;
  let { action } = props;
  let { languageChoice } = props;
  let { colorScheme } = props;
  let heading;
  let unitList = [
    { unitId: 1, name: "Number" },
    { unitId: 2, name: "Multiple of 250 gm" },
    { unitId: 3, name: "Multiple of 1 kg" },
    { unitId: 4, name: "Multiple of 500 gm" },
    { unitId: 5, name: "10 kg and above" },
  ];
  if (action === "ADD") {
    heading = "Add a Product";
  } else if (action === "UPDATE") {
    heading = "Update the Product";
  }
  // prepare options
  let optionsUnit = unitList.map((unit, index) => (
    <option value={unit.name} key={index} id={unit.unitId}>
      {unit.name}
    </option>
  ));
  let optionsCategory = categoryList.map((category, index) =>
    category.rating != 1 ? (
      <option value={category.name} key={index} id={category.categoryId}>
        {category.name}
      </option>
    ) : null
  );
  let optionsBrand = brandList.map((brand, index) => (
    <option value={brand.name} key={index} id={brand.brandId}>
      {brand.name}
    </option>
  ));

  return (
    <>
      <div className="form-heading col-12">{heading}</div>
      <form
        className={"text-thick p-4 bg-my" + colorScheme}
        onSubmit={handleSubmit}
      >
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-4 text-right">
            <label>Product Name</label>
          </div>
          <div className="col-8 px-0">
            <input
              type="text"
              className="form-control"
              name="name"
              value={product.name}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Product Name"
              required
            />
          </div>
          <div className="offset-4">
            {errorProduct.name.message ? (
              <span className="error-text">{errorProduct.name.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        {languageChoice ? (
          <div className="form-group row  align-items-center">
            <div className="col-4 text-right">
              <label>Name in Regional lang.</label>
            </div>
            <div className="col-8  px-0">
              <input
                type="text"
                className="form-control"
                name="nameMarathi"
                value={product.nameMarathi}
                onChange={handleChange}
                placeholder="Product Name in regional language"
              />
            </div>
          </div>
        ) : null}
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-4 text-right">
            <label>Selling Unit </label>
          </div>
          <div className="col-8 px-0">
            <select
              className="form-control"
              name="unit"
              value={product.unit}
              onChange={handleSelectUnitChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            >
              <option> Select Unit </option>
              {optionsUnit}
            </select>
          </div>
          <div className="offset-5">
            {errorProduct.unit.message ? (
              <span className="error-text">{errorProduct.unit.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        {product.unit == "Number" && (
          <div className="form-group row  align-items-center">
            <div className="col-4 text-right">
              <label>Unit Information</label>
            </div>
            <div className="col-8 px-0">
              <input
                type="text"
                className="form-control"
                name="information"
                value={product.information}
                onChange={handleChange}
                placeholder="Like dozen, bunch, etc."
              />
            </div>
          </div>
        )}
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-4 text-right">
            <label>MRP {product.unitId <= 1 ? "":"(per kg)"}</label>
          </div>
          <div className="col-8 px-0 input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Rs.</span>
            </div>
            <input
              type="text"
              className="form-control"
              name="mrp"
              value={product.mrp}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          </div>
          <div className="offset-5">
            {errorProduct.mrp.message ? (
              <span className="error-text">{errorProduct.mrp.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-4 text-right">
          <label>Final Price {product.unitId <= 1 ? "":"(per kg)"}</label>
          </div>
          <div className="col-8 px-0 input-group ">
            <div className="input-group-prepend">
              <span className="input-group-text">Rs.</span>
            </div>
            <input
              type="text"
              className="form-control"
              name="finalPrice"
              value={product.finalPrice}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            />
          </div>
          <div className="offset-4 ">
            {errorProduct.finalPrice.message ? (
              <span className="error-text">
                {errorProduct.finalPrice.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-4 text-right ">
            <label className="form-check-label">In Stock ?</label>
          </div>
          <div className="col-8 px-0 text-left ">
            <input
              className={"form-control my" + colorScheme + "-dark"}
              type="checkbox"
              name="instock"
              value=""
              onChange={handleChange}
              checked={Boolean(product.instock)}
            />
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-4 text-right">
            <label>Rating</label>
          </div>
          <div className="col-8 px-0">
            {[...Array(10)].map((star, i) => {
              return (
                <label key={i + 1}>
                  <input
                    className="form-control-inline star-radio-buttons"
                    type="radio"
                    name="rating"
                    value={i + 1}
                    onClick={() => handleRatingClick(i + 1)}
                  />
                  <FaStar
                    className="star"
                    size={18}
                    color={i + 1 <= product.rating ? colorScheme : "grey"}
                  />
                </label>
              );
            })}
          </div>
        </div>
        {/* row ends */}

        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-4 text-right">
            <label>Category</label>
          </div>
          <div className="col-8 px-0">
            <select
              className="form-control"
              name="category"
              value={product.category}
              onChange={handleSelectCategoryChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            >
              <option> Select Category </option>
              {optionsCategory}
            </select>
          </div>
          {/* <div className="offset-5">
            {errorProduct.category.message ? (
              <span className="error-text">
                {errorProduct.category.message}
              </span>
            ) : null}
          </div> */}
        </div>
        {/* row ends */}
        {/* row starts */}
        {brandList.length != 0 && (
          <div className="form-group row  align-items-center">
            <div className="col-4 text-right">
              <label>Brand</label>
            </div>
            <div className="col-8 px-0">
              <select
                className="form-control"
                name="brand"
                value={product.brand}
                onChange={handleSelectBrandChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              >
                <option> Select Brand </option>
                {optionsBrand}
              </select>
            </div>
          </div>
        )}
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row align-items-center">
          <div className="col-4 text-right">
            <label>Search Words </label>
          </div>
          <div className="col-8 px-0">
            <textarea
              type="text"
              className="form-control"
              rows="2"
              cols="50"
              name="searchWords"
              value={product.searchWords}
              onChange={handleChange}
              placeholder="Enter words to search this product"
            />
          </div>
          {/* <div className="offset-5">
            {errorProduct.searchWords.message ? (
              <span className="error-text">
                {errorProduct.searchWords.message}
              </span>
            ) : null}
          </div> */}
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row offset-5 text-center mb-0">
          <button className={"btn-my" + colorScheme + "-dark"} type="submit">
            {action}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            className={"btn-my" + colorScheme + "-dark"}
            onClick={handleCancelClick}
          >
            CANCEL
          </button>
        </div>
        <input type="hidden" name="productId" value={product.productId} />
      </form>
    </>
  );
}
export default AdminProductAdd;
