export class DietDB {
  constructor(diets) {
    this.diets = diets;
  }
  allDiets() {
    return this.diets;
  }
  searchByDietId(id) {
    for (let i = 0; i < this.diets.length; i++) {
      if (this.diets[i].dietId == id) {
        return this.diets[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredDiets = [];
    for (let i = 0; i < this.diets.length; i++) {
      if (this.diets[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredDiets.push(this.diets[i]);
      }
    } //for
    return filteredDiets;
  }
}
export default DietDB;
