import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import QuickProduct from "./quick-product";
import { Product } from "../product-class";
import { useState } from "react";

function ProductsQuickDisplay(props) {
  
  let flagShowImages = true;
  let [flagLoad, setFlagLoad] = useState(false);
  let [flagOnlyAdded, setFlagOnlyAdded] = useState(false);
  let [flagOnlyFavourites, setFlagOnlyFavourites] = useState(false);
  let [favouriteList, setFavouriteList] = useState([]);
  useEffect(() => {
    //Runs only on the first render
    init();
  }, []);
  function getProductQuantity(id) {
    let i;
    for (i = 0; i < props.cartItems.length; i++) {
      if (id == props.cartItems[i].productId) {
        return props.cartItems[i].quantity;
      }
    }
    return 0;
  }
  function getProductFavouriteInfo(id) {
    let { userType } = props;
    if (userType === "GUEST") {
      // for Guest, no facility of favourite
      return false;
    }
    // let { favouriteList } = state;
    let i;
    for (i = 0; i < favouriteList.length; i++) {
      if (id === favouriteList[i]) {
        return true;
      } //if
    }
    return false;
  }

  function handleChangeProductQuantity(by, product) {
    props.onChangeProductQuantity(by, product);
  }
  function handleAddToCartItemList(product) {
    // Add this product to cart
    // this is original product obtained
    // copy it another product
    let multipleOf;
    if (product.unitId == 1) {
      // unit is numbers
      multipleOf = 1;
    } else if (product.unitId == 2) {
      // unit is 250 gms
      multipleOf = 0.25;
    } else if (product.unitId == 3) {
      // unit is kg
      multipleOf = 1;
    } else if (product.unitId == 4) {
      // unit is 500 gms
      multipleOf = 0.5;
    } else if (product.unitId == 5) {
      // unit is 10 kg and above
      multipleOf = 10; // start with 10kg only
    }

    let newProduct = new Product(
      product.productId,
      product.name,
      product.nameMarathi,
      product.information,
      product.mrp,
      product.finalPrice,
      multipleOf, //quantity,
      product.instock,
      product.unitId
    );
    // searchByProductId
    props.onAddToCartItemList(newProduct);
  }
  function handleFavouriteClick(productId, flagSetReset) {
    // Now this productId should be added to local storage
    // props.onFavouriteClick(productId);
    let { userType } = props;
    if (userType === "GUEST") {
      if (
        window.confirm("Guest is not allowed to mark a product as favourite.")
      );
      return false;
    }
    let { shopUrl } = props;
    let updatedFavouriteList = [...favouriteList];
    if (flagSetReset) updatedFavouriteList.push(productId);
    else {
      //remove from array
      for (var i = 0; i < updatedFavouriteList.length; i++) {
        if (updatedFavouriteList[i] === productId) {
          updatedFavouriteList.splice(i, 1);
          break;
        } //if
      } //for
    } //else
    window.localStorage.setItem(
      "favourite_" + shopUrl,
      JSON.stringify(favouriteList)
    );
    setFavouriteList(updatedFavouriteList);
    return true;
  }

  // componentDidMount = () => {
  function init() {
    setFlagLoad(true);
    // doInitialCalculation();
    // The user is regular user
    // So, get his/her favourites
    // if not create in local storage
    let { userType } = props;
    if (userType === "GUEST") {
      setFlagLoad(false);
      // no need to create favourite list in local storage
      return;
    }
    setFlagLoad(true);
    let { shopUrl } = props;
    let favouriteInfo = window.localStorage.getItem("favourite_" + shopUrl);
    if (!favouriteInfo) {
      // no favourites available, so create the one (ofcourse empty one)
      window.localStorage.setItem("favourite_" + shopUrl, JSON.stringify([]));
    } //if
    // First get data from favourite_shopUrl from local storage
    let favouriteList = JSON.parse(
      window.localStorage.getItem("favourite_" + shopUrl)
    );
    setFlagLoad(false);
    setFavouriteList(favouriteList);
  } //componentDidMount
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleOnlyFavouritesClick() {
    setFlagOnlyFavourites(!flagOnlyFavourites);
  }
  function handleOnlyAddedClick() {
    setFlagOnlyAdded(!flagOnlyAdded);
  }
  let {colorScheme}=props;
  
  let { selectedCategoryName } = props;
  let { selectedBrandName } = props;
  let { specialOffersList } = props;
  let { searchBy } = props;
  let { matchedFlag } = props;
  let { filteredProducts } = props;
  let { flagSearch } = props;
  let { flagCategoryLinkClick } = props;
  let { flagBrandLinkClick } = props;
  let { flagQuickMode } = props;
  let heading;
  let { productsPhoto } = props;
  let { informationPlace } = props;
  let { languageChoice } = props;
  let { cartItems } = props;

  let b;
  let { shopTypeId } = props;
  let  classRow;
  let btnClassList = "btn-my"+colorScheme;
  classRow = "row p-1 mb-4 justify-content-center border-all-my"+colorScheme;
  let repeated_products_display = (
    <div className="row">
      {filteredProducts.map((product, index) => (
        <QuickProduct
          product={product}
          productQty={getProductQuantity(product.productId)}
          productFavorite={getProductFavouriteInfo(product.productId)}
          onChangeProductQuantity={handleChangeProductQuantity}
          onAddToCartItemList={handleAddToCartItemList}
          flagShowImages={flagShowImages}
          key={product.productId}
          productsPhoto={productsPhoto}
          informationPlace={informationPlace}
          languageChoice={languageChoice}
          shopTypeId={props.shopTypeId}
          flagQuickMode={flagQuickMode}
          onFavouriteClick={handleFavouriteClick}
          flagOnlyFavourites={flagOnlyFavourites}
          flagOnlyAdded={flagOnlyAdded}
          // dummyPrice={props.dummyPrice}
          colorScheme={colorScheme}
        />
      ))}
    </div>
  );
  if (searchBy === "DEFAULT") {
    heading = "Total " + filteredProducts.length + " products";
  } else if (searchBy === "CATEGORY") {
    heading =
      // "Selected Category : " +
      selectedCategoryName + " (" + filteredProducts.length + ")";
  } else if (searchBy === "BRAND") {
    heading =
      // "Selected Brand : " +
      selectedBrandName + " (" + filteredProducts.length + ")";
  } else if (searchBy === "TYPING" && matchedFlag === true) {
    heading = "Total " + filteredProducts.length + " matched products";
  } else if (searchBy === "TYPING" && matchedFlag === false) {
    heading = "No matched product found";
    if (filteredProducts.length > 0) {
      heading =
        heading + " Similar products: (" + filteredProducts.length + ")";
    }
  }
  if (flagLoad)
    return (
      <div className="text-center mt-5">
        <BeatLoader size={16} color={colorScheme} flagLoad />
      </div>
    );
  return (
    <React.Fragment>
      <div className="container-fluid container-md container-a-quick-products column">
        {/* filter options start */}

        <div className={classRow}>
          {searchBy === "DEFAULT" &&
          !flagSearch &&
          !flagCategoryLinkClick &&
          !flagBrandLinkClick ? (
            <React.Fragment>
              <div className="col-3 mb-2 text-thick p-0 column">FILTER</div>
              <div className="col-5 p-0 column">
                <input
                  type="checkbox"
                  className="form-check-input form-control-inline"
                  checked={flagOnlyFavourites}
                  onChange={handleOnlyFavouritesClick}
                />
                Favourites ({favouriteList.length})
              </div>
              <div className="col-3 p-0 column">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={flagOnlyAdded}
                  onChange={handleOnlyAddedClick}
                />{" "}
                Added ({cartItems.length})
              </div>
            </React.Fragment>
          ) : null}
          {!flagSearch && !flagCategoryLinkClick && !flagBrandLinkClick ? (
            <React.Fragment>
              <div className="col-3 text-thick column">ARRANGE</div>
              <div className="col-3">
                <Link to="#" onClick={handleHeaderClick} id="name">
                  Namewise
                </Link>
              </div>
              <div className="col-3">
                <Link to="#" onClick={handleHeaderClick} id="finalPrice">
                  Pricewise
                </Link>
              </div>
              <div className="col-3">
                <Link to="#" onClick={handleHeaderClick} id="instock">
                  Stockwise
                </Link>
              </div>
            </React.Fragment>
          ) : null}
        </div>

        {/* filter options end */}
        {searchBy !== "SPECIAL" &&
          !flagSearch &&
          !flagCategoryLinkClick &&
          !flagBrandLinkClick && (
            // flagSearch is true when user types something in the search text box
            <React.Fragment>{repeated_products_display}</React.Fragment>
          )}
        {/* </div> */}
        {/* row ends */}
        {searchBy === "SPECIAL" && specialOffersList.length != 0 && (
          <div className="row justify-content-center ">
            {specialOffersList.map((specialOffer, index) => (
              <React.Fragment key={index}>
                <div className="col-6 text-center bg-mygray bigger-text mb-3">
                  <div className="text-center bg-mygrey text-thick mb-3">
                    {specialOffer.name}
                  </div>
                </div>
                <div className="col-10 bigger-text card-special-offer mb-3">
                  {specialOffer.description}
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
        {searchBy === "SPECIAL" && specialOffersList.length == 0 && (
          <div className="row justify-content-center ">
            <div>No Special offers yet.</div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
export default ProductsQuickDisplay;
