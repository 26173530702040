import React from "react";
import { Link } from "react-router-dom";
function SearchList(props) {
  function handleSearchBoxProductClick(event) {
    let productId = event.target.id;
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onSearchBoxProductClick(productId);
  }
  function productPrice(product) {
    let unit = "";
    if (product.unitId != 1) {
      unit = "/kg";
    }
    return "--- Rs. " + product.finalPrice + unit;
  }
  let { searchText } = props;
  let { prdb } = props;
  // let searchedProducts = prdb.filterByNameAndSearchWords(searchText); // 2023 tried
  let searchedProducts = prdb.filterBySmartWay(searchText); // 2023 triedfilterBySmartWay(query)
  // let searchedProducts = prdb.filterByFirstNameThenSearchWords(searchText); // 2023 added
  // let searchedProducts = prdb.filterByName(searchText);  2023  //removed

  return (
    <>
      {searchedProducts.map((product, index) => (
        <Link
          className="btn btn-block border-bottom text-thick text-left pl-5"
          to="#"
          onClick={handleSearchBoxProductClick}
          id={product.productId}
          key={index}
        >
          {product.name} {product.information ? product.information : null}{" "}
          {productPrice(product)}
        </Link>
      ))}
    </>
  );
}

export default SearchList;
