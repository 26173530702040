import React from "react";

class AdminMakeYaadiCustomerForm extends React.Component {
  errorCustomerInfo = {
    name: { message: "", mxLen: 30, mnLen: 6, onlyDigits: false },
    whatsappNumber: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    address: { message: "", mxLen: 50, mnLen: 10, onlyDigits: false },
  };
  constructor(props) {
    super(props);
    let customerInfo;

    let flagFormInvalid;
    let flagNewCustomer;

    customerInfo = {
      name: "",
      whatsappNumber: "",
      address: "",
    };
    flagNewCustomer = true;

    if (flagNewCustomer === true) {
      flagFormInvalid = true; // disable submit button
    } else {
      flagFormInvalid = false;
    }
    this.state = {
      customerInfo: customerInfo,
      errorCustomerInfo: this.errorCustomerInfo,
      flagFormInvalid: flagFormInvalid,
      flagNewCustomer: flagNewCustomer,
      customerSelection: 0,
      flagFormFocus: false,
      flagLoading: false,
    };
  }
  handleChange = (event) => {
    let { errorCustomerInfo } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        customerInfo: { ...this.state.customerInfo, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorCustomerInfo[`${name}`].mxLen;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      message = "Maximum " + mxLen + " characters allowed";
    } //if...
    else {
      message = "";
    }
    errorCustomerInfo[`${name}`].message = message;
    this.setState({
      errorCustomerInfo: errorCustomerInfo,
      customerInfo: { ...this.state.customerInfo, [name]: value },
    });
    this.checkAllErrors(errorCustomerInfo);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorCustomerInfo } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorCustomerInfo[`${name}`].mnLen;
      onlyDigits = errorCustomerInfo[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        message = "Min " + mnLen + " characters required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    errorCustomerInfo[`${name}`].message = message;
    this.setState({ errorCustomerInfo: errorCustomerInfo });
    this.checkAllErrors(errorCustomerInfo);
  };
  handleFocus = (event) => {
    let name = event.target.name;

    let errorCustomerInfo = this.state.errorCustomerInfo;
    errorCustomerInfo[`${name}`].message = "";
    this.setState({
      errorCustomerInfo: errorCustomerInfo,
      flagFormFocus: true,
    });
    this.checkAllErrors(errorCustomerInfo);
  };
  checkAllErrors = (errorCustomerInfo) => {
    //let { errorPersonalInfo } = this.state;
    let flagFormInvalid = false;
    for (let field in errorCustomerInfo) {
      if (errorCustomerInfo[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  runCaptcha = () => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(
          "https://www.google.com/recaptcha/api.js?render=6Le027sZAAAAAFl63owUIeKcCXIwGo8iQXD4ioEb",
          { action: "homepage" }
        )
        .then(function (token) {
          this.captcha = token;
        });
    });
  };
  componentDidMount = () => {};
  handleCustomerInfoSubmit = (event) => {
    event.preventDefault();
    // this.state.customer.shopUrl = shopDetails.shopUrl;
    // this.onFormSubmit(this.state.customer);
  };
  handleCustomerSelection = (value) => {
    // this.getDataFromDatabase(value); // value is whichDate (today/yesterday)
    this.setState({
      customerSelection: value,
    });
  };
  render() {
    // let { customerInfo } = this.state;
    let { customerSelection } = this.state;
    let { errorCustomerInfo } = this.state;
    let { flagFormInvalid } = this.state;
    let { flagFormFocus } = this.state;
    let { flagLoading } = this.state;
    let { flagCustomerSelected } = this.props;
    let customerInfo;
    if(flagCustomerSelected)
    {
        customerInfo=this.props.customerInfo;
    }
    else
    {
        customerInfo=this.state.customerInfo;
    }

    // prepare options
    let classList = "";
    if (flagFormFocus) {
      classList = "d-none d-md-block";
    }
    return (
      <React.Fragment>
        <div className="container-fluid container-md container-add-form">
          {/* row starts */}
          <div className="form-customer-entry">
            <form
              className="text-thick"
              onSubmit={this.handleCustomerInfoSubmit}
            >
              <div className="text-thick my-2 px-4 py-4 bg-mycyan">
                <div className="form-group row  align-items-center px-2">
                  {/* row starts */}
                  <div className="col-5 text-right">
                    <label>Name</label>
                  </div>
                  <div className="col-7  px-0">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={customerInfo.name}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      onFocus={this.handleFocus}
                      placeholder="Your Full Name"
                      required
                    />
                  </div>
                  <div className="offset-5">
                    {errorCustomerInfo.name.message ? (
                      <span className="error-text">
                        {errorCustomerInfo.name.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/* row ends */}
                {/* row starts */}
                <div className="form-group row  align-items-center px-2">
                  <div className="col-5 text-right">
                    <label>WhatsApp No.</label>
                  </div>
                  <div className="col-7 px-0">
                    <input
                      type="text"
                      className="form-control"
                      name="whatsappNumber"
                      value={customerInfo.whatsappNumber}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      onFocus={this.handleFocus}
                      placeholder="WhatsApp No."
                    />
                  </div>
                  <div className="offset-5">
                    {errorCustomerInfo.whatsappNumber.message ? (
                      <span className="error-text">
                        {errorCustomerInfo.whatsappNumber.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/* row starts */}
                <div className="form-group row  align-items-center">
                  <div className="col-5 text-right">
                    <label>Address</label>
                  </div>
                  <div className="col-7 px-0">
                    <textarea
                      type="text"
                      className="form-control"
                      rows="3"
                      cols="50"
                      name="address"
                      value={customerInfo.address}
                      onChange={this.handleChange}
                      onBlur={this.handleBlur}
                      onFocus={this.handleFocus}
                      placeholder="Enter Flat No., Building Name"
                    />
                  </div>
                  <div className="offset-5">
                    {errorCustomerInfo.address.message ? (
                      <span className="error-text">
                        {errorCustomerInfo.address.message}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/* row ends */}
                {/* row starts */}
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AdminMakeYaadiCustomerForm;
