import React, {  useEffect } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import AProduct from "./a-product";
import { Product } from "../product-class";
import { useState } from "react";

function ProductsDisplay(props) {
  let batch = 12,
    cycles,
    count,
    flagShowImages = true;
  // classList = [];
  // showMoreButtonList = [];
  // let cycles = 0;
  // let flagShowImages = true, // controls showing image
  // this.numberOfProducts = this.props.filteredProducts.length;
  // this.state = {
  //   flagLoading: true,
  // };

  function handleShowAllClick() {
    props.onShowAllClick();
  }
  let [flagLoading, setFlagLoading] = useState(false);
  function getProductQuantity(id) {
    // This is very important function
    // Quantity is not stored in the product object obtained from database(mysql)
    // we are creating a local product object for the "added-to-yaadi" product
    // it contains the quantity
    // This product object is then pushed to local storage (cartItems contains
    // copy of this local storage in memory)
    // See handleAddToCartItemList function, how this product is added
    // On similar way we can work out on favourite items
    let i;
    for (i = 0; i < props.cartItems.length; i++) {
      if (id == props.cartItems[i].productId) {
        return props.cartItems[i].quantity;
      }
    }
    return 0;
  }
  function handleChangeProductQuantity(by, product) {
    props.onChangeProductQuantity(by, product);
  }
  function handleAddToCartItemList(product) {
    // Add this product to cart
    // this is original product obtained
    // copy it another product
    let multipleOf;
    if (product.unitId == 1) {
      // unit is numbers
      multipleOf = 1;
    } else if (product.unitId == 2) {
      // unit is 250 gms
      multipleOf = 0.25;
    } else if (product.unitId == 3) {
      // unit is kg
      multipleOf = 1;
    } else if (product.unitId == 4) {
      // unit is 500 gms
      multipleOf = 0.5;
    } else if (product.unitId == 5) {
      // unit is 10 kg and above
      multipleOf = 10; // start with 10kg only
    }
    let newProduct = new Product(
      product.productId,
      product.name,
      product.nameMarathi,
      product.information,
      product.mrp,
      product.finalPrice,
      multipleOf, //stored as quantity, see the constructor
      product.instock,
      product.unitId
    );
    props.onAddToCartItemList(newProduct);
  }
  useEffect(() => {
    //Runs only on the first render
    init();
  }, []);
  function init() {
    setFlagLoading(true);
    setFlagLoading(false);
  } //componentDidMount

  if (flagLoading)
    return (
      <div className="text-center mt-5">
        <BeatLoader size={16} color={props.colorScheme} flagLoading />
      </div>
    );
  let { selectedCategoryName } = props;
  let { selectedBrandName } = props;
  let { specialOffersList } = props;
  let { searchBy } = props;
  let { matchedFlag } = props;
  let { filteredProducts } = props;
  let { flagSearch } = props;
  let { flagCategoryLinkClick } = props;
  let { flagBrandLinkClick } = props;
  let { flagQuickMode } = props;
  let { colorScheme } = props;
  
  let heading;
  let { productsPhoto } = props;
  let { informationPlace } = props;
  let { languageChoice } = props;
  let b;
  let { shopTypeId } = props;
  let btnClassList;
  if (shopTypeId == 2) {
    // vegetable shop
    btnClassList = "btn-mygreen";
  } else if (shopTypeId == 5) {
    // grocery shop
    btnClassList = "btn-mycyan";
  } else if (shopTypeId == 6) {
    // dryfruit store
    btnClassList = "btn-mybrown";
  } else if (shopTypeId == 8) {
    // restaurant
    btnClassList = "btn-mypurple";
  }
  if (searchBy === "DEFAULT") b = batch;
  else {
    b = filteredProducts.length;
    count = 0;
  }
  let repeated_products_display = (
    <>
      <div className="row">
        {filteredProducts.map((product, index) => (
          <AProduct
            product={product}
            productQty={getProductQuantity(product.productId)}
            onChangeProductQuantity={handleChangeProductQuantity}
            onAddToCartItemList={handleAddToCartItemList}
            flagShowImages={flagShowImages}
            key={product.productId}
            productsPhoto={productsPhoto}
            informationPlace={informationPlace}
            languageChoice={languageChoice}
            shopTypeId={props.shopTypeId}
            // dummyPrice={props.dummyPrice}
            flagQuickMode={flagQuickMode}
            colorScheme={colorScheme}
          />
        ))}
      </div>
    </>
  );
  if (searchBy === "DEFAULT") {
    heading = "Total " + filteredProducts.length + " products";
  } else if (searchBy === "CATEGORY") {
    heading =
      // "Selected Category : " +
      selectedCategoryName + " (" + filteredProducts.length + ")";
  } else if (searchBy === "BRAND") {
    heading =
      // "Selected Brand : " +
      selectedBrandName + " (" + filteredProducts.length + ")";
  } else if (searchBy === "TYPING" && matchedFlag === true) {
    heading = "Total " + filteredProducts.length + " matched products";
  } else if (searchBy === "TYPING" && matchedFlag === false) {
    heading = "No matched product found";
    if (filteredProducts.length > 0) {
      heading =
        heading + " Similar products: (" + filteredProducts.length + ")";
    }
  }
  return (
    <>
      <div className="container-fluid container-md container-a-products column">
        <div className="row align-items-center column">
          {/* row begins */}
          <div className="info-selected-category column col-8 mb-0"></div>
          {searchBy === "CART" && (
            // Link can be styled as Button
            <Link to="/check-out" className="btn btn-dark">
              Checkout
            </Link>
          )}
          {/* <div className="show-image">
              <input
                type="checkbox"
                name="showImages"
                id="flagShowImages"
                value=""
                checked={this.state.flagShowImages}
                onChange={this.handleShowImagesChange}
              />
              Show Images of products
            // </div> */}
        </div>
        {/* row ends */}
        {/* <div className="row align-items-center column"> */}
        {/* <div> */}
        {/* row begins */}
        {searchBy !== "SPECIAL" &&
          !flagSearch &&
          !flagCategoryLinkClick &&
          !flagBrandLinkClick && (
            // true && (
            <React.Fragment>{repeated_products_display}</React.Fragment>
          )}
        {/* </div> */}
        {/* row ends */}
        {searchBy === "SPECIAL" && specialOffersList.length != 0 && (
          <div className="row justify-content-center ">
            {specialOffersList.map((specialOffer, index) => (
              <div key={index}>
                <div className="col-6 text-center bg-mygray bigger-text mb-3">
                  <div className="text-center bg-mygrey text-thick mb-3">
                    {specialOffer.name}
                  </div>
                </div>
                <div className="col-10 bigger-text card-special-offer mb-3">
                  {specialOffer.description}
                </div>
              </div>
            ))}
          </div>
        )}
        {searchBy === "SPECIAL" && specialOffersList.length == 0 && (
          <div className="row justify-content-center ">
            <div>No Special offers yet.</div>
          </div>
        )}
      </div>
    </>
  );
}
export default ProductsDisplay;