import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
function AdminReportSummaryListSingle(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  function handleDeleteYaadi(yaadi) {
    props.onDeleteYaadi(yaadi);
  }
  function handleEditYaadi(yaadi) {
    props.onEditYaadi(yaadi);
  }

  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  function openWhatsApp(customerWhatsApp) {
    customerWhatsApp = "91" + customerWhatsApp;
    let whatsAppMessage = "Received your order, Thanks";
    window.location = `https://wa.me/${customerWhatsApp}?text=${whatsAppMessage}`;
  }
  function handleSingleYaadiDownloadClick(event) {
    props.onSingleYaadiDownloadClick(event);
  }
  function handleCustomerAdd() {
    // event.preventDefault();
    // Get data of customer from this yaadi and add to database now
    let { yaadi } = props;
    let customer = new Object();
    customer.name = yaadi.customerName;
    customer.whatsApp = yaadi.customerWhatsApp;
    customer.address = yaadi.customerAddress;
    setFlagLoad(true);
    axios
      .post(
        window.routerPrefix + "/customer/addCustomer/" + props.shopUrl,
        customer,
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        }
      )
      .then((res) => {
        props.addToCustomerList(customer); // Add again
        setFlagLoad(false);
      })
      .catch((err) => {
        console.log("error!");
        setFlagLoad(false);
      });
  }
  let { exists } = props;
  let { sectionNumber } = props;
  let { flagDisplay } = props;
  let { yaadi } = props;
  let { colorScheme } = props;

  let content = (
    <>
      {/* row starts */}
      {flagDisplay && (
        <>
          <tr className="myborder-top">
            <td>{yaadi.yaadiId}</td>
            <td>
              {yaadi.customerName}
              <Link to="#" onClick={() => openWhatsApp(yaadi.customerWhatsApp)}>
                <span className="smaller-text ">
                  {" "}
                  ({yaadi.customerWhatsApp})
                </span>
              </Link>
              <span className="smaller-text text-danger"> {yaadi.dealer}</span>
            </td>
            {sectionNumber == 1 && (
              <td>
                <i className="fas fa-rupee-sign"></i>{" "}
                {yaadi.totalAmount.toFixed(2)}
              </td>
            )}
            {sectionNumber == 2 && <td>{yaadi.customerAddress}</td>}
          </tr>
          <tr>
            <td>
              {!exists ? (
                <Link to="#" onClick={handleCustomerAdd}>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </Link>
              ) : null}
              {flagLoad ? (
                <span className="smaller-text thick-red-text">Adding..</span>
              ) : (
                ""
              )}
            </td>
            <td>
              <Link
                to="#"
                onClick={() => {
                  if (
                    window.confirm(
                      "You really want to delete Yaadi No. " +
                        yaadi.yaadiId +
                        " of " +
                        yaadi.customerName +
                        " of " +
                        yaadi.totalAmount.toFixed(2) +
                        " amount ?"
                    )
                  )
                    handleDeleteYaadi(yaadi);
                }}
              >
                {/* Delete */}
                <i className="far fa-trash-alt admin-icons"></i>
              </Link>
              &nbsp;&nbsp;&nbsp;
              {/* <Link to="#" 
                  onClick={() => {this.handleEditYaadi(yaadi)}}>
                  <i className="fas fa-edit admin-icons"></i>
                </Link> */}
            </td>
            <td>
              <button
                className={"btn-my" + colorScheme}
                onClick={handleSingleYaadiDownloadClick}
                id={yaadi.yaadiId}
              >
                Download
              </button>
            </td>
          </tr>
        </>
      )}
    </>
  );
  if (error) {
    return <div>Error retriving Yaadies </div>;
  } else {
    return content;
  } //else
}
export default AdminReportSummaryListSingle;
