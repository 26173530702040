export class CategoryDB {
  constructor(categories) {
    this.categories = categories;
  }
  allCategories() {
    return this.categories;
  }
  searchByCategoryId(id) {
    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i].categoryId == id) {
        return this.categories[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredCategories = [];
    for (let i = 0; i < this.categories.length; i++) {
      if (this.categories[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredCategories.push(this.categories[i]);
      }
    } //for
    return filteredCategories;
  }
}
export default CategoryDB;
