import React, { Component } from "react";
import "./css/login.css";
function Login() {
  function handleUserLogIn() {
    this.props.onUserLogIn();
  }
  function handleAdminLogIn() {
    this.props.onAdminLogIn();
    // change content in the menubars in the navbar
  }
  return (
    <div className="container-login">
      <button
        className="btn-mycyan login-buttons"
        onClick={this.handleUserLogIn}
      >
        Click to User Login
      </button>
      <button className="btn-mycyan" onClick={this.handleAdminLogIn}>
        Click to Admin Login
      </button>
    </div>
  );
}
export default Login;