import React, { useEffect, useState } from "react";
import AdminCustomerList from "./admin-customer-list";
import AdminCustomerAdd from "./admin-customer-add";
import { BeatLoader } from "react-spinners";
import { CustomerDB } from "../customerDB";
import axios from "axios";

function AdminCustomer(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [filteredCustomers, setFilteredCustomers] = useState([]);
  let [customerList, setCustomerList] = useState([]);
  let [customerdb, setCustomerdb] = useState([]);
  let [customer, setCustomer] = useState(null);
  let [sectionNumber, setSectionNumber] = useState("");
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  useEffect(() => {
    window.scroll(0, 0);
    getCustomerListFromDatabase();
  }, []);
  let { shopUrl } = props;
  function getCustomerListFromDatabase() {
    setFlagLoad(true);
    axios
      .get(window.routerPrefix + "/customer/customers/" + shopUrl)
      .then((res) => {
        let customerList = res.data;
        setFlagLoad(false);
        setFilteredCustomers(customerList);
        setCustomerList(customerList);
        setCustomerdb(new CustomerDB(customerList));
      })
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  }
  function handleDownloadClick() {
    JSONToCSVConvertor(filteredCustomers, "", true);
  }
  function JSONToCSVConvertor(filteredCustomers, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof filteredCustomers != "object"
        ? JSON.parse(filteredCustomers)
        : filteredCustomers;
    var arrData = filteredCustomers;
    //Set Report title in first row or line
    CSV += ReportTitle + "\r\n\n";
    //This condition will generate the Label/Header
    let headers = ["Name", "Address", "WhatsApp No."];
    if (ShowLabel) {
      var row = "";
      var CSV = "";
      //This loop will extract the label from 1st index of on array
      for (let i = 0; i < headers.length; i++) {
        //Now convert each value to string and comma-seprated

        row += headers[i] + ",";
      }
      // row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }
    //1st loop is to extract each row
    let data;
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        data = arrData[i][index];
        if (
          index === "customerId" ||
          index === "dateAdded" ||
          index === "dateModified"
        ) {
          continue;
        }
        row += '"' + data + '",';
      } //for
      CSV += row + "\r\n";
    }
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    let fileName = "Customers  ";
    //  + ".xls";
    //this will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedCustomers = [];
    searchedCustomers = customerdb.filterByName(query);
    setFilteredCustomers(searchedCustomers);
  }
  function handleCancelClick() {
    props.onCancelClick();
  }
  function addToCustomerList(customer) {
    let cList = [...customerList];
    cList.unshift(customer);
    let fCustomers = [...filteredCustomers];
    fCustomers.unshift(customer);
    setFilteredCustomers(fCustomers);
    setCustomerList(cList);
  }
  function updateCustomerList(customer) {
    let cList = [...customerList];
    for (let i = 0; i < cList.length; i++) {
      if (cList[i].customerId == customer.customerId) {
        cList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        cList.unshift(customer);
        break;
      }
    } //for
    // remove from filteredchapters also
    let fCustomers = [...filteredCustomers];
    for (let i = 0; i < fCustomers.length; i++) {
      if (fCustomers[i].customerId == customer.customerId) {
        fCustomers.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        fCustomers.unshift(customer);
        break;
      }
    } //for
    //sorting
    setFilteredCustomers(fCustomers);
    setCustomerList(cList);
  }
  function handleDeleteCustomer(customer, result) {
    let message;
    if (result == 0) {
      //Error... deleting customer
      message = "Error....could not delete the customer";
      props.onCrudAction(message, "LIST");
      return;
    }
    let cList = [...customerList];
    for (let i = 0; i < cList.length; i++) {
      if (cList[i].customerId == customer.customerId) {
        cList.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    // remove from filteredcustomers also
    let fCustomers = [...filteredCustomers];
    for (let i = 0; i < fCustomers.length; i++) {
      if (fCustomers[i].customerId == customer.customerId) {
        fCustomers.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    if (fCustomers.length == 0 && searchText != "") {
      // only one entity was searched and deleted, so search text should be removed, and all products should be shown
      setSearchText("");
      setFilteredCustomers(cList);
    } else {
      setFilteredCustomers(fCustomers);
    }
    setCustomerList(cList);
    setCustomerdb(new CustomerDB(cList));
    message = customer.name + " deleted successfully";
    props.onCrudAction(message, "LIST");
  }
  function onFormSubmit(customer) {
    let message;
    let { action } = props;
    let { shopUrl } = props;
    setFlagLoad(true);
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/customer/update/" + shopUrl, customer)
        .then((res) => {
          setFlagLoad(false);
          if (res.data == 0) {
            // error updating customer
            message = "Error... could not update customer.";
          } else {
            updateCustomerList(customer);
            let message =
              "The customer '" + customer.name + "' updated successfully.";
            props.onCrudAction(message, "LIST", "Add a Customer");
          }
          // getCustomerListFromDatabase();
        })
        .catch((err) => {
          setFlagLoad(false);
          setError(err);
        });
    } else if (action === "ADD") {
      //ADD / POST
      setFlagLoad(true);
      axios
        .post(
          window.routerPrefix + "/customer/addCustomer/" + props.shopUrl,
          customer,
          {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
            },
          }
        )
        .then((res) => {
          setFlagLoad(false);
          if (res.data == 0) {
            message = "Something went wrong...";
            props.onCrudAction(message, "ADD"); // Add again
          } else {
            customer.customerId = res.data; // id of the entity is returned
            addToCustomerList(customer);
            message = "Customer " + customer.name + " added successfully.";
            props.onCrudAction(message, "ADD"); // Add again
          }
          // getCustomerListFromDatabase();
        })
        .catch((err) => {
          message = "Error... could not add customer.";
          props.onCrudAction(message, "ADD"); // Add again
          setFlagLoad(false);
          setError(err);
        });
    } //else
  }
  function handleEditCustomer(customer) {
    setCustomer(customer);
    setSearchText("");
    props.onEditClick();
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    let fCustomers = [...filteredCustomers];
    if (d == false) {
      //in ascending order
      fCustomers = fCustomers.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      fCustomers = fCustomers.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredCustomers(fCustomers);
    setSortedField(field);
  }
  let { action } = props;
  let content;
  let { colorScheme } = props;
  let { subject } = props;

  let versionTypeId = props.shopDetails.versionTypeId;
  let downloadPanel = (
    <div className="container-fluid container-md fixed-bottom py-2 bg-mycyan text-center text-white">
      {/* <div className="container-fluid homepage-contact-details text-white text-center py-0"> */}
      <div className="col-10 col-md-2 mx-auto  p-0">
        <button className="btn-mycyan" onClick={handleDownloadClick}>
          Download Customer Information
        </button>
      </div>
    </div>
  );
  if (versionTypeId == 1) {
    content = (
      <div className="bigger-text text-thick text-center">
        This feature is available in Premium version !
      </div>
    );
  } //if
  else if (flagLoad)
    content = (
      <div className="text-center">
        <BeatLoader size={16} color={colorScheme} flagLoad />
      </div>
    );
  else if (action === "LIST") {
    // First show list
    content = (
      <AdminCustomerList
        onEditCustomer={handleEditCustomer}
        onDeleteCustomer={handleDeleteCustomer}
        onSectionNumberClick={handleSectionNumberClick}
        sectionNumber={sectionNumber}
        onSearchTextChange={handleSearchTextChange}
        filteredCustomers={filteredCustomers}
        searchText={searchText}
        subject={subject}
        onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
        onHeaderClick={handleHeaderClick}
        colorScheme={colorScheme}
        shopUrl={shopUrl}
        currentSize={customerList.length}
      />
    );
  } else if (action === "ADD" || action === "UPDATE") {
    // After pressing Add Button, show only form and not the list
    content = (
      <div>
        {/* content={" "} */}
        <AdminCustomerAdd
          onFormSubmit={onFormSubmit}
          onCancelClick={handleCancelClick}
          customer={customer}
          action={action}
          colorScheme={colorScheme}
        />
      </div>
    );
  } else if (action === "DELETE") {
    content = <div>Delete action in Progress.</div>;
  }
  return (
    <div className="container-fluid container-md container-list-table">
      {content}
      {versionTypeId > 1 && action === "LIST" ? downloadPanel : null}
    </div>
  );
}
export default AdminCustomer;
