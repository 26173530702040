export class YaadiDB {
  constructor(yaadies) {
    this.yaadies = yaadies;
    this.size = this.yaadies.length; //(Used function here, but gave problems)
  }
  allYaadies() {
    return this.yaadies;
  }
  numberOfYaadies() {
    return this.yaadies.length;
  }
  searchByYaadiId(id) {
    for (let i = 0; i < this.yaadies.length; i++) {
      if (this.yaadies[i].yaadiId == id) {
        return this.yaadies[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredYaadies = [];
    for (let i = 0; i < this.yaadies.length; i++) {
      if (
        this.yaadies[i].customerName.toLowerCase().includes(query.toLowerCase())
      ) {
        filteredYaadies.push(this.yaadies[i]);
      }
    } //for
    return filteredYaadies;
  }
  filterByDate(query) {
    let filteredYaadies = [];
    let dt = new Date();
    if (query === "yesterday") {
      dt.setDate(dt.getDate() - 1);
    }
    for (let i = 0; i < this.yaadies.length; i++) {
      if (new Date(this.yaadies[i].dateOfYaadi).getDate() === dt.getDate()) {
        filteredYaadies.push(this.yaadies[i]);
      }
    } //for
    return filteredYaadies;
  }
}
export default YaadiDB;
