import React, { Component } from "react";
import AdminDealerList from "./admin-dealer-list";
import AdminDealerAdd from "./admin-dealer-add";
import { BeatLoader } from "react-spinners";
import { DealerDB } from "../DealerDB";
import axios from "axios";

class AdminDealer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      dealer: null,
      filteredDealers: [],
      dealerdb: {},
      searchText: "",
      flagLoading: false,
      sectionNumber: 1,
      sortedField: "",
      sortedDirection: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getDealerListFromDatabase = () => {
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/dealers/" + shopUrl)
      .then((res1) => {
        let dealerList = res1.data;
        this.setState({
          filteredDealers: dealerList,
          dealerdb: new DealerDB(res1.data),
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    this.getDealerListFromDatabase();
  }
  handleDownloadClick = () => {
    this.JSONToCSVConvertor(this.state.filteredDealers, "", true);
  };
  JSONToCSVConvertor = (filteredDealers, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof filteredDealers != "object"
        ? JSON.parse(filteredDealers)
        : filteredDealers;
    var arrData = filteredDealers;
    //Set Report title in first row or line
    CSV += ReportTitle + "\r\n\n";
    //This condition will generate the Label/Header
    let headers = ["Name", "Address", "WhatsApp No."];
    if (ShowLabel) {
      var row = "";
      var CSV = "";
      //This loop will extract the label from 1st index of on array
      for (let i = 0; i < headers.length; i++) {
        //Now convert each value to string and comma-seprated

        row += headers[i] + ",";
      }
      // row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }
    //1st loop is to extract each row
    let data;
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        data = arrData[i][index];
        if (
          index === "dealerId" ||
          index === "dateAdded" ||
          index === "dateModified"
        ) {
          continue;
        }
        row += '"' + data + '",';
      } //for
      CSV += row + "\r\n";
    }
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    let fileName = "Dealers  ";
    //  + ".xls";
    //this will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedDealers = [];
    searchedDealers = this.state.dealerdb.filterByName(query);
    this.setState({
      filteredDealers: searchedDealers,
    });
  };
  handleCancelClick = () => {
    this.props.onCancelClick();
  };
  onFormSubmit(data) {
    let message;
    let { action } = this.props;
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
    });
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(
          window.routerPrefix + "/dealers/" + shopUrl + "/" + data.dealerId,
          data
        )
        .then((res) => {
          this.setState({
            response: res.data,
            flagLoading: false,
          });
          message = "Dealer updated successfully";
          this.props.onCrudAction(message, "LIST", "Add a dealer");
          this.getDealerListFromDatabase();
        })
        .catch((err) => {
          this.setState({
            flagLoading: false,
          });
          console.log(err);
        });
    } else if (action === "ADD") {
      //ADD / POST
      this.setState({
        flagLoading: true,
      });
      axios
        .post(window.routerPrefix + "/dealers/" + this.props.shopUrl, data, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        })
        .then((res) => {
          this.setState({
            response: res.data,
            flagLoading: false,
          });
          message = "Dealer " + data.name + " added successfully.";
          this.props.onCrudAction(message, "ADD"); // Add again
          this.getDealerListFromDatabase();
        })
        .catch((err) => {
          console.log("error...");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditDealer = (dealer) => {
    this.setState({
      dealer: dealer,
      searchText: "",
    });
    this.props.onEditClick();
  };
  handleDeleteDealer = (dealer) => {
    let message;
    let { shopUrl } = this.props;
    let { dealerId } = dealer;
    let { name } = dealer;
    // Now call for delete api
    this.setState({
      flagLoading: true,
      searchText: "",
    });
    axios
      .delete(window.routerPrefix + "/dealers/" + shopUrl + "/" + dealerId)
      .then((res) => {
        this.setState({
          response: res.data,
          flagLoading: false,
        });
        message = "Dealer '" + name + "' deleted successfully";
        this.props.onCrudAction(message, "LIST"); // show list again
        this.getDealerListFromDatabase();
      })
      .catch((err) => {
        this.setState({
          flagLoading: false,
        });
        console.log(err);
      });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;
    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredDealers } = this.state;
    if (direction == false) {
      //in ascending order
      filteredDealers = filteredDealers.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredDealers = filteredDealers.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({
      filteredDealers: filteredDealers,
      sortedField: field,
    });
  };
  render() {
    let { action } = this.props;
    let { dealer } = this.state;
    let { shopUrl } = this.props;
    let { colorScheme } = this.props;
    let content;
    let { flagLoading } = this.state;
    let dealerFacility = this.props.shopDetails.dealerFacility;
    let versionTypeId = this.props.shopDetails.versionTypeId;
    let downloadPanel = (
      <div className="container-fluid container-md fixed-bottom py-2 bg-mycyan text-center text-white">
        {/* <div className="container-fluid homepage-contact-details text-white text-center py-0"> */}
        <div className="col-10 col-md-2 mx-auto  p-0">
          <button className="btn-mycyan" onClick={this.handleDownloadClick}>
            Download Cutsomer Information
          </button>
        </div>
      </div>
    );
    if (!dealerFacility) {
      content = (
        <div className="bigger-text text-thick text-center">
          This Facility is for Managing dealers. You have not opted for it.
        </div>
      );
    } //if
    else if (flagLoading)
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={colorScheme} flagLoading />
        </div>
      );
    else if (action === "LIST") {
      // First show list
      content = (
        <AdminDealerList
          onEditDealer={this.handleEditDealer}
          onDeleteDealer={this.handleDeleteDealer}
          onSectionNumberClick={this.handleSectionNumberClick}
          sectionNumber={this.state.sectionNumber}
          onSearchTextChange={this.handleSearchTextChange}
          filteredDealers={this.state.filteredDealers}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminDealerAdd
            onFormSubmit={this.onFormSubmit}
            onCancelClick={this.handleCancelClick}
            dealer={dealer}
            action={action}
            shopUrl={shopUrl}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress.</div>;
    }
    return (
      <div className="container-fluid container-md container-list-table">
        {content}
        {versionTypeId > 1 && action === "LIST" ? downloadPanel : null}
      </div>
    );
  }
}
export default AdminDealer;
