import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Select from "react-select";

function FormStartStore(props) {
  let [admin, setAdmin] = useState({
    adminId: "",
    shopUrl: "",
    // mobile: "",
    // otp: "",
    shopTypeId: -1,
    shopType: "",
    shopInfo: "",
    userName: "",
    password: "",
  });
  let [errorAdmin, setErrorAdmin] = useState({
    shopUrl: {
      message: "",
      mxLen: 20,
      mnLen: 4,
      onlyDigits: false,
      allDigits: false,
      noSymbols: true,
      description: "Store name",
    },
    // mobile: {
    //   message: "",
    //   mxLen: 10,
    //   mnLen: 10,
    //   onlyDigits: true,
    //   allDigits: true,
    //   noSymbols: true,
    // },
    // otp: {
    //   message: "",
    //   mxLen: 6,
    //   mnLen: 6,
    //   onlyDigits: true,
    //   allDigits: true,
    //   noSymbols: true,
    // },
    shopType: {
      message: "",
      description: "type of store",
    },
    shopTypeId: {
      message: "",
    },
    shopInfo: {
      message: "",
      description: "Type of products",
    },
    userName: {
      message: "",
      mxLen: 30,
      mnLen: 8,
      onlyDigits: false,
      allDigits: false,
      noSymbols: false,
    },
    password: {
      message: "",
      mxLen: 8,
      mnLen: 8,
      onlyDigits: false,
      allDigits: false,
      noSymbols: false,
    },
  });
  let [step, setStep] = useState(1);
  let [resultShopUrlCheck, setResultShopUrlCheck] = useState({
    status: "cool",
    message: "",
    isDone: "",
    textColor: "text-red",
  });
  let [resultMobileCheck, setResultMobileCheck] = useState({
    status: "cool",
    message: "",
    isDone: "",
    textColor: "text-red",
    attempts: 0,
  });
  let [resultShopTypeSelection, setResultShopTypeSelection] = useState({
    status: "cool",
    message: "",
    isDone: "",
    textColor: "text-red",
    attempts: 0,
  });
  let [resultCredentials, setResultCredentials] = useState({
    status: "cool",
    message: "",
    isDone: "",
    textColor: "text-red",
    attempts: 0,
  });
  let [flagLoad, setFlagLoad] = useState(false);
  let [shopTypeList, setshopTypeList] = useState([]);
  let [selectedShopType, setSelectedShopType] = useState({ name: "" });
  // Initially submit button is disabled
  let [flagFormInvalid, setFlagFormInvalid] = useState(true);
  let button_Label;
  if (step == 1) {
    button_Label = "Ok";
  } else if (step == 2 && resultShopTypeSelection.status == "cool") {
    button_Label = "Ok";
  } else if (step == 3 && resultCredentials.status == "cool") {
    button_Label = "Ok";
  } else if (step == 3 && resultCredentials.status == "emailPasswordSent") {
    button_Label = "Ok";
  }
  useEffect(() => {
    window.scroll(0, 0);
    getShopTypes();
  }, []);
  function getShopTypes() {
    // first assign session for new user
    setFlagLoad(true);
    axios
      .get(window.routerPrefix + "/shopType/welcome")
      .then((res) => {
        axios
          .get(window.routerPrefix + "/shopType/shopTypes")
          .then((res) => {
            setshopTypeList(res.data);
            setFlagLoad(false);
          })
          .catch((err) => {
            console.log(err);
            setFlagLoad(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
      });
  }
  function handleSubmit(event) {
    event.preventDefault();
    // check whether this is already taken
    if (step == 1) {
      setFlagLoad(true);
      axios
        .post(window.routerPrefix + "/admin/checkShopUrl", admin, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          setFlagLoad(false);
          if (res.data == 0) {
            // already present
            setResultShopUrlCheck({
              message: "Oh! This name is already taken! Try some other name",
              textColor: "text-red",
            });
          } else if (res.data == 2) {
            // reserved word
            setResultShopUrlCheck({
              message: "This name is reserved. Try some other name",
              textColor: "text-red",
            });
          } else if (res.data == 1) {
            // available
            setResultShopUrlCheck({
              message: "Congratulations, this name is available!",
              isDone: "Completed",
              textColor: "text-green",
            });
            setStep(step + 1); // Step 1 over, step 2 starts
            setFlagFormInvalid(false);
          } else if (res.data == -1) {
            setResultShopUrlCheck({
              message: "Something went wrong, refresh this page.",
            });
          }
        })
        .catch((err) => {
          setFlagLoad(false);
          console.log("error!");
        });
    } //setp 1 ... shop check
    else if (step == 2) {
      // just set the type of the shop
      setStep(step + 1);
      setFlagFormInvalid(true); // don't allow submit-press
      admin.shopType = selectedShopType.name; // npm react-select 26.07.2023
      admin.shopTypeId = selectedShopType.shopTypeId; // npm react-select 26.07.2023
      let message;

      if (admin.shopType != "Other Type of Store") {
        message = "You have selected " + admin.shopType;
      } else {
        message = "Your store will contain products like " + admin.shopInfo;
      }

      setResultShopTypeSelection({
        ...resultShopTypeSelection,
        isDone: "Completed",
        message: message,
        textColor: "text-green",
      });
    } else if (step == 3 && resultCredentials.status == "cool") {
      // Check emailid, whether it exists
      setFlagLoad(true);
      axios
        .post(window.routerPrefix + "/admin/checkUserName", admin, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          setFlagFormInvalid(true); // don't allow submit button-press
          setFlagLoad(false);
          if (res.data == 0) {
            // already present
            setResultCredentials({
              ...resultCredentials,
              message:
                "Oh! This email-id is already registered,  Try some other name",
              textColor: "text-red",
            });
          } else if (res.data == 1) {
            // email is available, shop is created, email is sent
            setFlagFormInvalid(true);
            setResultCredentials({
              ...resultCredentials,
              isDone: "Completed",
              textColor: "text-green",
              message: "Username and password is emailed to you.",
            });
            setStep(step + 1); // Step 3 over
            // in local storage, store info about this admin
            // let shopUrlList = []; // shops managed by same admin
            // shopUrlList.push(admin.shopUrl);
            // window.localStorage.setItem("admin", JSON.stringify(shopUrlList));
            window.localStorage.setItem("admin", admin.shopUrl);
          } else if (res.data == -1) {
            setResultCredentials({
              ...resultCredentials,
              message: "Something went wrong, refresh this page",
              textColor: "text-red",
            });
          }
        })
        .catch((err) => {
          setFlagLoad(false);
          console.log("error!");
          setResultCredentials({ ...resultCredentials, status: "cool" });
        });
    }
  }
  function handleBackClick() {
    props.onBackClick();
  }
  function handleChange(event) {
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      setAdmin({ ...admin, [name]: event.target.value });
      return;
    }
    // let value = event.target.value.trim();
    setAdmin({ ...admin, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
        if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorAdmin[`${name}`].mnLen;
      mxLen = errorAdmin[`${name}`].mxLen;
      onlyDigits = errorAdmin[`${name}`].onlyDigits;
      allDigits = errorAdmin[`${name}`].allDigits;
      noSymbols = errorAdmin[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      // else if (!allDigits) {
      //   for (i = value.length - 1, flag = false; i >= 0; i--) {
      //     const d = value.charCodeAt(i);
      //     if (d < 48 || d > 57) {
      //       message = "";
      //       flag = true;
      //       break;
      //     } //if
      //   } //for
      //   if (flag == false) {
      //     message = "Only digits are not allowed, use some alphabets also";
      //   }
      // } //else if... allDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value == 0) {
        message = "You have not selected " + errorAdmin[name].description + ".";
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    errorAdmin[`${name}`].message = message;
    checkAllErrors();
  }
  function handleFocus(event) {
    if (step == 1) {
      setResultShopUrlCheck({ ...resultShopUrlCheck, message: "" });
    } else if (step == 3) {
      setResultCredentials({ ...resultCredentials, message: "" });
    }
    let name = event.target.name;
    let errAdmin = { ...errorAdmin };
    // errShop[name]["message"] = "";
    // setErrorShop(errShop);
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorAdmin) {
      if (errorAdmin[field].message !== "") {
        // error is there
        // flagFormInvalid = true;
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  let optionsShopType = shopTypeList.map((shopType, index) => ({
    ...shopType,
    value: shopType.name,
    label: shopType.name,
  }));
  return (
    <>
      <div className="container-fluid container-md container-form-start">
        {/* row-begins */}
        <div className="text-center">
          <Link className="" to="#" onClick={handleBackClick}>
            Back to homepage
          </Link>
        </div>
        <div className="row justify-content-center">
          <div className="col-8 col-md-4  text-center  mb-5">
            <img
              src={
                window.routerPrefix +
                "/files/downloadImageFile/meriyaadi_logo.jpg"
              }
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-md-12  text-center ">
            <h4 className="home-page-blue-color text-thick text-center">
              {" "}
              Start your online store in 3 easy steps
            </h4>

            <h4 className={" text-center " + resultShopUrlCheck.textColor}>
              Step 1: {resultShopUrlCheck.isDone}
            </h4>
            <h4 className="home-page-blue-color text-center text-italic">
              Choose name for your online store
            </h4>
            <h6 className="home-page-blue-color text-center">
              if you choose name "laxmanShoppe", then link for your online store
              will be: https://www.meriyaadi.com/laxmanShoppe
            </h6>
          </div>
        </div>
        <form className="text-thick p-4" onSubmit={handleSubmit}>
          <div className="form-group row justify-content-center">
            <div className="col-8 col-md-4 px-0">
              <input
                type="text"
                className="form-control"
                name="shopUrl"
                value={admin.shopUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                placeholder="Type your desired store name"
                required
                disabled={resultShopUrlCheck.isDone == "Completed"}
              />
            </div>
          </div>
          <div className="form-group row justify-content-center">
            <div className="col-8 col-md-4">
              {errorAdmin.shopUrl.message ? (
                <span className="error-text">{errorAdmin.shopUrl.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {resultShopUrlCheck.message && (
            <h6 className={"text-center " + resultShopUrlCheck.textColor}>
              {resultShopUrlCheck.message}
            </h6>
          )}
          {flagLoad && resultShopUrlCheck.status == "cool" && (
            <div className="text-center mt-5">
              <BeatLoader size={10} color={"blue"} flagLoad />
            </div>
          )}
          {resultShopUrlCheck.isDone == "Completed" && (
            <div>
              <h4
                className={" text-center " + resultShopTypeSelection.textColor}
              >
                Step 2: {resultShopTypeSelection.isDone}
              </h4>
              <h4 className="home-page-blue-color text-center text-italic">
                Select type of your store
              </h4>
              {flagLoad && step == 2 && (
                <div className="text-center mt-5">
                  <BeatLoader size={10} color={"blue"} flagLoad />
                </div>
              )}
              {/* row starts */}
              {resultShopUrlCheck.isDone == "Completed" && (
                <div className="form-group row justify-content-center">
                  <div className="col-8 col-md-4 px-0">
                    <Select
                      defaultValue={selectedShopType}
                      onChange={setSelectedShopType}
                      options={optionsShopType}
                      isDisabled={resultShopTypeSelection.isDone == "Completed"}
                    />
                  </div>
                </div>
              )}
              {resultShopUrlCheck.isDone == "Completed" && (
                <div className="form-group row justify-content-center">
                  <div className="col-8 col-md-4">
                    {errorAdmin.shopType.message ? (
                      <span className="error-text">
                        {errorAdmin.shopType.message}
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
              {/* row ends */}
              {selectedShopType.name == "Other Type of Store" && (
                <div className="text-center mb-2">
                  Which type of products your store will contain? Mention below.
                  E.g. Paintings, Books
                </div>
              )}
              {selectedShopType.name == "Other Type of Store" && (
                <div className="form-group row justify-content-center">
                  <div className="col-8 col-md-4 px-0">
                    <input
                      type="text"
                      className="form-control"
                      name="shopInfo"
                      value={admin.shopInfo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      placeholder="Type of products"
                      required
                      disabled={resultShopTypeSelection.isDone == "Completed"}
                    />
                  </div>
                </div>
              )}
              {selectedShopType.name == "Other Type of Store" && (
                <div className="form-group row justify-content-center">
                  <div className="col-8 col-md-4">
                    {errorAdmin.shopInfo.message ? (
                      <span className="error-text">
                        {errorAdmin.shopInfo.message}
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
              {/* row ends */}
              {resultShopTypeSelection.message && (
                <h6
                  className={"text-center " + resultShopTypeSelection.textColor}
                >
                  {resultShopTypeSelection.message}
                </h6>
              )}
              {/* row ends */}
            </div>
          )}
          {resultShopTypeSelection.isDone == "Completed" && (
            <div>
              <h4 className={" text-center " + resultCredentials.textColor}>
                Step 3: {resultCredentials.isDone}
              </h4>
              <h4 className="home-page-blue-color text-center text-italic">
                Enter your email-id
              </h4>
              <h6 className="home-page-blue-color text-center">
                Your user-name and password will be sent to this email-id.
              </h6>
              {/* row-begins */}
              <div className="form-group row justify-content-center">
                <div className="col-8 col-md-4 px-0">
                  <input
                    type="email"
                    className="form-control"
                    name="userName"
                    value={admin.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Enter your email-id as user-name"
                    required
                    disabled={resultCredentials.status != "cool"}
                  />
                </div>
              </div>
              {/* row ends */}
              {/* row begins */}
              <div className="form-group row justify-content-center">
                <div className="col-6 col-md-4">
                  {errorAdmin.userName.message ? (
                    <span className="error-text">
                      {errorAdmin.userName.message}
                    </span>
                  ) : null}
                </div>
              </div>
              {/* row ends */}
              {flagLoad && step == 3 && (
                <div className="text-center mt-5">
                  <BeatLoader size={10} color={"blue"} flagLoad />
                </div>
              )}
              {flagLoad && step == 3 && (
                <div className="text-center mt-5">
                  Wait...Your online store is being created.
                </div>
              )}
              {/* row begins */}
              <div className="form-group row justify-content-center">
                <div className="col-6 col-md-4">
                  {errorAdmin.password.message ? (
                    <span className="error-text">
                      {errorAdmin.password.message}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {resultCredentials.message && (
            <h6 className={"text-center " + resultCredentials.textColor}>
              {resultCredentials.message}
            </h6>
          )}
          {resultCredentials.isDone == "Completed" && (
            <div className="text-center">
              {/* row starts */}
              <h5>
                Yeee....Your store is ready!
                {admin.shopType.startsWith("vege") &&
                  "100+ vegetables and fruits have been added to your store"}
              </h5>
              <h4>Visit you store now:</h4>
              <h4>
                <Link
                  to={"/" + admin.shopUrl}
                  // onClick={handleLinkClick}
                >
                  https://www.meriyaadi.com/{admin.shopUrl}
                </Link>
              </h4>
              {/* row ends */}
            </div>
          )}

          {!flagLoad &&
            !(resultMobileCheck.isDone == "Failed") &&
            step != 4 && (
              <div className="form-group row justify-content-center text-center mb-0">
                <button
                  className="btn-mycyan-dark"
                  type="submit"
                  disabled={flagFormInvalid}
                >
                  {button_Label}
                </button>
                &nbsp;&nbsp;&nbsp;
              </div>
            )}
          <input type="hidden" name="shopId" value={admin.shopId} />
        </form>
        {/* row ends  */}
      </div>
    </>
  );
}
export default FormStartStore;
