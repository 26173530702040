import React, { Component } from "react";
import OwnerAdminPasswordList from "./owner-admin-password-list";
import OwnerAdminPasswordAdd from "./owner-admin-password-add";
import { BeatLoader } from "react-spinners";
import { adminPasswordDB } from "../AdminPasswordDB";

import axios from "axios";

class OwnerAdminPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      adminPassword: null,
      adminPasswordList:[],
      filteredAdminPasswords: [],
      searchText: "",
      sortedField: "",
      sortedDirection: false,
      sectionNumber: 1,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  componentDidMount() {
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/admin/admins")
      .then((res) => {
        let adminPasswordList = res.data;
        this.setState({
          adminPasswordList:adminPasswordList,
          filteredAdminPasswords: adminPasswordList,
          adminpassworddb: new adminPasswordDB(adminPasswordList),
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false });
      });
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedAdminPasswords = [];
    searchedAdminPasswords = this.state.adminpassworddb.filterByShopUrl(query);
    this.setState({
      filteredAdminPasswords: searchedAdminPasswords,
    });
  };
  onFormSubmit(data) {
    let message;
    let { action } = this.props;
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        // .put("/admins/" + data.adminId, data)
        .put(window.routerPrefix + "/admin/update", data)
        .then((res) => {
          this.setState({
            response: res.data,
          });
          message = data.shopUrl + " admin info updated successfully";
          this.props.onCrudAction(message, "LIST");
          this.getAdminPasswordListFromDatabase();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // ADD part is removed, only EDIT
  } //onFormSubmit
  handleEditAdminPassword = (adminPassword) => {
    this.setState({
      adminPassword: adminPassword,
      searchText: "",
    });
    this.props.onEditClick();
  };
  handleDeleteAdminPassword = (adminPassword, result) => {
    let message;
    if (result == 0) {
      //Error... deleting adminPassword
      message = "Error....could not delete the adminPassword";
      this.props.onCrudAction(message, "LIST");
      return;
    }
    let aList = [...this.state.adminPasswordList];
    for (let i = 0; i < aList.length; i++) {
      if (aList[i].adminId == adminPassword.adminId) {
        aList.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    // remove from filteredadminPasswords also
    let fAdminPasswords = [...this.state.filteredAdminPasswords];
    for (let i = 0; i < fAdminPasswords.length; i++) {
      if (fAdminPasswords[i].adminId == adminPassword.adminId) {
        fAdminPasswords.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    if (fAdminPasswords.length == 0) {
      // only one entity was searched and deleted, so search text should be removed, and all products should be shown
      this.setState({searchText:""})
    } else {

    }
    this.setState({
      adminPasswordList:aList,
      filteredAdminPasswords:fAdminPasswords
    });
    message = adminPassword.name + " deleted successfully";
    this.props.onCrudAction(message, "LIST");
  };
  // handleDeleteAdminPassword = (adminPassword) => {
  //   let message;
  //   let { adminId } = adminPassword;
  //   let { shopUrl } = adminPassword;
  //   this.setState({
  //     flagLoading: true,
  //     searchText: "",
  //   });
  //   // Now call for delete api
  //   axios
  //     .delete(window.routerPrefix +"/admin/delete/" + adminId)
  //     .then((res) => {
  //       this.setState({
  //         response: res.data,
  //         flagLoading: false,
  //       });
  //       message = "Shop '" + shopUrl + "' admin info deleted successfully";
  //       this.props.onCrudAction(message, "LIST");
  //       this.getAdminPasswordListFromDatabase();
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         flagLoading: false,
  //       });
  //       console.log(err);
  //     });
  // };
  getAdminPasswordListFromDatabase = () => {
    this.setState({
      flagLoading: true,
    });
    axios
      // .get("/admins/allAdmins")
      .get(window.routerPrefix + "/admin/admins")
      .then((res) => {
        let adminPasswordList = res.data;
        this.setState({
          adminPasswordList:adminPasswordList,
          filteredAdminPasswords: adminPasswordList,
          adminpassworddb: new adminPasswordDB(adminPasswordList),
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };

  handleHeaderClick = (event) => {
    let field = event.target.id;
    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredAdminPasswords } = this.state;
    if (direction == false) {
      //in ascending order
      filteredAdminPasswords = filteredAdminPasswords.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredAdminPasswords = filteredAdminPasswords.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({
      filteredAdminPasswords: filteredAdminPasswords,
      sortedField: field,
    });
  };
  handleSectionNumberClick = (event) => {
    this.setState({ sectionNumber: event.target.id });
  };

  render() {
    let { action } = this.props;
    let { adminPassword } = this.state;
    let { flagLoading } = this.state;
    let content;
    if (flagLoading)
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"orange"} flagLoading />
        </div>
      );
    else if (action === "LIST") {
      // First show list
      content = (
        <div>
          <OwnerAdminPasswordList
            onEditAdminPassword={this.handleEditAdminPassword}
            onDeleteAdminPassword={this.handleDeleteAdminPassword}
            onSearchTextChange={this.handleSearchTextChange}
            filteredAdminPasswords={this.state.filteredAdminPasswords}
            searchText={this.state.searchText}
            onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
            onHeaderClick={this.handleHeaderClick}
            onSectionNumberClick={this.handleSectionNumberClick}
            sectionNumber={this.state.sectionNumber}
          />
        </div>
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <OwnerAdminPasswordAdd
            onFormSubmit={this.onFormSubmit}
            adminPassword={adminPassword}
            action={action}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress...</div>;
    }
    return (
      <div className="container-fluid container-list-table p-0">{content}</div>
    );
  }
}
export default OwnerAdminPassword;
