import React, { useEffect, useState } from "react";
function QuantityChanger(props) {
  let [whichWeight, setWhichWeight] = useState(10);
  let [productQty, setProductQty] = useState(0);
  useEffect(() => {
    //Runs only on the first render
    setProductQty(props.productQty);
  }, []);
  function handleWeightSelection(value) {
    setWhichWeight(value);
  }
  function handleIncrementClick(event) {
    event.preventDefault();
    let multipleOf;
    if (props.unitId == 1) {
      // unit is numbers
      multipleOf = 1;
    } else if (props.unitId == 2) {
      // unit is 250 gms
      multipleOf = 0.25;
    } else if (props.unitId == 3) {
      // unit is kg
      multipleOf = 1;
    } else if (props.unitId == 4) {
      // unit is 500 gms
      multipleOf = 0.5;
    } else if (props.unitId == 5) {
      // unit is 1 kg / 10 kg / 100 kg / 1000 kg
      multipleOf = whichWeight;
    }

    setProductQty(productQty + multipleOf);
    props.onChangeProductQuantity(multipleOf);
  }
  function handleClearClick() {
    props.onChangeProductQuantity(-productQty);
    setProductQty(0);
  }
  function handleDecrementClick(event) {
    event.preventDefault();
    let multipleOf;

    if (props.unitId == 1) {
      // unit is numbers
      multipleOf = -1;
    } else if (props.unitId == 2) {
      // unit is 250 gms
      multipleOf = -0.25;
    } else if (props.unitId == 3) {
      // unit is kg
      multipleOf = -1;
    } else if (props.unitId == 4) {
      // unit is 500 gms
      multipleOf = -0.5;
    } else if (props.unitId == 5) {
      // unit is 1 kg / 10 kg / 100 kg / 1000 kg
      multipleOf = -whichWeight;
    }

    // let { productQty } = this.state;
    // productQty += multipleOf;
    setProductQty(productQty + multipleOf);
    // this.setState({ productQty: productQty });
    props.onChangeProductQuantity(multipleOf);
  }
  let { title } = props;
  let { shortTitle } = props;
  let { colorScheme } = props;
  let { action } = props;
  let { unitId } = props;
  let flagDecrement = false;
  // let { whichWeight } = this.state;
  let btnClassList = "btn-my" + colorScheme;
  let { shopTypeId } = props;
  
  if (action === "CART") {
    // disable decrement button for quantity=1 in cart display
    if (productQty === 1 && unitId == 1) {
      flagDecrement = true;
    } else if (productQty === 0.25 && unitId == 2) {
      flagDecrement = true;
    } else if (productQty === 1 && unitId == 3) {
      flagDecrement = true;
    } else if (productQty === 0.5 && unitId == 4) {
      flagDecrement = true;
    } else if (productQty == whichWeight && unitId == 5) {
      flagDecrement = true;
    } else {
      flagDecrement = false;
    }
  } //if...CART
  let dispQty;
  if (unitId == 1) {
    // measure in numbers
    dispQty = (
      <>
        <span className="normal-text smaller-text">{title}&nbsp;</span>
        <span className="short-text smaller-text">{shortTitle}</span>
        <span className="text-thick bigger-text">{productQty}</span>
      </>
    );
  } else if (unitId == 2 || unitId == 4) {
    // measure in gms
    let wt = productQty * 1000;
    let unit = " gms";
    if (wt >= 1000) {
      wt = wt / 1000;
      unit = " kg";
    }
    dispQty = (
      <span className="">
        {wt} {unit}
      </span>
    );
  } else if (unitId == 3 || unitId == 5) {
    // measure in kgs
    let wt = productQty;
    let unit = " kg";
    dispQty = (
      <span className="">
        {wt} {unit}
      </span>
    );
  }
  return (
    <>
      <div className="container-fluid">
        {unitId == 5 ? (
          <div className="row bg-warning  justify-content-center align-items-center p-0">
            <div className="col-6 column px-1 py-2 text-center">
              <input
                type="radio"
                className="form-control-inline"
                name="whichWeight"
                value="1"
                checked={whichWeight === 1}
                onClick={() => handleWeightSelection(1)}
                onChange={() => handleWeightSelection(1)}
              />
              1
            </div>
            <div className="col-6 column p-0 text-center">
              <input
                type="radio"
                className="form-control-inline"
                name="whichWeight"
                value="10"
                onClick={() => handleWeightSelection(10)}
                onChange={() => handleWeightSelection(10)}
                checked={whichWeight === 10}
              />
              10
            </div>
            <div className="offset-1 col-5 text-center column p-0">
              <input
                type="radio"
                className="form-control-inline"
                name="whichWeight"
                value="100"
                checked={whichWeight === 100}
                onClick={() => handleWeightSelection(100)}
                onChange={() => handleWeightSelection(100)}
              />
              100
            </div>
            <div className="col-5 column text-center p-0">
              <input
                type="radio"
                className="form-control-inline"
                name="whichWeight"
                value="1000"
                onClick={() => handleWeightSelection(1000)}
                onChange={() => handleWeightSelection(1000)}
                checked={whichWeight === 1000}
              />
              1000
            </div>
            <div className="col-4 column px-0 py-2">
              <button
                className="btn btn-block btn-success text-center"
                onClick={handleClearClick}
              >
                C
              </button>
            </div>
          </div>
        ) : null}
        <div className="row justify-content-center align-items-center">
          {/* row starts */}
          <div className="col-2 text-center p-0">
            <button
              className={"btn-block text-center btn-my"+colorScheme+"-dark"}
              onClick={handleDecrementClick}
              disabled={flagDecrement}
            >
              <i className="fa fa-minus" aria-hidden="true"></i>
            </button>
          </div>
          <div className={"col-8 py-1 text-center bg-my"+colorScheme}>{dispQty}</div>
          <div className="col-2  text-center p-0">
            <button
              className={"btn-block text-center btn-my"+colorScheme+"-dark"}
              onClick={handleIncrementClick}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        {/* row ends */}
      </div>
    </>
  );
}
export default QuantityChanger;
