import React, { Component, useState } from "react";
import { BeatLoader } from "react-spinners";
import { ProductDB } from "../ProductDB";
import DatePicker from "react-datepicker";
import AdminReportCumulativeList from "./admin-report-cumulative-list";
import axios from "axios";

function AdminReportCumulative(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [filteredProducts, setFilteredProducts] = useState([]);
  let [productdb, setProductDb] = useState([]);
  let [whichDate, setWhichDate] = useState("Today");
  let [dateFrom, setDateFrom] = useState(new Date());
  let [dateTo, setDateTo] = useState(new Date());
  let [flagDisplay, setFlagDisplay] = useState(false);
  let [searchText, setSearchText] = useState("");
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [sectionNumber, setSectionNumber] = useState("");
  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  function dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  function getDataFromDatabase(whichDate) {
    let { shopUrl } = props;
    setFlagLoad(true);
    let diff1, diff2;
    if (whichDate === "Between Dates") {
      let d = new Date();
      diff1 = dateDiffInDays(dateFrom, d);
      diff2 = dateDiffInDays(dateTo, d);
      whichDate = diff1 + "_" + diff2;
    }
    // First get list of yaadi id from mysql table yaadies
    // also list of yaadies of selected day
    axios
      .all([
        axios.get(window.routerPrefix +"/product/products/" + shopUrl),
        axios.get(window.routerPrefix +"/yaadi/yaadiesByDate/" + shopUrl + "/" + whichDate),
      ])
      .then(
        axios.spread((res1, res2) => {
          let productList = res1.data;
          let yaadiList = res2.data;
          let productArray = [];
          let productObject;
          for (let i = 0; i < productList.length; i++) {
            productObject = new Object();
            productObject.productId = productList[i].productId;
            productObject.unitId = productList[i].unitId;
            if (productList[i].information) {
              productList[i].name =
                productList[i].name + "-" + productList[i].information;
            }
            productObject.name = productList[i].name;
            productArray.push(productObject);
          } //for
          /*====Send yaadiId of first and last yaadi=====*/
          let yaadiIdArray = [];
          for (let i = 0; i < yaadiList.length; i++) {
            yaadiIdArray.push(yaadiList[i].yaadiId);
          } //for
          let yaadiProductList;
          if (yaadiList.length == 0) {
            // no yaadies
            // yaadiProductList=null;
            setFlagLoad(false);
            return;
          }
          let yaadiIdFirst = yaadiList[0].yaadiId;
          let yaadiIdLast = yaadiList[yaadiList.length - 1].yaadiId;
          /*====and get yaadiProducts belonging to these ids =====*/

          axios
            .get(window.routerPrefix +"/yaadiProduct/yaadiProductsByYaadiId/" + shopUrl+"/"+yaadiIdFirst+"/"+yaadiIdLast)
            .then((res) => {
              yaadiProductList = res.data;
              findCumulative(productArray, yaadiProductList);
              // this.JSONToCSVConvertor(productList, yaadiProductList);
              setFlagLoad(false);
            })
            .catch((err) => {
              console.log(err);
              setError(err);
              setFlagLoad(false);
            });
          /*=========*/
        })
      )
      .catch((err) => {
        console.log(err);
        setError(err);
        setFlagLoad(false);
      });
  }
  function findCumulative(productArray, yaadiProductList) {
    //we have to find now total of quantity of each product,
    for (let i = 0; i < productArray.length; i++) {
      // add one more field (quantity) to productArray[i] object
      productArray[i].quantity = 0;
      for (let j = 0; j < yaadiProductList.length; j++) {
        if (productArray[i].productId === yaadiProductList[j].productId) {
          productArray[i].quantity += yaadiProductList[j].quantity;
        }
      } //inner for
    } //outer for
    let productdb = new ProductDB(productArray);
    setFilteredProducts(productArray);
    setProductDb(productdb);
  }
  function handleDateSelection(value) {
    setWhichDate(value);
    setFlagDisplay(false);
  }
  function handleDateFromPickerClick(date) {
    setDateFrom(date);
    setFlagDisplay(false);
  }
  function handleDateToPickerClick(date) {
    setDateTo(date);
    setFlagDisplay(false);
  }
  function handleShowHereClick() {
    if (dateFrom > dateTo) {
      window.alert("Enter dates correctly...");
      return;
    }
    setFlagDisplay(true);
    getDataFromDatabase(whichDate); // value is whichDate (today/yesterday)
  }
  function handleExcelFileDownloadClick() {
    JSONToCSVConvertor(filteredProducts, "", true);
  }
  //JSONToCSVConvertor function obtained from following site
  //https://stackoverflow.com/questions/28892885/javascript-json-to-excel-file-download
  function JSONToCSVConvertor(filteredProducts, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    filteredProducts =
      typeof filteredProducts != "object"
        ? JSON.parse(filteredProducts)
        : filteredProducts;
    //Set Report title in first row or line
    CSV += ReportTitle + "\r\n\n";
    //This condition will generate the Label/Header
    let headers = ["S.No.", "Description", "Quantity"];
    if (ShowLabel) {
      var row = "";
      var CSV = "";
      //This loop will extract the label from 1st index of on array
      for (let i = 0; i < headers.length; i++) {
        //Now convert each value to string and comma-seprated
        row += headers[i] + ",";
      }
      // row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }
    //1st loop is to extract each row
    let data;
    let unitList = [
      { unitId: 0, name: "" },
      { unitId: 1, name: "" },
      { unitId: 2, name: " kg" },
      { unitId: 3, name: " kg" },
      { unitId: 4, name: " kg" },
      { unitId: 5, name: " kg" },
    ];
    for (var i = 0; i < filteredProducts.length; i++) {
      var row = '"' + (i + 1) + '",';
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in filteredProducts[i]) {
        data = filteredProducts[i][index];
        if (index === "quantity") {
          data += unitList[filteredProducts[i].unitId].name;
        }
        if (
          index === "unitId" ||
          index === "information" ||
          index === "productId"
        ) {
          continue;
        }

        row += '"' + data + '",';
      } //for
      // row.slice(0, row.length - 1);
      //add a line break after each row
      CSV += row + "\r\n";
    }
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    let dt = new Date();
    let fileName = "Cumulative ";
    if (whichDate === "Today") {
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    }
    if (whichDate === "Yesterday") {
      dt.setDate(dt.getDate() - 1);
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    } else if (whichDate === "Between Dates") {
      fileName +=
        pad(dateFrom.getDate(), 2) +
        "." +
        pad(dateFrom.getMonth() + 1, 2) +
        " to " +
        pad(dateTo.getDate(), 2) +
        "." +
        pad(dateTo.getMonth() + 1, 2);
    }
    // +
    // ".xls";
    //this will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedProducts = [];
    searchedProducts = productdb.filterByName(query);
    setFilteredProducts(searchedProducts);
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let direction = false;
    if (field === sortedField) {
      // same button clicked twice
      direction = !direction;
    } else {
      // different field
      direction = false;
    }
    setDirection(direction);
    if (direction == false) {
      //in ascending order
      filteredProducts = filteredProducts.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredProducts = filteredProducts.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredProducts(filteredProducts);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  let versionTypeId = props.shopDetails.versionTypeId;
  let d = new Date();
  let content = (
    // This is enclosed in container-list-table
    <>
      {/* row starts */}
      <div className=" col-12">
        <div className="form-heading">Productwise Sale</div>
      </div>
      {/* row ends */}
      {/* row starts */}
      <div className="row">
        <div className="col-12 my-2 text-center ">
          <input
            type="radio"
            className="form-control-inline"
            name="whichDate"
            value="Yesterday"
            onClick={() => handleDateSelection("Yesterday")}
            onChange={() => handleDateSelection("Yesterday")}
          />
          &nbsp;Yesterday
          <div className="col-12 my-2">
            <input
              type="radio"
              className="form-control-inline"
              name="whichDate"
              value="Today"
              onClick={() => handleDateSelection("Today")}
              onChange={() => handleDateSelection("Today")}
              checked={whichDate === "Today"}
            />
            &nbsp;Today
          </div>
          <div className="col-12 my-2">
            <input
              type="radio"
              className="form-control-inline"
              name="whichDate"
              value="Between Dates"
              onClick={() => handleDateSelection("Between Dates")}
              onChange={() => handleDateSelection("Between Dates")}
              checked={whichDate === "Between Dates"}
            />
            &nbsp;Between Dates:
          </div>
          {whichDate === "Between Dates" ? (
            <div className="row justify-content-start">
              <div className="col-4 my-2 text-right ">From:</div>
              <div className="col-8 my-2 text-left ">
                <DatePicker
                  selected={dateFrom}
                  selectsStart
                  startDate={dateFrom}
                  endDate={dateTo}
                  dateFormat="yyyy/MM/dd"
                  minDate={d.setDate(d.getDate() - 8)}
                  onChange={(date) => handleDateFromPickerClick(date)}
                />
              </div>

              <div className="col-4 my-2 text-right ">To:</div>
              <div className="col-8 my-2 text-left">
                <DatePicker
                  selected={dateTo}
                  selectsEnd
                  startDate={dateFrom}
                  endDate={dateTo}
                  dateFormat="yyyy/MM/dd"
                  maxDate={new Date()}
                  onChange={(date) => handleDateToPickerClick(date)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* row ends */}
      {flagLoad && (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoad />
        </div>
      )}
      {!flagLoad && (
        <AdminReportCumulativeList
          filteredProducts={filteredProducts}
          productdb={productdb}
          whichDate={whichDate}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          onHeaderClick={handleHeaderClick}
          onSectionNumberClick={handleSectionNumberClick}
          sectionNumber={sectionNumber}
          onExcelFileDownloadClick={handleExcelFileDownloadClick}
          onShowHereClick={handleShowHereClick}
          flagDisplay={flagDisplay}
        />
      )}
      {!flagLoad && versionTypeId == 1 && (
        <div className="bigger-text text-thick text-center">
          This feature is available in Premium version !
        </div>
      )}
    </>
  );
  if (error) {
    return <div>Error retriving Cumulative </div>;
  } else {
    return (
      // <div className="container-fluid bg-blue">
      // <div className="row">
      <div className="container-fluid container-list-table p-0">{content}</div>
      // </div>
      // </div>
    );
  } //else
}
export default AdminReportCumulative;
