import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import DropDownAdminManage from "./drop-down-admin-manage";
import DropDownAdminUpdate from "./drop-down-admin-update";
import DropDownAdminUtilities from "./drop-down-admin-utilities";
import ActionMessage from "./action-message";
function SdcNavBarAdmin(props) {
  function handleAdminManageClick(event) {
    props.onAdminManageClick(event.target.id);
  }
  function handleAdminUpdateClick(event) {
    props.onAdminUpdateClick(event.target.id);
  }
  function handleAdminReportClick(event) {
    props.onAdminReportClick(event.target.id);
  }
  function handleAdminUtilityClick(event) {
    props.onAdminUtilityClick(event.target.id);
  }
  function handleAdminManageProducts() {
    props.onAdminManageProducts();
  }
  function handleAddListToggleClick(event) {
    event.preventDefault(); // Not required
    props.onAddListToggleClick();
  }
  function handleLogoutClick() {
    props.onLogoutClick();
  }
  function handleYourAccountClick() {
    props.onYourAccountClick();
  }
  function handleMasterListClick() {
    props.onMasterListClick();
  }
  function handleHomeClick() {
    props.onHomeClick();
  }
  let { adminManageList } = props;
  let { adminUpdateList } = props;
  let { adminUtilityList } = props;
  let { selectedMenuIndex } = props;

  let { shopDetails } = props;
  let { message } = props;
  let { menu } = props;
  let { validAdmin } = props;
  let { colorScheme } = props;
  let { firstLogin } = props;
  let { shopUrl } = props;
  let classList =
    "container-fluid container-md container-navbar-admin fixed-top";
  if (validAdmin) {
    classList += " bg-my" + colorScheme;
  } else {
    classList += " bg-mycyan";
  }
  return (
    <div className={classList}>
      {(!validAdmin || (validAdmin && firstLogin == 0)) && (
        <div className="row  justify-content-center align-items-center ">
          <div className="col-5 col-md-3 mx-auto column p-2">
            <div>
              <img
                src={
                  window.routerPrefix +
                  "/files/downloadImageFile/meriyaadi_logo.jpg"
                }
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      )}
      {validAdmin && firstLogin == 1 && (
        <>
          {/* a row ends */}
          {/* If store is  disabled, dont show these menus */}
          {/* 28.09.2023 - Following is removed from nav-bar */}
          {false && shopDetails.status != "disabled" && (
            <div className="row mt-2">
              <div className="col-4 text-center text-thick bigger-text   p-0">
                <DropDownAdminManage
                  adminManageList={adminManageList}
                  onAdminManageClick={handleAdminManageClick}
                  title={"Manage"}
                  shortTitle={"Manage"}
                  colorScheme={colorScheme}
                />
              </div>
              <div className="col-4 text-center text-thick bigger-text   p-0">
                <DropDownAdminUpdate
                  adminUpdateList={adminUpdateList}
                  onAdminUpdateClick={handleAdminUpdateClick}
                  title={"Update"}
                  shortTitle={"Update"}
                  colorScheme={colorScheme}
                />
              </div>
              <div className="col-4 text-center text-thick bigger-text  p-0">
                <DropDownAdminUtilities
                  adminUtilityList={adminUtilityList}
                  onAdminUtilityClick={handleAdminUtilityClick}
                  title={"Utilities"}
                  shortTitle={"Utilities"}
                  colorScheme={colorScheme}
                />
              </div>
            </div>
          )}
        </>
      )}

      <div className="row justify-content-center my-2">
        <div className="col-6 text-thick text-center">
          {" "}
          <Link
            to={"#"}
            className="text-thick text-black"
            onClick={handleHomeClick}
          >
            {selectedMenuIndex != -1 ? "Home" : " "}
          </Link>
        </div>
      </div>

      {/* a row ends */}
      {/* a row starts */}
      <div className="row  justify-content-center ">
        <div className="col-11">
          {message && (
            <div className="cart-message text-center text-danger bg-white column ">
              <ActionMessage
                // productName={productName}
                message={message}
              />
            </div>
          )}
        </div>
      </div>
      {/* a row ends */}
      {/* a row starts */}
      {menu && (
        <div className="row  bg-white justify-content-center mt-2">
          <div className="col-12  text-center p-2">
            <button
              className={"bigger-text text-thick btn-my" + colorScheme}
              onClick={handleAddListToggleClick}
            >
              {menu}
            </button>
          </div>
        </div>
      )}
      {/* a row ends */}
    </div>
  );
}
export default SdcNavBarAdmin;
