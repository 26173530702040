import React, { useEffect } from "react";
import axios from "axios";
import "./css/login.css";
import AdminLoginForm from "./admin-login-form";
import { BeatLoader } from "react-spinners";
import { useState } from "react";
function AdminLogin(props) {
  let [flagLoading, setFlagLoading] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  
  function handleFormSubmit(shop) {
    setFlagLoading(true);
    axios
      .post(window.routerPrefix +"/admin/check", shop, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        let r = res.data;
        setFlagLoading(false);
        props.onLoginFormSubmit(
          1,
          r.shopUrl
        );
      })
      .catch((err) => {
        console.log("error...");
        setFlagLoading(false);
      });
  }
  // let { flagLoading } = this.state;
  let content;
  if (flagLoading) {
    content = (
      <div className="text-center ">
        <BeatLoader size={16} color={"blue"} flagLoading />
      </div>
    );
  } else {
    content = <AdminLoginForm onFormSubmit={handleFormSubmit} />;
  }
  return (
    <React.Fragment>
      <div className="container-fluid container-md p-0">{content}</div>
    </React.Fragment>
  );
}
export default AdminLogin;
