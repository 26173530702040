export class SpecialOfferDB {
  constructor(specialOffers) {
    this.specialOffers = specialOffers;
  }
  allSpecialOffers() {
    return this.specialOffers;
  }
  searchBySpecialOfferId(id) {
    for (let i = 0; i < this.specialOffers.length; i++) {
      if (this.specialOffers[i].specialOfferId == id) {
        return this.specialOffers[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredSpecialOffers = [];
    for (let i = 0; i < this.specialOffers.length; i++) {
      if (this.specialOffers[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredSpecialOffers.push(this.specialOffers[i]);
      }
    } //for
    return filteredSpecialOffers;
  }
}
export default SpecialOfferDB;