import React, { Component } from "react";

function FinalYaadiProduct(props) {
  let { product } = props;
  let { srNo } = props;
  let unitDisplay;
  let subTotal = product.finalPrice * product.quantity;
  let { languageChoice } = props;
  let { dummyPrice } = props;
  let productRate;
  if (dummyPrice) {
    productRate = <span className=""> 1 </span>;
  } else if (product.mrp == product.finalPrice) {
    productRate = (
      <span>
        <i className="fas fa-rupee-sign"></i>
        <span className=""> {product.mrp}</span>
      </span>
    );
  } else if (product.mrp != product.finalPrice) {
    productRate = (
      <span>
        <i className="fas fa-rupee-sign"></i>
        <span className="striked-text"> {product.mrp} </span>
        <span className=" ">&nbsp;{product.finalPrice}</span>
      </span>
    );
  }
  subTotal = subTotal.toFixed(2);
  if (product.unitId == 1) {
    // unit is numbers
    unitDisplay = product.quantity;
  } else if (product.unitId == 2 || product.unitId == 4) {
    // unit is gms
    unitDisplay = product.quantity * 1000;
    if (unitDisplay >= 1000) {
      unitDisplay /= 1000;
      unitDisplay = unitDisplay + " kg";
    } else {
      unitDisplay = unitDisplay + " gms";
    }
  } else if (product.unitId == 3 || product.unitId == 5) {
    // unit is kg
    unitDisplay = product.quantity * 1 + " kg";
  }
  return (
    <>
      {/* a row starts.. */}
      {/* First row contains sr.no.+name+weight+price+finalprice+total */}
      <div className="row justify-content-center">
        <div className="col-5 col-md-4 product-name column text-left p-0 pl-2">
          <span>
            {srNo}. {product.name}
            {languageChoice && product.nameMarathi ? (
              <span className="bigger-text text-thick">
                /{product.nameMarathi}
              </span>
            ) : null}
          </span>
          {product.information && (
            <span className="product-information ">
              {" "}
              ({product.information})
            </span>
          )}
        </div>
        <div className="col-3 col-md-2 text-left p-0 column text-center mb-2">
          {unitDisplay}
        </div>
        <div className="col-2 col-md-2 p-0">
          <span>{productRate}</span>
        </div>

        <div className="col-2 col-md-2 text-right p-0 column text-thick mb-2 pr-2">
          {!dummyPrice ? subTotal : ""}
        </div>
      </div>
    </>
  );
}

export default FinalYaadiProduct;
