import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { FaStar } from "react-icons/fa";

function  AdminProductListSingleStockRating(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  function handleSaveProductStockRating  ()  {
    let { product } = props;
    let { shopUrl } = props;
    // save this product
   setFlagLoad(true);
    axios
      // .put("/products/" + shopUrl + "/" + product.productId, product)
      .put(window.routerPrefix +"/product/update/" + shopUrl, product)
      .then((res) => {
        setFlagLoad(false);
        props.onSaveProductStockRating(product);
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
  };
  function handleChange  (event)  {
    let userInput = event.target.checked;
    let { product } = props;
    props.onInStockCheckBoxChange(product, Number(userInput));
  };
  function handleRatingChange (value) {
    let { product } = props;
    props.onRatingChange(product, value);
  };
    let { product } = props;
    let { index } = props;
    let { languageChoice } = props;
    let { colorScheme } = props;
    let content = (
      // This is enclosed in container-list-table
      <>
        <div className="col-12 text-thick mb-1">
          <div className="">
            {index + 1}. &nbsp;
            {product.name}
            {product.information ? "-" + product.information : null}
            {languageChoice ? "/" + product.nameMarathi : null}
            &nbsp;
            {product.flagStockRatingChange &&
              !product.flagStockRatingUpdate &&
              !flagLoad && (
                <Link
                  to="#"
                  className="btn btn-danger"
                  onClick={() => handleSaveProductStockRating()}
                >
                  <span className="bigger-text">Save</span>
                </Link>
              )}
            {product.flagStockRatingUpdate && !flagLoad && (
              <span className={"text-my"+colorScheme}>(Updated..)</span>
            )}
            {flagLoad && <BeatLoader size={8} color={colorScheme} flagLoad />}
          </div>
        </div>
        <div className="col-4 text-center  mb-1 p-0 ">
          In Stock ?
          <input
            className="form-control"
            type="checkbox"
            name="instock"
            value=""
            onChange={handleChange}
            checked={product.instock}
          />
        </div>
        <div className="col-8 text-center  mb-1 p-0 ">
          Rating
          {[...Array(10)].map((star, i) => {
            return (
              <label key={i + 1}>
                <input
                  className="form-control-inline star-radio-buttons"
                  type="radio"
                  name="rating"
                  value={i + 1}
                  onClick={() => handleRatingChange(i + 1)}
                />
                <FaStar
                  className="star"
                  size={15}
                  color={i + 1 <= product.rating ? colorScheme : "grey"}
                />
              </label>
            );
          })}
        </div>
        <div className="col-12 my-2 border-mygreen"></div>
      </>
    );
    if (error) {
      return <div>Error retriving products </div>;
    } else {
      return content;
    } //else
  }
export default AdminProductListSingleStockRating;