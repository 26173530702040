import React, { Component } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Here we will create excel file containing
// name of customers, whatsapp number, total time of shopping, total amount of purchase
class OwnerCustomerData extends Component {
  grandTotal = 0;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      shops: [],
      response: {},
      flagLoading: false,
      whichDate: "Today",
      versionTypeId: 0,
      dateTo: new Date(),
      dateFrom: new Date(),
      shopId: -1,
      shop: "",
    };
  }
  pad = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };
  componentDidMount = () => {
    // Get all shopUrl
    let shops;
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix +"/shops")
      .then((res1) => {
        shops = res1.data; // only one shop
        this.setState({
          shops: res1.data, // only one shop
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  dateDiffInDays = (a, b) => {
    // Discard the time and time-zone information.
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };
  getDataFromDatabase = (whichDate) => {
    let { shop } = this.state;
    this.setState({
      flagLoading: true,
    });
    let diff1, diff2;
    if (whichDate === "Between Dates") {
      let d = new Date();
      let { dateFrom } = this.state;
      let { dateTo } = this.state;
      diff1 = this.dateDiffInDays(dateFrom, d);
      diff2 = this.dateDiffInDays(dateTo, d);
      whichDate = diff1 + "_" + diff2;
    }
    // First get list of yaadi id from mysql table yaadies
    axios
      .get(window.routerPrefix +"/reportSummary/" + shop + "/" + whichDate)
      .then((res) => {
        let yaadiList = res.data;
        this.setState({
          flagLoading: false,
        });
        let yaadiIdFirst = yaadiList[0].yaadiId;
        let yaadiIdLast = yaadiList[yaadiList.length - 1].yaadiId;
        /*=========*/
        // Now get yaadiProducts of each of these yaadies
        axios
          .get(window.routerPrefix +"/reportAll/" + shop, {
            params: {
              yaadiIdFirst: yaadiIdFirst,
              yaadiIdLast: yaadiIdLast,
            },
          })
          .then((res) => {
            let yaadiProductList = res.data;
            this.yaadiesToExcel(yaadiList, yaadiProductList);
            this.setState({
              flagLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ error: err, flagLoading: false }); // error is set
          });
        /*=========*/
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err }); // error is set
      });
  };
  getInfoFromDatabase = (whichDate) => {
    let { shop } = this.state;
    this.setState({
      flagLoading: true,
    });
    let diff1, diff2;
    if (whichDate === "Between Dates") {
      let d = new Date();
      let { dateFrom } = this.state;
      let { dateTo } = this.state;
      diff1 = this.dateDiffInDays(dateFrom, d);
      diff2 = this.dateDiffInDays(dateTo, d);
      whichDate = diff1 + "_" + diff2;
    }
    // First get list of yaadi id from mysql table yaadies
    axios
      .get(window.routerPrefix +"/reportSummary/" + shop + "/" + whichDate)
      .then((res1) => {
        let yaadiList = res1.data;
        // This yaadilist contains all information of the yaadies of
        // selected shop, write it to excel file
        this.infoToExcel(yaadiList);
        this.setState({
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err }); // error is set
      });
  };
  infoToExcel = (yaadiList) => {
    yaadiList =
      typeof yaadiList != "object" ? JSON.parse(yaadiList) : yaadiList;
    let CSV = "";
    let yaadiId;
    let dt;
    for (let k = 0; k < yaadiList.length; k++) {
      yaadiId = yaadiList[k].yaadiId;
      // a yaadi starts
      // First Show Customer Information
      //this.pad(d.getDate(), 2) +
      //this.pad(d.getMonth() + 1, 2) +
      dt = new Date(yaadiList[k].dateOfYaadi);
      let dd = this.pad(dt.getDate(), 2);
      let mm = this.pad(dt.getMonth() + 1, 2);
      let yy = dt.getFullYear();
      // Address contains commas, so replace them by -
      //   var newStr = ;
      CSV +=
        yaadiList[k].customerWhatsApp +
        "," +
        yaadiList[k].customerName +
        "," +
        yaadiList[k].customerAddress.replace(/,/g, "-") +
        "\r\n";
    } // for
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    // var fileName = "Yaadies.xls";
    let { dateFrom } = this.state;
    let { dateTo } = this.state;
    dt = new Date();
    let fileName = "All Yaadies ";
    if (this.state.whichDate === "Today") {
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    }
    if (this.state.whichDate === "Yesterday") {
      dt.setDate(dt.getDate() - 1);
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    } else if (this.state.whichDate === "Between Dates") {
      fileName +=
        this.pad(dateFrom.getDate(), 2) +
        "." +
        this.pad(dateFrom.getMonth() + 1, 2) +
        " to " +
        this.pad(dateTo.getDate(), 2) +
        "." +
        this.pad(dateTo.getMonth() + 1, 2);
    }
    var universalBOM = "\ufeff";
    var universalBOM = "%EF%BB%BF";
    //  var uri = 'data:text/plain; charset=utf-8,'+encodeURIComponent(CSV);
    // var uri = 'data:text/plain;charset=utf-8,'+encodeURIComponent(universalBOM+CSV);
    var uri = "data:text/csv;charset=utf-8," + encodeURIComponent(CSV);
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  handleDateSelection = (value) => {
    this.setState({
      whichDate: value,
    });
  };
  handleDateFromPickerClick = (date) => {
    this.setState({
      dateFrom: date,
    });
  };
  handleDateToPickerClick = (date) => {
    this.setState({
      dateTo: date,
    });
  };
  handleYaadiFileDownloadClick = () => {
    let { dateFrom } = this.state;
    let { dateTo } = this.state;
    if (dateFrom > dateTo) {
      window.alert("Enter dates correctly...");
      return;
    }
    // get yaadies of the shop, which will give yaadiid
    // for each id, get yaadiproducts
    this.getDataFromDatabase(this.state.whichDate); // value is whichDate (today/yesterday)
  };
  yaadiesToExcel = (yaadiList, yaadiProductsList) => {
    yaadiList =
      typeof yaadiList != "object" ? JSON.parse(yaadiList) : yaadiList;
    yaadiProductsList =
      typeof yaadiProductsList != "object"
        ? JSON.parse(yaadiProductsList)
        : yaadiProductsList;
    let CSV = "";
    let yaadiId;
    for (let k = 0; k < yaadiList.length; k++) {
      yaadiId = yaadiList[k].yaadiId;
      // a yaadi starts
      // First Show Customer Information
      //this.pad(d.getDate(), 2) +
      //this.pad(d.getMonth() + 1, 2) +
      let dt = new Date(yaadiList[k].dateOfYaadi);
      let dd = this.pad(dt.getDate(), 2);
      let mm = this.pad(dt.getMonth() + 1, 2);
      let yy = dt.getFullYear();
      CSV +=
        ",," +
        yaadiList[k].yaadiId +
        "," +
        dd.toString() +
        "." +
        mm.toString() +
        "." +
        yy.toString() +
        //yaadiList[k].dateOfYaadi
        "\r\n" +
        ",," +
        yaadiList[k].customerWhatsApp +
        "\r\n" +
        ",," +
        yaadiList[k].customerName +
        "\r\n" +
        "," +
        yaadiList[k].customerAddress +
        "\r\n";
      // Now show headers of the table
      let headers = [
        "S.No.",
        "Description",
        "quantity",
        // "mrp",
        "final price",
        "Total",
      ];
      var row = "";
      for (let j = 0; j < headers.length; j++) {
        row += headers[j] + ",";
      } //for
      CSV += row + "\r\n";
      // Now show Products
      //1st loop is to extract each row
      let data, total;
      for (let i = 0, t = 0; i < yaadiProductsList.length; i++) {
        if (yaadiProductsList[i].yaadiId === yaadiId) {
          // yaadiProductsList[i] contains the products of current yaadi
          var row = '"' + (t + 1) + '",';
          //2nd loop will extract each column and convert it in string comma-seprated
          for (var index in yaadiProductsList[i]) {
            data = yaadiProductsList[i][index];
            let unitId = yaadiProductsList[i]["unitId"];
            total =
              yaadiProductsList[i]["quantity"] *
              yaadiProductsList[i]["finalPrice"];
            if (index === "name") {
              if (yaadiProductsList[i]["information"]) {
                data = data + " - " + yaadiProductsList[i]["information"];
              }
            }
            if (
              index === "yaadiId" ||
              index === "nameMarathi" ||
              index === "information" ||
              index === "unitId" ||
              index === "mrp" ||
              index === "productId"
            ) {
              continue;
            }
            if (index === "quantity") {
              if (unitId == 1) {
              } else if (unitId == 2 || unitId == 4 || unitId == 5) {
                data = data * 1000;
                if (data >= 1000) {
                  data /= 1000;
                  if (data == 1) {
                    data = data + " kg";
                  } else {
                    data = data + " kgs";
                  }
                } else {
                  data = data + " gms";
                }
              } else if (unitId == 3) {
                if (data == 1) {
                  data = data + " kg";
                } else {
                  data = data + " kgs";
                }
              }
            } //... quantity
            row += '"' + data + '",';
          } //inner for
          t = t + 1;
          row += "" + total;
          CSV += row + "\r\n";
        } //if
      } //for
      CSV += ",,,," + yaadiList[k].totalAmount;
      CSV += "\r\n\r\n\r\n";
      // a yaadi ends
    } //for
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    // var fileName = "Yaadies.xls";
    let { dateFrom } = this.state;
    let { dateTo } = this.state;
    let dt = new Date();
    let fileName = "All Yaadies ";
    if (this.state.whichDate === "Today") {
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    }
    if (this.state.whichDate === "Yesterday") {
      dt.setDate(dt.getDate() - 1);
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    } else if (this.state.whichDate === "Between Dates") {
      fileName +=
        this.pad(dateFrom.getDate(), 2) +
        "." +
        this.pad(dateFrom.getMonth() + 1, 2) +
        " to " +
        this.pad(dateTo.getDate(), 2) +
        "." +
        this.pad(dateTo.getMonth() + 1, 2);
    }
    // fileName += dt.toDateString()
    // +
    // ".xls";

    //this will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    // var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    var universalBOM = "\ufeff";
    var universalBOM = "%EF%BB%BF";
    //  var uri = 'data:text/plain; charset=utf-8,'+encodeURIComponent(CSV);
    // var uri = 'data:text/plain;charset=utf-8,'+encodeURIComponent(universalBOM+CSV);
    var uri = "data:text/csv;charset=utf-8," + encodeURIComponent(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  handleInfoFileDownloadClick = () => {
    let { dateFrom } = this.state;
    let { dateTo } = this.state;
    if (dateFrom > dateTo) {
      window.alert("Enter dates correctly...");
      return;
    }
    // get yaadies of the shop, which will give info of the customers
    this.getInfoFromDatabase(this.state.whichDate);
  };
  handleNewDataFileDownloadClick = () => {
    let { dateFrom } = this.state;
    let { dateTo } = this.state;
    if (dateFrom > dateTo) {
      window.alert("Enter dates correctly...");
      return;
    }
    // get yaadies of the shop, which will give info of the customers
    this.getNewDataFromDatabase(this.state.whichDate);
  };
  getNewDataFromDatabase = (whichDate) => {
    let { shop } = this.state;
    this.setState({
      flagLoading: true,
    });
    let diff1, diff2;
    if (whichDate === "Between Dates") {
      let d = new Date();
      let { dateFrom } = this.state;
      let { dateTo } = this.state;
      diff1 = this.dateDiffInDays(dateFrom, d);
      diff2 = this.dateDiffInDays(dateTo, d);
      whichDate = diff1 + "_" + diff2;
    }
    // First get list of yaadi id from mysql table yaadies
    // Also get data from Table named customerData
    axios
      .all([
        axios.get(window.routerPrefix +"/reportSummary/" + shop + "/" + whichDate),
        axios.get(window.routerPrefix +"/customerData"),
      ])
      .then(
        axios.spread((res1, res2) => {
          let yaadiList = res1.data;
          let customerDataList = res2.data;
          // The yaadilist contains all information of the yaadies of
          // selected shop
          //from yaadiList, we have to add data of new customers to customerData table
          // this.infoToExcel(yaadiList);
          this.getListOfNewCustomers(yaadiList, customerDataList);
          this.setState({
            flagLoading: false,
          });
        })
      )
      .catch((err) => {
        console.log(err);
        this.setState({ error: err }); // error is set
      });
  };
  getListOfNewCustomers = (yaadiList, customerDataList) => {
    let i, j, flag;
    let { shop } = this.state;
    if (shop.length > 10) {
      shop = shop.substr(0, 10);
    }
    let newCustomers = [];
    for (i = 0; i < yaadiList.length; i++) {
      for (j = 0, flag = false; j < customerDataList.length; j++) {
        if (
          yaadiList[i].customerWhatsApp === customerDataList[j].whatsApp
        ) {
          flag = true;
          break;
        } //if
      } //inner for
      if (flag == false) {
        // not found, add to customerData
        let newCustomer = new Object();
        newCustomer.name = shop + " " + yaadiList[i].customerName;
        newCustomer.whatsappNumber = yaadiList[i].customerWhatsApp;
        newCustomer.address = yaadiList[i].customerAddress;
        newCustomers.push(newCustomer);
        // add in customerDataList also not to repeat
        customerDataList.push(newCustomer);
      } //if
    } //outer for
    // write this data to excel file
    this.customerDataToExcel(newCustomers);
    // write this array to customerData table in database
    // post this yaadi to server
    this.setState({
      flagLoading: true,
    });
    axios
      .post(window.routerPrefix +"/customerData", newCustomers, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => {
        this.setState({
          response: res.data,
          flagLoading: false,
        });
      })
      // this status is failure means without session somebody tried, so its supsicious
      // In such case, page of personalDetails should be shown.
      .catch((err) => {
        console.log("error...");
        this.setState({
          flagLoading: false,
        });
      });
  };
  customerDataToExcel = (newCustomers) => {
    newCustomers =
      typeof newCustomers != "object" ? JSON.parse(newCustomers) : newCustomers;
    let CSV = "";
    let yaadiId;
    let dt;
    for (let k = 0; k < newCustomers.length; k++) {
      // Address contains commas, so replace them by -
      //   var newStr = ;
      let address = newCustomers[k].address.split("\n").join(" ");
      // str
      address = address.replace(/,/g, "-");
      // address=address.replace(/\r\n,/g, "-");
      CSV +=
        newCustomers[k].name +
        "," +
        newCustomers[k].whatsappNumber +
        "," +
        address + //newCustomers[k].address.replace(/,/g, "-") +
        "\r\n";
    } // for
    if (CSV == "") {
      window.alert("No Data");
      return;
    }
    //Generate a file name
    // var fileName = "Yaadies.xls";
    let { dateFrom } = this.state;
    let { dateTo } = this.state;
    dt = new Date();
    let fileName = this.state.shop + " All Customers ";
    var universalBOM = "\ufeff";
    var universalBOM = "%EF%BB%BF";
    //  var uri = 'data:text/plain; charset=utf-8,'+encodeURIComponent(CSV);
    // var uri = 'data:text/plain;charset=utf-8,'+encodeURIComponent(universalBOM+CSV);
    var uri = "data:text/csv;charset=utf-8," + encodeURIComponent(CSV);
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  handleSelectShopChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instead of selected value
    var optionElement = event.target.childNodes[index];
    var selectedShopId = optionElement.getAttribute("id");
    let shop = event.target.value.trim();
    let shopId = selectedShopId;
    this.setState({
      shop: shop,
      shopId: shopId,
    });
  };
  render() {
    let { flagLoading } = this.state;
    let { error } = this.state;
    let { whichDate } = this.state;
    let { dateTo } = this.state;
    let { dateFrom } = this.state;
    let { versionTypeId } = this.state;
    let { shops } = this.state;
    let { shop } = this.state;
    let { shopId } = this.state;
    let optionsShops = shops.map((shop, index) => (
      <option value={shop.shopUrl} key={index} id={shop.shopId}>
        {shop.shopUrl}
      </option>
    ));
    let d = new Date();
    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        <div className="column col-12">
          <div className="form-heading">Select a Shop</div>
        </div>
        {/* row starts */}
        <div className="row justify-content-center">
          <div className="col-6 px-0">
            <select
              className="form-control"
              name="shop"
              value={this.state.shop}
              onChange={this.handleSelectShopChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
            >
              <option> Select Shop </option>
              {optionsShops}
            </select>
          </div>
        </div>
        {/* row starts */}
        {shopId != -1 ? (
          <div className="row my-2">
            <div className="offset-4 col-8 my-2">
              <input
                type="radio"
                className="form-control-inline"
                name="whichDate"
                value="Yesterday"
                onClick={() => this.handleDateSelection("Yesterday")}
                onChange={() => this.handleDateSelection("Yesterday")}
              />
              &nbsp;Yesterday
            </div>
            <div className="offset-4 col-8 my-2">
              <input
                type="radio"
                className="form-control-inline"
                name="whichDate"
                value="Today"
                onClick={() => this.handleDateSelection("Today")}
                onChange={() => this.handleDateSelection("Today")}
                checked={whichDate === "Today"}
              />
              &nbsp;Today
            </div>
            <div className="offset-4 col-8 my-2">
              <input
                type="radio"
                className="form-control-inline"
                name="whichDate"
                value="Between Dates"
                onClick={() => this.handleDateSelection("Between Dates")}
                onChange={() => this.handleDateSelection("Between Dates")}
                checked={whichDate === "Between Dates"}
              />
              &nbsp;Between Dates:
            </div>
          </div>
        ) : null}
        {/* row ends */}
        {whichDate === "Between Dates" ? (
          <div className="row justify-content-start">
            <div className="col-4 my-2 text-right column">From:</div>
            <div className="col-8 my-2 text-left column">
              <DatePicker
                selected={dateFrom}
                selectsStart
                startDate={dateFrom}
                endDate={dateTo}
                dateFormat="yyyy/MM/dd"
                minDate={d.setDate(d.getDate() - 60)}
                // maxDate={d}
                onChange={(date) => this.handleDateFromPickerClick(date)}
              />
            </div>

            <div className="col-4 my-2 text-right ">To:</div>
            <div className="col-8 my-2 text-left">
              <DatePicker
                selected={dateTo}
                selectsEnd
                startDate={dateFrom}
                endDate={dateTo}
                dateFormat="yyyy/MM/dd"
                maxDate={new Date()}
                onChange={(date) => this.handleDateToPickerClick(date)}
              />
            </div>
          </div>
        ) : null}
        {/* </div> */}
        {shopId != -1 ? (
          <div className="row justify-content-center mb-3">
            <div className="col-12 my-2 text-center">
              <button
                className="btn btn-primary"
                onClick={this.handleYaadiFileDownloadClick}
              >
                Download {whichDate}'s yaadies
              </button>
            </div>
            <div className="col-12 my-2 text-center">
              <button
                className="btn btn-primary"
                onClick={this.handleInfoFileDownloadClick}
              >
                Download {whichDate}'s Customer's Info
              </button>
            </div>
            <div className="col-12 my-2 text-center">
              <button
                className="btn btn-primary"
                onClick={this.handleNewDataFileDownloadClick}
              >
                Download {whichDate}'s new customers' data
              </button>
            </div>
          </div>
        ) : null}
        {/* row ends */}
        {flagLoading && (
          <div className="text-center mt-5">
            <BeatLoader size={16} color={"blue"} flagLoading />
          </div>
        )}
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving Yaadies </div>;
    } else {
      return (
        // <div className="container-fluid bg-blue">
        // <div className="row">
        <div className="container-fluid container-list-table p-0">
          {content}
        </div>
        // </div>
        // </div>
      );
    } //else
  }
}
export default OwnerCustomerData;
