import React from "react";
import AdminProductListSingleStockRating from "./admin-product-list-single-stockrating";

function  AdminProductListStockRating (props) {

  function handleSaveProductStockRating  (product) {
    props.onSaveProductStockRating(product);
  };
  function handleRatingChange  (product, newRating)  {
    props.onRatingChange(product, newRating);
  };
  function handleInStockCheckBoxChange  (product, userInput)  {
    props.onInStockCheckBoxChange(product, userInput);
  };
  function handleCrudAction  (message, action)  {
    props.onCrudAction(message, action);
  };
  function handleSearchTextBoxKeyUp  (event)  {
    props.onSearchTextBoxKeyUp(event);
  };
  function handleSearchTextChange (event)  {
    props.onSearchTextChange(event);
  };
    let { filteredProducts } = props;
    let { searchText } = props;
    let { languageChoice } = props;
    let { shopUrl } = props;
    let { productsLimit } = props;
    let { currentSize } = props;
    let { colorScheme } = props;
    
    let contentNoproducts = <div className="text-center">No products !</div>;
    let content = (
      // This is enclosed in container-list-table
      <>
        <div className="form-heading  col-12">
        Product Stock and Rating
        </div>
        {/* row starts */}
        <div className="row justify-content-center">
          <div className="col-10 col-md-6 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={handleSearchTextBoxKeyUp}
              onChange={handleSearchTextChange} // This is contolled by parent
              placeholder="Search Products"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row px-2">
          {filteredProducts.map((product, index) => (
            <AdminProductListSingleStockRating
              product={product}
              key={index}
              index={index}
              languageChoice={languageChoice}
              onSaveProductStockRating={handleSaveProductStockRating}
              onRatingChange={handleRatingChange}
              onInStockCheckBoxChange={handleInStockCheckBoxChange}
              onCrudAction={handleCrudAction}
              shopUrl={shopUrl}
              productsLimit={productsLimit}
              currentSize={currentSize}
              colorScheme={colorScheme}
            />
          ))}
        </div>
      </>
    );
if (filteredProducts.length >= 0) {
      return (
        <div className="">{content}</div>
      );
    } //else
    else if (filteredProducts.length == 0) {
      return contentNoproducts;
    } //else
  }
export default AdminProductListStockRating;
