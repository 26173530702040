import React, { Component } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { CustomerDB } from "../customerDB";

class AdminMakeYaadiCustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagLoading: false,
      filteredCustomers: [],
      customerList: [],
      error: null,
      response: {},
      flagGotCustomerData: false,
    };
  }
  componentDidMount() {
    this.getCustomerListFromDatabase();
    this.setState({
      flagGotCustomerData: true,
    });
  }
  getCustomerListFromDatabase = () => {
    let { flagGotCustomerData } = this.state;
    if (flagGotCustomerData) return;
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
    });
    axios
      .get("window.routerPrefix +/customers/" + shopUrl)
      .then((res) => {
        let customerList = res.data;
        this.setState({
          filteredCustomers: customerList,
          customerList: customerList,
          customerdb: new CustomerDB(res.data),
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  handleEditCustomer = (customer) => {
    this.props.onEditCustomer(customer);
  };
  handleDeleteCustomer = (customer) => {
    this.props.onDeleteCustomer(customer);
  };
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedCustomers = [];
    searchedCustomers = this.state.customerdb.filterByName(query);
    this.setState({
      filteredCustomers: searchedCustomers,
    });
  };

  handleCustomerSelectClick = (event) => {
    let customerId = event.target.id;
    let { customerList } = this.state;
    // get this customer from customerList
    let i, customer;
    for (i = 0; i < customerList.length; i++) {
      if (customerList[i].customerId == customerId) {
        customer = customerList[i];
        break;
      } //if
    }
    this.props.onCustomerSelectClick(customer);
  };
  render() {
    let { error } = this.state;
    let { searchText } = this.props;
    let { sectionNumber } = this.props;
    let { filteredCustomers } = this.state;
    let { flagLoading } = this.state;
    if (flagLoading)
      return (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        {/* row starts */}
        <div className="row justify-content-center">
          <div className="col-10 col-md-6">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={this.handleSearchTextBoxKeyUp}
              onChange={this.handleSearchTextChange} // This is contolled by parent
              placeholder="Search Customers"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th>Customer</th>
                  </tr>
                </thead>
                {filteredCustomers.map((customer, index) =>
                  index < 10 ? (
                    <tbody key={index}>
                      <tr className="myborder-top">
                        <td>{customer.name}</td>
                      </tr>
                      <tr>
                        <td>{customer.whatsApp}</td>
                      </tr>
                      <tr>
                        <td>{customer.address}</td>
                      </tr>
                      <tr>
                        <td>
                          <button
                            className="btn-mycyan"
                            onClick={this.handleCustomerSelectClick}
                            id={customer.customerId}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  ) : null
                )}
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving Customers </div>;
    } else {
      return (
        // <div className="container-fluid bg-blue">
        //   <div className="row">
        <div className="col-md-12">{content}</div>
        //   </div>
        // </div>
      );
    }
  }
}
export default AdminMakeYaadiCustomerList;
