import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import axios from "axios";

function AdminCategoryAdd(props) {
  let [category, setCategory] = useState({
    categoryId: "",
    name: "",
    rating: 5,
    categoryShopType: "",
    categoryShopTypeId: 0,
  });
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [errorCategory, setErrorCategory] = useState({
    name: { message: "", mxLen: 30, mnLen: 1, onlyDigits: false },
    categoryShopType: { message: "" },
    categoryShopTypeId: { message: "" },
  });
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let [file, setFile] = useState("");
  let [fileName, setFileName] = useState("");
  let { shopUrl } = props;
  let { shopTypeId } = props;

  let emptyCategory;
  // constructor(props) {
  //   super(props);
  //   let category;
  //   let flagFormInvalid;
  //   let { action } = props;
  //   if (action === "ADD") {
  //     flagFormInvalid = true;
  //     category = emptyCategory;
  //   } else if (action === "UPDATE") {
  //     // in edit mode, keep the update button enabled at the beginning
  //     flagFormInvalid = false;
  //     category = props.category;
  //   }
  //   state = {
  //     category: category,
  //     errorCategory: errorCategory,
  //     flagFormInvalid: flagFormInvalid,
  //   };
  // }
  useEffect(() => {
    init();
  }, [props]);

  function init() {
    emptyCategory = category;
    let { action } = props;
    if (action === "ADD") {
      setFlagFormInvalid(true);
      // may retain some properties
      emptyCategory.categoryShopTypeId = props.shopTypeId; //2023
      setCategory(emptyCategory);
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      setFlagFormInvalid(false);
      setCategory(props.category);
    }
   
  }
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      setCategory({ ...category, [name]: event.target.value });
      return;
    }
    if (inputType == "file") {
      let file = event.target.files[0];
      if (!file) {
        return;
      }
      let fileType = file.type.substring(0, file.type.indexOf("/"));
      let errCategory = { ...errorCategory };
      let fileName = file.name;

      if (errorCategory[name]["imageType"] != fileType) {
        errorCategory[`${name}`].message = "Check type of file";
        setErrorCategory(errCategory);
      } else {
        errorCategory[`${name}`].message = "";
        setErrorCategory(errCategory);
        setCategory({ ...category, [name]: event.target.value });
        setFile(file);
        setFileName(fileName);
      }
      return;
    }
    setCategory({ ...category, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorCategory[`${name}`].mnLen;
      mxLen = errorCategory[`${name}`].mxLen;
      onlyDigits = errorCategory[`${name}`].onlyDigits;
      allDigits = errorCategory[`${name}`].allDigits;
      noSymbols = errorCategory[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      // else if (!allDigits) {
      //   for (i = value.length - 1, flag = false; i >= 0; i--) {
      //     const d = value.charCodeAt(i);
      //     if (d < 48 || d > 57) {
      //       message = "";
      //       flag = true;
      //       break;
      //     } //if
      //   } //for
      //   if (flag == false) {
      //     message = "Only digits are not allowed, use some alphabets also";
      //   }
      // } //else if... allDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errCategory = { ...errorCategory };
    errorCategory[`${name}`].message = message;
    setErrorCategory(errCategory);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorCategory) {
      if (errorCategory[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleRatingClick(value) {
    setCategory({ ...category, rating: value });
  }
  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onFormSubmit(category);
  }
  function handleCancelClick() {
    props.onCancelClick();
  }
  function handleSelectShopTypeChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var categoryShopTypeId = optionElement.getAttribute("id");
    let categoryShopType = event.target.value.trim();
    setCategory({
      ...category,
      categoryShopType: categoryShopType,
      categoryShopTypeId: categoryShopTypeId,
    });
  }
  let { action } = props;
  let { colorScheme } = props;
  let { shopTypeList } = props;
  
  let heading;
  if (action === "ADD") {
    heading = "Add a Category";
  } else if (action === "UPDATE") {
    heading = "Update the Category";
  }
  let optionsShopType = shopTypeList.map((shoptype, index) => (
    <option value={shoptype.name} key={index} id={shoptype.shopTypeId}>
      {shoptype.name}
    </option>
  ));
  return (
    <>
      <div className="form-heading column col-12">{heading}</div>
      <form
        className={"text-thick p-4 bg-my" + colorScheme}
        onSubmit={handleSubmit}
      >
        <div className="form-group row column align-items-center">
          {/* row starts */}
          <div className="col-5 text-right">
            <label>Category</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="name"
              value={category.name}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Category name"
              required
            />
          </div>
          <div className="offset-5">
            {errorCategory.name.message ? (
              <span className="error-text">{errorCategory.name.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Rating</label>
          </div>
          <div className="col-7 px-0">
            {[...Array(10)].map((star, i) => {
              return (
                <label key={i + 1}>
                  <input
                    className="form-control-inline star-radio-buttons"
                    type="radio"
                    name="rating"
                    value={i + 1}
                    onClick={() => handleRatingClick(i + 1)}
                  />
                  <FaStar
                    className="star"
                    size={18}
                    color={i + 1 <= category.rating ? colorScheme : "grey"}
                  />
                </label>
              );
            })}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        {/* Only for masterlist, this facility is there 2023 */}
        {shopUrl == window.masterList && (
          <div className="form-group row  align-items-center">
            <div className="col-4 text-right">
              <label>Store Type</label>
            </div>
            <div className="col-8 px-0">
              <select
                className="form-control"
                name="categoryShopType"
                value={category.categoryShopType}
                onChange={handleSelectShopTypeChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              >
                <option> Select Store Type </option>
                {optionsShopType}
              </select>
            </div>
          </div>
        )}
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row offset-5 text-center mb-0">
          <button
            className={"btn-my" + colorScheme + "-dark"}
            type="submit"
            disabled={flagFormInvalid}
          >
            {action}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            className={"btn-my" + colorScheme + "-dark"}
            onClick={handleCancelClick}
          >
            CANCEL
          </button>
        </div>
        <input type="hidden" name="categoryId" value={category.categoryId} />
      </form>
    </>
  );
}
export default AdminCategoryAdd;
