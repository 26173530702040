import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { BeatLoader } from "react-spinners";

function AdminSettingsStore(props) {
  let [shop, setShop] = useState({
    shopId: "",
    colorScheme: "",
    languageChoice: "",
    plan: "",
    productsLimit: "",
  });
  let [errorShop, setErrorShop] = useState({
    colorScheme: {
      message: "",
    },
    languageChoice: {
      message: "",
    },
    productsLimit: {
      message: "",
    },
  });

  let emptyShop;
  let [flagLoad, setFlagLoad] = useState(false);
  let [formUpdateStatus, setformUpdateStatus] = useState("no");
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let [imagePreview, setImagePreview] = useState("");
  let [file, setFile] = useState("");
  let [fileName, setFileName] = useState("");
  let [error, setError] = useState("");
  let [brandList, setBrandList] = useState([]);
  let [selectedColorScheme, setSelectedColorScheme] = useState(null);
  let [selectedPlan, setSelectedPlan] = useState(null);
  const customStyles = {
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        color: isFocused ? "black" : "black",
        backgroundColor: isFocused ? "white" : "white",
      };
    },
  };
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      setShop({ ...shop, [name]: event.target.value });
      return;
    }
    if (inputType == "file") {
      let file = event.target.files[0];
      if (!file) {
        return;
      }
      let fileType = file.type.substring(0, file.type.indexOf("/"));
      let errShop = { ...errorShop };
      let fileName = file.name;

      if (errorShop[name]["imageType"] != fileType) {
        errorShop[`${name}`].message = "Check type of file";
        setErrorShop(errShop);
      } else {
        errorShop[`${name}`].message = "";
        setErrorShop(errShop);
        setShop({ ...shop, [name]: event.target.value });
        let imagePreview = URL.createObjectURL(file);
        setImagePreview(imagePreview);
        setFile(file);
        setFileName(fileName);
      }
      return;
    }
    setShop({ ...shop, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorShop[`${name}`].mnLen;
      mxLen = errorShop[`${name}`].mxLen;
      onlyDigits = errorShop[`${name}`].onlyDigits;
      allDigits = errorShop[`${name}`].allDigits;
      noSymbols = errorShop[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      // else if (!allDigits) {
      //   for (i = value.length - 1, flag = false; i >= 0; i--) {
      //     const d = value.charCodeAt(i);
      //     if (d < 48 || d > 57) {
      //       message = "";
      //       flag = true;
      //       break;
      //     } //if
      //   } //for
      //   if (flag == false) {
      //     message = "Only digits are not allowed, use some alphabets also";
      //   }
      // } //else if... allDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }

    // errorShop[`${name}`].message = message;
    let errShop = { ...errorShop };
    errorShop[`${name}`].message = message;
    setErrorShop(errShop);
    checkAllErrors();
  }
  function handleFocus(event) {
    let name = event.target.name;
    let errShop = { ...errorShop };
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorShop) {
      if (errorShop[field].message !== "") {
        // error is there
        // flagFormInvalid = true;
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleFormCancelClick() {
    props.onCancelFormClick();
  }
  function getShopFromDatabase() {
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .all([axios.get(window.routerPrefix + "/shop/byShopUrl/" + shopUrl)])
      .then(
        axios.spread((res) => {
          if (!res) {
            setFlagLoad(false);
            return;
          }
          let sh = res.data;
          setShop(sh);
          setSelectedColorScheme({
            name: sh.colorScheme,
            label: sh.colorScheme,
          });
          // setSelectedPlan({name:sh.productsLimit, label:"plan-"+sh.productsLimit});
          setSelectedPlan({
            name: sh.productsLimit,
            label:
              "plan-" +
              sh.productsLimit +
              " (max." +
              sh.productsLimit +
              " products, Rs. 299/mnth)",
          });

          setFlagLoad(false);
        })
      )
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setError(err);
      });
  }
  useEffect(() => {
    emptyShop = shop;
    getShopFromDatabase();
  }, []);
  function handleSubmit(event) {
    event.preventDefault();
    window.scroll(0, 0);
    setFlagLoad(true);
    setformUpdateStatus("yes");
    let extension = fileName.substring(fileName.indexOf("."), fileName.length);
    shop.imageLogo = shop.shopUrl + "_logo" + extension;
    shop.colorScheme = selectedColorScheme.name;
    shop.productsLimit = selectedPlan.name;
    //EDIT / PUT
    axios
      .put(window.routerPrefix + "/shop/updateStoreSettings/", shop)
      .then((res) => {
        setFlagLoad(false);
        setformUpdateStatus("done");
        let message = "Store Settings updated successfully.";
        props.updateStoreSettings(shop);
        props.onCrudAction(message, "", "");
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setformUpdateStatus("done");
      });
  }
  function fileChangedHandler(event) {
    let file = event.target.files[0];
    if (!file) {
      this.setState({ message: "" });
      return;
    }
    let fileType = file.type.substring(0, file.type.indexOf("/"));
    if (fileType != "image") {
      this.setState({ message: "Invalid file..." });
    }
    //  else if (file.size > 5000) {
    //   this.setState({ message: "File-size should be below 5kb" });
    // }
    else {
      this.setState({ message: "" });
    }
    let previewImage = URL.createObjectURL(file);
    this.setState({ file: file });
    this.setState({ previewImage: previewImage });
  }
  function handleSelectColorSchemeChange(event) {
    let colorScheme = event.target.value.trim();
    setShop({ ...shop, colorScheme: colorScheme });
  }
  function handleLanguageChoiceSelection(value) {
    setShop({ ...shop, languageChoice: value });
  }
  let { action } = props;
  let { shopDetails } = props;
  let { colorScheme } = props;
  let optionsColorScheme = [
    { name: "green", label: "green" },
    { name: "blue", label: "blue" },
    { name: "cyan", label: "cyan" },
    { name: "brown", label: "brown" },
    { name: "purple", label: "purple" },
    { name: "yellow", label: "yellow" },
  ];
  let optionsPlan = [
    { name: "150", label: "plan-150 (max. 150 products, Rs. 399/mnth)" },
    { name: "500", label: "plan-500 (max. 500 products, Rs. 499/mnth)" },
  ];
  if (shopDetails.status == "not ready" && formUpdateStatus == "yes") {
    return (
      <div className="container-fluid container-md container-list-table">
        <div className="form-heading">
          Wait... Your store is getting activated
        </div>
        {flagLoad && (
          <div className="text-center">
            <BeatLoader size={16} color={colorScheme} flagLoading />
          </div>
        )}
      </div>
    );
  }
  if (flagLoad) {
    return (
      <div className="container-fluid container-md container-list-table">
        <div className="text-center">
          <BeatLoader size={16} color={colorScheme} flagLoading />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="container-fluid container-md container-list-table">
        <div className="form-heading col-12">Store Settings</div>
        <form
          className={"text-thick p-4 bg-my" + colorScheme}
          onSubmit={handleSubmit}
        >
          {/* row starts */}
          {/* row starts */}
          <div className="form-group row  align-items-center">
            <div className="col-4 text-right">
              <label>Color Scheme</label>
            </div>
            <div className="col-8 px-0">
              <Select
                defaultValue={selectedColorScheme}
                // value={selectedColorScheme}
                onChange={setSelectedColorScheme}
                options={optionsColorScheme}
                isMulti={false}
                styles={customStyles}
                // isDisabled={resultShopTypeSelection.isDone == "Completed"}
              />
            </div>
            <div className="offset-5">
              {errorShop.colorScheme.message ? (
                <span className="error-text">
                  {errorShop.colorScheme.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row starts */}
          {/* row starts */}
          <div className="form-group row  align-items-center">
            <div className="col-4 text-right">
              <label>Language for product-name</label>
            </div>
            <div className="col-8  my-2 text-left ">
              <input
                type="radio"
                className="form-control-inline"
                name="languageChoice"
                value="1"
                onClick={() => handleLanguageChoiceSelection(1)}
                onChange={() => handleLanguageChoiceSelection(1)}
                checked={shop.languageChoice == 1}
              />
              &nbsp;English+Regional &nbsp;&nbsp;&nbsp;&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="languageChoice"
                value="0"
                onClick={() => handleLanguageChoiceSelection(0)}
                onChange={() => handleLanguageChoiceSelection(0)}
                checked={shop.languageChoice == 0}
              />
              &nbsp;English
            </div>
          </div>
          {/* row starts */}
          <div className="form-group row  align-items-center">
            <div className="col-4 text-right">
              <label>Plan</label>
            </div>
            <div className="col-8 px-0">
              <Select
                defaultValue={selectedPlan}
                // value={selectedColorScheme}
                onChange={setSelectedPlan}
                options={optionsPlan}
                styles={customStyles}
              />
            </div>
            <div className="offset-5">
              {errorShop.productsLimit.message ? (
                <span className="error-text">
                  {errorShop.productsLimit.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row starts */}

          {!flagLoad && (
            <div className="form-group row offset-5 text-center mb-0">
              <button
                className={"btn-my" + colorScheme + "-dark"}
                type="submit"
                disabled={flagFormInvalid}
              >
                {action}
              </button>
              &nbsp; &nbsp;
              <button
                className={"btn-my" + colorScheme + "-dark"}
                onClick={handleFormCancelClick}
                type="cancel"
              >
                Cancel
              </button>
            </div>
          )}
          {flagLoad && (
            <div className="text-center">
              <BeatLoader size={16} color={colorScheme} flagLoading />
            </div>
          )}
          <input type="hidden" name="shopId" value={shop.shopId} />
        </form>
      </div>
    </>
  );
}
export default AdminSettingsStore;
