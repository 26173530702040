import React, { Component } from "react";
import AdminMakeYaadiSingle from "./admin-make-yaadi-single";
import { BeatLoader } from "react-spinners";
import NavBarAdminMakeYaadi from "./navbar-admin-make-yaadi";
import AdminMakeYaadiCustomerInfo from "./admin-make-yaadi-customer-info";

import axios from "axios";
// This component is not used yet 2023
class AdminMakeYaadi extends Component {
  // localYaadies = ["yaadi1", "yaadi2", "yaadi3"];
  maxYaadiesAllowed = 3;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      productList: [],
      action: "BLANK",
      flagLoading: false,
      // flagYaadiStart: false,
      itemCount: 1,
      pendingYaadies: [],
      newYaadiNumber: -1,
      pendingYaadiSelected: -1,
      currentYaadi: -1,
      currentYaadiContent: [],
      flagGotProductData: false,
      productCount: -1,
    };
  }
  componentDidMount() {
    // In local storage create space to store 3 yaadies
    let { shopUrl } = this.props;
    this.localYaadies = [];
    for (let i = 0; i < this.maxYaadiesAllowed; i++) {
      this.localYaadies.push(shopUrl + "_yaadi" + (i + 1));
    }
    let localYaadi;
    for (let i = 0; i < this.localYaadies.length; i++) {
      localYaadi = window.localStorage.getItem(this.localYaadies[i]);
      if (!localYaadi) {
        // create it
        window.localStorage.setItem(this.localYaadies[i], JSON.stringify([]));
      }
    } //for
    // now check which yaadi has items in it.
    // the empty one will be selected for new yaadi
    let pendingYaadies = [],
      flag = false,
      currentYaadi,
      newYaadiNumber;
    for (let i = 0; i < this.localYaadies.length; i++) {
      localYaadi = JSON.parse(
        window.localStorage.getItem(this.localYaadies[i])
      );
      if (localYaadi.length != 0) {
        pendingYaadies.push(i + 1);
      } else if (flag == false) {
        newYaadiNumber = i + 1;
        flag = true;
      }
    } //for
    this.setState({
      pendingYaadies: pendingYaadies,
      newYaadiNumber: newYaadiNumber,
    });
  }
  getProductListFromDatabase = () => {
    // Get data only once
    this.setState({
      flagLoading: true,
    });
    let { flagGotProductData } = this.state;
    if (flagGotProductData) return;
    let { shopUrl } = this.props;

    axios
      .get(window.routerPrefix + "/products/" + shopUrl + "/name")
      .then((res) => {
        let productList = res.data;
        // modify name of product
        // for (let i = 0; i < products.length; i++) {
        //   products[i].name = this.getDescription(products[i]);
        // }
        this.setState({
          productList: productList,
          flagLoading: false,
          flagGotProductData: true,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  getDescription = (product) => {
    let description;
    description = product.name;
    if (product.information) {
      description += "-" + product.information;
    }
    if (product.nameMarathi) {
      description += "/" + product.nameMarathi;
    }
    if (product.finalPrice != product.mrp) {
      description += "  " + product.finalPrice + " (" + product.mrp + ")";
    } else {
      description += product.mrp;
    }
    return description;
  };
  // onSelectQuantity
  handleSelectQuantity = (srNo, product, quantity) => {
    // product is already added, just edit the quantity
    this.handleEditProduct(srNo, product, quantity);
  };
  // onSelectProduct
  handleSelectProduct = (srNo, product, quantity) => {
    let { currentYaadiContent } = this.state;
    if (srNo <= currentYaadiContent.length) {
      // Its edit mode
      this.handleEditProduct(srNo, product, quantity);
    } else {
      // or add mode, just product is selected.
      // Add it
      this.handleAddProduct(srNo, product, quantity);
    }
  };
  handleEditProduct = (srNo, product, quantity) => {
    // update in the currentYaadiContentcart
    // search this from cartItems and update
    let currentYaadiContent = [...this.state.currentYaadiContent];
    currentYaadiContent[srNo - 1] = {
      // srNo-1 because in array, position starts with 0
      productId: product.productId,
      name: product.name,
      mrp: product.mrp,
      finalPrice: product.finalPrice,
      unitId: product.unitId,
      quantity: quantity,
    };
    this.setState({
      currentYaadiContent: currentYaadiContent,
      productCount: currentYaadiContent.length,
    });
    this.updateLocalStorage(currentYaadiContent);
  };
  handleAddProduct = (srNo, product, quantity) => {
    // key is the sr. number of item-row
    if (srNo <= this.state.currentYaadiContent.length) {
      // its edit mode
      this.handleEditProduct(srNo, product, quantity);
    } else {
      // add-mode
      // add to local storage
      let currentYaadiContent = [...this.state.currentYaadiContent];
      currentYaadiContent.push({
        productId: product.productId,
        name: product.name,
        mrp: product.mrp,
        finalPrice: product.finalPrice,
        unitId: product.unitId,
        quantity: quantity,
      });
      this.setState({
        currentYaadiContent: currentYaadiContent,
        productCount: currentYaadiContent.length,
      });
      this.updateLocalStorage(currentYaadiContent);
    } //else
  };
  handleDeleteProduct = (productId) => {
    // search this from cartItems and delete
    this.setState({ flagLoading: true });
    let { currentYaadiContent } = this.state;
    let cart = [];
    for (let i = 0; i < currentYaadiContent.length; i++) {
      if (currentYaadiContent[i].productId != productId) {
        cart.push(currentYaadiContent[i]);
      }
    } //for
    this.updateLocalStorage(cart);
    this.setState({
      currentYaadiContent: cart,
      productCount: cart.length,
      flagLoading: false,
    });
  };
  // Update cartItems list in the local storage
  updateLocalStorage = (currentYaadiContent) => {
    let { currentYaadi } = this.state;
    let { shopUrl } = this.props;
    window.localStorage.setItem(
      shopUrl + "_yaadi" + currentYaadi,
      JSON.stringify(currentYaadiContent)
    );
  };

  handleBackClick = () => {
    this.props.onBackClick();
  };
  handleKeepPendingClick = () => {
    // Add currentYaadi to pending list
    let currentYaadi = this.state.currentYaadi;
    let pendingYaadies = [...this.state.pendingYaadies];
    let i, flag;
    for (i = 0, flag = false; i < pendingYaadies.length; i++) {
      if (currentYaadi === pendingYaadies[i]) {
        flag = true;
        break; // it is already pending, don't add again to pending list
      }
    }
    if (flag == false) {
      pendingYaadies.push(currentYaadi);
    }
    //this.newYaadiNumber may change now, find it
    flag = false;
    let newYaadiNumber;
    for (i = 0; i < this.localYaadies.length; i++) {
      let localYaadi = JSON.parse(
        window.localStorage.getItem(this.localYaadies[i])
      );
      if (localYaadi.length == 0) {
        newYaadiNumber = i + 1;
        flag = true;
        break;
      }
    } //for
    if (flag == false) {
      // all yaadies are pending
      newYaadiNumber = -1;
    }
    this.setState({
      pendingYaadies: pendingYaadies,
      // flagYaadiStart: false,
      action: "BLANK",
      newYaadiNumber: newYaadiNumber,
    });
  };
  handleCancelClick = () => {
    this.setState({
      // flagYaadiStart: false,
      action: "BLANK",
    });
  };
  handleDoneClick = () => {
    this.setState({
      action: "CUSTOMERINFO",
    });
  };
  handleNewYaadiClick = () => {
    // Admin wants new yaadi
    // First get product list from server
    this.getProductListFromDatabase();
    let { shopUrl } = this.props;
    let currentYaadiContent = JSON.parse(
      window.localStorage.getItem(
        shopUrl + "_yaadi" + this.state.newYaadiNumber
      )
    );
    // currentYaadiContent.push({});
    // start the new yaadi now
    this.setState({
      action: "PREPARE",
      currentYaadi: this.state.newYaadiNumber,
      currentYaadiContent: currentYaadiContent,
      productCount: currentYaadiContent.length,
    });
  };

  handlePendingYaadiSelection = (value) => {
    this.getProductListFromDatabase();
    let { shopUrl } = this.props;
    let currentYaadi = value;
    let currentYaadiContent = JSON.parse(
      window.localStorage.getItem(shopUrl + "_yaadi" + currentYaadi)
    );
    this.setState({
      // flagYaadiStart: true,
      action: "PREPARE",
      currentYaadi: currentYaadi,
      currentYaadiContent: currentYaadiContent,
      productCount: currentYaadiContent.length,
    });
  };
  render() {
    let { shopUrl } = this.props;
    let versionTypeId = this.props.shopDetails.versionTypeId;
    let { message } = this.props;
    let { flagLoading } = this.state;
    let { action } = this.state;
    let { productList } = this.state;
    let { pendingYaadies } = this.state;
    let { currentYaadi } = this.state;
    let { currentYaadiContent } = this.state;
    let { productCount } = this.state;
    let content, navBar;
    // console.log(versionTypeId+"@@@@@@@@");
    navBar = (
      <NavBarAdminMakeYaadi
        onBackClick={this.handleBackClick}
        onNewYaadiClick={this.handleNewYaadiClick}
        onKeepPendingClick={this.handleKeepPendingClick}
        onCancelClick={this.handleCancelClick}
        onPendingYaadiSelection={this.handlePendingYaadiSelection}
        onDoneClick={this.handleDoneClick}
        message={message}
        itemCount={currentYaadiContent.length}
        action={action}
        pendingYaadies={pendingYaadies}
        maxYaadiesAllowed={this.maxYaadiesAllowed}
      />
    );
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    } else if (versionTypeId != 3) {
      content = (
        <div className="bigger-text text-thick text-center">
          This feature is available in Turbo version !!!
        </div>
      );
    } else if (action === "PREPARE") {
      content = [...new Array(productCount + 1)].map((cycle, index) => (
        <AdminMakeYaadiSingle
          onAddProduct={this.handleAddProduct}
          onSelectQuantity={this.handleSelectQuantity}
          onDeleteProduct={this.handleDeleteProduct}
          onSelectProduct={this.handleSelectProduct}
          productList={productList}
          key={index}
          srNo={index + 1} // S. Number of product, initially 1
          productCount={productCount}
          action={action}
          currentYaadi={currentYaadi}
          product={
            productCount == 0
              ? null
              : index < productCount
              ? currentYaadiContent[index]
              : null
          }
          // searchText={index < productCount ? currentYaadiContent[index].name : ""}
        />
      ));
    } else if (action === "CUSTOMERINFO") {
      content = <AdminMakeYaadiCustomerInfo shopUrl={shopUrl} />;
    }
    return (
      <div className="container-fluid container-md container-list-table ">
        {versionTypeId == 3 && navBar}
        {content}
      </div>
    );
  }
}
export default AdminMakeYaadi;
