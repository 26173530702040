import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/home";
import HomePage from "./components/home-page";
import CheckOut from "./components/check-out";
import OrderSuccess from "./components/order-success";
import AdminActivity from "./components/admin-activity";
import SdcAdminActivity from "./components/sdc-admin-activity";
import OwnerActivity from "./components/owner-activity";
import FinalYaadiAnytime from "./components/final-yaadi-anytime";
import Registration from "./components/registration";
window.routerPrefix = "api"; //production
window.masterList = "masterlist"; //production
class App extends Component {
  handleAdminLogIn = () => {
    this.setState({
      userName: "Admin",
      userType: "Admin",
    });
  };
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {/* <Route
            exact
            path="/"
            render={(props) => (
              <Home
                filteredProducts={this.state.filteredProducts}
                onCategoryClick={this.handleCategoryClick}
                onBrandClick={this.handleBrandClick}
                onShowAllClick={this.handleShowAllClick}
                selectedCategoryName={this.selectedCategoryName}
                selectedBrandName={this.selectedBrandName}
                searchBy={this.state.searchBy}
                matchedFlag={this.state.matchedFlag}
                addToCartItemList={this.addToCartItemList}
                cartItems={this.state.cartItems}
                changeProductQuantity={this.changeProductQuantity}
              />
            )}
          /> */}
          <Route exact path="/" component={HomePage} />
          {/* <Route path="/admin" component={AdminActivity} /> */}
          <Route path="/adminpanel" component={AdminActivity} />
          <Route path="/ownerrajesh" component={OwnerActivity} />
          <Route path="/yaadilist" component={FinalYaadiAnytime} />
          <Route path="/registration" component={Registration} />
          <Route path="/sdcsdcsdcsdc" component={SdcAdminActivity} />
          {/* <Route path="/admin/products" component={AdminActivityProduct} />
          <Route path="/admin/orders" component={AdminActivityOrder} />
          <Route path="/admin/categories" component={AdminActivityCategory} />
          <Route path="/admin/brands" component={AdminActivityBrand} /> */}
          <Route
            path="/check-out"
            render={(props) => (
              <CheckOut
              // onUserLogIn={this.handleUserLogin}
              // onAdminLogIn={this.handleAdminLogIn}
              />
            )}
          />
          <Route path="/order-success" component={OrderSuccess} />
          {/* <Route
            path="/login"
            render={(props) => (
              <Login
                onUserLogIn={this.handleUserLogin}
                onAdminLogIn={this.handleAdminLogIn}
              />
            )}
          />
          <Route path="/shopping-cart" component={ShoppingCart} /> */}
          {/* <Route path="/my/orders" component={MyOrders} /> */}
          {/* if no matching then this route */}
          <Route component={Home} />
        </Switch>
        {/* <Route path='/Contact' component={Contact}/> */}
      </BrowserRouter>
    );
  }
} //class
export default App;
