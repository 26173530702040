import React, { useEffect, useState } from "react";
import AdminProductListStockRating from "./admin-product-list-stockrating";
import { BeatLoader } from "react-spinners";
import { ProductDB } from "../ProductDB";
import axios from "axios";

function AdminProductStockRating(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [filteredProducts, setFilteredProducts] = useState([]);
  let [productList, setProductList] = useState([]);
  let [productdb, setProductdb] = useState([]);
  let [brandList, setBrandList] = useState([]);
  let [categoryList, setCategoryList] = useState([]);
  let [searchText, setSearchText] = useState("");
  
  useEffect(() => {
    init();
  }, []);
  function init() {
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .all([
        axios.get(window.routerPrefix + "/product/products/" + shopUrl),
        axios.get(window.routerPrefix + "/category/categories/" + shopUrl),
        axios.get(window.routerPrefix + "/brand/brands/" + shopUrl),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          let pList = res1.data;
          let cList = res2.data;
          let bList = res3.data;
          let updatedProducts = [...pList];
          setFlagLoad(false);
          pList.map((product, index) => {
            // get category (string) from categoryId
            for (let i = 0; i < cList.length; i++) {
              if (product.categoryId === cList[i].categoryId) {
                updatedProducts[index].category = cList[i].name;
                break;
              }
            }
            // get brand (string) from brandId
            for (let i = 0; i < bList.length; i++) {
              if (product.brandId === bList[i].brandId) {
                updatedProducts[index].brand = bList[i].name;
                break;
              }
            } //for
            let unitList = [
              { unitId: 1, name: "Number" },
              { unitId: 2, name: "Multiple of 250 gm" },
              { unitId: 3, name: "Multiple of 1 kg" },
              { unitId: 4, name: "Multiple of 500 gm" },
              { unitId: 5, name: "Multiple of 1 kg" },
            ];
            // get unit (string) from unitId
            for (let i = 0; i < unitList.length; i++) {
              if (product.unitId === unitList[i].unitId) {
                updatedProducts[index].unit = unitList[i].name;
                break;
              } //if
            } //for
            updatedProducts[index].flagStockRatingChange = false;
            updatedProducts[index].flagStockRatingUpdate = false;
          }); //map
          setProductList(updatedProducts);
          setFilteredProducts(updatedProducts);
          setProductdb(new ProductDB(updatedProducts));
          setCategoryList(cList);
          setBrandList(bList);
        })
      )
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedProducts = [];
    searchedProducts = productdb.filterByName(query);
    setFilteredProducts(searchedProducts);
  }
  function handleRatingChange(product, newRating) {
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i].rating = newRating;
        pList[i].flagStockRatingChange = true;
        pList[i].flagStockRatingUpdate = false;
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i].rating = newRating;
        fProducts[i].flagStockRatingChange = true;
        fProducts[i].flagStockRatingUpdate = false;
        break;
      }
    } //for
    setProductdb(new ProductDB(pList));
    setProductList(pList);
    setFilteredProducts(fProducts);
  }

  function handleInStockCheckBoxChange(product, userInput) {
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i].instock = userInput;
        pList[i].flagStockRatingChange = true;
        pList[i].flagStockRatingUpdate = false;
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i].instock = userInput;
        fProducts[i].flagStockRatingChange = true;
        fProducts[i].flagStockRatingUpdate = false;
        break;
      }
    } //for
    setProductdb(new ProductDB(pList));
    setProductList(pList);
    setFilteredProducts(fProducts);
  }
  function handleSaveProductStockRating(product) {
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i] = product; // update the product
        pList[i].flagStockRatingUpdate = true;
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i] = product; // update the product
        fProducts[i].flagStockRatingUpdate = true;
        break;
      }
    } //for
    setProductdb(new ProductDB(pList));
    setProductList(pList);
    setFilteredProducts(fProducts);
    let message =
      "Stock and Rating of " + product.name + " updated successfully";
    props.onCrudAction(message, "LIST");
  }
  let { action } = props;
  let { shopDetails } = props;
  let { productsLimit } = shopDetails;
  let { languageChoice } = shopDetails;
  let { shopUrl } = props;
  let { colorScheme } = props;
  return (
    <div className="container-fluid container-md container-list-table p-0">
      {flagLoad && (
        <div className="text-center">
          <BeatLoader size={16} color={colorScheme} flagLoad />
        </div>
      )}
      {action == "LIST" && !flagLoad&&(
        <AdminProductListStockRating
          onSaveProductStockRating={handleSaveProductStockRating}
          onRatingChange={handleRatingChange}
          onInStockCheckBoxChange={handleInStockCheckBoxChange}
          filteredProducts={filteredProducts}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          languageChoice={languageChoice}
          productsLimit={productsLimit}
          shopUrl={shopUrl}
          colorScheme={colorScheme}
        />
      )}
    </div>
  );
}
export default AdminProductStockRating;
