import React from "react";
import QuantityChanger from "./quantity-changer";
import LazyLoad from "react-lazyload";

function AProduct(props) {
  function handleChangeProductQuantity(by) {
    let { product } = props;
    props.onChangeProductQuantity(by, product);
  }
  function handleAddToCartItemList(event) {
    event.preventDefault();
    let { product } = props;
    props.onAddToCartItemList(product);
  }
  function getProductImageLink() {
    let { product } = props;
    let serverResponse = "/files/downloadImageFile/" + product.image;
    return serverResponse;
  }
  let { product } = props;
  let { productQty } = props;
  let { flagShowImages } = props;
  let { informationPlace } = props;
  // let { dummyPrice } = props;
  let discount, productRate;
  // if (dummyPrice) {
  //   productRate = <span className=""> 1 (Dummy Price)</span>;
  // } else
  if (product.mrp == product.finalPrice) {
    productRate = <span className=""> {product.mrp}</span>;
  } else if (product.mrp != product.finalPrice) {
    productRate = (
      <span>
        <span className="striked-text"> {product.mrp} </span>
        <span className=" ">&nbsp;{product.finalPrice}</span>
      </span>
    );
  }
  if (product.mrp == product.finalPrice) {
    discount = 0;
  } else {
    discount = ((product.mrp - product.finalPrice) / product.mrp) * 100;
  }
  // added marathi language feature on 27.08.2020
  // https://stackoverflow.com/questions/40628092/how-insert-marathi-using-php-and-mysql
  let { languageChoice } = props;
  let { colorScheme } = props;
  let { shopTypeId } = props;
  let classList = "col-12 product-border column text-center text-thick ";
  let cList = "border-my" + colorScheme;
  if (product.rating == 1) {
    // do not show products of rating =1
    return null;
  }
  let aButton;
  if (product.instock === 0) {
    aButton = (
      <button
        className="btn btn-block btn-dark"
        id={product.productId}
        disabled
      >
        Out of Stock
      </button>
    );
  } else if (productQty == 0) {
    aButton = (
      <button
        className="btn btn-block btn-warning"
        id={product.productId}
        onClick={handleAddToCartItemList}
      >
        Add to {shopTypeId != 8 ? "Yaadi" : "Cart"}
      </button>
    );
  } else if (productQty > 0) {
    aButton = (
      <QuantityChanger
        productQty={productQty}
        unitId={product.unitId}
        productId={product.productId}
        onChangeProductQuantity={handleChangeProductQuantity}
        title={"Quantity "}
        shortTitle={"Qty "}
        action={"VIEW"}
        shopTypeId={props.shopTypeId}
        colorScheme={colorScheme}
      />
    );
  }
  let classes = "col-12 col-md-3 container-a-product ";
  // let classes = "row ";
  if (productQty > 0) classes = classes + " carted-product";
  return (
    <>
      <div className={classes}>
        <div
          className={
            "row justify-content-center myborder align-items-center mb-1 border-my" +
            colorScheme
          }
        >
          {/* image */}
          <div className="text-center col-6 col-md-12 p-0 container-product-image myborder">
            {flagShowImages && product.image != "no" && (
              // https://twobin.github.io/react-lazyload/examples/#/image?_k=menygq
              <LazyLoad>
                <img
                  className="div-product-image"
                  src={
                    window.routerPrefix +
                    "/files/downloadImageFile/" +
                    product.image
                  }
                  alt=""
                />
                {discount != 0 && (
                  <div className="discount-amount">
                    {discount.toFixed(0)}% OFF
                  </div>
                )}
                {/* {discount != 0 && !dummyPrice ? (
                  <div className="discount-amount">
                    {discount.toFixed(0)}% OFF
                  </div>
                ) : null} 2023*/}
              </LazyLoad>
            )}
          </div>
          {/* Normally informationPlace is 0 */}
          {/* For restaurants it may be made 1 */}
          <div className="col-6 col-md-12 ">
            <div className="row justify-content-center align-items-center myborder">
              <div className="col-12 product-name text-center  p-0 ">
                <span>{product.name} </span>
              </div>
              {informationPlace == 0 && (
                <div className="col-12 product-name text-center  p-0 ">
                  {languageChoice == 1 && product.nameMarathi && (
                    <span className="text-thick">({product.nameMarathi})</span>
                  )}
                  {languageChoice == 1 && !product.nameMarathi && (
                    <span className="text-thick">&nbsp;</span>
                  )}
                </div>
              )}
              {informationPlace == 1 && (
                <div className="col-12 product-name text-center  p-0 ">
                  <span className="text-thick">{product.name} </span>
                  <div className="col-12 product-name text-center  p-0 ">
                    {product.information && (
                      <span>({product.information})</span>
                    )}
                  </div>
                </div>
              )}
              {informationPlace == 1 && (
                <div className="col-12 product-name text-center  p-0">
                  <span className="text-thick">{product.name} </span>
                  <div className="col-12 product-name text-center  p-0">
                    {product.information && (
                      <span>({product.information})</span>
                    )}
                  </div>
                </div>
              )}
              <div className="col-12 text-center p-0 ">
                <span className="text-thick">
                  <i className="fas fa-rupee-sign"></i>
                </span>
                {productRate}
                {product.unitId != 1 && <span className="">/kg</span>}
                {informationPlace == 0 &&
                  product.unitId == 1 &&
                  product.information && <span> ({product.information})</span>}
              </div>
              {/* unitId 1 means product is measured in numbers */}
              <div className="col-12 col-md-10  btn-add-product text-center p-0 myborder">
                {aButton}
              </div>
              <div className={classList}>
                {/* {productQty > 0 && !dummyPrice ? ( */}
                {productQty > 0 ? (
                  <span>Amt: Rs. {product.finalPrice * productQty}</span>
                ) : (
                  <span> &nbsp; </span>
                )}
              </div>
            </div>
          </div>
          {/* {informationPlace == 0 && (
            <div className="col-12 product-name text-center column p-0 ">
              <span>{product.name} </span>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
}
export default AProduct;
