import React from "react";
import AdminProductListSingleImage from "./admin-product-list-single-image";

function AdminProductListImage (props) {
  
  function handleSaveProductImage  (product) {
    props.onSaveProductImage(product);
  };
  function handleUploadProductImage  (product, imageName)  {
    props.onUploadProductImage(product, imageName);
  };
  
  function handleChangeImage  (product)  {
    props.onChangeImage(product);
  };
  function handleCancelChangeImageClick  (product)  {
    props.onCancelChangeImageClick(product);
  };
  function handleCrudAction  (message, action)  {
    props.onCrudAction(message, action);
  };
  function handleSearchTextBoxKeyUp  (event) {
    props.onSearchTextBoxKeyUp(event);
  };
  function handleSearchTextChange  (event)  {
    props.onSearchTextChange(event);
  };
    let { filteredProducts } = props;
    let { searchText } = props;
    let { languageChoice } = props;
    let { shopUrl } = props;
    let { productsLimit } = props;
    let { currentSize } = props;
    let { colorScheme } = props;
    
    let contentNoproducts = <div className="text-center">No products !</div>;
    return (
      // This is enclosed in container-list-table
      <>
        <div className="form-heading column col-12">
          Product Images
        </div>
        {/* row starts */}
        <div className="row justify-content-center">
          <div className="col-10 col-md-6 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={handleSearchTextBoxKeyUp}
              onChange={handleSearchTextChange} // This is contolled by parent
              placeholder="Search Products"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row px-2 justify-content-center">
          {filteredProducts.map((product, index) => (
            <AdminProductListSingleImage
              product={product}
              key={index}
              index={index}
              languageChoice={languageChoice}
              onHandleChangeImage={handleChangeImage}
              onUploadProductImage={handleUploadProductImage}
              onSaveProductImage={handleSaveProductImage}
              onCancelChangeImageClick={handleCancelChangeImageClick}
              onCrudAction={handleCrudAction}
              shopUrl={shopUrl}
              productsLimit={productsLimit}
              currentSize={currentSize}
              colorScheme={colorScheme}
            />
          ))}
        </div>
      </>
    );
          }
export default AdminProductListImage;