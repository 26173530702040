import React, { Component } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import SdcNavBarAdmin from "./sdc-navbar-admin";
import FooterPanel from "./footer-panel";
import AdminClient from "./admin-client";
import AdminDiet from "./admin-diet";
import AdminClientSheet from "./admin-client-sheet";
import AdminLogin from "./admin-login";
import AdminLoginFirst from "./admin-login-first";
import AdminHomePage from "./admin-home-page";
import SdcAdminHomePageNew from "./sdc-admin-home-page-new";

import AdminMakeYaadi from "./admin-make-yaadi";
import Billing from "./admin-billing";
class SdcAdminActivity extends Component {
  //  navigate = useNavigate();
  adminManageList = [
    { name: "Clients", subject: "CLIENT", type: 3 },
    { name: "Diets", subject: "DIET", type: 3 },
    { name: "Client Sheets", subject: "CLIENTSHEET", type: 2 },
  ];
  utilityList = [
    "SUMMARY",
    "ALL",
    "CUMULATIVE",
    "PRODUCTSHEET",
    "CUSTOMERVISITS",
  ];
  mainMenus = [{ menu: "Manage", list: this.adminManageList }];
  constructor(props) {
    super(props);
    this.state = {
      subject: "ADMINHOMEPAGENEW",
      action: "LIST", // Initially show LIST, can be switched to ADD
      menu: "",
      message: "",
      validAdmin: true, // false will show login form,
      firstLogin: 1, // 0 means not logged first time
      shopUrl: "",
      masterListName: "",
      shopDetails: {}, //[],
      colorScheme: "green",
      flagLoading: false,
      selectedMenuIndex: -1,
      selectedSubMenuIndex: -1,
    };
  }
  handleLoginFormSubmit = (firstLogin, shopUrl) => {
    let message = "";
    if (shopUrl == "-1") {
      message = "Something went wrong... Try again";
      this.setState({ validAdmin: false, message: message });
      return;
    }
    if (shopUrl == "0") {
      message = "Invalid username... Try again";
      this.setState({ validAdmin: false, message: message });
      return;
    }
    if (shopUrl == "1") {
      message = "Invalid password... Try again";
      this.setState({ validAdmin: false, message: message });
      return;
    }
    // log in success,

    this.setState({
      flagLoading: true,
      validAdmin: true,
    });
    // in local storage write down "admin" : <shopUrl> key so that
    // admin panel will be available to admin
    localStorage.setItem("admin", shopUrl);
    this.setState({
      message: message,
      shopUrl: shopUrl,
    });
    this.getShopDetails(shopUrl);
  };
  
  
  handleAddListToggleClick = () => {
    let { action } = this.state;
    let { subject } = this.state;
    let { menu } = this.state;
    if (action === "LIST") {
      action = "ADD";
      if (subject === "CLIENT") {
        menu = "List of Clients";
      } else if (subject === "DIET") {
        menu = "List of diets";
      } else if (subject === "CLIENTSHEET") {
        menu = "List of client sheets";
      }
    } //if
    else if (action === "ADD") {
      action = "LIST";
      if (subject === "CLIENT") {
        menu = "Add a Client";
      } else if (subject === "DIET") {
        menu = "Add a Diet";
      }
      else if (subject === "CLIENTSHEET") {
        menu = "Add a Client Sheet";
      }
    } //...else if
    else if (action === "UPDATE") {
      action = "LIST";
      if (subject === "CLIENT") {
        menu = "Add a Client";
      } else if (subject === "DIET") {
        menu = "Add a Diet";
      }
      // else if (subject === "CLIENTSHEET") {
      //   menu = "Add a Client Sheet";
      // }
      else if (subject === "DEALER") {
        menu = "Add a Dealer";
      }
    }
    this.setState({
      action: action,
      menu: menu,
      message: "",
    });
  };
  componentDidMount() {
    // Check whether session exists,
    window.scroll(0, 0);
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/sdcAdmin/welcome/")
      .then((res) => {
        this.setState({
          flagLoading: false,
        });
        let sessionExists = res.data;
        let validAdmin;
        if (sessionExists == 0) {
          validAdmin = false;
          this.setState({
            validAdmin: validAdmin,
          });
          return; // don't go ahead
        }
        validAdmin = true;
        // get colorscheme and shopUrl from localstorage
        this.setState({
          validAdmin: validAdmin,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleYourAccountClick = () => {
    this.setState({ subject: "ACCOUNT", selectedMenuIndex: 0 });
  };
  handleMenuClicked = (index) => {
    this.setState({
      selectedMenuIndex: index,
    });
  };
  handleSubMenuClicked = (selectedMenuIndex, selectedSubMenuIndex) => {
    this.setState({
      selectedSubMenuIndex: selectedSubMenuIndex,
    });
    if (selectedMenuIndex == 0) {
      this.handleAdminManageClick(selectedSubMenuIndex);
    } else if (selectedMenuIndex == 1) {
      this.handleAdminUpdateClick(selectedSubMenuIndex);
    } else if (selectedMenuIndex == 2) {
      this.handleAdminUtilityClick(selectedSubMenuIndex);
    }
    // Billing
    else if (selectedMenuIndex == 3) {
      // this.props.history.push("/"+this.state.shopUrl); // remove the invalid shopUrl 2023
      //     this.setState({
      //       flagInvalidUrl: true,
      //       flagLoading: false,
      //       action: "VIEW",
      //     });
      this.props.history.push({
        pathname: "/" + this.state.shopUrl,
        state: { billing: "yes" },
      }); // remove the invalid shopUrl 2023
    }
  };
  handleHomeClick = () => {
    this.setState({
      subject: "ADMINHOMEPAGENEW",
      action: "", // Initially show LIST, can be switched to ADD
      menu: "",
      selectedSubMenuIndex: -1,
      selectedMenuIndex: -1,
    });
  };
  handleAdminManageClick = (index) => {
    let subject = this.adminManageList[index]["subject"];
    let help = this.adminManageList[index]["help"];
    if (subject == "NONE") {
      return;
    }
    this.setState({
      subject: subject,
      action: "LIST",
      message: "",
      help: help,
    });
   if (subject == "MASTERLIST") {
      this.setState({
        menu: "MasterList",
        // menu: "",
      });
    }
    else {
      this.setState({
        menu: "Add a " + subject,
        // menu: "Add a " + this.manageList[index],
      });
    }
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
 
 
  // Following 2 functions are written to see the effects immediately
  handleUpdateStoreSettings = (shop) => {
    // In update store settings, color-scheme and language of the store are modified
    this.setState({ colorScheme: shop.colorScheme });
    this.setState({
      shopDetails: {
        ...this.state.shopDetails,
        languageChoice: shop.languageChoice,
      },
    });
  };
  handleUploadPaymentScreenShot = () => {
    this.setState({
      shopDetails: { ...this.state.shopDetails, lastPaymentVerified: 0 },
    });
  };
  handleCrudAction = (message, action, menu) => {
    if (menu) {
      this.setState({ menu: menu });
    }
    this.setState({ message: message, action: action });
  };
  handleShopReady = () => {
    this.setState({
      shopDetails: { ...this.state.shopDetails, status: "ready" },
    });
  };
  handleCancelFormClick = () => {
    // if any form of update menus is cancelled, then show adminhomepage
    this.setState({ subject: "ADMINHOMEPAGE" });
  };
  handleEditClick = () => {
    let { subject } = this.state;
    // let { menu } = this.state;
    let menu; //  = this.state;
    if (subject === "CLIENT") {
      menu = "List of Clients";
    } else if (subject === "DIET") {
      menu = "List of Diets";
    } else if (subject === "CLIENTSHEET") {
      menu = "List of Client Sheets";
    } 
    this.setState({ action: "UPDATE", menu: menu, message: "" });
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  handleCancelClick = () => {
    window.scrollTo(0, 0); // moving scrollbar at the top
    // this.setState({ action: "LIST"});
    this.handleAddListToggleClick();
  };
  askToLogin = () => {
    this.setState({
      validAdmin: false,
      message: "Session expired, login-again",
    });
  };
  handleBackClick = () => {
    this.setState({
      subject: "",
    });
  };
  showAddButton = () => {
    // This is type-3
    let { subject } = this.state;
    this.setState({
      menu: "Add a " + subject,
    });
  };
  render() {
    let { flagLoading } = this.state;
    if (flagLoading) {
      return (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    }
    let { subject } = this.state;
    let { action } = this.state;
    let { menu } = this.state;
    let { message } = this.state;
    let { shopUrl } = this.state;
    let { validAdmin } = this.state;
    let { firstLogin } = this.state;
    let { shopDetails } = this.state;
    let { colorScheme } = this.state;
    let { selectedMenuIndex } = this.state;
    let { selectedMenuList } = this.state;
    let { selectedSubMenuIndex } = this.state;
    // let { versionTypeId } = this.state.shopDetails;

    let content;
    //
    if (!validAdmin) {
      content = <AdminLogin onLoginFormSubmit={this.handleLoginFormSubmit} />;
    } else if (validAdmin && firstLogin == 0) {
      // first time login
      content = (
        <AdminLoginFirst
          onLoginFirstFormSubmit={this.handleLoginFirstFormSubmit}
        />
      );
    } else if (subject === "CLIENT") {
      content = (
        <AdminClient
          action={action}
          menu={menu}
          subject={subject}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "DIET") {
      content = (
        <AdminDiet
          action={action}
          menu={menu}
          subject={subject}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "CLIENTSHEET") {
      content = (
        <AdminClientSheet
          action={action}
          menu={menu}
          subject={subject}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          showAddButton={this.showAddButton}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } 
    else if (subject === "ADMINHOMEPAGE") {
      content = <AdminHomePage shopUrl={shopUrl} colorScheme={colorScheme} />;
    } else if (subject === "ADMINHOMEPAGENEW") {
      content = (
        <SdcAdminHomePageNew
          shopUrl={shopUrl}
          colorScheme={colorScheme}
          selectedMenuIndex={selectedMenuIndex}
          selectedMenuList={selectedMenuList}
          selectedSubMenuIndex={selectedSubMenuIndex}
          adminManageList={this.adminManageList}
          adminUpdateList={this.adminUpdateList}
          adminUtilityList={this.adminUtilityList}
          mainMenus={this.mainMenus}
          handleMenuClicked
          onMenuClicked={this.handleMenuClicked}
          onSubMenuClicked={this.handleSubMenuClicked}
        />
      );
    }

    return (
      <>
        {/* During creating yaadi don't show this navbar */}
        {(subject !== "MAKEYAADIES" ||
          (subject == "MAKEYAADIES" && shopDetails.versionTypeId != 3)) && (
          <SdcNavBarAdmin
            adminManageList={this.adminManageList}
            adminUpdateList={this.adminUpdateList}
            adminUtilityList={this.adminUtilityList}
            menu={menu}
            message={message}
            validAdmin={validAdmin}
            shopUrl={shopUrl}
            firstLogin={firstLogin}
            colorScheme={colorScheme}
            shopDetails={shopDetails}
            selectedMenuIndex={selectedMenuIndex}
            onAdminManageClick={this.handleAdminManageClick}
            onAdminUpdateClick={this.handleAdminUpdateClick}
            onAdminReportClick={this.handleAdminReportClick}
            onAdminUtilityClick={this.handleAdminUtilityClick}
            onAddListToggleClick={this.handleAddListToggleClick}
            onYourAccountClick={this.handleYourAccountClick}
            onLogoutClick={this.handleLogoutClick}
            onMasterListClick={this.handleMasterListClick}
            onHomeClick={this.handleHomeClick}
          />
        )}
        {content}
        {shopDetails.status != "not ready" && !validAdmin && (
          <FooterPanel
            shopUrl={shopUrl}
            colorScheme={colorScheme}
            validAdmin={validAdmin}
          />
        )}
      </>
    );
    //
  }
}
export default SdcAdminActivity;
