import React, { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import AdminMasterListSingle from "./admin-master-list-single";
import ProductMasterList from "./product-master-list";
function AdminMasterList(props) {
  let productsLimit = 0;

  let [flagLoad, setFlagLoad] = useState(false);
  //2023  This flagAnyCategoryExplored is used to save download data size
  // Previously, shop's productlist was downloaded during mounting of this component
  // Now when user clicks any category first time to explore, that time productList is downloaded and
  // this flag is set
  let [flagAnyCategoryExplored, setFlagAnyCategoryExplored] = useState(false);
  let [error, setError] = useState("");
  let [flagCriteriaChanged, setFlagCriteriaChanged] = useState(false);
  let [flagSuitableOther, setFlagSuitableOther] = useState(false);

  let [filteredMasterListProducts, setFilteredMasterListProducts] = useState(
    []
  );
  let [shopProductList, setShopProductList] = useState([]);
  let [masterproductdb, setMasterproductdb] = useState([]);
  let [shopType, setShopType] = useState({});

  let [searchText, setSearchText] = useState("");
  let [cntAdded, setCntAdded] = useState(0);
  let [cntNotAdded, setCntNotAdded] = useState(0);
  let [selectedCategory, setSelectedCategory] = useState("");
  let [selectedBrand, setSelectedBrand] = useState("");
  let [flagInitialLoading, setFlagInitialLoading] = useState("");
  let [message, setMessage] = useState("");
  let [menu, setMenu] = useState("");
  let [masterListName, setMasterListName] = useState("");
  let [masterListCategoryList, setMasterListCategoryList] = useState("");
  let [masterListBrandList, setMasterListBrandList] = useState("");
  let [optionsVege, setOptionsVege] = useState("");
  let [optionsGrocery, setOptionsGrocery] = useState("");
  let [optionsBrand, setOptionsBrand] = useState("");
  let [suitableCategoryList, setSuitableCategoryList] = useState([]);
  let [otherCategoryList, setOtherCategoryList] = useState([]);
  let [optionsSuitable, setOptionsSuitable] = useState("");
  let [selectionOption, setSelectionOption] = useState("");
  let [selectionCriteria, setSelectionCriteria] = useState("");
  let { shopDetails } = props;
  let languageChoice = shopDetails.languageChoice;
  let shopUrl = shopDetails.shopUrl;
  function handleCrudAction() {
    props.onCrudAction();
  }
  function markAdded(product) {
    let productId = product.productId;
    let fMasterListProducts = [...filteredMasterListProducts];
    let cAdded = cntAdded;
    let cNotAdded = cntNotAdded;
    let i;
    // First, let us add the product in the shopProductList
    let shProductList = shopProductList;
    shProductList.push(product);
    for (i = 0; i < fMasterListProducts.length; i++) {
      if (fMasterListProducts[i].productId === productId) {
        fMasterListProducts[i].added = true;
        break;
      } //if
    } //outer for
    // Do sorting
    fMasterListProducts = fMasterListProducts.sort((a, b) => {
      if (a["added"] > b["added"]) {
        return 1;
      }
      if (a["added"] < b["added"]) {
        return -1;
      }
      return 0;
    });
    cAdded++;
    cNotAdded--;
    setFilteredMasterListProducts(fMasterListProducts);
    setShopProductList(shProductList);
    setCntAdded(cAdded);
    setCntNotAdded(cNotAdded);
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedProducts;
    searchedProducts = masterproductdb.filterByName(query);
    setFilteredMasterListProducts(searchedProducts);
  }
  function handleExploreOtherCategoriesClick() {
    // other categories to be explored
    window.scroll(0, 0);
    setFlagSuitableOther(true);
  }

  function handleExploreSuitableCategoriesClick() {
    // other categories to be explored
    window.scroll(0, 0);
    setFlagSuitableOther(false);
  }

  useEffect(() => {
    init();
  }, []);

  function init() {
    setFlagLoad(true);
    setMenu("");
    setMessage("");

    let shopTypeId = shopDetails.shopTypeId;
    productsLimit = shopDetails.productsLimit;
    languageChoice = shopDetails.languageChoice;
    axios
      .all([
        // axios.get(window.routerPrefix + "/category/categories/vege"),
        axios.get(window.routerPrefix + "/category/categories/"+window.masterList),
        // axios.get(window.routerPrefix + "/brand/brands/vege"),
        axios.get(window.routerPrefix + "/brand/brands/"+window.masterList),
        axios.get(window.routerPrefix + "/shopType/shopTypeById/" + shopTypeId),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          setFlagLoad(false);
          let masterListCategoryList = res1.data;
          let masterListBrandList = res2.data;
          setShopType(res3.data);

          let suitableCategoryList = masterListCategoryList.filter(
            (category, index) => category.categoryShopTypeId == shopTypeId
          );
          let otherCategoryList = masterListCategoryList.filter(
            (category, index) => category.categoryShopTypeId != shopTypeId
          );

          suitableCategoryList = suitableCategoryList.map(
            (category, index) => ({ ...category, flagExplore: false })
          );
          otherCategoryList = otherCategoryList.map((category, index) => ({
            ...category,
            flagExplore: false,
          }));
          setSuitableCategoryList(suitableCategoryList);
          setOtherCategoryList(otherCategoryList);
          // prepare optionsBrand to be filled in the select
          setMasterListName(masterListName);
          setMasterListCategoryList(masterListCategoryList);
          setMasterListBrandList(masterListBrandList);
          setOptionsSuitable(optionsSuitable);
        })
      )
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  }

  function handleOptionSelection(value) {
    setSelectionOption(value);
  }
  function handleCriteriaSelection(value) {
    setSelectionCriteria(value);
    setFlagCriteriaChanged(true);
  }
  function handleBackClick() {
    setSelectedCategory("");
  }
  function handleExploreClick(category) {
    // suitableCategoryList
    suitableCategoryList = suitableCategoryList.map((c, index) => {
      if (c.categoryId == category.categoryId) {
        c.flagExplore = true;
      } else {
        c.flagExplore = false;
      }
      return c;
    });
    setSuitableCategoryList(suitableCategoryList);
    setSelectedCategory(category);
    // get products from masterlist of selected category
    window.scroll(0,0);
    setFlagLoad(true);
    axios
      .get(
        window.routerPrefix +
          // "/product/byCategoryId/vege/" +
          "/product/byCategoryId/"+window.masterList+"/" +
          category.categoryId
      )
      .then((res) => {
        let fMasterListProducts = res.data;
        setFlagLoad(false);
        setFilteredMasterListProducts(fMasterListProducts);
        setMasterproductdb(fMasterListProducts);
        // Also check whether flagAnyCategoryExplored is not set
        // that means products of the shop are to be downloaded now
        // now find how many are already added and how many not.
        // compareWithMaster(fMasterListProducts);  2023
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
  }
  let { colorScheme } = props;
  let content_SelectionCriteria;
  let content_search = (
    <div className="row justify-content-center">
      <div className="col-10 col-md-6 my-2 ">
        <input
          type="search"
          className="container-fluid form-control"
          value={searchText}
          onKeyUp={handleSearchTextBoxKeyUp}
          onChange={handleSearchTextChange} // This is contolled by parent
          placeholder="Search Products"
          id=""
        />
      </div>
    </div>
  );
  if (flagLoad) {
    return (
      <>
        <div className="text-center">
          <BeatLoader size={16} color={colorScheme} flagLoading />
        </div>
      </>
    );
  }
  return (
    <>
      {!selectedCategory && !flagSuitableOther && (
        <h4 className="col-12 my-2 text-center">
          Explore following {suitableCategoryList.length}{" "}
          {suitableCategoryList.length == 1 ? "category" : "categories"} of{" "}
          {shopType.name}
        </h4>
      )}
      {!selectedCategory && flagSuitableOther && (
        <h4 className="col-12 my-2 text-center">
          Explore following {otherCategoryList.length}{" "}
          {otherCategoryList.length == 1 ? "category" : "categories"} of{" "}
          Complete MasterList
        </h4>
      )}
      {!selectedCategory && !flagSuitableOther && (
        <h5 className="col-12 my-2 text-center">
          ( OR{" "}
          <Link to="#" onClick={handleExploreOtherCategoriesClick}>
            Explore Complete Masterlist
          </Link>
          ){" "}
        </h5>
      )}
      {!selectedCategory && flagSuitableOther && (
        <h5 className="col-12 my-2 text-center">
          ( OR{" "}
          <Link to="#" onClick={handleExploreSuitableCategoriesClick}>
            Explore Categories of {shopType.name}
          </Link>
          ){" "}
        </h5>
      )}
      {selectedCategory && (
        <div className="text-center">
          <Link to="#" onClick={handleBackClick}>
            {" "}
            Back{" "}
          </Link>
        </div>
      )}
      <div className="text-center text-thick text-bigger my-2">
        {" "}
        {selectedCategory.name}{" "}
      </div>

      {suitableCategoryList.length != 0 &&
        !selectedCategory &&
        !flagSuitableOther && (
          <div className="container-fluid">
            {suitableCategoryList.map((category, index) => (
              <AdminMasterListSingle
                key={index}
                index={index}
                category={category}
                colorScheme={colorScheme}
                onExploreClick={handleExploreClick}
              />
            ))}
          </div>
        )}
      {otherCategoryList.length != 0 &&
        !selectedCategory &&
        flagSuitableOther && (
          <div className="container-fluid">
            {otherCategoryList.map((category, index) => (
              <AdminMasterListSingle
                key={index}
                index={index}
                category={category}
                colorScheme={colorScheme}
                onExploreClick={handleExploreClick}
              />
            ))}
          </div>
        )}
      {selectedCategory && !flagLoad && (
        <ProductMasterList
          filteredMasterListProducts={filteredMasterListProducts}
          selectionOption={selectionOption}
          languageChoice={languageChoice}
          colorScheme={colorScheme}
          shopUrl={shopUrl}
          onCrudAction={handleCrudAction}
        />
      )}
    </>
  );
}
export default AdminMasterList;
