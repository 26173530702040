import React, { useEffect, useState } from "react";
import AdminCategoryList from "./admin-category-list";
import AdminCategoryAdd from "./admin-category-add";
import { BeatLoader } from "react-spinners";
import { CategoryDB } from "../CategoryDB";
import axios from "axios";

function AdminCategory(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [filteredCategories, setFilteredCategories] = useState([]);
  let [categoryList, setCategoryList] = useState([]);
  let [categorydb, setCategorydb] = useState([]);
  let [category, setCategory] = useState(null);
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [sectionNumber, setSectionNumber] = useState("");
  let [shopTypeList, setshopTypeList] = useState([]);
  let { shopDetails } = props;
  function getCategoryListFromDatabase() {
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .get(window.routerPrefix + "/category/categories/" + shopUrl)
      .then((res) => {
        let cList = res.data;
        setFlagLoad(false);
        // it may be masterlist, so categoryShopType should be available 2023
        if (shopUrl == window.masterList) {
          setFlagLoad(true);
          axios
            .get(window.routerPrefix + "/shopType/shopTypes")
            .then((res) => {
              setFlagLoad(false);
              let stList = res.data;
              setshopTypeList(stList);
              // update category objects
              cList.map((c, index) => {
                for (let i = 0; i < stList.length; i++) {
                  if (c.categoryShopTypeId === stList[i].shopTypeId) {
                    cList[index].categoryShopType = stList[i].name;
                    break;
                  }
                }
              }); //map
            })
            .catch((err) => {
              setFlagLoad(false);
              setError(err);
            });
        }
        setFilteredCategories(cList);
        setCategoryList(cList);
        setCategorydb(new CategoryDB(cList));
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
        props.onCrudAction("Something went wrong", "LIST", "Add a Category");
      });
  }
  useEffect(() => {
    window.scroll(0, 0);
    getCategoryListFromDatabase();
  }, []);
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    // if (query.length == 0) {
    //   setFilteredCategories(categoryList);
    //   return;
    // }
    let searchedCategories = [];
    searchedCategories = categorydb.filterByName(query);
    setFilteredCategories(searchedCategories);
  }
  function handleCancelClick() {
    props.onCancelClick();
  }
  function onFormSubmit(category) {
    let message;
    let { action } = props;
    let { shopUrl } = props;

    if (action === "UPDATE") {
      setFlagLoad(true);
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/category/update/" + shopUrl, category)
        .then((res) => {
          setFlagLoad(false);
          if (res.data == 0) {
            // error updating category
            message = "Error... could not update category.";
          } else {
            let message =
              "The category '" + category.name + "' updated successfully.";
            updateCategoryList(category);
            props.onCrudAction(message, "LIST", "Add a Category");
          }
        })
        .catch((err) => {
          setFlagLoad(false);
          setError(err);
        });
    } else if (action === "ADD") {
      //ADD / POST
      setFlagLoad(true);
      axios
        .post(
          window.routerPrefix + "/category/addCategory/" + props.shopUrl,
          category,
          {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
            },
          }
        )
        .then((res) => {
          setFlagLoad(false);
          if (res.data == 0) {
            // error adding category
            message = "Something went wrong...";
            props.onCrudAction(message, "ADD"); // Add again
          } else {
            category.categoryId = res.data; // id of the added entity is returned
            addToCategoryList(category);
            message = "Category " + category.name + " added successfully.";
            props.onCrudAction(message, "ADD"); // Add again
          }
        })
        .catch((err) => {
          message = "Error... could not add category.";
          props.onCrudAction(message, "ADD"); // Add again
          setFlagLoad(false);
          setError(err);
        });
    } //else
  }
  function addToCategoryList(category) {
    let cList = [...categoryList];
    cList.unshift(category);
    let fCategories = [...filteredCategories];
    fCategories.unshift(category);
    setFilteredCategories(fCategories);
    setCategoryList(cList);
  }
  function updateCategoryList(category) {
    let cList = [...categoryList];
    for (let i = 0; i < cList.length; i++) {
      if (cList[i].categoryId == category.categoryId) {
        cList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        cList.unshift(category);
        break;
      }
    } //for
    // remove from filteredchapters also
    let fCategories = [...filteredCategories];
    for (let i = 0; i < fCategories.length; i++) {
      if (fCategories[i].categoryId == category.categoryId) {
        fCategories.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        fCategories.unshift(category);
        break;
      }
    } //for
    //sorting
    setFilteredCategories(fCategories);
    setCategoryList(cList);
  }
  function handleEditCategory(category) {
    setCategory(category);
    setSearchText("");
    props.onEditClick();
  }
  function handleDeleteCategory(category, result) {
    let message;
    if (result == 0) {
      //Error... deleting category
      message = "Error....could not delete the category";
      props.onCrudAction(message, "LIST");
      return;
    }
    let cList = [...categoryList];
    for (let i = 0; i < cList.length; i++) {
      if (cList[i].categoryId == category.categoryId) {
        cList.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    // remove from filteredcategories also
    let fCategories = [...filteredCategories];
    for (let i = 0; i < fCategories.length; i++) {
      if (fCategories[i].categoryId == category.categoryId) {
        fCategories.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    if (fCategories.length == 0 && searchText != "") {
      // only one entity was searched and deleted, so search text should be removed, and all products should be shown
      setSearchText("");
      setFilteredCategories(cList);
    } else {
      setFilteredCategories(fCategories);
    }
    setCategoryList(cList);
    setCategorydb(new CategoryDB(categoryList));

    message = category.name + " deleted successfully";
    props.onCrudAction(message, "LIST");
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    let fCategories = [...filteredCategories];
    if (d == false) {
      //in ascending order
      fCategories = fCategories.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      fCategories = filteredCategories.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredCategories(fCategories);
    setSortedField(field);
  }
  let { action } = props;
  let { colorScheme } = props;
  let { subject } = props;
  let { shopUrl } = props;
  let content;
  if (flagLoad)
    content = (
      <div className="text-center">
        <BeatLoader size={16} color={colorScheme} flagLoad />
      </div>
    );
  else if (action === "LIST") {
    // First show list
    content = (
      <AdminCategoryList
        onEditCategory={handleEditCategory}
        onDeleteCategory={handleDeleteCategory}
        onSearchTextChange={handleSearchTextChange}
        onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
        onHeaderClick={handleHeaderClick}
        onSectionNumberClick={handleSectionNumberClick}
        filteredCategories={filteredCategories}
        shopUrl={shopUrl}
        searchText={searchText}
        subject={subject}
        currentSize={categoryList.length}
      />
    );
  } else if (action === "ADD" || action === "UPDATE") {
    // After pressing Add Button, show only form and not the list
    content = (
      <div>
        {/* content={" "} */}
        <AdminCategoryAdd
          onFormSubmit={onFormSubmit}
          onCancelClick={handleCancelClick}
          category={category}
          action={action}
          colorScheme={colorScheme}
          shopTypeId={shopDetails.shopTypeId}
          shopUrl={shopDetails.shopUrl}
          shopTypeList={shopTypeList}
        />
      </div>
    );
  } else if (action === "DELETE") {
    content = <div>Delete action in Progress.</div>;
  }
  return (
    <div className="container container-md container-list-table">{content}</div>
  );
}
export default AdminCategory;
