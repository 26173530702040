import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import LazyLoad from "react-lazyload";
function AdminStoreLogo(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [previewImage, setPreviewImage] = useState(false);
  let [flagImageChange, setFlagImageChange] = useState(false);
  let [flagImageUploaded, setFlagImageUploaded] = useState(false);
  let [message, setMessage] = useState("");
  let [file, setFile] = useState("");
  function handleChangeImageClick() {
    setFlagImageChange(true);
  }
  function handleUploadImageClick() {
    let { shopUrl } = props;
    const formData = new FormData();
    formData.append("imageFile", file);
    setFlagLoad(true);
    axios
      .post(
        window.routerPrefix + "/files/uploadShopLogo/" + shopUrl + "/",
        formData,
        {
          headers: {
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data != "####") {
          //successfully uploaded
          let imageName = res.data;
          setFlagImageChange(false);
          setFlagImageUploaded(false);
          setPreviewImage(false);
          let message = "Logo updated successfully.";
          props.onCrudAction(message, "", "");
        } else {
          let message = "Error updating logo";
          props.onCrudAction(message, "", "");
        }
        setFlagLoad(false);
      })
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  }
  function handleCancelChangeImageClick() {
    setMessage("");
    setFile("");
    setPreviewImage("");
    setFlagImageChange(false);
  }
  function fileChangedHandler(event) {
    let file = event.target.files[0];
    if (!file) {
      setMessage("");
      return;
    }
    let fileType = file.type.substring(0, file.type.indexOf("/"));
    if (fileType != "image") {
      setMessage("Invalid file...");
      setFile("");
    }
    //  else if (file.size > 5000) {
    //   setState({ message: "File-size should be below 5kb" });
    // }
    else {
      setMessage("");
      let previewImage = URL.createObjectURL(file);
      setFile(file);
      setPreviewImage(previewImage);
    }
  }
  function handleSaveProductImage() {
    let { shopUrl } = props;
    // save this product
    setFlagLoad(true);
    axios
      // .put("/products/" + shopUrl + "/" + product.productId, product)
      .put(window.routerPrefix + "/product/update/" + shopUrl)
      .then((res) => {
        setFlagLoad(false);
        // props.onSaveProductImage(product);
        props.onSaveProductImage();
      })
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  }
  let { shopUrl } = props;
  let { colorScheme } = props;
  return (
    <div className="container-fluid container-md container-list-table">
      {!flagLoad && (
        <div className="row justify-content-center">
          {(!flagImageChange || !previewImage || (previewImage && message)) && (
            <div className="col-12 text-center mb-3  ">
              <img
                src={
                  window.routerPrefix +
                  "/files/downloadLogoImageFile/" +
                  shopUrl
                }
                alt=""
                className="div-shop-logo "
              />
            </div>
          )}
          {flagImageChange && previewImage && !message && (
            <div className="col-12 text-center mb-3">
              <LazyLoad>
                <img className="div-product-image" src={previewImage} alt="" />
              </LazyLoad>
            </div>
          )}

          {/* row ends */}

          {!flagImageChange && (
            <div className="col-12 text-center ">
              <button
                className="btn btn-dark "
                onClick={handleChangeImageClick}
              >
                Change Logo
              </button>
            </div>
          )}
          {flagImageChange && !previewImage && (
            <div className="col-12 text-center ">
              <input type="file" name="file" onChange={fileChangedHandler} />
            </div>
          )}
          {flagImageChange && (
            <div className="col-12 text-center mb-2">
              <button
                className="btn btn-dark "
                onClick={handleCancelChangeImageClick}
              >
                Cancel
              </button>
            </div>
          )}
          {flagImageChange && message && (
            <div className="col-12 text-center text-red">{message}</div>
          )}

          {flagImageChange &&
            previewImage &&
            !message &&
            !flagImageUploaded && (
              <div className="col-12 text-center ">
                <button
                  className="btn btn-dark "
                  onClick={handleUploadImageClick}
                >
                  Update Logo
                </button>
              </div>
            )}
        </div>
      )}
      {error && <div>Error retriving products </div>}
      {flagLoad && (
        <div className="text-center">
          <BeatLoader size={16} color={colorScheme} flagLoading />
        </div>
      )}
    </div>
  );
}
export default AdminStoreLogo;
