import React from "react";
import axios from "axios";

class UploadImage extends React.Component {
  constructor(props) {
    super(props);
    let shop;
    let flagFormInvalid;
    this.state = {
      image: null
    };
  }

  handleChange = (event) => {
    let { errorShop } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        shop: { ...this.state.shop, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorShop[`${name}`].mxLen;
    let onlyDigits = errorShop[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen + 1);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorShop[`${name}`].message = message;
    this.setState({
      errorShop: errorShop,
      shop: { ...this.state.shop, [name]: value },
    });
    this.checkAllErrors(errorShop);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorShop } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorShop[`${name}`].mnLen;
      onlyDigits = errorShop[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      // Currently no checking
      value = event.target.selectedIndex;
      if (value == 0) {
        // message = name + requiredMessage;
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorShop[`${name}`].message = message;
    this.setState({ errorShop: errorShop });
    this.checkAllErrors(errorShop);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorShop = this.state.errorShop;
    errorShop[`${name}`].message = "";
    this.setState({ errorShop: errorShop });
    this.checkAllErrors(errorShop);
  };
  checkAllErrors = (errorShop) => {
    // let { errorShop } = this.state;
    let flagFormInvalid = false;
    for (let field in errorShop) {
      if (errorShop[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleSelectShopTypeChange = (event) => {
    let index = event.target.selectedIndex; // get selected index, instead of selected value
    var optionElement = event.target.childNodes[index];
    var selectedShopTypeId = optionElement.getAttribute("id");
    let shopType = event.target.value.trim();
    let shopTypeId = selectedShopTypeId;
    this.setState({
      shop: {
        ...this.state.shop,
        shopType: shopType,
        shopTypeId: shopTypeId,
      },
    });
  };
  handleRadioButtonClick = (event) => {
    this.setState({
      shop: { ...this.state.shop, [event.target.name]: event.target.value },
    });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
      axios
        .post(window.routerPrefix +"/uploadImage", this.state.image, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
             "Content-Type": `multipart/form-data;`,
          },
        })
        .then((res) => {
          this.setState({
            flagLoading: false,
            response: res.data,
          });
          this.setState({
            flagLoading: false,
          });
          this.getProductListFromDatabase();
        })
        .catch((err) => {
          console.log("error!");
          this.setState({
            flagLoading: false,
          });
        });
  };
  render() {
    let { shop } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let heading;
    return (
      <React.Fragment>
        <div className="container-fluid container-list-table p-0">
        <div className="form-heading column col-12">{heading}</div>
        <form
           className="text-thick bg-mycyan p-4"
          onSubmit={this.handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Image File</label>
            </div>
            <div className="col-7 px-0">
              <input
                type="file"
                className="form-control"
                name="this.state.image"
              />
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={flagFormInvalid}
            >
              Submit
            </button>
          </div>
        </form>
        </div>
      </React.Fragment>
    );
  }
}
export default UploadImage;