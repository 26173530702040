export class ClientSheetDB {
  constructor(clientSheets) {
    this.clientSheets = clientSheets;
  }
  allClientSheets() {
    return this.clientSheets;
  }
  searchByClientSheetId(id) {
    for (let i = 0; i < this.clientSheets.length; i++) {
      if (this.clientSheets[i].clientSheetId == id) {
        return this.clientSheets[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredClientSheets = [];
    for (let i = 0; i < this.clientSheets.length; i++) {
      if (this.clientSheets[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredClientSheets.push(this.clientSheets[i]);
      }
    } //for
    return filteredClientSheets;
  }
}
export default ClientSheetDB;
