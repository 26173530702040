import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function AdminReportAll(props) {
  let grandTotal = 0;
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [whichDate, setWhichDate] = useState("Today");
  let [dateFrom, setDateFrom] = useState(new Date());
  let [dateTo, setDateTo] = useState(new Date());
  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  function dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
  function getDataFromDatabase(whichDate, infoType) {
    let { shopUrl } = props;

    let diff1, diff2;
    if (whichDate === "Between Dates") {
      let d = new Date();
      diff1 = dateDiffInDays(dateFrom, d);
      diff2 = dateDiffInDays(dateTo, d);
      whichDate = diff1 + "_" + diff2;
    }
    setFlagLoad(true);
    // First get list of yaadi id from mysql table yaadies
    axios
      .get(
        window.routerPrefix +
          "/yaadi/yaadiesByDate/" +
          shopUrl +
          "/" +
          whichDate
      )
      .then((res) => {
        let yaadiList = res.data;

        let yaadiIdFirst = yaadiList[0].yaadiId;
        let yaadiIdLast = yaadiList[yaadiList.length - 1].yaadiId;
        /*=========*/
        // Now get yaadiProducts of each of these yaadies
        axios
          // .get("yaadi/reportAll/" + shopUrl, {
          //   params: {
          //     yaadiIdFirst: yaadiIdFirst,
          //     yaadiIdLast: yaadiIdLast,
          //   },
          // })
          .get(window.routerPrefix+
            "/yaadiProduct/yaadiProductsByYaadiId/" +
              shopUrl +
              "/" +
              yaadiIdFirst +
              "/" +
              yaadiIdLast
          )
          .then((res) => {
            let yaadiProductList = res.data;
            if (infoType === "Yaadies")
              CreateExcelOfYaadies(yaadiList, yaadiProductList);
            else if (infoType === "Challans")
              CreateExcelOfChallans(yaadiList, yaadiProductList);
            setFlagLoad(false);
          })
          .catch((err) => {
            console.log(err);
            setFlagLoad(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
      });
  }
  function handleDateSelection(value) {
    setWhichDate(value);
  }
  function handleDateFromPickerClick(date) {
    setDateFrom(date);
  }
  function handleDateToPickerClick(date) {
    setDateTo(date);
  }
  function handleExcelFileDownloadClick() {
    if (dateFrom > dateTo) {
      window.alert("Enter dates correctly...");
      return;
    }
    // get yaadies of the shop, which will give yaadiid
    // for each id, get yaadiproducts
    getDataFromDatabase(whichDate, "Yaadies"); // value is whichDate (today/yesterday)
  }
  function handleChallanExcelFileDownloadClick() {
    if (dateFrom > dateTo) {
      window.alert("Enter dates correctly...");
      return;
    }
    // get yaadies of the shop, which will give yaadiid
    // for each id, get yaadiproducts
    getDataFromDatabase(whichDate, "Challans"); // value is whichDate (today/yesterday)
  }

  //Following function obtained from following site
  //https://stackoverflow.com/questions/28892885/javascript-json-to-excel-file-download

  function CreateExcelOfYaadies(yaadiList, yaadiProductsList) {
    yaadiList =
      typeof yaadiList != "object" ? JSON.parse(yaadiList) : yaadiList;
    yaadiProductsList =
      typeof yaadiProductsList != "object"
        ? JSON.parse(yaadiProductsList)
        : yaadiProductsList;
    let CSV = "";
    let yaadiId;
    for (let k = 0; k < yaadiList.length; k++) {
      yaadiId = yaadiList[k].yaadiId;
      // a yaadi starts
      // First Show Customer Information
      let dt = new Date(yaadiList[k].dateOfYaadi);
      let dd = pad(dt.getDate(), 2);
      let mm = pad(dt.getMonth() + 1, 2);
      let yy = dt.getFullYear();
      CSV +=
        ",," +
        yaadiList[k].yaadiId +
        "," +
        dd.toString() +
        "." +
        mm.toString() +
        "." +
        yy.toString() +
        //yaadiList[k].dateOfYaadi
        "\r\n" +
        ",," +
        yaadiList[k].customerWhatsApp +
        "\r\n" +
        ",," +
        yaadiList[k].customerName +
        "\r\n" +
        "," +
        yaadiList[k].customerAddress +
        "\r\n";
      // Now show headers of the table
      let headers = [
        "S.No.",
        "Description",
        "quantity",
        // "mrp",
        "final price",
        "Total",
      ];
      var row = "";
      for (let j = 0; j < headers.length; j++) {
        row += headers[j] + ",";
      } //for
      CSV += row + "\r\n";
      // Now show Products
      //1st loop is to extract each row
      let data, total;
      for (let i = 0, t = 0; i < yaadiProductsList.length; i++) {
        if (yaadiProductsList[i].yaadiId === yaadiId) {
          // yaadiProductsList[i] contains the products of current yaadi
          var row = '"' + (t + 1) + '",';
          //2nd loop will extract each column and convert it in string comma-seprated
          for (var index in yaadiProductsList[i]) {
            data = yaadiProductsList[i][index];
            let unitId = yaadiProductsList[i]["unitId"];
            total =
              yaadiProductsList[i]["quantity"] *
              yaadiProductsList[i]["finalPrice"];
            if (index === "name") {
              if (yaadiProductsList[i]["information"]) {
                data = data + " - " + yaadiProductsList[i]["information"];
              }
            }
            if (
              index === "yaadiId" ||
              index === "nameMarathi" ||
              index === "information" ||
              index === "unitId" ||
              index === "mrp" ||
              index === "productId" ||
              index === "yaadiProductId"
            ) {
              continue;
            }
            if (index === "quantity") {
              if (unitId == 1) {
              } else if (unitId == 2 || unitId == 4 || unitId == 5) {
                data = data * 1000;
                if (data >= 1000) {
                  data /= 1000;
                  if (data == 1) {
                    data = data + " kg";
                  } else {
                    data = data + " kgs";
                  }
                } else {
                  data = data + " gms";
                }
              } else if (unitId == 3) {
                if (data == 1) {
                  data = data + " kg";
                } else {
                  data = data + " kgs";
                }
              }
            } //... quantity
            row += '"' + data + '",';
          } //inner for
          t = t + 1;
          row += "" + total;
          CSV += row + "\r\n";
        } //if
      } //for
      // CSV += ",,,," + yaadiList[k].totalAmount;
      CSV += ",,," + "Total Amount" + "," + yaadiList[k].totalAmount + "\r\n";
      if (yaadiList[k].deliveryCharges) {
        CSV +=
          ",,," +
          "Delivery Charges" +
          "," +
          yaadiList[k].deliveryCharges +
          "\r\n";
      }
      if (yaadiList[k].deliveryCharges)
        CSV +=
          ",,," +
          "Payable amount" +
          "," +
          (yaadiList[k].totalAmount + yaadiList[k].deliveryCharges);
      CSV += "\r\n\r\n\r\n";
      // a yaadi ends
    } //for
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    // var fileName = "Yaadies.xls";
    let dt = new Date();
    let fileName = "All Yaadies ";
    if (whichDate === "Today") {
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    }
    if (whichDate === "Yesterday") {
      dt.setDate(dt.getDate() - 1);
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    } else if (whichDate === "Between Dates") {
      fileName +=
        pad(dateFrom.getDate(), 2) +
        "." +
        pad(dateFrom.getMonth() + 1, 2) +
        " to " +
        pad(dateTo.getDate(), 2) +
        "." +
        pad(dateTo.getMonth() + 1, 2);
    }
    // fileName += dt.toDateString()
    // +
    // ".xls";

    //this will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    // var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    var universalBOM = "\ufeff";
    var universalBOM = "%EF%BB%BF";
    //  var uri = 'data:text/plain; charset=utf-8,'+encodeURIComponent(CSV);
    // var uri = 'data:text/plain;charset=utf-8,'+encodeURIComponent(universalBOM+CSV);
    var uri = "data:text/csv;charset=utf-8," + encodeURIComponent(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function CreateExcelOfChallans(yaadiList, yaadiProductsList) {
    yaadiList =
      typeof yaadiList != "object" ? JSON.parse(yaadiList) : yaadiList;
    yaadiProductsList =
      typeof yaadiProductsList != "object"
        ? JSON.parse(yaadiProductsList)
        : yaadiProductsList;
    let CSV = "";
    let yaadiId;
    for (let k = 0; k < yaadiList.length; k++) {
      yaadiId = yaadiList[k].yaadiId;
      // a yaadi starts
      // First Show Customer Information
      let dt = new Date(yaadiList[k].dateOfYaadi);
      let dd = pad(dt.getDate(), 2);
      let mm = pad(dt.getMonth() + 1, 2);
      let yy = dt.getFullYear();
      CSV +=
        ",," +
        yaadiList[k].yaadiId +
        "," +
        dd.toString() +
        "." +
        mm.toString() +
        "." +
        yy.toString() +
        //yaadiList[k].dateOfYaadi
        "\r\n" +
        ",," +
        yaadiList[k].customerWhatsApp +
        "\r\n" +
        ",," +
        yaadiList[k].customerName +
        "\r\n" +
        "," +
        yaadiList[k].customerAddress +
        "\r\n";
      // Now show headers of the table
      let headers = [
        "S.No.",
        "Description",
        "quantity",
        // "mrp",
        // "final price",
        // "Total",
      ];
      var row = "";
      for (let j = 0; j < headers.length; j++) {
        row += headers[j] + ",";
      } //for
      CSV += row + "\r\n";
      // Now show Products
      //1st loop is to extract each row
      let data;
      for (let i = 0, t = 0; i < yaadiProductsList.length; i++) {
        if (yaadiProductsList[i].yaadiId === yaadiId) {
          // yaadiProductsList[i] contains the products of current yaadi
          var row = '"' + (t + 1) + '",';
          //2nd loop will extract each column and convert it in string comma-seprated
          for (var index in yaadiProductsList[i]) {
            data = yaadiProductsList[i][index];
            let unitId = yaadiProductsList[i]["unitId"];
            // total =
            //   yaadiProductsList[i]["quantity"] *
            //   yaadiProductsList[i]["finalPrice"];
            if (index === "name") {
              if (yaadiProductsList[i]["information"]) {
                data = data + " - " + yaadiProductsList[i]["information"];
              }
            }
            if (
              index === "yaadiId" ||
              index === "nameMarathi" ||
              index === "information" ||
              index === "finalPrice" ||
              index === "unitId" ||
              index === "mrp" ||
              index === "productId"
            ) {
              continue;
            }
            if (index === "quantity") {
              if (unitId == 1) {
              } else if (unitId == 2 || unitId == 4 || unitId == 5) {
                data = data * 1000;
                if (data >= 1000) {
                  data /= 1000;
                  if (data == 1) {
                    data = data + " kg";
                  } else {
                    data = data + " kgs";
                  }
                } else {
                  data = data + " gms";
                }
              } else if (unitId == 3) {
                if (data == 1) {
                  data = data + " kg";
                } else {
                  data = data + " kgs";
                }
              }
            } //... quantity
            row += '"' + data + '",';
          } //inner for
          t = t + 1;
          // row += "" + total;
          CSV += row + "\r\n";
        } //if
      } //for
      // CSV += ",,,," + yaadiList[k].totalAmount;
      CSV += "\r\n\r\n\r\n";
      // a yaadi ends
    } //for
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    // var fileName = "Yaadies.xls";
    let dt = new Date();
    let fileName = "All Challans";
    if (whichDate === "Today") {
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    }
    if (whichDate === "Yesterday") {
      dt.setDate(dt.getDate() - 1);
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    } else if (whichDate === "Between Dates") {
      fileName +=
        pad(dateFrom.getDate(), 2) +
        "." +
        pad(dateFrom.getMonth() + 1, 2) +
        " to " +
        pad(dateTo.getDate(), 2) +
        "." +
        pad(dateTo.getMonth() + 1, 2);
    }
    // fileName += dt.toDateString()
    // +
    // ".xls";

    //this will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    // var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    var universalBOM = "\ufeff";
    var universalBOM = "%EF%BB%BF";
    //  var uri = 'data:text/plain; charset=utf-8,'+encodeURIComponent(CSV);
    // var uri = 'data:text/plain;charset=utf-8,'+encodeURIComponent(universalBOM+CSV);
    var uri = "data:text/csv;charset=utf-8," + encodeURIComponent(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  let { colorScheme } = props;
  
  let versionTypeId = props.shopDetails.versionTypeId;

  let d = new Date();
  let content = (
    // This is enclosed in container-list-table
    <>
      <div className=" col-12">
        <div className="form-heading">All Yaadies</div>
      </div>
      {/* row starts */}
      <div className="row">
        <div className="col-12 my-2 text-center ">
          <input
            type="radio"
            className="form-control-inline"
            name="whichDate"
            value="Yesterday"
            onClick={() => handleDateSelection("Yesterday")}
            onChange={() => handleDateSelection("Yesterday")}
          />
          &nbsp;Yesterday
          <div className="col-12 my-2">
            <input
              type="radio"
              className="form-control-inline"
              name="whichDate"
              value="Today"
              onClick={() => handleDateSelection("Today")}
              onChange={() => handleDateSelection("Today")}
              checked={whichDate === "Today"}
            />
            &nbsp;Today
          </div>
          <div className="col-12 my-2">
            <input
              type="radio"
              className="form-control-inline"
              name="whichDate"
              value="Between Dates"
              onClick={() => handleDateSelection("Between Dates")}
              onChange={() => handleDateSelection("Between Dates")}
              checked={whichDate === "Between Dates"}
            />
            &nbsp;Between Dates:
          </div>
          {whichDate === "Between Dates" ? (
            <div className="row justify-content-start">
              <div className="col-4 my-2 text-right ">From:</div>
              <div className="col-8 my-2 text-left ">
                <DatePicker
                  selected={dateFrom}
                  selectsStart
                  startDate={dateFrom}
                  endDate={dateTo}
                  dateFormat="yyyy/MM/dd"
                  minDate={d.setDate(d.getDate() - 31)}
                  onChange={(date) => handleDateFromPickerClick(date)}
                />
              </div>

              <div className="col-4 my-2 text-right ">To:</div>
              <div className="col-8 my-2 text-left">
                <DatePicker
                  selected={dateTo}
                  selectsEnd
                  startDate={dateFrom}
                  endDate={dateTo}
                  dateFormat="yyyy/MM/dd"
                  maxDate={new Date()}
                  onChange={(date) => handleDateToPickerClick(date)}
                />
              </div>
            </div>
          ) : null}
        </div>
        {/* </div> */}
      </div>

      <div className="row justify-content-center mb-3">
        <button className={"btn-my"+colorScheme} onClick={handleExcelFileDownloadClick}>
          Download {whichDate}'s Yaadies
        </button>
      </div>
      <div className="row justify-content-center mb-3">
        <button
          className={"btn-my"+colorScheme}
          onClick={handleChallanExcelFileDownloadClick}
        >
          Download {whichDate}'s Challans
        </button>
      </div>
      {!flagLoad && versionTypeId == 1 && (
        <div className="bigger-text text-thick text-center">
          This feature is available in Premium version !
        </div>
      )}
      {flagLoad && (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoad />
        </div>
      )}
    </>
  );
  if (error) {
    return <div>Error retriving Yaadies </div>;
  } else {
    return (
      <div className="container-fluid container-list-table p-0">{content}</div>
    );
  } //else
}
export default AdminReportAll;
