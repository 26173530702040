import React, { useEffect, useState } from "react";
import "./css/login.css";
function AdminLoginForm(props) {
  let [admin, setAdmin] = useState({
    userName: "",
    password: "",
  });
  let [errorAdmin, setErrorAdmin] = useState({
    userName: {
      message: "",
      mxLen: 40,
      mnLen: 5,
      onlyDigits: false,
      allDigits: false,
      noSymbols: false,
    },
    password: {
      message: "",
    },
  });
  let [flagFormInvalid, setFlagFormInvalid] = useState(true);
  let [flagFirstTime, setFlagFirstTime] = useState(false);
  function handleChange(event) {
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      setAdmin({ ...admin, [name]: event.target.value });
      return;
    }
    // let value = event.target.value.trim();
    setAdmin({ ...admin, [name]: event.target.value });
    isValid(event);
  }
  useEffect(() => {
        window.scroll(0, 0);
        let shopUrl=localStorage.getItem("admin");

        if(!shopUrl)
        {
          setFlagFirstTime(true);
        }
  }, []);
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorAdmin[`${name}`].mnLen;
      mxLen = errorAdmin[`${name}`].mxLen;
      onlyDigits = errorAdmin[`${name}`].onlyDigits;
      allDigits = errorAdmin[`${name}`].allDigits;
      noSymbols = errorAdmin[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      // else if (!allDigits) {
      //   for (i = value.length - 1, flag = false; i >= 0; i--) {
      //     const d = value.charCodeAt(i);
      //     if (d < 48 || d > 57) {
      //       message = "";
      //       flag = true;
      //       break;
      //     } //if
      //   } //for
      //   if (flag == false) {
      //     message = "Only digits are not allowed, use some alphabets also";
      //   }
      // } //else if... allDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }

    errorAdmin[`${name}`].message = message;
    checkAllErrors();
  }
  function handleFocus() {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorAdmin) {
      if (errorAdmin[field].message !== "") {
        // error is there
        // flagFormInvalid = true;
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleUserLogIn() {
    props.onUserLogIn();
  }
  function handleAdminLogIn() {
    props.onAdminLogIn();
    // change content in the menubars in the navbar
  }
  function handleFormSubmit(event) {
    event.preventDefault();
    // let data = this.state.admin; //this.state
    props.onFormSubmit(admin);
  }

  return (
    <>
      <div className="container-fluid container-login-form container-md ">
        {/* // row starts */}
        <div className="row">
          <div className="form-heading   col-12">Admin Login</div>
          {flagFirstTime&&<div className="text-thick text-center  col-12">(Password has been sent to your email-id)</div>}
        </div>
        {/* // row ends */}
        {/* // row starts */}
        <div className="form-login ">
          <form className="text-thick bg-mycyan" onSubmit={handleFormSubmit}>
            <div className="text-thick bg-mycyan my-2 px-4 py-4">
              <div className="form-group row  align-items-center px-2">
                {/* row starts */}
                <div className="col-5 text-right">
                  <label>User Name</label>
                </div>
                <div className="col-7   px-0">
                  <input
                    type="email"
                    className="form-control"
                    name="userName"
                    value={admin.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Enter email-id"
                    required
                  />
                </div>
                <div className="offset-5">
                  {errorAdmin.userName.message ? (
                    <span className="error-text">
                      {errorAdmin.userName.message}
                    </span>
                  ) : null}
                </div>
              </div>
              {/* row ends */}
              {/* row starts */}
              <div className="form-group row  align-items-center">
                <div className="col-5 text-right">
                  <label>Password</label>
                </div>
                <div className="col-7 px-0">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={admin.password}
                    onChange={handleChange}
                    placeholder="Enter Password"
                  />
                </div>
              </div>
              {/* row ends */}
              {/* row starts */}
              <div className="form-group row offset-5 text-center mb-0">
                <button
                  className="btn-mycyan-dark"
                  type="submit"
                  disabled={flagFormInvalid}
                >
                  Submit
                </button>
              </div>
              {/* <input type="hidden" name="productId" value={product.productId} /> */}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AdminLoginForm;
