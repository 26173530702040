export class adminPasswordDB {
  constructor(adminPasswords) {
    this.adminPasswords = adminPasswords;
  }
  allAdminPasswords() {
    return this.adminPasswords;
  }
  searchByAdminPasswordId(id) {
    for (let i = 0; i < this.adminPasswords.length; i++) {
      if (this.adminPasswords[i].adminId == id) {
        return this.adminPasswords[i];
      }
    }
    return null; // not found
  }
  filterByShopUrl(query) {
    let filteredAdminPasswords = [];
    for (let i = 0; i < this.adminPasswords.length; i++) {
      if (
        this.adminPasswords[i].shopUrl
          .toLowerCase()
          .includes(query.toLowerCase())
      ) {
        filteredAdminPasswords.push(this.adminPasswords[i]);
      }
    } //for
    return filteredAdminPasswords;
  }
}
export default adminPasswordDB;
