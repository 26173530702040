import React, { Component } from "react";
import OwnerShopTypeList from "./owner-shoptype-list";
import OwnerShopTypeAdd from "./owner-shoptype-add";
import { BeatLoader } from "react-spinners";
import { ShopTypeDB } from "../ShopTypeDB";
import axios from "axios";

class OwnerShopType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      shopType: null,
      filteredShopTypes: [],
      shopTypeList: [],
      shopTypedb: {},
      searchText: "",
      flagLoading: false,
      sortedField: "",
      sortedDirection: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getShopTypeListFromDatabase = () => {
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/shopType/shopTypes")
      .then((res) => {
        let shopTypeList = res.data;
        this.setState({
          filteredShopTypes: shopTypeList,
          shopTypedb: new ShopTypeDB(res.data),
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    window.scroll(0, 0);
    this.getShopTypeListFromDatabase();
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedShopTypes = [];
    searchedShopTypes = this.state.shopTypedb.filterByName(query);
    this.setState({
      filteredShopTypes: searchedShopTypes,
    });
  };
  handleCancelClick = () => {
    this.props.onCancelClick();
  };
  onFormSubmit(shopType) {
    let message;
    let { action } = this.props;
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
    });
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/shopType/update", shopType)
        .then((res) => {
          this.setState({
            flagLoading: false,
          });
          if (res.data == 0) {
            // error updating shopType
            message = "Error... could not update shopType.";
          } else {
            this.updateShopTypeList(shopType);
            let message =
              "The shopType '" + shopType.name + "' updated successfully.";
          
          }
          this.props.onCrudAction(message, "LIST", "Add a ShopType");
        })
        .catch((err) => {
          this.setState({
            flagLoading: false,
          });
          console.log(err);
        });
    } else if (action === "ADD") {
      //ADD / POST
      this.setState({
        flagLoading: true,
      });
      axios
        .post(
          window.routerPrefix + "/shopType/addShopType",
          shopType,
          {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
            },
          }
        )
        .then((res) => {
          this.setState({
            flagLoading: false,
          });
          if (res.data == 0) {
            // error adding shopType
            message = "Error... could not add shopType.";
          } else {
            this.addToShopTypeList(shopType);
            message = "ShopType " + shopType.name + " added successfully.";
            this.props.onCrudAction(message, "ADD"); // Add again
          }
        })
        .catch((err) => {
          console.log("error...");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  addToShopTypeList = (shopType) => {
    let shopTypeList = [...this.state.shopTypeList];
    shopTypeList.unshift(shopType);
    let filteredShopTypes = [...this.state.filteredShopTypes];
    filteredShopTypes.unshift(shopType);
    this.setState({
      filteredShopTypes: filteredShopTypes,
      shopTypeList: shopTypeList,
    });
  };
  updateShopTypeList = (shopType) => {
    let shopTypeList = [...this.state.shopTypeList];
    for (let i = 0; i < shopTypeList.length; i++) {
      if (shopTypeList[i].shopTypeId == shopType.shopTypeId) {
        shopTypeList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        shopTypeList.unshift(shopType);
      }
    } //for
    // remove from filteredshoptypes also
    let filteredShopTypes = [...this.state.filteredShopTypes];
    for (let i = 0; i < filteredShopTypes.length; i++) {
      if (filteredShopTypes[i].shopTypeId == shopType.shopTypeId) {
        filteredShopTypes.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        filteredShopTypes.unshift(shopType);
      }
    } //for
    //sorting
    this.setState({
      filteredShopTypes: filteredShopTypes,
      shopTypeList: shopTypeList,
    });
  };
  handleEditShopType = (shopType) => {
    this.setState({
      shopType: shopType,
      searchText: "",
    });
    this.props.onEditClick();
  };
  handleDeleteShopType = (shopType, result) => {
    let message;
    if (result == 0) {
      //Error... deleting shopType
      message = "Error....could not delete the shopType";
      this.props.onCrudAction(message, "LIST");
      return;
    }
    let shopTypeList = [...this.state.shopTypeList];
    for (let i = 0; i < shopTypeList.length; i++) {
      if (shopTypeList[i].shopTypeId == shopType.shopTypeId) {
        shopTypeList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredshopTypes also
    let filteredShopTypes = [...this.state.filteredShopTypes];
    for (let i = 0; i < filteredShopTypes.length; i++) {
      if (filteredShopTypes[i].shopTypeId == shopType.shopTypeId) {
        filteredShopTypes.splice(i, 1); // remove 1 element from position i
      }
    } //for
    this.setState({
      shopTypedb: new ShopTypeDB(shopTypeList),
      filteredShopTypes: filteredShopTypes,
      shopTypeList: shopTypeList,
    });
    message = shopType.name + " deleted successfully";
    this.props.onCrudAction(message, "LIST");
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;
    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredShopTypes } = this.state;
    if (direction == false) {
      //in ascending order
      filteredShopTypes = filteredShopTypes.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredShopTypes = filteredShopTypes.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({
      filteredShopTypes: filteredShopTypes,
      sortedField: field,
    });
  };
  render() {
    let { action } = this.props;
    let { shopType } = this.state;

    let { flagLoading } = this.state;
    let { colorScheme } = this.props;
    let { shopUrl } = this.props;
    let content;
    if (flagLoading)
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={colorScheme} flagLoading />
        </div>
      );
    else if (action === "LIST") {
      // First show list
      content = (
        <OwnerShopTypeList
          onEditShopType={this.handleEditShopType}
          onDeleteShopType={this.handleDeleteShopType}
          onSearchTextChange={this.handleSearchTextChange}
          filteredShopTypes={this.state.filteredShopTypes}
          searchText={this.state.searchText}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
    shopUrl = {shopUrl}
          
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <OwnerShopTypeAdd
            onFormSubmit={this.onFormSubmit}
            onCancelClick={this.handleCancelClick}
            shopType={shopType}
            action={action}
            colorScheme={colorScheme}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress.</div>;
    }
    return (
      <div className="container container-md container-list-table">
        {content}
      </div>
    );
  }
}
export default OwnerShopType;
