import React from "react";
function NavBarYaadi(props) {
  function handleBackClick(event) {
    event.preventDefault(); // Not required
    props.onBackClick();
  }
  function handlePlaceorderClick(event) {
    event.preventDefault(); // Not required
    props.onPlaceorderClick();
  }
  let { numberOfCartProducts } = props;
  let { totalAmount } = props;
  let { message } = props;
  let { settings } = props;
  let { currentPage } = props;
  let { isBilling } = props;
  let { shopDetails } = props;
  let { colorScheme } = props;

  let textClassList =
    "col-10 col-md-8 bg-white text-center bigger-text column py-1";
  let btnClassList = "btn-my" + colorScheme;
  let classList = " container-fluid container-md fixed-top p-0 bg-my"+colorScheme+"-dark";
  
  
  // if (shopTypeId == 2) {
  //   // vegetable shop
  //   classList += " bg-mygreen";
  //   btnClassList = "btn-mygreen";
  //   textClassList += " text-mygreen";
  // } else if (shopTypeId == 5) {
  //   // grocery shop
  //   classList += " bg-mycyan";
  //   btnClassList = "btn-mycyan";
  //   textClassList += " text-mycyan";
  // } else if (shopTypeId == 6) {
  //   // dryfruit store
  //   classList += " bg-mybrown";
  //   btnClassList = "btn-mybrown";
  //   textClassList += " text-mybrown";
  // } else if (shopTypeId == 8) {
  //   // restaurant
  //   classList += " bg-mypurple";
  //   btnClassList = "btn-mypurple";
  //   textClassList += " text-mypurple";
  // }
  return (
    <>
      <div className={classList}>
        <div className="row justify-content-center mt-2 ">
          <div className={textClassList}>
            <span className="text-thick">
              {shopDetails.name}, {shopDetails.city}
            </span>
          </div>
        </div>
        {/* row starts */}
        <div className="row justify-content-center align-items-center my-2 mx-1">
          <div className="col-5 col-md-2 text-center p-0">
            <button
              className={btnClassList} //"btn btn-success"
              to="#"
              onClick={handleBackClick}
            >
              {!isBilling?"Back to Shopping":"Back"}
            </button>
          </div>
          {currentPage === "YAADI" ? (
            <div className="col-3 col-md-2 text-center p-0">
              <button
                // className="nav-link text-myblack column"
                className={btnClassList} //"btn btn-success"
                to="#"
                onClick={handlePlaceorderClick}
              >
                {!isBilling?"Place Order":"View Bill"}
              </button>
            </div>
          ) : (
            <span>&nbsp;&nbsp;&nbsp; </span>
          )}
          <div
            className="col-4 col-md-2 text-thick text-center p-0 bg-white"
          >
            <div>
              {numberOfCartProducts == 1 ? "Item" : "Items"}{" "}
              {numberOfCartProducts}
            </div>
            <div>
              {/* {!dummyPrice && ( */}
              {true && (
                <span>
                  Total: <i className="fas fa-rupee-sign"></i> {totalAmount}
                </span>
              )}
            </div>
          </div>
        </div>
        {/* row end */}
        <div className="row justify-content-center">
          {message != "" ? (
            <div className="col-12 text-center text-red bg-white column p-0">
              <div>{message}</div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
export default NavBarYaadi;