import React, { useEffect, useState } from "react";
import AdminProductListQuick from "./admin-product-list-quick";
import { BeatLoader } from "react-spinners";
import { ProductDB } from "../ProductDB";
import axios from "axios";

function AdminProductQuick(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [filteredProducts, setFilteredProducts] = useState([]);
  let [productList, setProductList] = useState([]);
  let [productdb, setProductdb] = useState([]);
  let [brandList, setBrandList] = useState([]);
  let [categoryList, setCategoryList] = useState([]);
 
  useEffect(() => {
    init();
  }, []);

  function init() {
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .all([
        axios.get(window.routerPrefix + "/product/products/" + shopUrl),
        axios.get(window.routerPrefix + "/category/categories/" + shopUrl),
        axios.get(window.routerPrefix + "/brand/brands/" + shopUrl),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          let productList = res1.data;
          let categoryList = res2.data;
          let brandList = res3.data;
          let updatedProducts = [...productList];
          productList.map((product, index) => {
            // get category (string) from categoryId
            for (let i = 0; i < categoryList.length; i++) {
              if (product.categoryId === categoryList[i].categoryId) {
                updatedProducts[index].category = categoryList[i].name;
                break;
              }
            }
            // get brand (string) from brandId
            for (let i = 0; i < brandList.length; i++) {
              if (product.brandId === brandList[i].brandId) {
                updatedProducts[index].brand = brandList[i].name;
                break;
              }
            } //for
            let unitList = [
              { unitId: 1, name: "Number" },
              { unitId: 2, name: "Multiple of 250 gm" },
              { unitId: 3, name: "Multiple of 1 kg" },
              { unitId: 4, name: "Multiple of 500 gm" },
              { unitId: 5, name: "Multiple of 1 kg" },
            ];
            // get unit (string) from unitId
            for (let i = 0; i < unitList.length; i++) {
              if (product.unitId === unitList[i].unitId) {
                updatedProducts[index].unit = unitList[i].name;
                break;
              } //if
            } //for
            updatedProducts[index].mrpOld = product.mrp;
            updatedProducts[index].finalPriceOld = product.finalPrice;
            updatedProducts[index].flagPriceChange = false;
            updatedProducts[index].flagPriceUpdate = false;
            // By, default mrp and final price should be same
            // updatedProducts[index].flagSamePrice = true;
            if(product.mrp!=product.finalPrice)
            {
              updatedProducts[index].flagSamePrice = false;
            }
            else
            {
              updatedProducts[index].flagSamePrice = true;
            }
            
          }); //map
          productList = updatedProducts;
          setFlagLoad(false);
          setProductList(productList);
          setFilteredProducts(productList);
          setProductdb(new ProductDB(productList));
          setCategoryList(categoryList);
          setBrandList(brandList);
        })
      )
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedProducts = [];
    searchedProducts = productdb.filterByName(query);
    setFilteredProducts(searchedProducts);
  }
  function handleCancelClick() {
    props.onCancelClick();
  }
  function handleMrpChange(product, newMrp) {
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i].mrp = newMrp;
        pList[i].flagPriceChange = true;
        pList[i].flagPriceUpdate = false;
        if (product.flagSamePrice) {
          pList[i].finalPrice = newMrp;
        }
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i].mrp = newMrp;
        fProducts[i].flagPriceChange = true;
        fProducts[i].flagPriceUpdate = false;
        if (product.flagSamePrice) {
          fProducts[i].finalPrice = newMrp;
        }
      }
    } //for
    setProductdb(new ProductDB(pList));
    setFilteredProducts(fProducts);
    setProductList(pList);
  }
  function handleFinalPriceChange(product, newFinalPrice) {
    if (product.flagSamePrice) {
      // mrp and final price are same, don't change
      return;
    }
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i].finalPrice = newFinalPrice;
        pList[i].flagPriceChange = true;
        pList[i].flagPriceUpdate = false;
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i].finalPrice = newFinalPrice;
        fProducts[i].flagPriceChange = true;
        fProducts[i].flagPriceUpdate = false;
      }
    } //for
    setProductdb(new ProductDB(pList));
    setFilteredProducts(fProducts);
    setProductList(pList);
  }
  function handleSamePriceCheckBoxChange(product, userInput) {
    //2023
    let pList = [...productList];
      let fProducts = [...filteredProducts];
    // if checked by user, then make the final price same as mrp
    if(userInput )// checked 
    { 
      if(product.mrp==product.finalPrice) // nothing to do
      {
        return;
      }
       // make both prices same
        for (let i = 0; i < pList.length; i++) {
          if (pList[i].productId == product.productId) {
            pList[i].flagSamePrice = userInput; //true/false
            if(userInput)
            {// checked, so prices should be same
              pList[i].finalPrice = pList[i].mrp;
              pList[i].flagPriceChange = true;
              pList[i].flagPriceUpdate = false;
            }
            break;
          }
        } //for
        // Update in  filteredproducts also
        for (let i = 0; i < fProducts.length; i++) {
          if (fProducts[i].productId == product.productId) {
            fProducts[i].flagSamePrice = userInput;
            if(userInput)
            {
              fProducts[i].finalPrice = pList[i].mrp;
              fProducts[i].flagPriceChange = true;
              fProducts[i].flagPriceUpdate = false;
            }
            break;
          }
        } //for
    }
    else  // unchecked 
    { // make it unchecked
for (let i = 0; i < pList.length; i++) {
  if (pList[i].productId == product.productId) {
    pList[i].flagSamePrice = userInput; //false
    break;
  }
} //for
// Update in  filteredproducts also
for (let i = 0; i < fProducts.length; i++) {
  if (fProducts[i].productId == product.productId) {
    fProducts[i].flagSamePrice = userInput;
    break;
  }
} //for
    }
    setProductdb(new ProductDB(pList));
    setFilteredProducts(fProducts);
    setProductList(pList);
  }
  function handleSaveProductPrice(product) {
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i] = product; // update the product
        pList[i].flagPriceUpdate = true;
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i] = product; // update the product
        fProducts[i].flagPriceUpdate = true;
        break;
      }
    } //for
    setProductdb(new ProductDB(pList));
    setFilteredProducts(fProducts);
    setProductList(pList);
    let message = "Price of " + product.name + " updated successfully";
    props.onCrudAction(message, "LIST");
  }

  function handleDeleteProduct(product) {
    
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    // remove from filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    setProductdb(new ProductDB(pList));
    setFilteredProducts(fProducts);
    setProductList(pList);
    let message = product.name + " deleted successfully";
    props.onCrudAction(message, "LIST");
  }
  let { action } = props;
  let { shopDetails } = props;
  let { productsLimit } = shopDetails;
  let { languageChoice } = shopDetails;
  let { shopUrl } = props;
  let { colorScheme } = props;
  return (
    <div className="container-fluid container-md container-list-table p-0">
    {flagLoad && (<div className="text-center">
    <BeatLoader size={16} color={colorScheme} flagLoad />
  </div>)}

{!flagLoad&&action === "LIST" &&(
  <AdminProductListQuick
    onSaveProductPrice={handleSaveProductPrice}
    onMrpChange={handleMrpChange}
    onFinalPriceChange={handleFinalPriceChange}
    onSamePriceCheckBoxChange={handleSamePriceCheckBoxChange}
    filteredProducts={filteredProducts}
    searchText={searchText}
    onSearchTextChange={handleSearchTextChange}
    onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
    languageChoice={languageChoice}
    productsLimit={productsLimit}
    currentSize={productdb.size}
    shopUrl={shopUrl}
    colorScheme={colorScheme}
  />)}    
  </div>
  )
}
export default AdminProductQuick;