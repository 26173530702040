import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { customerVisitedDB } from "../customerVisitedDB";
import AdminReportCustomerVisitsList from "./admin-report-customer-visits-list";
import axios from "axios";
function AdminReportCustomerVisits(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [flagDisplay, setFlagDisplay] = useState(false);
  let [whichDate, setWhichDate] = useState("Today");
  let [dateFrom, setDateFrom] = useState(new Date());
  let [dateTo, setDateTo] = useState(new Date());
  let [filteredCustomerVisited, setFilteredCustomerVisited] = useState([]);
  let [searchText, setSearchText] = useState("");
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [sectionNumber, setSectionNumber] = useState("");
  let [customerVisitedDb, setCustomerVisitedDb] = useState([]);

  //  constructor(props) {
  //   super(props);
  //   this.state = {
  //     error: null,
  //     response: {},
  //     filteredProducts: [],
  //     filteredCustomerVisited: [],
  //     customervisiteddb: {},
  //     flagLoading: false,
  //     whichDate: "Today",
  //     searchText: "",
  //     flagDisplay: false,
  //     dateTo: new Date(),
  //     dateFrom: new Date(),
  //   };
  //  }
  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  function dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  function getDataFromDatabase(whichDate) {
    let { shopUrl } = props;

    let diff1, diff2;
    if (whichDate === "Between Dates") {
      let d = new Date();
      diff1 = dateDiffInDays(dateFrom, d);
      diff2 = dateDiffInDays(dateTo, d);
      whichDate = diff1 + "_" + diff2;
    }
    setFlagLoad(true);
    // Get list of customers visited
    axios
      .get(
        window.routerPrefix +
          "/customerEntry/customerEntries/" +
          shopUrl +
          "/" +
          whichDate
      )
      .then((res) => {
        let filteredCustomerVisited=res.data;
        setFilteredCustomerVisited(filteredCustomerVisited);
        setFlagLoad(false);
        let customerDb = new customerVisitedDB(filteredCustomerVisited);
        setCustomerVisitedDb(customerDb);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setError(err);
      });
  }
  function handleDateSelection(value) {
    setWhichDate(value);
    setFlagDisplay(false);
  }
  function handleDateFromPickerClick(date) {
    setDateFrom(date);
    setFlagDisplay(false);
  }
  function handleDateToPickerClick(date) {
    setDateTo(date);
    setFlagDisplay(false);
  }
  function handleShowHereClick() {
    if (dateFrom > dateTo) {
      window.alert("Enter dates correctly...");
      return;
    }
    setFlagDisplay(true);
    getDataFromDatabase(whichDate); // value is whichDate (today/yesterday)
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedCustomerVisited = [];
    searchedCustomerVisited = customerVisitedDB.filterByName(query);
    setFilteredCustomerVisited(searchedCustomerVisited);
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let direction = false;
    if (field === sortedField) {
      // same button clicked twice
      direction = !direction;
    } else {
      // different field
      direction = false;
    }
    setDirection(direction);
    if (direction == false) {
      //in ascending order
      filteredCustomerVisited = filteredCustomerVisited.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredCustomerVisited = filteredCustomerVisited.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredCustomerVisited(filteredCustomerVisited);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  let d = new Date();
  console.log(error);
  let content = (
    // This is enclosed in container-list-table
    <>
      {/* row starts */}
      <div className=" col-12">
        <div className="form-heading">CUSTOMER VISITS</div>
      </div>
      {/* row ends */}
      {/* row starts */}
      <div className="row">
        <div className="col-12 my-2 text-center ">
          <input
            type="radio"
            className="form-control-inline"
            name="whichDate"
            value="Yesterday"
            onClick={() => handleDateSelection("Yesterday")}
            onChange={() => handleDateSelection("Yesterday")}
          />
          &nbsp;Yesterday
          <div className="col-12 my-2">
            <input
              type="radio"
              className="form-control-inline"
              name="whichDate"
              value="Today"
              onClick={() => handleDateSelection("Today")}
              onChange={() => handleDateSelection("Today")}
              checked={whichDate === "Today"}
            />
            &nbsp;Today
          </div>
          {/* <div className="col-12 my-2">
              <input
                type="radio"
                className="form-control-inline"
                name="whichDate"
                value="Between Dates"
                onClick={() => this.handleDateSelection("Between Dates")}
                onChange={() => this.handleDateSelection("Between Dates")}
                checked={whichDate === "Between Dates"}
              />
              &nbsp;Between Dates:
            </div> */}
          {/* {whichDate === "Between Dates" ? (
              <div className="row justify-content-start">
                <div className="col-4 my-2 text-right column">From:</div>
                <div className="col-8 my-2 text-left column">
                  <DatePicker
                    selected={dateFrom}
                    selectsStart
                    startDate={dateFrom}
                    endDate={dateTo}
                    dateFormat="yyyy/MM/dd"
                    minDate={d.setDate(d.getDate() - 8)}
                    onChange={(date) => this.handleDateFromPickerClick(date)}
                  />
                </div>

                <div className="col-4 my-2 text-right ">To:</div>
                <div className="col-8 my-2 text-left">
                  <DatePicker
                    selected={dateTo}
                    selectsEnd
                    startDate={dateFrom}
                    endDate={dateTo}
                    dateFormat="yyyy/MM/dd"
                    maxDate={new Date()}
                    onChange={(date) => this.handleDateToPickerClick(date)}
                  />
                </div>
              </div>
            ) : null} */}
        </div>
      </div>
      {flagLoad && (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoad />
        </div>
      )}
      {!flagLoad && (
        <AdminReportCustomerVisitsList
          filteredCustomerVisited={filteredCustomerVisited}
          whichDate={whichDate}
          searchText={searchText}
          sectionNumber={sectionNumber}
          flagDisplay={flagDisplay}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          onHeaderClick={handleHeaderClick}
          onSectionNumberClick={handleSectionNumberClick}
          onShowHereClick={handleShowHereClick}
        />
      )}
    </>
  );
  if (error) {
    return <div>Error retriving Cumulative </div>;
  } else {
    return (
      // <div className="container-fluid bg-blue">
      // <div className="row">
      <div className="container-fluid container-list-table p-0">{content}</div>
      // </div>
      // </div>
    );
  } //else
}
export default AdminReportCustomerVisits;
