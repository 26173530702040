export class ShopDB {
  constructor(shops) {
    this.shops = shops;
  }
  allShops() {
    return this.shops;
  }
  searchByShopId(id) {
    for (let i = 0; i < this.shops.length; i++) {
      if (this.shops[i].shopId == id) {
        return this.shops[i];
      }
    }
    return null; // not found
  }
  filterByShopType(shopTypeId) {
    let filteredShops = [];
    for (let i = 0; i < this.shops.length; i++) {
      if (this.shops[i].shopTypeId == shopTypeId) {
        filteredShops.push(this.shops[i]);
      }
    }
    return filteredShops;
  }
  filterByShopUrl(query) {
    let filteredShops = [];
    for (let i = 0; i < this.shops.length; i++) {
      if (this.shops[i].shopUrl.toLowerCase().includes(query.toLowerCase())) {
        filteredShops.push(this.shops[i]);
      }
    } //for
    return filteredShops;
  }
}
export default ShopDB;
