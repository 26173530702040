import React, { Component } from 'react';
import { Link } from "react-router-dom";

class CheckOut extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <p>
              <Link to="/">
                  Back to Shopping
              </Link>
            </p>
         );
    }
}
 
export default CheckOut;