import React, { useEffect, useState } from "react";
function AdminClientAdd(props) {
  let [client, setClient] = useState({
    clientId: "",
    name: "",
    age: "",
    address: "",

    whatsApp: "",
    weight_original: "",
    height: "",
    illness: "",
    status: "",
    dietDeliveryDay: "",
    extraInfo: "",
  });
  let [errorClient, setErrorClient] = useState({
    name: { message: "", mxLen: 40, mnLen: 5, onlyDigits: false },
    age: { message: "", mxLen: 3, mnLen: 1, onlyDigits: true },
    address: { message: "", mxLen: 80, mnLen: 5, onlyDigits: false },
    whatsApp: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    weight_original: { message: "", mxLen: 6, mnLen: 2 },
    height: { message: "", mxLen: 10, mnLen: 2 },
    status: { message: "" },
    illness: { message: "", mxLen: 100, mnLen: 2 },
    dietDeliveryDay: { message: "" },
    extraInfo: { message: "", mxLen: 80, mnLen: 2, onlyDigits: false },
  });
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let [file, setFile] = useState("");
  let [fileName, setFileName] = useState("");
  let emptyClient;
  useEffect(() => {
    init();
  }, [props]);
  function init() {
    emptyClient = client;
    let { action } = props;
    if (action === "ADD") {
      setFlagFormInvalid(true);
      // may retain some properties
      setClient(emptyClient);
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      setFlagFormInvalid(false);
      setClient(props.client);
    }
  }
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      setClient({ ...client, [name]: event.target.value });
      return;
    }
    if (inputType == "file") {
      let file = event.target.files[0];
      if (!file) {
        return;
      }
      let fileType = file.type.substring(0, file.type.indexOf("/"));
      let errClient = { ...errorClient };
      let fileName = file.name;

      if (errorClient[name]["imageType"] != fileType) {
        errorClient[`${name}`].message = "Check type of file";
        setErrorClient(errClient);
      } else {
        errorClient[`${name}`].message = "";
        setErrorClient(errClient);
        setClient({ ...client, [name]: event.target.value });
        setFile(file);
        setFileName(fileName);
      }
      return;
    }
    setClient({ ...client, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorClient[`${name}`].mnLen;
      mxLen = errorClient[`${name}`].mxLen;
      onlyDigits = errorClient[`${name}`].onlyDigits;
      allDigits = errorClient[`${name}`].allDigits;
      noSymbols = errorClient[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      // else if (!allDigits) {
      //   for (i = value.length - 1, flag = false; i >= 0; i--) {
      //     const d = value.charCodeAt(i);
      //     if (d < 48 || d > 57) {
      //       message = "";
      //       flag = true;
      //       break;
      //     } //if
      //   } //for
      //   if (flag == false) {
      //     message = "Only digits are not allowed, use some alphabets also";
      //   }
      // } //else if... allDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errClient = { ...errorClient };
    errorClient[`${name}`].message = message;
    setErrorClient(errClient);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorClient) {
      if (errorClient[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleRatingClick(value) {
    setClient({ ...client, rating: value });
  }
  function handleSubmit(event) {
    event.preventDefault();
    props.onFormSubmit(client);
    setClient(emptyClient);
  }
  function handleCancelClick() {
    props.onCancelClick();
  }
  function handleSelectStatusChange(event) {
    let index = event.target.selectedIndex; // get selected index, instead of selected value
    var optionElement = event.target.childNodes[index];
    let status = event.target.value.trim();
    setClient({ ...client, status: status });
  }
  function handleSelectDayChange(event) {
    let index = event.target.selectedIndex; // get selected index, instead of selected value
    var optionElement = event.target.childNodes[index];
    let dietDeliveryDay = event.target.value.trim();
    setClient({ ...client, dietDeliveryDay: dietDeliveryDay });
  }
  let statusList = ["enquired", "Active", "On Hold", "Stopped"];
  let optionsStatus = statusList.map((status, index) => (
    <option value={status} key={index} id={index}>
      {status}
    </option>
  ));
  let days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  let optionsDietDeliveryDay = days.map((day, index) => (
    <option value={day} key={index} id={index}>
      {day}
    </option>
  ));
  let { action } = props;
  let { colorScheme } = props;
  let heading;
  if (action === "ADD") {
    heading = "Add a Client";
  } else if (action === "UPDATE") {
    heading = "Update the Client";
  }
  return (
    <>
      <div className="form-heading  col-12">{heading}</div>
      <form
        className={"text-thick sdc-bigger-text p-4 bg-my" + colorScheme}
        onSubmit={handleSubmit}
      >
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Name</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="name"
              value={client.name}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Client name"
              required
            />
          </div>
          <div className="offset-5">
            {errorClient.name.message ? (
              <span className="error-text">{errorClient.name.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Age</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="age"
              value={client.age}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Client Age"
              required
            />
          </div>
          <div className="offset-5">
            {errorClient.age.message ? (
              <span className="error-text">{errorClient.age.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Address</label>
          </div>
          <div className="col-7 column px-0">
            <textarea
              type="text"
              className="form-control"
              rows="2"
              cols="50"
              name="address"
              value={client.address}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Client address max 80 characters"
              required
            />
          </div>
          <div className="offset-5">
            {errorClient.address.message ? (
              <span className="error-text">{errorClient.address.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>WhatsApp Number</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="whatsApp"
              value={client.whatsApp}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="WhatsApp Number"
              required
            />
          </div>
          <div className="offset-5">
            {errorClient.whatsApp.message ? (
              <span className="error-text">{errorClient.whatsApp.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Original Weight</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="weight_original"
              value={client.weight_original}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Weight"
              required
            />
          </div>
          <div className="offset-5">
            {errorClient.weight_original.message ? (
              <span className="error-text">
                {errorClient.weight_original.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Height</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="height"
              value={client.height}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Height"
              required
            />
          </div>
          <div className="offset-5">
            {errorClient.height.message ? (
              <span className="error-text">{errorClient.height.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Illness</label>
          </div>
          <div className="col-7 column px-0">
            <textarea
              type="text"
              className="form-control"
              rows="4"
              cols="50"
              name="illness"
              value={client.illness}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Client Illness in max 100 characters"
              required
            />
          </div>
          <div className="offset-5">
            {errorClient.illness.message ? (
              <span className="error-text">{errorClient.illness.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}

        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Status</label>
          </div>
          <div className="col-7 px-0">
            <select
              className="form-control"
              name="status"
              value={client.status}
              onChange={handleSelectStatusChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            >
              <option> Select Status </option>
              {optionsStatus}
            </select>
          </div>
          {/* <div className="offset-5">
            {errorProduct.category.message ? (
              <span className="error-text">
                {errorProduct.category.message}
              </span>
            ) : null}
          </div> */}
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Client-Day</label>
          </div>
          <div className="col-7 px-0">
            <select
              className="form-control"
              name="dietDeliveryDay"
              value={client.dietDeliveryDay}
              onChange={handleSelectDayChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
            >
              <option> Select Day </option>
              {optionsDietDeliveryDay}
            </select>
          </div>
          {/* <div className="offset-5">
            {errorProduct.category.message ? (
              <span className="error-text">
                {errorProduct.category.message}
              </span>
            ) : null}
          </div> */}
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Extra Information</label>
          </div>
          <div className="col-7 column px-0">
            <textarea
              type="text"
              className="form-control"
              rows="2"
              cols="50"
              name="extraInfo"
              value={client.extraInfo}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Extra Information"
            />
          </div>
          <div className="offset-5">
            {errorClient.extraInfo.message ? (
              <span className="error-text">
                {errorClient.extraInfo.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row offset-5 text-center mb-0">
          <button
            className={"btn-my" + colorScheme + "-dark"}
            type="submit"
            disabled={flagFormInvalid}
          >
            {action}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            className={"btn-my" + colorScheme + "-dark"}
            onClick={handleCancelClick}
          >
            CANCEL
          </button>
        </div>
        <input type="hidden" name="clientId" value={client.clientId} />
      </form>
    </>
  );
}
export default AdminClientAdd;
