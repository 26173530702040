import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";

function AdminClientList(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  function handleEditClient(client) {
    props.onEditClient(client);
  }
  function handleDeleteClient(client) {
    // Now call for delete api
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .delete(
        window.routerPrefix +
          "/client/delete/" +
          shopUrl +
          "/" +
          client.clientId
      )
      .then((res) => {
        setFlagLoad(false);
        if (res.data != 1) {
          // could not delete the client
          props.onDeleteClient(client, 0);
        } else {
          props.onDeleteClient(client, 1);
        }
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
    // props.onDeleteClient(client);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  let { searchText } = props;
  let { sectionNumber } = props;
  let { subject } = props;
  let { filteredClients } = props;
  let { currentSize } = props;

  return (
    <div className="col-md-12">
      {filteredClients.length != 0 && (
        <div className="form-heading  col-12">
          LIST OF {subject} ({filteredClients.length})
        </div>
      )}
      {filteredClients.length == 0 && (
        <div className="form-heading  col-12">NO {subject}</div>
      )}
      {/* row starts */}

      {filteredClients.length != 0 && (
        <div className="row smaller-text my-1 justify-content-center align-items-center ">
          <div className="col-6 d-md-none text-center p-0">
            <Link to="#" id="1" onClick={handleSectionNumberClick}>
              WhatsApp
            </Link>
          </div>
          <div className="col-6 d-md-none text-center p-0">
            <Link to="#" id="2" onClick={handleSectionNumberClick}>
              {" "}
              Address
            </Link>
          </div>
        </div>
      )}
      {/* row ends */}
      {/* row starts */}
      {/* no enity at all, so don't provide search facility  */}
      {currentSize != 0 && (
        <div className="row justify-content-center">
          <div className="col-10 col-md-6">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={handleSearchTextBoxKeyUp}
              onChange={handleSearchTextChange} // is contolled by parent
              placeholder="Search Clients"
              id=""
            />
          </div>
        </div>
      )}
      {/* row ends */}
      {filteredClients.length != 0 && (
        <div className="row justify-content-center">
          <div className="col-12 ">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-borderless sdc-bigger-text">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="name">
                        Client
                      </Link>
                    </th>
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="status">
                        Status
                      </Link>
                    </th>
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="dietDeliveryDay">
                        Day
                      </Link>
                    </th>
                    {sectionNumber == 1 && (
                      <th>
                        <Link to="#" onClick={handleHeaderClick} id="whatsApp">
                          WhatsApp
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 2 && (
                      <th>
                        <Link to="#" onClick={handleHeaderClick} id="address">
                          Address
                        </Link>
                      </th>
                    )}
                    <th></th>
                  </tr>
                </thead>
                {filteredClients.map((client, index) => (
                  <tbody key={index}>
                    <tr className="myborder-top mt-5">
                      <td>{index + 1}</td>
                      <td>{client.name}</td>
                      <td>{client.status}</td>
                      <td>{client.dietDeliveryDay}</td>
                      {sectionNumber == 1 && <td>{client.whatsApp}</td>}
                      {sectionNumber == 2 && <td>{client.address}</td>}
                    </tr>
                    <tr className="mb-5 p-5 ">
                      <td></td>
                      <td>
                        <Link to="#" onClick={() => handleEditClient(client)}>
                          {/* Edit */}
                          <i className="fas fa-edit admin-icons"></i>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                          to="#"
                          onClick={() => {
                            if (
                              window.confirm(
                                "You really want to delete " +
                                  client.name +
                                  " client ?"
                              )
                            )
                              handleDeleteClient(client);
                          }}
                        >
                          {/* Delete */}
                          <i className="far fa-trash-alt admin-icons"></i>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </Link>
                      </td>
                      <td>
                        {flagLoad && (
                          <div className="col-4">
                            <BeatLoader size={8} color={"blue"} flagLoad />
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default AdminClientList;
