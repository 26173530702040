import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminCustomerData(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [flagDownload, setFlagDownload] = useState(false);
  let [error, setError] = useState("");
  let [filteredCustomers, setFilteredCustomers] = useState([]);
  let { colorScheme } = props;
  function getCustomersFromDatabase() {
    let { shopUrl } = props;
    setFlagDownload(false);
    setFlagLoad(true);
    axios
      .get(window.routerPrefix + "/customer/customers/" + shopUrl)
      .then((res) => {
        let customerList = res.data;
        let updatedCustomers = [...customerList];
        customerList.map((customer, index) => {
          let unitList = [
            { unitId: 1, name: "Number" },
            { unitId: 2, name: "Multiple of 250 gm" },
            { unitId: 3, name: "Multiple of 1 kg" },
            { unitId: 4, name: "Multiple of 500 gm" },
            { unitId: 5, name: "Multiple of 1 kg" },
          ];
          // get unit (string) from unitId
          for (let i = 0; i < unitList.length; i++) {
            if (customer.unitId === unitList[i].unitId) {
              updatedCustomers[index].unit = unitList[i].name;
              break;
            } //if
          } //for
        }); //map
        customerList = updatedCustomers;
        setFlagLoad(false);
        setFlagDownload(true);
        setFilteredCustomers(customerList);
        JSONToCSVConvertor(customerList, "", true);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setError(err);
      });
  }
  function handleDownloadClick() {
    getCustomersFromDatabase();
  }
  function JSONToCSVConvertor(filteredCustomers, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof filteredCustomers != "object"
        ? JSON.parse(filteredCustomers)
        : filteredCustomers;
    var arrData = filteredCustomers;
    //Set Report title in first row or line
    CSV += ReportTitle + "\r\n\n";
    //condition will generate the Label/Header
    let headers = ["Name", "Address", "WhatsApp"];
    if (ShowLabel) {
      var row = "";
      var CSV = "";
      //This loop will extract the label from 1st index of on array
      for (let i = 0; i < headers.length; i++) {
        //Now convert each value to string and comma-seprated

        row += headers[i] + ",";
      }
      // row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }
    //1st loop is to extract each row
    let data;
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        data = arrData[i][index];
        if (
          index === "customerId" ||
          index === "dateAdded" ||
          index === "dateModified"
        ) {
          continue;
        }
        // if (index === "name") {
        //   data = arrData[i]["name"] + "-" + arrData[i]["information"];
        // } else if (index === "instock") {
        //   data = arrData[i]["instock"] ? "Yes" : "No";
        // }
        row += '"' + data + '",';
      } //for
      CSV += row + "\r\n";
    }
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    let fileName = "Customers  ";
    let dt = new Date();
    fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    //  + ".xls";
    //will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but will not work in some browsers
    // or you will not get the correct file extension

    //This trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //This part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  let content = (
    // This is enclosed in container-list-table
    <></>
  );
  //   if (error) {
  //     return <div>Error retriving Yaadies </div>;
  //   } else {
  return (
    <div className="container container-list-table ">
      {!flagLoad && (
        <div className=" text-center ">
          <button
            className={"btn-my" + colorScheme}
            onClick={handleDownloadClick}
          >
            Download Customer Data
          </button>
        </div>
      )}
      {flagLoad && (
        <div className=" text-center ">
          <BeatLoader size={16} color={colorScheme} flagLoad />
        </div>
      )}
      {error && (
        <div className="text-center text-red">
          "Error retriving Customer Sheet"
        </div>
      )}
      {flagDownload && (
        <div className="text-center text-red">Customer-Data downloaded.</div>
      )}
    </div>
  );
}
export default AdminCustomerData;
