import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminCategoryListSingle(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");

  function handleEditCategory(category) {
    props.onEditCategory(category);
  }

  function handleDeleteCategory(category) {
    // Now call for delete api
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .delete(
        window.routerPrefix +
          "/category/delete/" +
          shopUrl +
          "/" +
          category.categoryId
      )
      .then((res) => {
        setFlagLoad(false);
        if (res.data != 1) {
          // could not delete the category
          props.onDeleteCategory(category, 0);
        } else {
          props.onDeleteCategory(category, 1);
        }
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
  }

  let { category } = props;
  let { index } = props;
  let content = (
    // This is enclosed in container-list-table
    <>
      <tr className="myborder-top">
        <td>{index + 1}</td>
        <td>{category.name}</td>
        <td>{category.rating}</td>
      </tr>
      <tr>
        <td></td>
        <td>
          <div className="row">
            <div className="col-4">
              <Link to="#" onClick={() => handleEditCategory(category)}>
                {/* Edit */}
                <i className="fas fa-edit admin-icons"></i>
              </Link>
            </div>
            {!flagLoad && (
              <div className="col-4">
                <Link
                  to="#"
                  onClick={() => {
                    if (
                      window.confirm(
                        "You really want to delete " + category.name + " ?"
                      )
                    )
                      handleDeleteCategory(category);
                  }}
                >
                  {/* Delete */}
                  <i className="far fa-trash-alt admin-icons"></i>
                </Link>
              </div>
            )}
            {flagLoad && (
              <div className="col-4">
                <BeatLoader size={8} color={"blue"} flagLoad />
              </div>
            )}
          </div>
        </td>
      </tr>
    </>
  );
  if (error) {
    return <div>Error retriving categoriess </div>;
  } else {
    return content;
  } //else
}
export default AdminCategoryListSingle;
