export class ShopTypeDB {
  constructor(shopTypes) {
    this.shopTypes = shopTypes;
  }
  allShopTypes() {
    return this.shopTypes;
  }
  searchByShopTypeId(id) {
    for (let i = 0; i < this.shopTypes.length; i++) {
      if (this.shopTypes[i].shopTypeId == id) {
        return this.shopTypes[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredShopTypes = [];
    for (let i = 0; i < this.shopTypes.length; i++) {
      if (this.shopTypes[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredShopTypes.push(this.shopTypes[i]);
      }
    } //for
    return filteredShopTypes;
  }
}
export default ShopTypeDB;
