export class BrandDB {
  constructor(brands) {
    this.brands = brands;
  }
  allBrands() {
    return this.brands;
  }
  searchByBrandId(id) {
    for (let i = 0; i < this.brands.length; i++) {
      if (this.brands[i].brandId == id) {
        return this.brands[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredBrands = [];
    for (let i = 0; i < this.brands.length; i++) {
      if (this.brands[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredBrands.push(this.brands[i]);
      }
    } //for
    return filteredBrands;
  }
}
export default BrandDB;
