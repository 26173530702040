import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminReportSummaryListSingle from "./admin-report-summary-list-single";
function AdminReportSummaryList(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [customerList, setCustomerList] = useState([]);
  let [searchText, setSearchText] = useState("");
  let [filteredYaadies, setFilteredYaadies] = useState([]);
  let [sortedField, setSortedField] = useState([]);
  let [direction, setDirection] = useState([]);
  let [sectionNumber, setSectionNumber] = useState([]);
  useEffect(() => {
    //Runs only on the first render
    setFilteredYaadies(props.yaadiList);
    getCustomers();
  }, [props]);
  function getCustomers() {
    let { versionTypeId } = props;
    // Get data of customers
    if (versionTypeId == 1)
      // This facility not in basic version
      return;
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .get(window.routerPrefix + "/customer/customers/" + shopUrl)
      .then((res) => {
        setFlagLoad(false);
        setCustomerList(res.data);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setError(err);
      });
  }
  function handleDeleteYaadi(yaadi) {
    props.onDeleteYaadi(yaadi);
  }
  function handleEditYaadi(yaadi) {
    props.onEditYaadi(yaadi);
  }
  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  function handleShowHereClick(event) {
    event.preventDefault();
    props.onShowHereClick();
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let { yaadidb } = props;
    let query = searchText.trim();
    let searchedYaadies = [];
    searchedYaadies = yaadidb.filterByName(query);
    setFilteredYaadies(searchedYaadies);
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let direction = false;
    if (field === sortedField) {
      // same button clicked twice
      direction = !direction;
    } else {
      // different field
      direction = false;
    }
    setDirection(direction);
    if (direction == false) {
      //in ascending order
      filteredYaadies = filteredYaadies.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredYaadies = filteredYaadies.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredYaadies(filteredYaadies);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  function handleExcelFileDownloadClick() {
    props.onExcelFileDownloadClick();
  }
  function handleSingleYaadiDownloadClick(event) {
    props.onSingleYaadiDownloadClick(event);
  }
  function openWhatsApp(customerWhatsApp) {
    customerWhatsApp = "91" + customerWhatsApp;
    let whatsAppMessage = "Received your order, Thanks";
    window.location = `https://wa.me/${customerWhatsApp}?text=${whatsAppMessage}`;
  }
  function exists(customerWhatsApp) {
    let { versionTypeId } = props;
    // Get data of customers
    if (versionTypeId == 1)
      // This facility not in basic version
      return true; // don't show plus symbol
    for (let i = 0; i < customerList.length; i++) {
      if (customerWhatsApp == customerList[i].whatsApp) {
        return true;
      }
    } //for
    return false;
  }
  function handleAddToCustomerList(customer) {
    customerList.push(customer);
    setCustomerList(customerList);
  }
  let { versionTypeId } = props;
  // if (flagLoading) {
  //   return  (
  //     <div className="text-center">
  //       <BeatLoader size={16} color={"blue"} flagLoading />
  //     </div>
  //   );
  // }
  let { whichDate } = props;
  let { flagDisplay } = props;
  let { grandTotal } = props;
  let { shopUrl } = props;
  let { colorScheme } = props;

  let content = (
    // This is enclosed in container-list-table
    <>
      {/* row starts */}
      <div className="row justify-content-center my-1">
        <div className="col-12 text-center">
          <Link className="" to="#" onClick={handleShowHereClick}>
            Show Here
          </Link>
        </div>
        {flagDisplay && filteredYaadies.length == 0 && (
          <div className="col-12 text-center thick-red-text mt-2">
            No Yaadies.
          </div>
        )}
        {flagDisplay && filteredYaadies.length != 0 && (
          <div className="col-12 text-center thick-red-text">
            Total yaadies: {filteredYaadies.length}&nbsp;&nbsp; Total Amount:{" "}
            <i className="fas fa-rupee-sign"></i> {grandTotal.toFixed(2)}
          </div>
        )}
      </div>
      {/* row starts */}
      {flagDisplay && filteredYaadies.length != 0 && (
        <div className="row justify-content-center my-3">
          <button
            // className="btn btn-cyan"
            className={"btn-my" + colorScheme}
            onClick={handleExcelFileDownloadClick}
          >
            Download {whichDate}'s Summary
          </button>
        </div>
      )}
      {/* row ends */}
      {/* row starts */}
      {flagDisplay && filteredYaadies.length != 0 && (
        <div className="row smaller-text my-1 justify-content-center align-items-center column">
          <div className="col-6 col-md-3 text-center">
            <Link to="#" id="1" onClick={handleSectionNumberClick}>
              Amount
            </Link>
          </div>
          <div className="col-6 col-md-3 text-center">
            <Link to="#" id="2" onClick={handleSectionNumberClick}>
              {" "}
              Address
            </Link>
          </div>
        </div>
      )}
      {/* row ends */}
      {/* row starts */}
      {flagDisplay && filteredYaadies.length != 0 && (
        <div className="row justify-content-center">
          <div className="col-10 col-md-6 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={handleSearchTextBoxKeyUp}
              onChange={handleSearchTextChange} // This is contolled by parent
              placeholder="Search Customer Name"
              id=""
            />
          </div>
        </div>
      )}
      {/* row ends */}
      {/* row starts */}
      {flagDisplay && filteredYaadies.length != 0 && (
        <div className="row p-0">
          <div className="col-12 col-md-6 mx-auto">
            <div className="table-responsive">
              <table className="table table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="yaadiId">
                        Y.N.
                      </Link>
                    </th>
                    <th>
                      <Link
                        to="#"
                        onClick={handleHeaderClick}
                        id="customerName"
                      >
                        Name
                      </Link>
                    </th>
                    {sectionNumber == 1 && (
                      <th>
                        <Link
                          to="#"
                          onClick={handleHeaderClick}
                          id="totalAmount"
                        >
                          Amount
                        </Link>
                      </th>
                    )}
                    {/* {sectionNumber == 1 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="dateOfYaadi"
                        >
                          Time
                        </Link>
                      </th>
                    )} */}
                    {/* {sectionNumber == 1 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="customerWhatsApp"
                        >
                          WhApp No.
                        </Link>
                      </th>
                    )} */}

                    {sectionNumber == 2 && (
                      <th>
                        <Link
                          to="#"
                          onClick={handleHeaderClick}
                          id="customerAddress"
                        >
                          Address
                        </Link>
                      </th>
                    )}
                  </tr>
                </thead>
                {filteredYaadies.map((yaadi, index) => (
                  <tbody key={yaadi.yaadiId}>
                    <AdminReportSummaryListSingle
                      yaadi={yaadi}
                      flagDisplay={flagDisplay}
                      shopUrl={shopUrl}
                      exists={exists(yaadi.customerWhatsApp)}
                      addToCustomerList={handleAddToCustomerList}
                      sectionNumber={sectionNumber}
                      onDeleteYaadi={handleDeleteYaadi}
                      onEditYaadi={handleEditYaadi}
                      onSingleYaadiDownloadClick={
                        handleSingleYaadiDownloadClick
                      }
                      colorScheme={colorScheme}
                    />
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
  if (error) {
    return <div>Error retriving Yaadies </div>;
  } else {
    return <div className="col-md-12">{content}</div>;
  } //else
}
export default AdminReportSummaryList;
