import React from "react";
import { Link } from "react-router-dom";

function FooterAdvt(props) {
  let btnClassList = "";
  let { colorScheme } = props;
  let { adminAsUser } = props;
  btnClassList += "btn-my" + props.colorScheme;
  let classList =
    "container container-md  fixed-bottom py-3 text-center text-white container-footer";
  classList += " bg-my" + colorScheme + "-dark";
  return (
    <div className={classList}>
      {!adminAsUser && (
        <div className="row">
          <div className="col-10 mx-auto column p-0">
            <div className="my-1">Create Your Online Store using </div>
            <div className="col-5 col-md-2 mx-auto column p-0">
              <Link to="/">
                <img
                  src={
                    "/"+window.routerPrefix +
                    "/files/downloadImageFile/meriyaadi_logo.jpg"
                  }
                  className="img-fluid"
                />
              </Link>
            </div>
            <div className="my-1">in 2 minutes! in 3 easy Steps!!</div>
          </div>
        </div>
      )}
      {props.adminAsUser && (
        <div className={"row"}>
          <div className="col-6 mx-auto column p-2">
            <Link to="/adminpanel" className={btnClassList}>
              ADMIN PANEL
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
export default FooterAdvt;
