import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faYoutube,
//   faFacebook,
//   faTwitter,
//   faInstagram,
// } from "@fortawesome/free-brands-svg-icons";


function HomePageInfo(props) {
  function handleContactNowClick() {
    let whatsappNumber = "918355915361";
    let whatsAppMessage = "I am interested to start my online store";
    window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`;
  }
  function handleStartNowClick() {
    props.onStartNowClick();
  }
  return (
    <>
      <div className="container-fluid container-md ">
        {/* row-begins */}
        <div className="row justify-content-center">
          <div className="col-6 col-md-3 text-center homepage-logo column mt-2">
            <img className="img-fluid" src="images/meriyaadi_logo.jpg" alt="" />
          </div>
        </div>
        {/* row-ends */}
        {/* row-begins */}
        <div className="row">
          <div className="col-12 col-md-6 text-center mx-auto homepage-image">
            <img className="img-fluid" src="images/shopkeeper.jpg" alt="" />
          </div>
        </div>
        {/* row-ends */}
        {/* row-begins */}
        <div className="row home-page-borders">
          <div className="col-12 text-center mx-auto homepage-image my-3">
            <h4 className="home-page-blue-color">
              {" "}
              Start your online store within 2 minutes...
            </h4>
            <h4 className="home-page-blue-color"> In 3 easy steps...</h4>
          </div>
          <div className="col-6 col-md-4 text-center mx-auto homepage-image">
            <button
              className="btn btn-success btn-block"
              // onClick={handleContactNowClick}
              onClick={handleStartNowClick}
            >
              Start Now
            </button>
          </div>
        </div>
        {/* row ends  */}
        {/* row-begins */}
        <div className="row py-4 my-2 justify-content-center home-page-borders">
          <div className="col-5 col-md-5  text-center home-page-blue-color p-0">
            <h5>Orders via WhatsApp</h5>
          </div>
          <div className="col-5 col-md-5  text-center home-page-blue-color p-0">
            <h5>Display Offers</h5>
          </div>
          <div className="col-5 col-md-  text-center home-page-blue-color p-0">
            <img src="images/icon_whatsapp.jpg" alt="" />
          </div>
          <div className="col-5 col-md-5  text-center home-page-blue-color p-0">
            <img src="images/icon_discount.jpg" alt="" />
          </div>
        </div>
        <div className="row py-4 justify-content-center">
          <div className="col-5 col-md-5  text-center home-page-blue-color p-0">
            <h5>Useful Reports</h5>
          </div>
          <div className="col-5 col-md-5  text-center home-page-blue-color p-0">
            <h5>Generate Bills</h5>
          </div>
          <div className="col-5 col-md-5  text-center home-page-blue-color p-0">
            <img src="images/icon_excel.jpg" alt="" />
          </div>
          <div className="col-5 col-md-5  text-center home-page-blue-color p-0">
            <img src="images/icon_billing.jpg" alt="" />
          </div>
        </div>
        {/* row-begins */}
        <div className="row justify-content-center home-page-borders py-3">
          <div className="col-12 text-center">
            <iframe
              src="https://www.youtube.com/embed/_s9wneJugNM"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            />
          </div>
        </div>
        {/* row ends  */}
        {/* row-begins */}
        <div className="row home-page-borders">
          <div className="col-12 text-center mx-auto homepage-image my-3">
            <h2 className="home-page-blue-color">
              {" "}
              Useful for Kirana stores, Vegetable shops, Sweet marts, Food
              shoppee, Dryfruit stores
            </h2>
          </div>
          <div className="col-12 col-md-8 text-center mx-auto homepage-image">
            <img className="img-fluid" src="images/all_in_one.jpg" alt="" />
          </div>
        </div>
        {/* row ends  */}
        {/* row-begins */}
        <div className="row home-page-borders">
          <div className="col-12 text-center mx-auto homepage-image my-3">
            <h2 className="home-page-blue-color"> Be a Smart Enterprenuer</h2>
            <h5 className="home-page-blue-color">
              {" "}
              Showcase your products and increase your sales 24*7.
            </h5>
          </div>
        </div>
        {/* row ends  */}
        {/* row-begins */}
        <div className="row home-page-borders">
          <div className="col-12 text-center mx-auto homepage-image my-3">
            <h2 className="home-page-blue-color">
              {" "}
              Just 4 easy steps for your customers
            </h2>
          </div>
          <div className="col-12 col-md-8 text-center mx-auto homepage-image">
            <img className="img-fluid" src="images/customer_steps.jpg" alt="" />
          </div>
        </div>
        {/* row ends  */}

        <div className="row home-page-borders">
          <div className="col-12 text-center mx-auto homepage-image my-3">
            <h2 className="home-page-blue-color"> Rs. 399/month</h2>
            <h4 className="home-page-blue-color"> (Free for first month)</h4>
            <h5 className="home-page-blue-color">
              {" "}
              Start your online store within 2 minutes...
            </h5>
            <h5 className="home-page-blue-color"> In 3 easy steps...</h5>
          </div>
          <div className="col-6 col-md-4 text-center mx-auto homepage-image">
            <button
              className="btn btn-success btn-block"
              // onClick={handleContactNowClick}
              onClick={handleStartNowClick}
            >
              Start Now
            </button>
          </div>
        </div>
        {/* row ends  */}
        <div className="row justify-content-center py-3 home-page-borders">
          <div className="col-12 text-center">
            <h3 className="home-page-blue-color">
              Try our demo online stores on mobile.
            </h3>
            <h5 className="home-page-blue-color">
              <Link to="/demoveggiestore" style={{ textDecoration: "none" }}>
                Vegetable Store
              </Link>
            </h5>
            <h5 className="home-page-blue-color">
              <Link to="/demogrocerystore" style={{ textDecoration: "none" }}>
                Grocery Store
              </Link>
            </h5>
            <h5 className="home-page-blue-color">
              <Link to="/demodryfruitstore" style={{ textDecoration: "none" }}>
                Dryfruit Store
              </Link>
            </h5>
          </div>
        </div>
        {/* row-begins */}
      </div>
      
      <div className="my-3 h5 py-3 text-center home-page-borders">
          <Link to="#" className={"text-mygreen"} onClick={handleContactNowClick}>
            Contact Us <i className="fab fa-whatsapp"></i>
          </Link>
        </div>
      <div className="container-md ">
      
        <div className="row justify-content-center text-center home-page-borders">
          <div className="col-12 my-3">www.meriYaadi.com &#169; 2020</div>
        </div>
      </div>
    </>
  );
}
export default HomePageInfo;
