import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavBarAdminMakeYaadi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagEmptyCart: true,
      controlNavbar: false,
      flagInvalidPlaceOrder: false,
    };
    document.addEventListener("keyup", this.handleKeyUp);
  }
  handleBackClick = (event) => {
    event.preventDefault(); // Not required
    this.props.onBackClick();
  };
  handleNewYaadiClick = (event) => {
    event.preventDefault(); // Not required
    this.props.onNewYaadiClick();
  };

  toggleModal = () => {
    this.setState({
      flagInvalidPlaceOrder: !this.state.flagInvalidPlaceOrder,
    });
  };
  handlePendingYaadiSelection = (value) => {
    this.setState({
      pendingYaadiSelected: value,
    });
    this.props.onPendingYaadiSelection(value);
  };

  handleKeepPendingClick = () => {
    this.setState({
      pendingYaadiSelected: -1,
    });
    this.props.onKeepPendingClick();
  };
  handleCancelClick = () => {
    this.props.onCancelClick();
  };
  
  handleDoneClick = () => {
    this.props.onDoneClick();
  };
  render() {
    let { message } = this.props;
    let { pendingYaadies } = this.props;
    // let { flagYaadiStart } = this.props;
    let { action } = this.props;
    
    let { pendingYaadiSelected } = this.state;

    let { maxYaadiesAllowed } = this.props;
    let { itemCount } = this.props;
    let colwidth;
    if (pendingYaadies.length != 0) {
      colwidth = 12 / pendingYaadies.length;
    }

    let radioButtonGroup = pendingYaadies.map((pendingYaadi, index) => {
      return (
        <div className={"px-1 text-center col-" + colwidth} key={index}>
          <input
            type="radio"
            className="form-control-inline"
            name="pendingYaadiSelected"
            value="1"
            disabled={action==="PREPARE"}
            checked={pendingYaadi === pendingYaadiSelected}
            onClick={() => this.handlePendingYaadiSelection(pendingYaadi)}
            onChange={() => this.handlePendingYaadiSelection(pendingYaadi)}
          />
          {pendingYaadi}
        </div>
      );
    });
    if (this.state.controlNavbar) return null;
    return (
      <React.Fragment>
        <div className="container-fluid container-md container-make-yaadi fixed-top bg-mycyan p-0 column">
          {/* row starts */}
          <div className="row justify-content-center column align-items-center py-1 ">
            <div className="col-3 col-md-2 text-center p-0">
              <button
                // className="nav-link text-myblack column"
                className="btn-mycyan" //"btn btn-success"
                to="#"
                onClick={this.handleBackClick}
              >
                Back
              </button>
            </div>
            {true ? (
              <div className="col-4 col-md-2 text-center p-0 border-all-mycyan">
                <div className="row ">
                  <div className="col-12 column px-1 text-center ">
                    {pendingYaadies.length == 0 ? "Nothing" : ""} Pending
                  </div>
                  {radioButtonGroup}
                </div>
              </div>
            ) : null}
            <div className="col-5 col-md-2 text-thick text-center colum p-0">
              {action!="PREPARE" && pendingYaadies.length < maxYaadiesAllowed && (
                <button
                  className="btn-mycyan"
                  to="#"
                  onClick={this.handleNewYaadiClick}
                >
                  New yaadi
                </button>
              )}
              {action==="PREPARE" && itemCount == 0 && (
                <button
                  to="#"
                  className="btn-mycyan"
                  onClick={this.handleCancelClick}
                >
                  Cancel
                </button>
              )}
              {action==="PREPARE" && itemCount != 0 && (
                <button to="#" className="btn-mycyan mb-2"
                onClick={this.handleDoneClick}
                >
                  Done
                </button>
              )}
              {action==="PREPARE" && itemCount != 0 && (
                <button
                  to="#"
                  className="btn-mycyan"
                  onClick={this.handleKeepPendingClick}
                >
                  Keep Pending
                </button>
              )}
            </div>
          </div>
          {/* row end */}
          <div className="row justify-content-center">
            {message != "" ? (
              <div className="col-12 text-center bg-white column p-0">
                <div>{message}</div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="nav-item">
            <Link className="nav-link text-white" to="#saved">
              {/* View Saved Products */}
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default NavBarAdminMakeYaadi;
