import React, { Component } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import NavBarAdmin from "./navbar-admin";
import FooterPanel from "./footer-panel";
import AdminAccount from "./admin-account";
import AdminProduct from "./admin-product";
import AdminCategory from "./admin-category";
import AdminBrand from "./admin-brand";
import AdminCustomer from "./admin-customer";
import AdminProductQuick from "./admin-product-quick";
import AdminProductStockRating from "./admin-product-stockrating";
import AdminProductImage from "./admin-product-image";
import AdminUploadProductSheet from "./admin-upload-product-sheet";
import AdminStoreLogo from "./admin-store-logo";
import AdminStoreImages from "./admin-store-images";
import AdminSpecialOffer from "./admin-specialoffer";
import AdminSettingsHomePage from "./admin-settings-homepage";
import AdminSettingsStore from "./admin-settings-store";
import AdminSettingsFirstPage from "./admin-settings-firstpage";
import AdminDealer from "./admin-dealer";
import AdminLogin from "./admin-login";
import AdminLoginFirst from "./admin-login-first";
import AdminHomePage from "./admin-home-page";
import AdminHomePageNew from "./admin-home-page-new";
import AdminMasterList from "./admin-master-list";
import AdminMakeYaadi from "./admin-make-yaadi";
import Billing from "./admin-billing";
import AdminReportSummary from "./admin-report-summary";
import AdminProductSheet from "./admin-product-sheet";
import AdminCustomerData from "./admin-customer-data";
import AdminReportAll from "./admin-report-all";
import AdminReportCumulative from "./admin-report-cumulative";
import AdminReportCustomerVisits from "./admin-report-customer-visits";
class AdminActivity extends Component {
  //  navigate = useNavigate();
  adminManageList = [
    { name: "Products", subject: "PRODUCT", type: 2, help: true },
    { name: "Categories", subject: "CATEGORY", type: 3 },
    { name: "Brands", subject: "BRAND", type: 3 },
    { name: "Customers", subject: "CUSTOMER", type: 3 },
    { name: "________", subject: "NONE", type: 3 },
    { name: "Products-Price", subject: "PRODUCTQUICK", type: 3 },
    { name: "Products-Stock, Rating", subject: "PRODUCTSTOCKRATING", type: 3 },
    { name: "Products-Image", subject: "PRODUCTIMAGE", type: 3 },
    { name: "________", subject: "NONE", type: 3 },
    { name: "Use Master List", subject: "MASTERLIST", type: 3 },
  ];
  adminUpdateList = [
    { name: "Home Page Settings", subject: "SETTINGS-HOMEPAGE", type: 3 },
    { name: "First Page Settings", subject: "SETTINGS-FIRSTPAGE", type: 3 },
    { name: "Store Settings", subject: "SETTINGS-STORE", type: 3 },
    { name: "Store Logo", subject: "STORE-LOGO", type: 3 },
    { name: "Store Images", subject: "STORE-IMAGES", type: 3 },
  ];

  adminUtilityList = [
    { name: "Yaadi Summary", subject: "SUMMARY", type: 3 },
    { name: "Yaadies", subject: "ALL", type: 3 },
    { name: "Productwise Sale", subject: "CUMULATIVE", type: 3 },
    { name: "Customer Visits", subject: "CUSTOMERVISITS", type: 3 },
    { name: "Customer Data", subject: "CUSTOMERDATA", type: 3 },
    { name: "________", subject: "NONE", type: 3 },
    { name: "DOWNLOAD Product Sheet", subject: "PRODUCTSHEET", type: 3 },
    { name: "________", subject: "NONE", type: 3 },
    { name: "UPLOAD Product Sheet", subject: "UPLOADPRODUCTSHEET", type: 3 },
  ];
  adminBillingList = [
    { name: "Cash Billing", subject: "CASHBILLING", type: 3 },
  ];
  utilityList = [
    "SUMMARY",
    "ALL",
    "CUMULATIVE",
    "PRODUCTSHEET",
    "CUSTOMERVISITS",
  ];
  mainMenus = [
    { menu: "Manage ", list: this.adminManageList },
    { menu: "Control ", list: this.adminUpdateList },
    { menu: "Utilities ", list: this.adminUtilityList },
    // { menu: "Billing ", list: this.adminBillingList },
  ];
  constructor(props) {
    super(props);
    this.state = {
      subject: "ADMINHOMEPAGENEW",
      action: "LIST", // Initially show LIST, can be switched to ADD
      menu: "",
      message: "",
      validAdmin: false, // false will show login form,
      firstLogin: 1, // 0 means not logged first time
      shopUrl: "",
      masterListName: "",
      shopDetails: {}, //[],
      colorScheme: "",
      flagLoading: false,
      selectedMenuIndex: -1,
      selectedSubMenuIndex: -1,
    };
  }
  handleLoginFormSubmit = (firstLogin, shopUrl) => {
    let message = "";
    if (shopUrl == "-1") {
      message = "Something went wrong... Try again";
      this.setState({ validAdmin: false, message: message });
      return;
    }
    if (shopUrl == "0") {
      message = "Invalid username... Try again";
      this.setState({ validAdmin: false, message: message });
      return;
    }
    if (shopUrl == "1") {
      message = "Invalid password... Try again";
      this.setState({ validAdmin: false, message: message });
      return;
    }
    // log in success,

    this.setState({
      flagLoading: true,
      validAdmin: true,
    });
    // in local storage write down "admin" : <shopUrl> key so that
    // admin panel will be available to admin
    localStorage.setItem("admin", shopUrl);
    this.setState({
      message: message,
      shopUrl: shopUrl,
    });
    this.getShopDetails(shopUrl);
  };
  getShopDetails = (shopUrl) => {
    let shopDetails;
    axios
      .get(window.routerPrefix + "/shop/byShopUrl/" + shopUrl)
      .then((res) => {
        shopDetails = res.data; // only one shop
        this.setState({ colorScheme: shopDetails.colorScheme });
        // write down in localstorage also
        localStorage.setItem("colorScheme", shopDetails.colorScheme);
        this.setState({
          shopDetails: res.data, // only one shop
          flagLoading: false,
        });
        // is it ready or new shop
        if (shopDetails.status == "not ready") {
          // from updateList find index of SETTINGS-HOMEPAGE
          // This form should be displayed initially
          let index = 0; //Hard coding this.adminUpdateListupdateList.indexOf("SETTINGS-HOMEPAGE");
          this.handleAdminUpdateClick(index);
        }
        if (shopDetails.homePageFacility == 0) {
          this.adminUpdateList = this.adminUpdateList.filter(
            (e, index) => e.subject != "STORE-IMAGES"
          );
          // no Home-page Facility
          // { name: "Store Images", subject: "STORE-IMAGES", type: 3 },
        }

        if (shopDetails.status == "disabled") {
          let message =
            "Your admin-panel is disabled due to non-payment of fees.";
          this.setState({ message: message });
        } else if (shopDetails.paymentMessage) {
          let payDate = new Date(shopDetails.paymentDueDate);
          let StringPayDate =
            payDate.getDate() +
            "/" +
            (payDate.getMonth() + 1) +
            "/" +
            payDate.getFullYear();
          let message =
            "Payment Due Date is " +
            StringPayDate +
            ". Please pay in time to avoid interruption of services.";
          this.setState({ message: message });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  handleLoginFirstFormSubmit = (status) => {
    let message = "";
    let firstLogin;
    if (status === false) {
      message = "Entered old password is wrong, try again";
      firstLogin = 0;
      status = !status; // user need no login using userName
    } else if (status === true) {
      // log in success, password changed successfully
      message = "Password is modified successfully";
      firstLogin = 1;
    }
    this.setState({
      validAdmin: status,
      firstLogin: firstLogin,
      message: message,
    });
  };
  handleLogoutClick = () => {
    // we have to destroy the session
    this.setState({
      flagLoading: true,
    });
    axios
      .post(window.routerPrefix + "/admin/logout")
      .then((res) => {
        this.setState({
          flagLoading: false,
        });
        if (res.data == 0) {
          // invalid
          this.setState({
            validAdmin: false,
            message: "Something went wrong... refresh this page",
            menu: "",
          });
        } else if (res.data == 1) {
          this.setState({
            validAdmin: false,
            message: "",
            menu: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  handleMasterListClick = () => {
    this.setState({
      message: "",
      menu: "",
    });
  };
  handleAddListToggleClick = () => {
    let { action } = this.state;
    let { subject } = this.state;
    let { menu } = this.state;
    if (action === "LIST") {
      action = "ADD";
      if (
        subject === "PRODUCT" ||
        subject === "PRODUCTQUICK" ||
        subject === "PRODUCTSTOCKRATING" ||
        subject === "PRODUCTIMAGE"
      ) {
        menu = "List of Products";
      } else if (subject === "CATEGORY") {
        menu = "List of Categories";
      } else if (subject === "BRAND") {
        menu = "List of Brands";
      }
      // else if (subject === "SPECIAL OFFER") {
      //   menu = "List of Special Offers";
      // }
      else if (subject === "PROMO CODE") {
        menu = "List of Promo codes";
      } else if (subject === "SETTINGS-HOMEPAGE") {
        menu = "";
      } else if (subject === "SETTINGS-FIRSTPAGE") {
        menu = "";
      } else if (subject === "SETTINGS-STORE") {
        menu = "";
      } else if (subject === "STORE-LOGO") {
        menu = "";
      } else if (subject === "CUSTOMER") {
        menu = "List of Customers";
      } else if (subject === "DEALER") {
        menu = "List of Dealers";
      }
    } //if
    else if (action === "ADD") {
      action = "LIST";
      if (subject === "PRODUCT") {
        menu = "Add a Product";
      } else if (subject === "CATEGORY") {
        menu = "Add a Category";
      } else if (subject === "BRAND") {
        menu = "Add a Brand";
      }
      // else if (subject === "SPECIAL OFFER") {
      //   menu = "Add a Special Offer";
      // }
      else if (subject === "PROMO CODE") {
        menu = "Add a promo code";
      } else if (subject === "CUSTOMER") {
        menu = "Add a Customer";
      } else if (subject === "DEALER") {
        menu = "Add a Dealer";
      }
    } //...else if
    else if (action === "UPDATE") {
      action = "LIST";
      if (subject === "PRODUCT") {
        menu = "Add a Product";
      } else if (subject === "CATEGORY") {
        menu = "Add a Category";
      } else if (subject === "BRAND") {
        menu = "Add a Brand";
      }
      // else if (subject === "SPECIAL OFFER") {
      //   menu = "Add a Special Offer";
      // }
      else if (subject === "PROMO CODE") {
        menu = "Add a Promo Code";
      } else if (subject === "CUSTOMER") {
        menu = "Add a Customer";
      } else if (subject === "DEALER") {
        menu = "Add a Dealer";
      }
    }
    this.setState({
      action: action,
      menu: menu,
      message: "",
    });
  };
  componentDidMount() {
    // Check whether session exists,
    window.scroll(0, 0);
    let colorScheme = localStorage.getItem("colorScheme");
    // this shopUrl is purposly set, it is obtained from local-storage
    // so without log-in, admin can see his store
    // shopUrl: shopUrl,
    let shopUrl = localStorage.getItem("admin");
    this.setState({
      colorScheme: colorScheme,
      shopUrl: shopUrl,
    });

    this.setState({
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + "/admin/welcome/")
      .then((res) => {
        this.setState({
          flagLoading: false,
        });
        let sessionExists = res.data;
        let validAdmin;
        if (sessionExists == 0) {
          validAdmin = false;
          this.setState({
            validAdmin: validAdmin,
          });
          return; // don't go ahead
        }
        validAdmin = true;
        // get colorscheme and shopUrl from localstorage
        this.setState({
          validAdmin: validAdmin,
        });
        this.getShopDetails(shopUrl);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  }
  handleYourAccountClick = () => {
    this.setState({ subject: "ACCOUNT", selectedMenuIndex: 0 });
  };
  handleMenuClicked = (index) => {
    this.setState({
      selectedMenuIndex: index,
    });
  };
  handleSubMenuClicked = (selectedMenuIndex, selectedSubMenuIndex) => {
    this.setState({
      selectedSubMenuIndex: selectedSubMenuIndex,
    });
    if (selectedMenuIndex == 0) {
      this.handleAdminManageClick(selectedSubMenuIndex);
    } else if (selectedMenuIndex == 1) {
      this.handleAdminUpdateClick(selectedSubMenuIndex);
    } else if (selectedMenuIndex == 2) {
      this.handleAdminUtilityClick(selectedSubMenuIndex);
    }
    // Billing
    else if (selectedMenuIndex == 3) {
      // this.props.history.push("/"+this.state.shopUrl); // remove the invalid shopUrl 2023
      //     this.setState({
      //       flagInvalidUrl: true,
      //       flagLoading: false,
      //       action: "VIEW",
      //     });
      this.props.history.push({
        pathname: "/" + this.state.shopUrl,
        state: { billing: "yes" },
      }); // remove the invalid shopUrl 2023
    }
  };
  handleHomeClick = () => {
    this.setState({
      subject: "ADMINHOMEPAGENEW",
      action: "", // Initially show LIST, can be switched to ADD
      menu: "",
      selectedSubMenuIndex: -1,
      selectedMenuIndex: -1,
    });
  };
  handleAdminManageClick = (index) => {
    let subject = this.adminManageList[index]["subject"];
    let help = this.adminManageList[index]["help"];
    if (subject == "NONE") {
      return;
    }
    this.setState({
      subject: subject,
      action: "LIST",
      message: "",
      help: help,
    });
    if (
      subject == "PRODUCTQUICK" ||
      subject == "PRODUCTSTOCKRATING" ||
      subject == "PRODUCTIMAGE"
    ) {
      this.setState({
        menu: "",
      });
    } else if (subject == "MASTERLIST") {
      this.setState({
        menu: "MasterList",
        // menu: "",
      });
    } else {
      this.setState({
        menu: "Add a " + subject,
        // menu: "Add a " + this.manageList[index],
      });
    }
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  handleAdminUpdateClick = (index) => {
    let subject = this.adminUpdateList[index]["subject"];
    this.setState({
      subject: subject,
      action: "LIST",
      message: "",
    });
    // for Home-page message and product-quick, no facility of add, only edit
    if (
      subject === "SETTINGS-HOMEPAGE" ||
      subject === "SETTINGS-FIRSTPAGE" ||
      subject === "SETTINGS-STORE" ||
      subject === "STORE-LOGO" ||
      subject === "STORE-IMAGES"
    ) {
      this.setState({
        menu: "",
      });
    } else {
      this.setState({
        menu: "Add a " + subject,
      });
    }
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  handleAdminUtilityClick = (index) => {
    let subject = this.adminUtilityList[index]["subject"];
    this.setState({
      subject: subject,
      action: "LIST",
      menu: "",
      message: "",
    });
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  handleAdminBillingClick = (index) => {
    let subject = this.adminUtilityList[index]["subject"];
    this.setState({
      subject: subject,
      action: "LIST",
      menu: "",
      message: "",
    });
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  handleAdminReportClick = (index) => {
    this.setState({
      subject: this.reportList[index],
      action: "LIST",
      menu: "",
      message: "",
    });
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  // Following 2 functions are written to see the effects immediately
  handleUpdateStoreSettings = (shop) => {
    // In update store settings, color-scheme and language of the store are modified
    this.setState({ colorScheme: shop.colorScheme });
    this.setState({
      shopDetails: {
        ...this.state.shopDetails,
        languageChoice: shop.languageChoice,
      },
    });
  };
  handleUploadPaymentScreenShot = () => {
    this.setState({
      shopDetails: { ...this.state.shopDetails, lastPaymentVerified: 0 },
    });
  };
  handleCrudAction = (message, action, menu) => {
    if (menu) {
      this.setState({ menu: menu });
    }
    this.setState({ message: message, action: action });
    // for home-page message, show admin-home-page
    //ADMINHOMEPAGE
    if (
      this.state.subject === "SETTINGS-HOMEPAGE" ||
      this.state.subject === "SETTINGS-FIRSTPAGE" ||
      this.state.subject === "SETTINGS-STORE"
    ) {
      this.setState({ subject: "ADMINHOMEPAGE" });
    }
    // window.scrollTo(0, 0); // moving scrollbar at the top
  };
  handleShopReady = () => {
    this.setState({
      shopDetails: { ...this.state.shopDetails, status: "ready" },
    });
  };
  handleCancelFormClick = () => {
    // if any form of update menus is cancelled, then show adminhomepage
    this.setState({ subject: "ADMINHOMEPAGE" });
  };
  handleEditClick = () => {
    let { subject } = this.state;
    // let { menu } = this.state;
    let menu; //  = this.state;
    if (subject === "PRODUCT") {
      menu = "List of Products";
    } else if (subject === "CATEGORY") {
      menu = "List of Categories";
    } else if (subject === "BRAND") {
      menu = "List of Brands";
    }
    // else if (subject === "SPECIAL OFFER") {
    //   menu = "List of Special Offers";
    // }
    else if (subject === "PROME CODE") {
      menu = "List of Promo Code";
    } else if (subject === "CUSTOMER") {
      menu = "List of Customers";
    } else if (subject === "DEALER") {
      menu = "List of Dealers";
    }
    this.setState({ action: "UPDATE", menu: menu, message: "" });
    window.scrollTo(0, 0); // moving scrollbar at the top
  };
  handleCancelClick = () => {
    window.scrollTo(0, 0); // moving scrollbar at the top
    // this.setState({ action: "LIST"});
    this.handleAddListToggleClick();
  };
  askToLogin = () => {
    this.setState({
      validAdmin: false,
      message: "Session expired, login-again",
    });
  };
  handleBackClick = () => {
    this.setState({
      subject: "",
    });
  };
  render() {
    let { flagLoading } = this.state;
    if (flagLoading) {
      return (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    }
    let { subject } = this.state;
    let { action } = this.state;
    let { menu } = this.state;
    let { message } = this.state;
    let { shopUrl } = this.state;
    let { validAdmin } = this.state;
    let { firstLogin } = this.state;
    let { shopDetails } = this.state;
    let { colorScheme } = this.state;
    let { help } = this.state;
    let { selectedMenuIndex } = this.state;
    let { selectedMenuList } = this.state;
    let { selectedSubMenuIndex } = this.state;
    // let { versionTypeId } = this.state.shopDetails;

    let content;
    //
    if (!validAdmin) {
      content = <AdminLogin onLoginFormSubmit={this.handleLoginFormSubmit} />;
    } else if (validAdmin && firstLogin == 0) {
      // first time login
      content = (
        <AdminLoginFirst
          onLoginFirstFormSubmit={this.handleLoginFirstFormSubmit}
        />
      );
    } else if (subject === "PRODUCT") {
      // routine login
      content = (
        <AdminProduct
          action={action}
          subject={subject}
          menu={menu}
          help={help}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          askToLogin={this.askToLogin}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "PRODUCTQUICK") {
      // routine login
      content = (
        <AdminProductQuick
          action={action}
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          askToLogin={this.askToLogin}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "PRODUCTSTOCKRATING") {
      content = (
        <AdminProductStockRating
          action={action}
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          askToLogin={this.askToLogin}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "PRODUCTIMAGE") {
      content = (
        <AdminProductImage
          action={action}
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          askToLogin={this.askToLogin}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "CATEGORY") {
      content = (
        <AdminCategory
          action={action}
          menu={menu}
          subject={subject}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          colorScheme={colorScheme}
          shopDetails={shopDetails}
        />
      );
    } else if (subject === "BRAND") {
      content = (
        <AdminBrand
          action={action}
          menu={menu}
          subject={subject}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          colorScheme={colorScheme}
          shopDetails={shopDetails}
        />
      );
    } else if (subject === "PROMO CODE") {
      content = (
        <AdminSpecialOffer
          action={action}
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          shopUrl={shopUrl}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "SETTINGS-HOMEPAGE") {
      content = (
        <AdminSettingsHomePage
          action="Update" // No add option here
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onShopReady={this.handleShopReady}
          onCancelFormClick={this.handleCancelFormClick}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "SETTINGS-STORE") {
      content = (
        <AdminSettingsStore
          action="Update" // No add option here
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onShopReady={this.handleShopReady}
          updateStoreSettings={this.handleUpdateStoreSettings}
          onCancelFormClick={this.handleCancelFormClick}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "SETTINGS-FIRSTPAGE") {
      content = (
        <AdminSettingsFirstPage
          action="Update" // No add option here
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelFormClick={this.handleCancelFormClick}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "STORE-LOGO") {
      content = (
        <AdminStoreLogo
          action="Update" // No add option here
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "STORE-IMAGES") {
      content = (
        <AdminStoreImages
          action="Update" // No add option here
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "CUSTOMER") {
      content = (
        <AdminCustomer
          action={action}
          menu={menu}
          subject={subject}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "DEALER") {
      content = (
        <AdminDealer
          action={action}
          menu={menu}
          onCrudAction={this.handleCrudAction}
          onEditClick={this.handleEditClick}
          onCancelClick={this.handleCancelClick}
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "SUMMARY") {
      content = (
        <AdminReportSummary
          shopUrl={shopUrl}
          onCrudAction={this.handleCrudAction}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "ALL") {
      content = (
        <AdminReportAll
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "CUMULATIVE") {
      content = (
        <AdminReportCumulative shopUrl={shopUrl} shopDetails={shopDetails} />
      );
    } else if (subject === "PRODUCTSHEET") {
      content = (
        <AdminProductSheet
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "CUSTOMERDATA") {
      content = (
        <AdminCustomerData
          shopUrl={shopUrl}
          shopDetails={shopDetails}
          colorScheme={colorScheme}
        />
      );
    } else if (subject === "CUSTOMERVISITS") {
      content = (
        <div className="container container-md container-list-table p-0">
          <AdminReportCustomerVisits
            shopUrl={shopUrl}
            onCrudAction={this.handleCrudAction}
            shopDetails={shopDetails}
            colorScheme={colorScheme}
          />
        </div>
      );
    } else if (subject === "UPLOADPRODUCTSHEET") {
      content = (
        <div className="container container-md container-list-table p-0">
          <AdminUploadProductSheet
            shopUrl={shopUrl}
            onCrudAction={this.handleCrudAction}
            shopDetails={shopDetails}
            colorScheme={colorScheme}
          />
        </div>
      );
    } else if (subject === "ACCOUNT") {
      content = (
        <div className="container container-md container-list-table p-0">
          <AdminAccount
            shopUrl={shopUrl}
            onCrudAction={this.handleCrudAction}
            shopDetails={shopDetails}
            colorScheme={colorScheme}
            onUploadPaymentScreenShot={this.handleUploadPaymentScreenShot}
          />
        </div>
      );
    } else if (subject === "MASTERLIST") {
      content = (
        <div className="container-fluid container-md container-list-table p-0">
          <AdminMasterList
            shopUrl={shopUrl}
            onCrudAction={this.handleCrudAction}
            colorScheme={colorScheme}
            shopDetails={shopDetails}
          />
        </div>
      );
    } else if (subject === "MAKEYAADIES") {
      content = (
        <div className="container container-md container-list-table p-0">
          <AdminMakeYaadi
            shopUrl={shopUrl}
            onCrudAction={this.handleCrudAction}
            onBackClick={this.handleBackClick}
            message={message}
            shopDetails={shopDetails}
            colorScheme={colorScheme}
          />
        </div>
      );
    } else if (subject === "BILLING") {
      content = (
        <div className="container-fluid container-md container-list-table p-0">
          <Billing
            shopUrl={shopUrl}
            onCrudAction={this.handleCrudAction}
            shopDetails={shopDetails}
            colorScheme={colorScheme}
          />
        </div>
      );
    } else if (subject === "ADMINHOMEPAGE") {
      content = <AdminHomePage shopUrl={shopUrl} colorScheme={colorScheme} />;
    } else if (subject === "ADMINHOMEPAGENEW") {
      content = (
        <AdminHomePageNew
          shopUrl={shopUrl}
          colorScheme={colorScheme}
          selectedMenuIndex={selectedMenuIndex}
          selectedMenuList={selectedMenuList}
          selectedSubMenuIndex={selectedSubMenuIndex}
          adminManageList={this.adminManageList}
          adminUpdateList={this.adminUpdateList}
          adminUtilityList={this.adminUtilityList}
          mainMenus={this.mainMenus}
          handleMenuClicked
          onMenuClicked={this.handleMenuClicked}
          onSubMenuClicked={this.handleSubMenuClicked}
        />
      );
    }

    return (
      <>
        {/* During creating yaadi don't show this navbar */}
        {(subject !== "MAKEYAADIES" ||
          (subject == "MAKEYAADIES" && shopDetails.versionTypeId != 3)) && (
          <NavBarAdmin
            adminManageList={this.adminManageList}
            adminUpdateList={this.adminUpdateList}
            adminUtilityList={this.adminUtilityList}
            menu={menu}
            message={message}
            validAdmin={validAdmin}
            shopUrl={shopUrl}
            firstLogin={firstLogin}
            colorScheme={colorScheme}
            shopDetails={shopDetails}
            selectedMenuIndex={selectedMenuIndex}
            onAdminManageClick={this.handleAdminManageClick}
            onAdminUpdateClick={this.handleAdminUpdateClick}
            onAdminReportClick={this.handleAdminReportClick}
            onAdminUtilityClick={this.handleAdminUtilityClick}
            onAddListToggleClick={this.handleAddListToggleClick}
            onYourAccountClick={this.handleYourAccountClick}
            onLogoutClick={this.handleLogoutClick}
            onMasterListClick={this.handleMasterListClick}
            onHomeClick={this.handleHomeClick}
          />
        )}
        {content}
        {shopDetails.status != "not ready" && !validAdmin && (
          <FooterPanel
            shopUrl={shopUrl}
            colorScheme={colorScheme}
            validAdmin={validAdmin}
          />
        )}
      </>
    );
    //
  }
}
export default AdminActivity;
