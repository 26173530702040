import React, { Component } from "react";
import { Link } from "react-router-dom";
class AdminReportCustomerVisitsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
    };
  }
  pad = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };
  componentDidMount = () => {};
  handleShowHereClick = (event) => {
    event.preventDefault();
    this.props.onShowHereClick();
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  gettime = (dateOfEntry) => {
    let dt = new Date(dateOfEntry);
    let hh = this.pad(dt.getUTCHours(), 2);
    let mm = this.pad(dt.getUTCMinutes(), 2);
    return hh + ":" + mm;
  };
  openWhatsApp = (customerWhatsApp) => {
    customerWhatsApp = "91" + customerWhatsApp;
    let whatsAppMessage = " ";
    window.location = `https://wa.me/${customerWhatsApp}?text=${whatsAppMessage}`;
  };
  render() {
    let { error } = this.state;
    let { flagDisplay } = this.props;
    let { filteredCustomerVisited } = this.props;
    let content = (
      // This is enclosed in container-list-table
      <>
        <div className="row justify-content-center my-3">
          <div className="col-12 text-center my-2">
            <Link className="" to="#" onClick={this.handleShowHereClick}>
              Show Here
            </Link>
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}

        {flagDisplay &&  filteredCustomerVisited.length!=0&&(
          <div className="row p-0">
            {flagDisplay && (
              <div className="col-12 text-center thick-red-text">
                Total {filteredCustomerVisited.length} Customer/s.
              </div>
            )}
            <div className="col-12 col-md-6 mx-auto">
              <div className="table-responsive">
                <table className="table table-sm table-hover table-borderless bigger-text">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="name">
                          Name
                        </Link>
                      </th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  {filteredCustomerVisited.map((customerVisited, index) => (
                    <tbody key={index}>
                      <tr className="myborder-top">
                        <td>{index + 1}</td>
                        <td>{customerVisited.name}</td>
                        <td>{this.gettime(customerVisited.dateOfEntry)}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <Link
                            to="#"
                            onClick={() =>
                              this.openWhatsApp(customerVisited.whatsApp)
                            }
                          >
                            ({customerVisited.whatsApp})
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        )}
        {flagDisplay &&  filteredCustomerVisited.length==0&&(
          <div className="row p-0">
           <div className="col-12 text-center">
            No Customer Visits.
           </div>
           
          </div>
        )}
      </>
    );
    if (error) {
      return <div>Error retriving Products </div>;
    } else {
      return <div className="container-fluid p-0">{content}</div>;
    } //else
  }
}
export default AdminReportCustomerVisitsList;