export class customerVisitedDB {
  constructor(customerVisitedList) {
    this.customerVisitedList = customerVisitedList;
    this.size = this.customerVisitedList.length;
  }
  allCustomerVisitedList() {
    return this.customerVisitedList;
  }
  current() {
    return this.size;
  }
  filterByName(query) {
    let words = query.split(" ");
    let filteredCustomerVisited = [];
    let target, flag, i, j;
    for (i = 0; i < this.customerVisitedList.length; i++) {
      // searching in name
      for (j = 0, flag = false; j < words.length; j++) {
        if (
          words[j].length >= 1 &&
          !this.customerVisitedList[i].name
            .toLowerCase()
            .includes(words[j].toLowerCase())
        ) {
          flag = true;
          break;
        }
      } //inner for
      if (flag == false) {
        // all words are present
        filteredCustomerVisited.push(this.products[i]);
      }
    } //for
    return filteredCustomerVisited;
  }
  filterByNameAndSearchWords(query) {
    let filteredProducts = [];
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredProducts.push(this.products[i]);
      }
    } //for
    for (let i = 0; i < this.products.length; i++) {
      if (
        this.products[i].searchWords.toLowerCase().includes(query.toLowerCase())
      ) {
        filteredProducts.push(this.products[i]);
      } //if
    } //for
    return filteredProducts;
  }

  filterBySearchWords(query) {
    let filteredProducts = [];
    for (let i = 0; i < this.products.length; i++) {
      if (!this.products[i].searchWords) {
        continue;
      }
      if (
        this.products[i].searchWords.toLowerCase().includes(query.toLowerCase())
      ) {
        filteredProducts.push(this.products[i]);
      } //if
    } //for
    return filteredProducts;
  }
}
export default customerVisitedDB;
