import React, { Component } from "react";
import AdminSpecialOfferList from "./admin-specialoffer-list";
import AdminSpecialOfferAdd from "./admin-specialoffer-add";
import { BeatLoader } from "react-spinners";
import { SpecialOfferDB } from "../SpecialOfferDB";
import axios from "axios";
class AdminSpecialOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      specialOffer: null,
      filteredSpecialOffers: [],
      specialofferdb: {},
      searchText: "",
      flagLoading: false,
      sortedField: "",
      sortedDirection: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  getSpecialOfferListFromDatabase = () => {
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
    });
    axios
      // .get("/specialOffers/" + shopUrl)
      .get(window.routerPrefix + "/specialOffer/specialOffers/" + shopUrl)
      .then((res) => {
        let specialOfferList = res.data;
        this.setState({
          filteredSpecialOffers: specialOfferList,
          specialofferdb: new SpecialOfferDB(res.data),
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err, flagLoading: false }); // error is set
      });
  };
  componentDidMount() {
    this.getSpecialOfferListFromDatabase();
  }
  handleSearchTextBoxKeyUp = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  handleSearchTextChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    this.performSearchOperation(searchText);
  };
  performSearchOperation = (searchText) => {
    let query = searchText.trim();
    let searchedSpecialOffers = [];
    searchedSpecialOffers = this.state.specialofferdb.filterByName(query);
    this.setState({
      specialOffers: searchedSpecialOffers,
    });
  };
  handleShowAllClick = () => {
    this.setState({
      action: "VIEW",
      filteredSpecialOffers: this.state.specialoffersdb.allSpecialOffers(),
      searchText: "",
    });
  };
  onFormSubmit(data) {
    let message;
    let { action } = this.props;
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
    });
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        // .put("/specialOffers/" + shopUrl + "/" + data.specialOfferId, data)
        .put(window.routerPrefix + "/specialOffer/update/" + shopUrl, data)
        .then((res) => {
          this.setState({
            response: res.data,
            flagLoading: false,
          });
          message = "Speical Offer  updated successfully";
          this.props.onCrudAction(message, "LIST", "Add a Special Offer");
          this.getSpecialOfferListFromDatabase();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            flagLoading: false,
          });
        });
    } else if (action === "ADD") {
      //ADD / POST
      this.setState({
        flagLoading: true,
      });
      axios
        // .post("/specialOffers/" + this.props.shopUrl, data, {
        .post(
          window.routerPrefix +
            "/specialOffer/addSpecialOffer/" +
            this.props.shopUrl,
          data,
          {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
              // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
          }
        )
        .then((res) => {
          this.setState({
            response: res.data,
            flagLoading: false,
          });
          message = "Special Offer " + data.name + " added successfully.";
          this.props.onCrudAction(message, "ADD"); // Add again
          this.getSpecialOfferListFromDatabase();
        })
        .catch((err) => {
          console.log("error...");
          this.setState({
            flagLoading: false,
          });
        });
    } //else
  }
  handleEditSpecialOffer = (specialOffer) => {
    this.setState({
      specialOffer: specialOffer,
      searchText: "",
    });
    this.props.onEditClick();
  };
  handleDeleteSpecialOffer = (specialOffer) => {
    let message;
    let { shopUrl } = this.props;
    let { specialOfferId } = specialOffer;
    let { name } = specialOffer;
    // Now call for delete api
    this.setState({
      flagLoading: true,
      searchText: "",
    });
    axios
      // .delete("/specialOffers/" + shopUrl + "/" + specialOfferId)
      .delete(
        window.routerPrefix +
          "/specialOffer/delete/" +
          shopUrl +
          "/" +
          specialOfferId
      )
      .then((res) => {
        this.setState({
          response: res.data,
          flagLoading: false,
        });
        message = "Special Offer '" + name + "' deleted successfully";
        this.props.onCrudAction(message, "LIST"); // show list again
        this.getSpecialOfferListFromDatabase();
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          flagLoading: false,
        });
      });
  };
  handleHeaderClick = (event) => {
    let field = event.target.id;
    let direction = false;
    if (field === this.state.sortedField) {
      // same button clicked twice
      direction = !this.state.direction;
    } else {
      // different field
      direction = false;
    }
    this.setState({ direction: direction });
    let { filteredSpecialOffers } = this.state;
    if (direction == false) {
      //in ascending order
      filteredSpecialOffers = filteredSpecialOffers.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredSpecialOffers = filteredSpecialOffers.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    this.setState({
      filteredSpecialOffers: filteredSpecialOffers,
      sortedField: field,
    });
  };
  render() {
    let { action } = this.props;
    let { specialOffer } = this.state;
    let content;
    let { flagLoading } = this.state;
    let { colorScheme } = this.props;
    if (flagLoading)
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={colorScheme} flagLoading />
        </div>
      );
    else if (action === "LIST") {
      // First show list
      content = (
        <AdminSpecialOfferList
          onEditSpecialOffer={this.handleEditSpecialOffer}
          onDeleteSpecialOffer={this.handleDeleteSpecialOffer}
          onSearchTextChange={this.handleSearchTextChange}
          filteredSpecialOffers={this.state.filteredSpecialOffers}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange}
          onSearchTextBoxKeyUp={this.handleSearchTextBoxKeyUp}
          onHeaderClick={this.handleHeaderClick}
        />
      );
    } else if (action === "ADD" || action === "UPDATE") {
      // After pressing Add Button, show only form and not the list
      content = (
        <div>
          {/* content={" "} */}
          <AdminSpecialOfferAdd
            onFormSubmit={this.onFormSubmit}
            specialOffer={specialOffer}
            action={action}
            colorScheme={colorScheme}
          />
        </div>
      );
    } else if (action === "DELETE") {
      content = <div>Delete action in Progress.</div>;
    }
    return (
      <div className="container-fluid container-md container-list-table">
        {content}
      </div>
    );
  }
}
export default AdminSpecialOffer;
