export class ClientDB {
  constructor(clients) {
    this.clients = clients;
  }
  allClients() {
    return this.clients;
  }
  searchByClientId(id) {
    for (let i = 0; i < this.clients.length; i++) {
      if (this.clients[i].clientId == id) {
        return this.clients[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredClients = [];
    for (let i = 0; i < this.clients.length; i++) {
      if (this.clients[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredClients.push(this.clients[i]);
      }
    } //for
    return filteredClients;
  }
}
export default ClientDB;
