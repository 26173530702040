import React, { Component } from "react";
import { ProductDB } from "../ProductDB";
import { Link } from "react-router-dom";

export class AdminMakeYaadiAutocomplete extends Component {
  prdb = new ProductDB(this.props.productList);
  state = {
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: "",
    searchText: "",
    searchedProducts: [],
    selectedId: 0,
    selectedOptionIndex: 0,
    flagSearch: false,
  };
  componentDidMount = () => {
    let searchText, product;
    product = this.props.product;
    if (product != null) {
      searchText = product.name;
    } else {
      searchText = "";
    }
    this.setState({
      searchText: searchText,
    });
  };
  handleSearchTextChange = (event) => {
    if (event.keyCode === 38) {
      return;
    }
    if (this.state.flagSearch) {
      // only during edit mode, change text otherwise keep it fixec
      this.setState({ searchText: event.target.value });
      this.props.onSearchTextChange(event);
    }
  };
  handleSearchTextBoxKeyDown = (event) => {
    let query = event.target.value.trim();
    let searchedProducts;
    if (query.length <= 2) {
      searchedProducts = [];
    } else {
      searchedProducts = this.prdb.filterByName(query);
    }
    this.setState({
      searchedProducts: searchedProducts,
    });
    let selectedOptionIndex = this.state.selectedOptionIndex;
    if (event.keyCode === 40) {
      // down-arrow key
      if (selectedOptionIndex < searchedProducts.length - 1) {
        selectedOptionIndex++;
        this.setState({
          selectedOptionIndex: selectedOptionIndex,
        });
      }
    } else if (event.keyCode === 38) {
      // Up-arrow key
      if (selectedOptionIndex > 0) {
        selectedOptionIndex--;
        this.setState({
          selectedOptionIndex: selectedOptionIndex,
        });
      }
    } else if (event.keyCode === 13 || event.keyCode === 9) {
      // Enter  key or tab key
      this.setState({
        flagSearch: false,
      });

      // let { selectedOptionIndex } = this.state;
      let product = this.state.searchedProducts[selectedOptionIndex];
      // event.target.blur(); // leave the text now. so that
      this.addProductSelected(product);
    } else {
      // any other key typed
      this.setState({
        selectedOptionIndex: 0,
      });
    }
  };
  handleSearchBoxProductClick = (event) => {
    // event.preventDefault();
    this.setState({
      flagSearch: false,
    });
    let index = event.target.id;
    let product = this.state.searchedProducts[index];
    let { selectedOptionIndex } = this.state;
    this.addProductSelected(product);
  };
  addProductSelected = (product) => {
    let searchText;
    if (product.information) {
      searchText = product.name + "-" + product.information;
    } else {
      searchText = product.name;
    }
    this.setState({
      searchText: searchText,
    });
    this.props.onSelection(product);
  };
  handleSearchTextFocus = (event) => {
    this.setState({ flagSearch: true });
  };
  handleSearchTextBlur = (event) => {
    //this.setState({ flagSearch: false });
  };
  handleSearchTextEnter = (event) => {
    this.setState({
      flagSearch: false,
    });

    let { selectedOptionIndex } = this.state;
    let product = this.state.searchedProducts[selectedOptionIndex];
    // event.target.blur(); // leave the text now. so that
    this.addProductSelected(product);
  };

  
  productPrice = (product) => {
    let unit = "";
    let price;
    if (product.unitId != 1) {
      unit = "/kg";
    }
    if (product.mrp === product.finalPrice) {
      price = product.finalPrice;
    } else {
      price = product.finalPrice + " (" + product.mrp + ")";
    }
    return "--- Rs. " + price + unit;
  };

  getDescription = (product) => {
    let description;
    description = product.name;
    if (product.information) {
      description += "-" + product.information;
    }
    if (product.nameMarathi) {
      description += "/" + product.nameMarathi;
    }
    if (product.finalPrice != product.mrp) {
      description += "  " + product.finalPrice + " (" + product.mrp + ")";
    } else {
      description += product.mrp;
    }
    return description;
  };
  getFinalDescription = (product) => {
    let description;
    description = product.name;
    if (product.information) {
      description += "-" + product.information;
    }
    if (product.nameMarathi) {
      description += "/" + product.nameMarathi;
    }
    return description;
  };
  render() {
    let { description } = this.props;
    let { searchText } = this.state;
    let { searchedProducts } = this.state;
    let { flagSearch } = this.state;
    let { selectedOptionIndex } = this.state;
    let classList = "btn-block border-bottom text-thick text-left pl-10";
    return (
      <>
        <div className="search">
          <input
            value={flagSearch ? searchText : description}
            className="form-control mr-sm-2"
            type="search"
            onKeyDown={this.handleSearchTextBoxKeyDown}
            onChange={this.handleSearchTextChange} // This is contolled by parent
            onFocus={this.handleSearchTextFocus}
            onBlur={this.handleSearchTextBlur}
            // onKeyPress={(event) => {
            //   if (event.key === "Enter") {

            //     this.handleSearchTextEnter(event);
            //   }
            // }}
            placeholder="Search product"
            // autoFocus
            // ref={(input) => {
            //   this.textInput = input;
            // }}
          />
        </div>
        {flagSearch && (
          <>
            {searchedProducts.map((product, index) => (
              <button
                style={{ color: "black" }}
                className={
                  (index === selectedOptionIndex ? "btn-mygrey " : "btn ") +
                  classList
                }
                onClick={this.handleSearchBoxProductClick}
                id={index}
                key={index}
              >
                {product.name}{" "}
                {product.information ? product.information : null}{" "}
                {this.productPrice(product)}
              </button>
            ))}
            {/* </div> */}
          </>
        )}
      </>
    );
  }
}
export default AdminMakeYaadiAutocomplete;
