export class CustomerDB {
  constructor(customers) {
    this.customers = customers;
  }
  allCustomers() {
    return this.customers;
  }
  searchByCustomerId(id) {
    for (let i = 0; i < this.customers.length; i++) {
      if (this.customers[i].customerId == id) {
        return this.customers[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredCustomers = [];
    for (let i = 0; i < this.customers.length; i++) {
      if (this.customers[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredCustomers.push(this.customers[i]);
      }
    } //for
    return filteredCustomers;
  }
}
export default CustomerDB;
