import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminProductSheet(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [flagDownload, setFlagDownload] = useState(false);
  let [error, setError] = useState("");
  let [filteredProducts, setFilteredProducts] = useState([]);
  let { colorScheme } = props;
  function getProductsFromDatabase() {
    let { shopUrl } = props;
    setFlagDownload(false);
    setFlagLoad(true);
    axios
      .all([axios.get(window.routerPrefix + "/product/products/" + shopUrl)])
      .then(
        axios.spread((res1) => {
          let productList = res1.data;
          let updatedProducts = [...productList];
          productList.map((product, index) => {
            let unitList = [
              { unitId: 1, name: "Number" },
              { unitId: 2, name: "Multiple of 250 gm" },
              { unitId: 3, name: "Multiple of 1 kg" },
              { unitId: 4, name: "Multiple of 500 gm" },
              { unitId: 5, name: "Multiple of 1 kg" },
            ];
            // get unit (string) from unitId
            for (let i = 0; i < unitList.length; i++) {
              if (product.unitId === unitList[i].unitId) {
                updatedProducts[index].unit = unitList[i].name;
                break;
              } //if
            } //for
          }); //map
          productList = updatedProducts;
          setFlagLoad(false);
          setFlagDownload(true);
          setFilteredProducts(productList);
          JSONToCSVConvertor(productList, "", true);
        })
      )
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setError(err);
      });
  }
  function handleDownloadClick() {
    getProductsFromDatabase();
  }
  function JSONToCSVConvertor(filteredProducts, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof filteredProducts != "object"
        ? JSON.parse(filteredProducts)
        : filteredProducts;
    var arrData = filteredProducts;
    //Set Report title in first row or line
    CSV += ReportTitle + "\r\n\n";
    //condition will generate the Label/Header
    /*
    ProductId	Name	Information	MRP	Final Price	Search Words	In Stock	Rating
    */
    let headers = [
      "productId",
      "name",
      "information",
      "mrp",
      "finalPrice",
      "instock",
      "rating",
      "unit"
    ];
    if (ShowLabel) {
      var row = "";
      var CSV = "";
      //This loop will extract the label from 1st index of on array
      for (let i = 0; i < headers.length; i++) {
        //Now convert each value to string and comma-seprated

        row += headers[i] + ",";
      }
      // row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }
    //1st loop is to extract each row
    let data;
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        data = arrData[i][index];
        if (
          // index === "productId" ||
          index === "nameMarathi" ||
          // index === "information" ||
          index === "unitId" ||
          // index === "unit" ||
          index === "categoryId" ||
          index === "category" ||
          index === "brandId" ||
          index === "brand" ||
          index === "searchWords" ||
          index === "image" ||
          // index === "rating" ||
          index === "dateAdded" ||
          index === "dateModified"
        ) {
          continue;
        }
        // if (index === "name") {
        //   data = arrData[i]["name"] + "-" + arrData[i]["information"];
        // } else
        if (index === "instock") {
          data = arrData[i]["instock"] ? "Yes" : "No";
        }
        row += '"' + data + '",';
      } //for
      CSV += row + "\r\n";
    }
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    let fileName = "Products  ";
    let dt = new Date();
    fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    //  + ".xls";
    //will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but will not work in some browsers
    // or you will not get the correct file extension

    //This trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //This part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  let content = (
    // This is enclosed in container-list-table
    <></>
  );
  //   if (error) {
  //     return <div>Error retriving Yaadies </div>;
  //   } else {
  return (
    <div className="container container-list-table ">
      {!flagLoad && (
        <div className=" text-center ">
          <button
            className={"btn-my" + colorScheme}
            onClick={handleDownloadClick}
          >
            Download Product Sheet
          </button>
        </div>
      )}
      {flagLoad && (
        <div className=" text-center ">
          <BeatLoader size={16} color={colorScheme} flagLoad />
        </div>
      )}
      {error && (
        <div className="text-center text-red">
          "Error retriving Product Sheet"
        </div>
      )}
      {flagDownload && (
        <div className="text-center text-red">Product-sheet downloaded.</div>
      )}
    </div>
  );
}
export default AdminProductSheet;
