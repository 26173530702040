import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import LazyLoad from "react-lazyload";
function AdminProductListSingleImage(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
 let [previewImage, setPreviewImage ]= useState(false);
   let [message, setMessage ]= useState("");
  let [file, setFile ]= useState("");
  function handleChangeImageClick  () {
  props.onHandleChangeImage(product);
  };
  function  handleCancelChangeImageClick()
  {
    setMessage("");
    setFile("");
    setPreviewImage("");
    props.onCancelChangeImageClick(product);
  }
  function handleUploadImageClick  ()  {
    let { product } = props;
    let { shopUrl } = props;
    const formData = new FormData();
    formData.append("imageFile",file);
    setFlagLoad(true);
    axios
      .post(
        window.routerPrefix +"/files/uploadProductImage/" + shopUrl + "/" + product.productId,
        formData,
        {
          headers: {
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data != "####") {
          //successfully uploaded      
          let imageName = res.data;
          // uploaded image should be reflected
          props.onUploadProductImage(product, imageName);
          setPreviewImage(false);
        } else {
          //Error uploading image
        }
        setFlagLoad(false);
      })
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  };
  
  function fileChangedHandler  (event)  {
    let file = event.target.files[0];
    if (!file) {
      setMessage("");
      return;
    }
    let fileType = file.type.substring(0, file.type.indexOf("/"));
    if (fileType != "image") {
      setMessage("Invalid file...");
      setFile("");
    }
    //  else if (file.size > 5000) {
    //   setState({ message: "File-size should be below 5kb" });
    // }
    else {
      setMessage("");
      let previewImage = URL.createObjectURL(file);
    setFile(file);
    setPreviewImage(previewImage);
    }
    
  };
  function handleSaveProductImage  ()  {
    let { product } = props;
    let { shopUrl } = props;
    // save this product
    setFlagLoad(true);
    axios
      // .put("/products/" + shopUrl + "/" + product.productId, product)
      .put(window.routerPrefix +"/product/update/" + shopUrl, product)
      .then((res) => {
        setFlagLoad(false);
        props.onSaveProductImage(product);
      })
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  };
  function handleChange  (event) {
    let userInput = event.target.checked;
    let { product } = props;
    props.onInStockCheckBoxChange(product, Number(userInput));
  };
    let { product } = props;
    let { index } = props;
    let { languageChoice } = props;
    let { colorScheme } = props;
    return(
      <>
      {/* within a row */}
      <div className="col-12 col-md-9 ">
              {index + 1}. &nbsp;
              {product.name}
              {product.information ? "-" + product.information : null}
              {languageChoice ? "/" + product.nameMarathi : null}
              &nbsp;
              {flagLoad && (
                <BeatLoader  size={8} color={colorScheme} flagLoad />
              )}
              {product.flagImageUploaded && (
                <span className={"my"+colorScheme}>(Updated...)</span>
              )}
            </div>
                    {/* row begins */}
            
          {(!product.flagImageChange || !previewImage ||(previewImage&&message))&&
          <div className="col-6  text-center col-md-4">
          <LazyLoad>
            <img
              className="div-product-image"
              src={window.routerPrefix+"/files/downloadImageFile/" + product.image}
              alt=""
            />
            </LazyLoad>
            </div>}

            {product.flagImageChange &&
              previewImage &&
              !message &&
              <div className="col-6   text-center col-md-4">
              <LazyLoad>
            <img
              className="div-product-image"
              src={previewImage}
              alt=""
            />
            </LazyLoad>
        </div>         }   
        <div className="col-6   col-md-3">
        <div className="row ">
        {product.flagImageChange && message && (
              <div className="col-12 text-center text-red">{message}</div>
            )}
        {!product.flagImageChange && (
              <div className="col-12 text-center mb-2">
                <button
                  // className="btn btn-dark "
                  className={"btn-my"+colorScheme}
                  onClick={handleChangeImageClick}
                >
                  Change image
                </button>
              </div>
            )}
            {(product.flagImageChange && !previewImage) && (
              <div className="col-12 text-center mb-2">
                <input
                  type="file"
                  name="file"
                  onChange={fileChangedHandler}
                />
              </div>
            )}
            { product.flagImageChange  && (
              <div className="col-12 text-center mb-2">
                <button
                  className={"btn-my"+colorScheme}
                  onClick={handleCancelChangeImageClick}
                >
                  Cancel
                </button>
              </div>
            )}
        {product.flagImageChange &&
              previewImage &&
              !message &&
              // !product.flagImageUploaded && 
              (
                <div className="col-12 text-center mt-4">
                  <button
                    className={"btn-my"+colorScheme}
                    onClick={handleUploadImageClick}
                  >
                    Update
                  </button>
                </div>
              )}
        
        </div>
        
        </div>
        <div className={"col-12 my-2 border-my"+colorScheme}></div>
        {/* row ends */}
      </>
    );
  }
export default AdminProductListSingleImage;
