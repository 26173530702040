import React, { useEffect, useState } from "react";
import AdminProductListImage from "./admin-product-list-image";
import { BeatLoader } from "react-spinners";
import { ProductDB } from "../ProductDB";
import axios from "axios";
function  AdminProductImage (props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [filteredProducts, setFilteredProducts] = useState([]);
  let [productList, setProductList] = useState([]);
  let [productdb, setProductdb] = useState([]);
  useEffect(() => {
    getProductListFromDatabase();
  }, []);
  function getProductListFromDatabase() {
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .get(window.routerPrefix +"/product/products/" + shopUrl)
      .then((res) => {
        let pList = res.data;
        let updatedProducts = [...pList];
        pList.map((product, index) => {
          updatedProducts[index].flagImageChange = false;
          updatedProducts[index].flagImageUpdate = false;
          updatedProducts[index].flagImageUploaded = false;
        }); //map
        pList = updatedProducts;
        setProductList(pList);
        setFilteredProducts(pList);
        setProductdb(new ProductDB(pList));
        setFlagLoad(false);
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
  }
  function handleSearchTextBoxKeyUp  (event)  {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  };
  function handleSearchTextChange  (event)  {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  };
  function performSearchOperation  (searchText)  {
    let query = searchText.trim();
    let searchedProducts = [];
    searchedProducts = productdb.filterByName(query);
    setFilteredProducts(searchedProducts);
  };
  function  handleCancelChangeImageClick(product)
  {
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i] = product; // update the product
        pList[i].flagImageChange = false;
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i] = product; // update the product
        fProducts[i].flagImageChange = false;
        break;
      }
    } //for
    setProductList(pList)
    setFilteredProducts(fProducts);
    setProductdb(pList)
  }
  function handleUploadProductImage  (product, imageName) {
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i].image = imageName;
        pList[i].flagImageChange = false;
        pList[i].flagImageUpdate = false;
        pList[i].flagImageUploaded = true;
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i].image = imageName;
        fProducts[i].flagImageChange = false;
        fProducts[i].flagImageUpdate = false;
        fProducts[i].flagImageUploaded = true;
        break;
      }
    } //for
    setProductList(pList)
    setFilteredProducts(fProducts);
    setProductdb(pList)
  };
  function  handleChangeImage(product)  {
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i] = product; // update the product
        pList[i].flagImageChange = true;
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i] = product; // update the product
        fProducts[i].flagImageChange = true;
        break;
      }
    } //for
    setProductList(pList)
    setFilteredProducts(fProducts);
    setProductdb(pList)
  };
  function  handleSaveProductImage  (product)  {
    let pList = [...productList];
    for (let i = 0; i < pList.length; i++) {
      if (pList[i].productId == product.productId) {
        pList[i] = product; // update the product
        pList[i].flagImageUpdate = true;
        break;
      }
    } //for
    // Update in  filteredproducts also
    let fProducts = [...filteredProducts];
    for (let i = 0; i < fProducts.length; i++) {
      if (fProducts[i].productId == product.productId) {
        fProducts[i] = product; // update the product
        fProducts[i].flagImageUpdate = true;
      }
    } //for
    setProductList(pList)
    setFilteredProducts(fProducts);
    setProductdb(pList)
    let message =
      "Image of " + product.name + " updated successfully";
    props.onCrudAction(message, "LIST");
  };
    let { action } = props;
    let { shopDetails } = props;
    let { productsLimit } = shopDetails;
    let { languageChoice } = shopDetails;
    let { shopUrl } = props;
    let { colorScheme } = props;
    let content;
    if (flagLoad) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={colorScheme} flagLoad />
        </div>
      );
    } else if (action === "LIST") {
      // First show list
      content = (
        <AdminProductListImage
        onChangeImage={handleChangeImage}
          onSaveProductImage={handleSaveProductImage}          
          onUploadProductImage={handleUploadProductImage}
          onCancelChangeImageClick={handleCancelChangeImageClick}
          filteredProducts={filteredProducts}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          languageChoice={languageChoice}
          productsLimit={productsLimit}
          currentSize={productdb.size}
          shopUrl={shopUrl}
          colorScheme={colorScheme}
        />
      );
    }
    return (
      <div className="container-fluid container-md container-list-table p-0">
        {content}
      </div>
    );
  }
export default AdminProductImage;