import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { YaadiDB } from "../YaadiDB";
import AdminReportSummaryList from "./admin-report-summary-list";
import AdminEditYaadi from "./admin-edit-yaadi";
import axios from "axios";
import DatePicker from "react-datepicker";
function AdminReportSummary(props) {
  let grandTotal = 0,
    grandNet = 0.0,
    grandDeliveryCharges = 0.0;
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [yaadidb, setYaadidb] = useState([]);
  let [yaadiList, setYaadiList] = useState([]);
  let [flagDisplay, setFlagDisplay] = useState(false);
  let [flagEditYaadi, setFlagEditYaadi] = useState("");
  let [yaadiId, setYaadiId] = useState(false);
  let [whichDate, setWhichDate] = useState("Today");
  let [dateFrom, setDateFrom] = useState(new Date());
  let [dateTo, setDateTo] = useState(new Date());
  let { colorScheme } = props;
  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  function handleRefreshClick() {
    // get data of items for today from yaadies
    getDataFromDatabase(whichDate);
  }
  function dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
  function getDataFromDatabase(whichDate) {
    let { shopUrl } = props;
    // let whichDate = value;
    setFlagLoad(true);
    let diff1, diff2;
    if (whichDate === "Between Dates") {
      let d = new Date();
      diff1 = dateDiffInDays(dateFrom, d);
      diff2 = dateDiffInDays(dateTo, d);
      whichDate = diff1 + "_" + diff2;
    }
    axios
      .get(
        window.routerPrefix +
          "/yaadi/yaadiesByDate/" +
          shopUrl +
          "/" +
          whichDate
      )
      .then((res) => {
        let yaadiList = res.data;
        let yaadidb = new YaadiDB(yaadiList);
        setFlagLoad(false);
        setYaadiList(yaadiList);
        setYaadidb(yaadidb);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setError(err);
      });
  }
  function handleDeleteYaadi(yaadi) {
    let { shopUrl } = props;
    let { yaadiId } = yaadi;
    setFlagLoad(true);
    // Now call for delete api
    axios
      .delete(window.routerPrefix + "/yaadi/delete/" + shopUrl + "/" + yaadiId)
      .then((res) => {
        let message = "Yaadi no. " + yaadiId + " deleted successfully";
        // now delete all products from yaadiProducts table
        axios
          .delete(
            window.routerPrefix + "/yaadiProduct/delete/" + shopUrl + "/" + yaadiId
          )
          .then((res) => {
            setFlagLoad(false);
            props.onCrudAction(message);
            getDataFromDatabase(whichDate);
          })
          .catch((err) => {
            console.log(err);
            setFlagLoad(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
      });
  }
  function handleEditYaadi(yaadi) {
    setFlagEditYaadi(true);
    setYaadiId(yaadi.yaadiId);
  }
  function handleDateSelection(value) {
    setWhichDate(value);
    setFlagDisplay(false);
  }
  function handleDateFromPickerClick(date) {
    setDateFrom(date);
    setFlagDisplay(false);
  }
  function handleDateToPickerClick(date) {
    setDateTo(date);
    setFlagDisplay(false);
  }
  function handleShowHereClick() {
    if (dateFrom > dateTo) {
      window.alert("Enter dates correctly...");
      return;
    }
    setFlagDisplay(true);
    getDataFromDatabase(whichDate); // value is whichDate (today/yesterday)
  }

  function handleExcelFileDownloadClick() {
    JSONToCSVConvertor(yaadiList, "", true);
  }
  function handleSingleYaadiDownloadClick(event) {
    let { shopUrl } = props;
    let yaadiId = event.target.id;
    // Get now products of this yaadiId
    let yaadi = yaadidb.searchByYaadiId(yaadiId);
    setFlagLoad(true);
    let yaadiProducts;
    axios
      .get(
        window.routerPrefix +
          "/yaadiProduct/yaadiProducts/" +
          shopUrl +
          "/" +
          yaadiId
      )
      .then((res) => {
        yaadiProducts = res.data;
        setFlagLoad(false);
        JSONToCSVConvertor_singleFile(yaadi, yaadiProducts);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setError(err);
      });
  }
  function JSONToCSVConvertor_singleFile(yaadi, yaadiProducts) {
    yaadi = typeof yaadi != "object" ? JSON.parse(yaadi) : yaadi;
    yaadiProducts =
      typeof yaadiProducts != "object"
        ? JSON.parse(yaadiProducts)
        : yaadiProducts;
    let CSV = "";
    let yaadiId;
    yaadiId = yaadi.yaadiId;
    // a yaadi starts
    // First Show Customer Information
    CSV +=
      ",," +
      yaadi.yaadiId +
      "\r\n" +
      ",," +
      yaadi.customerWhatsApp +
      "\r\n" +
      ",," +
      yaadi.customerName +
      "\r\n" +
      "," +
      yaadi.customerAddress +
      "\r\n";
    // Now show headers of the table
    let headers = [
      "S.No.",
      "Description",
      // "Marathi",
      "Quantity",
      // "mrp",
      "Final price",
      "Total",
    ];
    var row = "";
    for (let j = 0; j < headers.length; j++) {
      row += headers[j] + ",";
    } //for
    CSV += row + "\r\n";
    // Now show Products
    //1st loop is to extract each row
    let data, total;
    let t = 0;
    var row = '"' + (t + 1) + '",';
    //2nd loop will extract each column and convert it in string comma-seprated
    for (let i = 0, t = 0; i < yaadiProducts.length; i++) {
      var row = '"' + (t + 1) + '",';
      row += '"' + yaadiProducts[i].name + '",';
      // calculate  quantity
      let unitId = yaadiProducts[i].unitId;
      data=yaadiProducts[i].quantity;
      if (unitId == 1) {
      } else if (unitId == 2 || unitId == 4) {
        data = data * 1000;
        if (data >= 1000) {
          data /= 1000;
          if (data == 1) {
            data = data + " kg";
          } else {
            data = data + " kgs";
          }
        } else {
          data = data + " gms";
        }
      } else if (unitId == 3 || unitId == 5) {
        if (data == 1) {
          data = data + " kg";
        } else {
          data = data + " kgs";
        }
      }
      // obtained quantity
      row += '"' + data + '",';
      row += '"' + yaadiProducts[i].finalPrice + '",';
      // calculate total
      total = yaadiProducts[i]["quantity"] * yaadiProducts[i]["finalPrice"];
      row += '"' + total + '",';
      CSV += row + "\r\n";
      t = t + 1;
    } //for
    CSV += ",,," + "Total Amount" + "," + yaadi.totalAmount + "\r\n";
    if (yaadi.deliveryCharges) {
      CSV += ",,," + "Delivery Charges" + "," + yaadi.deliveryCharges + "\r\n";
    }
    if (yaadi.deliveryCharges)
      CSV += ",,," + "Payable amount" + "," + yaadi.netAmount;

    CSV += "\r\n\r\n\r\n";
    // a yaadi ends
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    var fileName = yaadiId + "_" + yaadi.customerName;
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    var arrData = JSONData;
    //Set Report title in first row or line
    CSV += ReportTitle + "\r\n\n";
    //This condition will generate the Label/Header
    let headers = [
      "S.No.",
      "Yaadi No.",
      "Name",
      "WhatsApp",
      "Address",
      "Total Amount",
      "Delivery Charges",
      "Net Amount",
      "Time",
    ];
    if (ShowLabel) {
      var row = "";
      var CSV = "";
      //This loop will extract the label from 1st index of on array
      for (let i = 0; i < headers.length; i++) {
        //Now convert each value to string and comma-seprated
        row += headers[i] + ",";
      }
      // row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }
    //1st loop is to extract each row
    let data;
    for (var i = 0; i < arrData.length; i++) {
      var row = '"' + (i + 1) + '",';
      row += '"' + arrData[i].yaadiId + '",';
      row += '"' + arrData[i].customerName + '",';
      row += '"' + arrData[i].customerWhatsApp + '",';
      row += '"' + arrData[i].customerAddress + '",';
      row += '"' + arrData[i].totalAmount.toFixed(2) + '",';
      row += '"' + arrData[i].deliveryCharges.toFixed(2) + '",';
      row += '"' + arrData[i].netAmount.toFixed(2) + '",';
      let dt = new Date(arrData[i].dateOfYaadi);
      let time = dt.getHours() + ":" + dt.getMinutes();
      row += '"' + time + '",';
      CSV += row + "\r\n";
    }
    CSV += " , , ,,," + grandTotal;
    CSV += " , " + grandDeliveryCharges;
    CSV += " , " + grandNet;
    if (CSV == "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    let dt = new Date();
    let fileName = "Summary ";
    if (whichDate === "Today") {
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    }
    if (whichDate === "Yesterday") {
      dt.setDate(dt.getDate() - 1);
      fileName += dt.toDateString() + " " + dt.toLocaleTimeString();
    } else if (whichDate === "Between Dates") {
      fileName +=
        pad(dateFrom.getDate(), 2) +
        "." +
        pad(dateFrom.getMonth() + 1, 2) +
        " to " +
        pad(dateTo.getDate(), 2) +
        "." +
        pad(dateTo.getMonth() + 1, 2);
    }
    //  + ".xls";
    //this will remove the blank-spaces from the title and replace it with an underscore
    // fileName += ReportTitle.replace(/ /g, "_");
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  let versionTypeId = props.shopDetails.versionTypeId;
  let { shopUrl } = props;
  let d = new Date();
  grandTotal = 0.0;
  grandNet = 0.0;
  grandDeliveryCharges = 0.0;
  for (let i = 0; i < yaadiList.length; i++) {
    grandTotal += yaadiList[i].totalAmount;
    grandNet += yaadiList[i].netAmount;
    grandDeliveryCharges += yaadiList[i].deliveryCharges;
  }
  let content = (
    // This is enclosed in container-list-table
    <>
      {/* row starts */}
      <div className=" col-12">
        <div className="form-heading">Yaadi Summary</div>
      </div>
      {/* row starts */}
      <div className="row">
        <div className="col-12 my-2 text-center ">
          <input
            type="radio"
            className="form-control-inline"
            name="whichDate"
            value="Yesterday"
            onClick={() => handleDateSelection("Yesterday")}
            onChange={() => handleDateSelection("Yesterday")}
          />
          &nbsp;Yesterday
          <div className="col-12 my-2">
            <input
              type="radio"
              className="form-control-inline"
              name="whichDate"
              value="Today"
              onClick={() => handleDateSelection("Today")}
              onChange={() => handleDateSelection("Today")}
              checked={whichDate === "Today"}
            />
            &nbsp;Today
          </div>
          <div className="col-12 my-2">
            <input
              type="radio"
              className="form-control-inline"
              name="whichDate"
              value="Between Dates"
              onClick={() => handleDateSelection("Between Dates")}
              onChange={() => handleDateSelection("Between Dates")}
              checked={whichDate === "Between Dates"}
            />
            &nbsp;Between Dates:
          </div>
          {whichDate === "Between Dates" ? (
            <div className="row justify-content-start">
              <div className="col-4 my-2 text-right ">From:</div>
              <div className="col-8 my-2 text-left ">
                <DatePicker
                  selected={dateFrom}
                  selectsStart
                  startDate={dateFrom}
                  endDate={dateTo}
                  dateFormat="yyyy/MM/dd"
                  minDate={d.setDate(d.getDate() - 8)}
                  onChange={(date) => handleDateFromPickerClick(date)}
                />
              </div>

              <div className="col-4 my-2 text-right ">To:</div>
              <div className="col-8 my-2 text-left">
                <DatePicker
                  selected={dateTo}
                  selectsEnd
                  startDate={dateFrom}
                  endDate={dateTo}
                  dateFormat="yyyy/MM/dd"
                  maxDate={new Date()}
                  onChange={(date) => handleDateToPickerClick(date)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {flagLoad && (
        <div className="text-center mt-5">
          <BeatLoader size={16} color={"blue"} flagLoad />
        </div>
      )}
      {!flagLoad && (
        <AdminReportSummaryList
          yaadiList={yaadiList}
          yaadidb={yaadidb}
          whichDate={whichDate}
          grandTotal={grandTotal}
          onExcelFileDownloadClick={handleExcelFileDownloadClick}
          onSingleYaadiDownloadClick={handleSingleYaadiDownloadClick}
          onDeleteYaadi={handleDeleteYaadi}
          onEditYaadi={handleEditYaadi}
          onShowHereClick={handleShowHereClick}
          flagDisplay={flagDisplay}
          onCrudAction={props.onCrudAction}
          shopUrl={props.shopUrl}
          versionTypeId={versionTypeId}
          colorScheme={colorScheme}
        />
      )}
    </>
  );
  if (error) {
    return <div>Error retriving Yaadies </div>;
  } else {
    return (
      <div className="container-fluid container-list-table p-0">
        {flagEditYaadi ? (
          <AdminEditYaadi yaadiId={yaadiId} shopUrl={shopUrl} />
        ) : (
          content
        )}
      </div>
    );
  } //else
}
export default AdminReportSummary;
