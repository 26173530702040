import React, { Component } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import DropDownOwnerMenu from "./drop-down-owner-menu";
import ActionMessage from "./action-message";
class NavBarOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      loginTitle: "Login",
    };
  }
  handleOwnerMenuClick = (event) => {
    this.props.onOwnerMenuClick(event);
  };
  handleUserLogOut = () => {
    this.props.onUserLogOut();
  };
  handleAdminLogOut = () => {
    this.props.onAdminLogOut();
  };
  handleAdminManageProducts = () => {
    this.props.onAdminManageProducts();
  };

  handleLogInClick = () => {
    this.props.onLogInClick();
  };
  handleKeyUp = (event) => {
    // let query = event.target.value;
    this.props.onKeyUp();
  };
  handleLogoClick = () => {
    this.props.onLogoClick();
  };
  handleCategoryClick = (event) => {
    this.props.onCategoryClick(event);
  };
  handleCartClick = () => {
    this.props.onCartClick();
  };
  handleBrandClick = (event) => {
    this.props.onBrandClick(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleAddListToggleClick = (event) => {
    event.preventDefault(); // Not required
    this.props.onAddListToggleClick();
  };

  render() {
    let { ownerManageList } = this.props;
    let { message } = this.props;
    let { menu } = this.props;
    return (
      <div className="container-fluid container-navbar-admin fixed-top bg-primary">
        {/* a row starts */}
        <div className="row column  justify-content-center ">
          <div className="col-4 mx-3 column text-center">
            <button
              className="btn-mycyan bigger-text text-thick"
              onClick={this.handleAddListToggleClick}
            >
              {menu}
            </button>
          </div>
          <div className="col-4 text-center text-thick bigger-text column">
            <DropDownOwnerMenu
              ownerManageList={ownerManageList}
              onOwnerMenuClick={this.handleOwnerMenuClick}
              title={"Owner Manage"}
              shortTitle={"Owner Manage"}
            />
          </div>
        </div>
        {/* The row end */}
        <div className="row  justify-content-center ">
          <div className="col-10">
            {message != "" ? (
              <div className="cart-message text-center pt-1 text-danger bg-white column mt-2 ml-4 pl-2 ">
                <ActionMessage
                  message={message}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="nav-item">
          <Link className="nav-link text-white" to="#saved">
            {/* View Saved Products */}
          </Link>
        </div>
      </div>
    );
  }
}
export default NavBarOwner;