import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
class AdminShopTypeListSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      flagLoading: false,
    };
  }
  handleEditShopType = (shopType) => {
    this.props.onEditShopType(shopType);
  };

  handleDeleteShopType = (shopType) => {
    // Now call for delete api
    let { shopUrl } = this.props;
    this.setState({
      flagLoading: true,
    });
    axios
      .delete(
        window.routerPrefix + "/shopType/delete/" + shopType.shopTypeId
      )
      .then((res) => {
        this.setState({
          flagLoading: false,
        });
        if (res.data != 1) {
          // could not delete the shopType
          this.props.onDeleteShopType(shopType, 0);
        } else {
          this.props.onDeleteShopType(shopType, 1);
        }
      })
      .catch((err) => {
        this.setState({
          flagLoading: false,
          // action: "LIST", // Back to list view
        });
        console.log(err);
      });
  };

  render() {
    let { error } = this.state;
    let { shopType } = this.props;
    let { index } = this.props;
    let { flagLoading } = this.state;
    let content = (
      // This is enclosed in container-list-table
      <>
        <tr className="myborder-top">
          <td>{index + 1}</td>
          <td>{shopType.name}</td>
          <td>{shopType.colorScheme}</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div className="row">
              <div className="col-4">
                <Link to="#" onClick={() => this.handleEditShopType(shopType)}>
                  {/* Edit */}
                  <i className="fas fa-edit admin-icons"></i>
                </Link>
              </div>
              {!flagLoading && (
                <div className="col-4">
                  <Link
                    to="#"
                    onClick={() => {
                      if (
                        window.confirm(
                          "You really want to delete " + shopType.name + " ?"
                        )
                      )
                        this.handleDeleteShopType(shopType);
                    }}
                  >
                    {/* Delete */}
                    <i className="far fa-trash-alt admin-icons"></i>
                  </Link>
                </div>
              )}
              {flagLoading && (
                <div className="col-4">
                  <BeatLoader size={8} color={"blue"} flagLoading />
                </div>
              )}
            </div>
          </td>
        </tr>
      </>
    );
    if (error) {
      return <div>Error retriving shopTypes </div>;
    } else {
      return content;
    } //else
  }
}
export default AdminShopTypeListSingle;
