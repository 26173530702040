import React, { useEffect, useState } from "react";
function AdminCustomerAdd(props) {
  let [customer, setCustomer] = useState({
    customerId: "",
    name: "",
    address: "",
    whatsApp: "",
  });
  let [errorCustomer, setErrorCustomer] = useState({
    name: { message: "", mxLen: 40, mnLen: 5, onlyDigits: false },
    address: { message: "", mxLen: 80, mnLen: 5, onlyDigits: false },
    whatsApp: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
  });
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let [file, setFile] = useState("");
  let [fileName, setFileName] = useState("");
  let emptyCustomer;
  useEffect(() => {
    init();
  }, [props]);
  function init() {
    emptyCustomer = customer;
    let { action } = props;
    if (action === "ADD") {
      setFlagFormInvalid(true);
      // may retain some properties
      setCustomer(emptyCustomer);
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      setFlagFormInvalid(false);
      setCustomer(props.customer);
    }
  }
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      setCustomer({ ...customer, [name]: event.target.value });
      return;
    }
    if (inputType == "file") {
      let file = event.target.files[0];
      if (!file) {
        return;
      }
      let fileType = file.type.substring(0, file.type.indexOf("/"));
      let errCustomer = { ...errorCustomer };
      let fileName = file.name;

      if (errorCustomer[name]["imageType"] != fileType) {
        errorCustomer[`${name}`].message = "Check type of file";
        setErrorCustomer(errCustomer);
      } else {
        errorCustomer[`${name}`].message = "";
        setErrorCustomer(errCustomer);
        setCustomer({ ...customer, [name]: event.target.value });
        setFile(file);
        setFileName(fileName);
      }
      return;
    }
    setCustomer({ ...customer, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorCustomer[`${name}`].mnLen;
      mxLen = errorCustomer[`${name}`].mxLen;
      onlyDigits = errorCustomer[`${name}`].onlyDigits;
      allDigits = errorCustomer[`${name}`].allDigits;
      noSymbols = errorCustomer[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      // else if (!allDigits) {
      //   for (i = value.length - 1, flag = false; i >= 0; i--) {
      //     const d = value.charCodeAt(i);
      //     if (d < 48 || d > 57) {
      //       message = "";
      //       flag = true;
      //       break;
      //     } //if
      //   } //for
      //   if (flag == false) {
      //     message = "Only digits are not allowed, use some alphabets also";
      //   }
      // } //else if... allDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    let errCustomer = { ...errorCustomer };
    errorCustomer[`${name}`].message = message;
    setErrorCustomer(errCustomer);
    checkAllErrors();
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorCustomer) {
      if (errorCustomer[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleRatingClick(value) {
    setCustomer({ ...customer, rating: value });
  }
  function handleSubmit(event) {
    event.preventDefault();
    props.onFormSubmit(customer);
    setCustomer(emptyCustomer);
  }
  function handleCancelClick() {
    props.onCancelClick();
  }
  let { action } = props;
  let { colorScheme } = props;
  let heading;
  if (action === "ADD") {
    heading = "Add a Customer";
  } else if (action === "UPDATE") {
    heading = "Update the Customer";
  }
  return (
    <React.Fragment>
      <div className="form-heading column col-12">{heading}</div>
      <form
        className={"text-thick p-4 bg-my" + colorScheme}
        onSubmit={handleSubmit}
      >
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Name</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="name"
              value={customer.name}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Customer name"
              required
            />
          </div>
          <div className="offset-5">
            {errorCustomer.name.message ? (
              <span className="error-text">{errorCustomer.name.message}</span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Address</label>
          </div>
          <div className="col-7 column px-0">
            <textarea
              type="text"
              className="form-control"
              rows="8"
              cols="50"
              name="address"
              value={customer.address}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Customer address max 80 characters"
              required
            />
          </div>
          <div className="offset-5">
            {errorCustomer.address.message ? (
              <span className="error-text">
                {errorCustomer.address.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>WhatsApp Number</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="whatsApp"
              value={customer.whatsApp}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="WhatsApp Number"
              required
            />
          </div>
          <div className="offset-5">
            {errorCustomer.whatsApp.message ? (
              <span className="error-text">
                {errorCustomer.whatsApp.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}

        {/* row starts */}
        <div className="form-group row offset-5 text-center mb-0">
          <button
            className={"btn-my" + colorScheme+"-dark"}
            type="submit"
            disabled={flagFormInvalid}
          >
            {action}
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            className={"btn-my" + colorScheme+"-dark"}
            onClick={handleCancelClick}
          >
            CANCEL
          </button>
        </div>
        <input type="hidden" name="customerId" value={customer.customerId} />
      </form>
    </React.Fragment>
  );
}
export default AdminCustomerAdd;
