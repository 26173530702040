import React, { useEffect, useState } from "react";
import AdminClientSheetList from "./admin-clientsheet-list";
import AdminClientSheetAdd from "./admin-clientsheet-add";
import { ClientSheetDB } from "../clientSheetDB";
import { BeatLoader } from "react-spinners";
import axios from "axios";
// This is used in AdminActivity
// When user wants to add/delete/edit a clientSheet
// A client has got clientSheets
function AdminClientSheet(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [sectionNumber, setSectionNumber] = useState(1);
  let [clientList, setClientList] = useState([]);
  let [selectedClient, setSelectedClient] = useState("");
  let [selectedClientId, setSelectedClientId] = useState(-1);
  let [filteredClientSheets, setFilteredClientSheets] = useState([]);
  let [clientSheetList, setClientSheetList] = useState([]);
  let [clientSheetdb, setClientSheetdb] = useState({});
  let [clientSheet, setClientSheet] = useState(null);
  let [flagClientSheetLoading, setFlagClientSheetLoading] = useState(false);
  let [lastClientSheetNumber, setLastClientSheetNumber] = useState(0);
  // constructor(props) {
  //   super(props);
  //    = {
  //     error: null,
  //     response: {},
  //     clientSheet: null,
  //     filteredClientSheets: [],
  //     clientList: [],
  //     clientSheetList: [],
  //     selectedClient: "",
  //     flagLoading: false,
  //     searchText: "",
  //     sortedField: "",
  //     sortedDirection: false,
  //     sectionNumber: 1,
  //   };
  //   onFormSubmit = onFormSubmit.bind(this);
  // }
  function getClientSheetListFromDatabase(client, clientId) {
    setFlagClientSheetLoading(true);
    
        axios.get(
          window.routerPrefix + "/clientSheet/clientSheets/" + clientId
        )
        .then((res) => {
          let clientSheetList = res.data;
          let updatedClientSheets = [...clientSheetList];
          clientSheetList.map((clientSheet, index) => {
            clientSheet.client = client;
          }); //map
          clientSheetList = updatedClientSheets;
          getLastClientSheetNumber(clientSheetList);
          setFlagClientSheetLoading(false);
          setClientSheetList(clientSheetList);
          setFilteredClientSheets(clientSheetList);
          setClientSheetdb(new ClientSheetDB(clientSheetList));
        })
      .catch((err) => {
        console.log(err);
        setFlagClientSheetLoading(false);
        setError(err);
      });
  }
  useEffect(() => {
    init();
  }, []);
  function init() {
    //  A client has got clientSheet
    // so clientSheet has got clientId
    // So first get all clients from database
    // We need to replace client field to clientSheet record
    // List of clients should be ready
    setFlagLoading(true);
    axios
      .get(window.routerPrefix + "/client/clients/")
      .then((res) => {
        setClientList(res.data);
        setFlagLoading(false);
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedClientSheets = [];
    searchedClientSheets = clientSheetdb.filterByName(query);
    setFilteredClientSheets(searchedClientSheets);
  }
  function onFormSubmit(clientSheet) {
    let { action } = props;
    setFlagLoading(true);
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/clientSheet/update", clientSheet)
        .then((res) => {
          setFlagLoading(false);
          if (res.data) {
            updateClientSheetList(clientSheet);
          } else {
            // Error, something went wrong
          }
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } else if (action === "ADD") {
      //ADD / POST
      axios
        .post(
          window.routerPrefix + "/clientSheet/addClientSheet",
          clientSheet,
          {
            headers: {
              accept: "application/json",
              "Accept-Language": "en-US,en;q=0.8",
            },
          }
        )
        .then((res) => {
          setFlagLoading(false);
          if (res.data) {
            clientSheet.clientSheetId = res.data.clientSheetId; // id of the added clientSheet is returned as response
            addToClientSheetList(clientSheet);
          } else {
            //error, something went wrong
          }

          // setState({
          //   clientToRetain: clientSheet.client,
          //   clientIdToRetain: clientSheet.clientId,
          // });
        })
        .catch((err) => {
          setError(err);
          setFlagLoading(false);
        });
    } //else
  }
  function handleEditClientSheet(clientSheet) {
    setClientSheet(clientSheet);
    setSearchText("");
    props.onEditClick();
  }
  function addToClientSheetList(clientSheet) {
    let cList = [...clientSheetList];
    cList.unshift(clientSheet); // insert at the beginning
    let fClientSheets = [...filteredClientSheets];
    fClientSheets.unshift(clientSheet);
    setFilteredClientSheets(fClientSheets);
    setClientSheetList(cList);
    let message =
      "The clientSheet '" + clientSheet.name + "' added successfully.";
    getLastClientSheetNumber(cList);
    props.onCrudAction(message, "ADD");
  }
  function updateClientSheetList(clientSheet) {
    let cList = [...clientSheetList];
    for (let i = 0; i < cList.length; i++) {
      if (cList[i].clientSheetId == clientSheet.clientSheetId) {
        if (cList[i].clientId == clientSheet.clientId) {
          // update, but it should be at the beginning
          // clientSheetList[i] = clientSheet;
          cList.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          cList.unshift(clientSheet);
        } else {
          //delete from current list
          cList.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    // remove from filteredclientSheets also
    let fClientSheets = [...filteredClientSheets];
    for (let i = 0; i < fClientSheets.length; i++) {
      if (fClientSheets[i].clientSheetId == clientSheet.clientSheetId) {
        if (fClientSheets[i].clientId == clientSheet.clientId) {
          // update
          fClientSheets.splice(i, 1); // remove 1 element from position i
          // and insert at the beginning as we want latest modified at the top
          fClientSheets.unshift(clientSheet);
        } else {
          //delete from current list
          fClientSheets.splice(i, 1); // remove 1 element from position i
        }
        break;
      } //if
    } //for
    setFilteredClientSheets(fClientSheets);
    setClientSheetList(cList);
    let message =
      "The clientSheet '" + clientSheet.name + "' updated successfully.";
    props.onCrudAction(message, "LIST", "Add a ClientSheet");
  }
  function handleDeleteClientSheet(clientSheet) {
    let cList = [...clientSheetList];
    for (let i = 0; i < cList.length; i++) {
      if (cList[i].clientSheetId == clientSheet.clientSheetId) {
        cList.splice(i, 1); // remove 1 element from position i
      }
    } //for
    // remove from filteredclientSheets also
    let fClientSheets = [...filteredClientSheets];
    for (let i = 0; i < fClientSheets.length; i++) {
      if (fClientSheets[i].clientSheetId == clientSheet.clientSheetId) {
        fClientSheets.splice(i, 1); // remove 1 element from position i
      }
    } //for
    setFilteredClientSheets(fClientSheets);
    setClientSheetList(cList);
    let message =
      "The clientSheet '" + clientSheet.name + "' deleted successfully.";
    props.onCrudAction(message, "LIST");
  }
  function getLastClientSheetNumber(clientSheetList) {
    let lastClientSheetNumber = 0;
    for (let i = 0; i < clientSheetList.length; i++) {
      if (clientSheetList[i].clientSheetNumber > lastClientSheetNumber) {
        lastClientSheetNumber = clientSheetList[i].clientSheetNumber;
      } //if
    } //for
    setLastClientSheetNumber(lastClientSheetNumber);
  }
  function handleHeaderClick(event) {
    let field = event.target.id;

    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    if (d == false) {
      //in ascending order
      filteredClientSheets = filteredClientSheets.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      filteredClientSheets = filteredClientSheets.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredClientSheets(filteredClientSheets);
    setSortedField(field);
  }
  function handleSectionNumberClick(event) {
    setSectionNumber(event.target.id);
  }
  function handleSelectClientChange(event) {
    // This is called for LIST as well ADD operation.
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedClientId = optionElement.getAttribute("id");
    let selectedClient = event.target.value;
    setSelectedClient(selectedClient);
    setSelectedClientId(selectedClientId);
    getClientSheetListFromDatabase(selectedClient, selectedClientId);
    if (selectedClient) {
      props.showAddButton();
    }
  }

  let optionsClient = clientList.map((client, index) => (
    <option value={client.name} key={index} id={client.clientId}>
      {client.name}
    </option>
  ));
  let { action } = props;
  let { accessLevel } = props;
  let { adminType } = props;
  let { colorScheme } = props;

  return (
    <div className="container-fluid container-md container-list-table p-0">
      {flagLoading && (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      )}
      {action == "LIST" && (
        <div className="row justify-content-center ">
          {!flagLoading && (
            <div className="col-12 col-md-7 text-center  ">
              <select
                className="form-control"
                name="selectedClient"
                value={selectedClient}
                onChange={handleSelectClientChange}
              >
                <option> Select Client </option>
                {optionsClient}
              </select>
            </div>
          )}
          {flagClientSheetLoading && (
            <div className="col-12 col-md-7 text-center">
              <BeatLoader size={16} color={"blue"} flagClientSheetLoading />
            </div>
          )}
          {!flagClientSheetLoading &&
            selectedClient &&
            clientSheetList.length != 0 && (
              <div className="form-heading column col-12">
                LIST OF clientSheets ({filteredClientSheets.length})
              </div>
            )}
          {!flagClientSheetLoading &&
            selectedClient &&
            clientSheetList.length == 0 && (
              <div className="form-heading column col-12 thick-red-text">
                No ClientSheets
              </div>
            )}
          {selectedClient &&
            clientSheetList.length != 0 &&
            !flagClientSheetLoading && (
              <div className="col-6 my-2 column">
                <input
                  type="search"
                  className="container-fluid form-control"
                  value={searchText}
                  onKeyUp={handleSearchTextBoxKeyUp}
                  onChange={handleSearchTextChange}
                  placeholder="Search clientSheets"
                  id=""
                />
              </div>
            )}
        </div>
      )}
      {/* row ends */}
      {!flagClientSheetLoading && action === "LIST" && (
        <AdminClientSheetList
          clientList={clientList}
          onSelectClientChange={handleSelectClientChange}
          selectedClient={selectedClient}
          onEditClientSheet={handleEditClientSheet}
          onDeleteClientSheet={handleDeleteClientSheet}
          filteredClientSheets={filteredClientSheets}
          searchText={searchText}
          onSearchTextChange={handleSearchTextChange}
          onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
          onHeaderClick={handleHeaderClick}
          onSectionNumberClick={handleSectionNumberClick}
          sectionNumber={sectionNumber}
          currentSize={clientSheetList.length}
          accessLevel={accessLevel}
          adminType={adminType}
        />
      )}
      {!flagClientSheetLoading && (action === "ADD" || action === "UPDATE") && (
        <div>
          <AdminClientSheetAdd
            onFormSubmit={onFormSubmit}
            clientList={clientList}
            clientSheet={clientSheet}
            action={action}
            selectedClient={selectedClient}
            selectedClientId={selectedClientId}
            lastClientSheetNumber={lastClientSheetNumber}
            colorScheme={colorScheme}
            // clientToRetain={clientToRetain}
            // clientIdToRetain={clientIdToRetain}
          />
        </div>
      )}
    </div>
  );
}
export default AdminClientSheet;
