import React, { Component } from "react";
import QuantityChanger from "./quantity-changer";
import { Link } from "react-router-dom";

function CartProduct(props) {
  function handleChangeProductQuantity(by) {
    let { product } = props;
    props.onChangeProductQuantity(by, product);
  }
  function handleSaveForLatter() {
    // Just make quantity of the product 0. So, it should be reduced by
    // its current quantity
    let { product } = props;
    props.onSaveForLatter(-product.quantity, product);
  }
  function handleDeleteFromCart() {
    let { product } = props;
    props.onDeleteFromCart(product);
  }
  let { product } = props;
  let { srNo } = props;
  let { productQty } = props;
  let unitDisplay;
  let { colorScheme } = props;
  let { languageChoice } = props;
  let { dummyPrice } = props;
  let productRate;
  if (dummyPrice) {
    productRate = <span className=""> 1 </span>;
  } else if (product.mrp == product.finalPrice) {
    productRate = <span className=""> {product.mrp}</span>;
  } else if (product.mrp != product.finalPrice) {
    productRate = (
      <span>
        <span className="striked-text"> {product.mrp} </span>
        <span className=" ">&nbsp;{product.finalPrice}</span>
      </span>
    );
  }
  let classList = "row column mb-2 justify-content-center align-items-center border-my"+colorScheme;
  // if (shopTypeId == 2) {
  //   // vegetable shop
  //   classList += " border-mygreen";
  // } else if (shopTypeId == 5) {
  //   // grocery shop
  //   classList += " border-mycyan";
  // } else if (shopTypeId == 6) {
  //   // dryfruit store
  //   classList += " border-mybrown";
  // } else if (shopTypeId == 8) {
  //   // restaurant
  //   classList += " border-mypurple";
  // }
  let subTotal = product.finalPrice * product.quantity;
  subTotal = subTotal.toFixed(2);
  if (product.unitId == 1) {
    // unit is numbers
    unitDisplay = product.quantity;
  } else if (product.unitId == 2 || product.unitId == 4) {
    // unit is gms
    unitDisplay = product.quantity * 1000;
    if (unitDisplay >= 1000) {
      unitDisplay /= 1000;
      unitDisplay = unitDisplay + " kg";
    } else {
      unitDisplay = unitDisplay + " gms";
    }
  } //else if product.unitId == 1
  else if (product.unitId == 3 || product.unitId == 5) {
    // unit is kg
    unitDisplay = product.quantity * 1 + " kg";
  }
  return (
    <React.Fragment>
      {/* a row starts.. */}
      {/* First row contains sr.no.+name+weight+price+finalprice+total */}
      <div className="row justify-content-center align-items-center div-cart-product">
        <div className="col-12 col-md-3 text-thick product-name column pl-2">
          <span>
            {srNo}. {product.name}
          </span>
          &nbsp;&nbsp;&nbsp;
        </div>
        <div className="col-12 col-md-3 text-thick product-name column pl-2">
          <span>
            {languageChoice && product.nameMarathi ? (
              <span className="bigger-text text-thick">
                ({product.nameMarathi})
              </span>
            ) : null}
          </span>
          {product.information && (
            <span className="product-information ">
              {" "}
              ({product.information})
            </span>
          )}
        </div>
        <div className="col-12 col-md-6 mr-auto ">
          <i className="fas fa-rupee-sign"></i>
          {productRate} (Qty:{" " + unitDisplay}) ={" "}
          {!dummyPrice && (
            <span>
              <i className="fas fa-rupee-sign"> </i>
              {subTotal}
            </span>
          )}
        </div>
        <div className="col-4 col-md-2 text-left p-0  mb-2 pr-2"></div>
      </div>
      {/* first row ends */}
      {/* Second row contains delete, save for later and quantity-change  */}

      <div className={classList}>
        <div className="column col-6 col-md-2 text-center bigger-text p-0">
          <Link to="#" onClick={handleDeleteFromCart}>
            {/* Delete */}
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Link>
        </div>
        
        <div className="column col-6 col-md-3 mb-2 pr-2 ">
          <QuantityChanger
            productQty={productQty}
            unitId={product.unitId}
            productId={product.productId}
            onChangeProductQuantity={handleChangeProductQuantity}
            title={"Quantity "}
            shortTitle={"Qty"}
            action={"CART"}
            colorScheme={colorScheme}
          />
        </div>
      </div>
      {/* second row ends */}
    </React.Fragment>
  );
}
export default CartProduct;
