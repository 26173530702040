import React, { Component, useEffect, useState } from "react";
import RepeatOrderProduct from "./repeat-order-product";
function RepeatOrder(props) {
  let [repeatOrderItems, setRepeatOrderItems] = useState([]);
  let {colorScheme}=props;
  function handleRepeatOrderClick() {
    // Means items from shopUrl_1 should be copied to shopUrl
    props.onRepeatOrderClick();
  }
  function handleNoRepeatOrderClick() {
    // Means items from shopUrl_1 should be copied to shopUrl
    props.onNoRepeatOrderClick();
  }
  function getInfo(product) {
    let { prdb } = props;
    let p = prdb.searchByProductId(product.productId);

    if (p == null) {
      // no such product, because its rating is 1 or deleted
      return "Not Available";
    }
    if (!p.instock) {
      return "Out-of-stock";
    }
    return "";
  }
  useEffect(() => {
    //Runs only on the first render
    init();
  }, []);
  // componentDidMount = () => {

  function init() {
    let { shopUrl } = props;
    let items = JSON.parse(window.localStorage.getItem(shopUrl + "_1"));
    setRepeatOrderItems(items);
  }
  let { cartItems } = props;
  let { shopTypeId } = props;
  
  let i = 0,
    j = 0;
  // total productes saved for later
  return (
    <React.Fragment>
      <div className="container-fluid container-md container-repeat-order p-0 text-center column m-2">
        {/* row starts */}
        <div className="row justify-content-center column">
          <div className="col-12 mx-auto text-center my-3">
            {cartItems.length == 0
              ? "The current yaadi is empty"
              : "There are " + cartItems.length + " items in current yaadi"}
          </div>
          <div className="col-12 mx-auto text-center my-2">
            <button className={"btn-my"+colorScheme} onClick={handleNoRepeatOrderClick}>
              {cartItems.length == 0
                ? "Continue with empty Yaadi"
                : "Continue with current Yaadi"}
            </button>
          </div>
          <div className="col-12 text-center my-2">OR</div>
        </div>
        <div className={"row justify-content-center  mb-3 border-all-my"+colorScheme}>
          <div className="col-12 text-left">
            <div>
              Items from your{" "}
              <span className="text-thick bigger-text">Last-Ordered-Yaadi</span>{" "}
              are listed here.
            </div>
            If you want, you may add ALL these items in the current yaadi.{" "}
            {cartItems.length != 0
              ? "(Note: The " +
                cartItems.length +
                " items of current yaadi will be removed)"
              : ""}
          </div>

          <div className={"col-12 col-md-4 m-1"}>
            {repeatOrderItems.map(
              (product, index) =>
                product.quantity > 0 && (
                  <RepeatOrderProduct
                    product={product}
                    key={index}
                    srNo={++index}
                    shopTypeId={props.shopTypeId}
                    languageChoice={props.languageChoice}
                    otherInfo={getInfo(product)}
                  />
                )
            )}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="row text-center justify-content-center my-2">
          <div className="col-12 ">
            <button className={"btn-my"+colorScheme}  onClick={handleRepeatOrderClick}>
              Add ALL these items in Yaadi
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default RepeatOrder;
