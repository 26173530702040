import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminMasterListSingle(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");

  function handleEditBrand(brand) {
    props.onEditBrand(brand);
  }
function handleExploreClick()
{
    props.onExploreClick(category);
}
  function handleDeleteBrand(brand) {
    // Now call for delete api
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .delete(
        window.routerPrefix + "/brand/delete/" + shopUrl + "/" + brand.brandId
      )
      .then((res) => {
        setFlagLoad(false);
        if (res.data != 1) {
          // could not delete the brand
          props.onDeleteBrand(brand, 0);
        } else {
          props.onDeleteBrand(brand, 1);
        }
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
  }
  let { category } = props;
  let { index } = props;
  let { colorScheme } = props;
  
  let content = (
    // This is enclosed in container-list-table
    <div className="row p-0 justify-content-center my-2 py-2 border-mygreen">
        <div className="col-4 text-right ">
            {index+1}
        </div>
        <div className="col-4 ">
            {category.name}
        </div>
        <div className="col-4 ">
            <button className={"btn-my"+colorScheme} onClick={handleExploreClick}>Explore</button>
        </div>
        {flagLoad && (
          <div className="col-4 ">
            <BeatLoader size={8} color={"blue"} flagLoad />
          </div>
        )}
    </div>
  );
  if (error) {
    return <div>Error retriving brands </div>;
  } else {
    return content;
  } //else
}
export default AdminMasterListSingle;
