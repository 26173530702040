export class ProductDB {
  constructor(products) {
    this.products = products;
    this.size = this.products.length;
  }
  allProducts() {
    return this.products;
  }
  current() {
    return this.size;
  }
  searchByProductId(id) {
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].productId == id) {
        return this.products[i];
      }
    }
    return null; // not found
  }
  filterByCategory(categoryId) {
    let filteredProducts = [];
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].categoryId == categoryId) {
        filteredProducts.push(this.products[i]);
      }
    }
    return filteredProducts;
  }
  filterByBrand(brandId) {
    let filteredProducts = [];
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].brandId == brandId) {
        filteredProducts.push(this.products[i]);
      }
    }
    return filteredProducts;
  }
  // filterByName(query) {
  //   let filteredProducts = [];
  //   let target;
  //   for (let i = 0; i < this.products.length; i++) {
  //     // searching in name
  //     target = this.products[i].name.toLowerCase();
  //     //  +        this.products[i].information.toLowerCase();
  //     if (target.includes(query.toLowerCase())) {
  //       filteredProducts.push(this.products[i]);
  //     }
  //   } //for
  //   return filteredProducts;
  // }
  filterByName(query) {
    // tried on 15.08.2020
    // first query to be split into number of words.
    let words = query.split(" ");
    let filteredProducts = [];
    let target, flag, i, j;
    for (i = 0; i < this.products.length; i++) {
      // searching in name
      for (j = 0, flag = false; j < words.length; j++) {
        if (
          words[j].length >= 1 &&
          // !this.products[i].name.toLowerCase().startsWith(words[j].toLowerCase())
          // !this.products[i].name.toLowerCase().includes(words[j].toLowerCase())
          !this.products[i].name.toLowerCase().includes(words[j].toLowerCase())
        ) {
          flag = true;
          break;
        }
      } //inner for
      if (flag == false) {
        // all words are present
        filteredProducts.push(this.products[i]);
      }
    } //for
    return filteredProducts;
  }

  filterByNameAndSearchWords(query) {
    let filteredProducts = [];
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredProducts.push(this.products[i]);
      }
    } //for
    for (let i = 0; i < this.products.length; i++) {
      if (!this.products[i].searchWords) {
        continue;
      }
      if (
        this.products[i].searchWords.toLowerCase().includes(query.toLowerCase())
      ) {
        // But this should not be repeated
        let fProducts = filteredProducts.filter(
          (e, index) => e.productId == this.products[i].productId
        );
        if (fProducts.length == 0) {
          filteredProducts.push(this.products[i]);
        }
      } //if
    } //for
    return filteredProducts;
  }
  // following is added in 2023
  filterBySmartWay(query) {
    // first query to be split into number of words.
    if (query == "") {
      return this.products;
    }
    let queryWords = query.split(" ");
    let filteredProducts = [];
    let target, flag, i, j, k;
    //split product name into number of words
    // first query to be split into number of words.
    //1 search only first word of product name
    let productWords, productName; //Words = query.split(" ");
    for (i = 0; i < this.products.length; i++) {
      productName = this.products[i].name;
      productWords = productName.split(" ");
      // how many words are there in product name
      // execute loop these many times
      for (k = 0; k < productWords.length; k++) {
        for (j = 0, flag = false; j < queryWords.length; j++) {
          if (
            queryWords[j].length >= 1 &&
            // !this.products[i].name.toLowerCase().startsWith(words[j].toLowerCase())
            // !this.products[i].name.toLowerCase().includes(words[j].toLowerCase())
            !productWords[k]
              .toLowerCase()
              .startsWith(queryWords[j].toLowerCase())
          ) {
            flag = true;
            break;
          }
        } //inner for...j
        if (flag == false) {
          // words is present
          // but this product should not be repeated
          let t = filteredProducts.filter(
            (e) => e.productId == this.products[i].productId
          );
          if (t.length == 0) filteredProducts.push(this.products[i]);
        }
      } //for...k
    } //for...i
    // display

    // if (filteredProducts.length != 0) return filteredProducts;
    //Otherwise add results from searching for "searched words"
    // procedure is same as above
    // Now add results from searching for "searched words"
    let searchWords;
    for (i = 0; i < this.products.length; i++) {
      if (!this.products[i].searchWords) continue;
      searchWords = this.products[i].searchWords.split(" ");
      for (k = 0; k < searchWords.length; k++) {
        for (j = 0, flag = false; j < queryWords.length; j++) {
          if (
            queryWords[j].length >= 1 &&
            // !this.products[i].name.toLowerCase().startsWith(words[j].toLowerCase())
            // !this.products[i].name.toLowerCase().includes(words[j].toLowerCase())
            !searchWords[k]
              .toLowerCase()
              .startsWith(queryWords[j].toLowerCase())
          ) {
            flag = true;
            break;
          }
        } //inner for...j
        if (flag == false) {
          // all words are present
          // But before adding check, this product should not be repeated
          let fProducts = filteredProducts.filter(
            (e, index) => e.productId == this.products[i].productId
          );

          if (fProducts.length == 0) {
            filteredProducts.push(this.products[i]);
          }
        }
      } //for...k
    } //for...i

    return filteredProducts;
  }
  // following is added 2023
  filterByFirstNameThenSearchWords(query) {
    let filteredProducts = [];
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredProducts.push(this.products[i]);
      }
    } //for
    if (filteredProducts.length != 0) return filteredProducts;
    // Search by name have no results, so try search by search-words
    for (let i = 0; i < this.products.length; i++) {
      if (
        this.products[i].searchWords.toLowerCase().includes(query.toLowerCase())
      ) {
        filteredProducts.push(this.products[i]);
      } //if
    } //for
    return filteredProducts;
  }

  filterBySearchWords(query) {
    let filteredProducts = [];
    for (let i = 0; i < this.products.length; i++) {
      if (!this.products[i].searchWords) {
        continue;
      }
      if (
        this.products[i].searchWords.toLowerCase().includes(query.toLowerCase())
      ) {
        filteredProducts.push(this.products[i]);
      } //if
    } //for
    return filteredProducts;
  }
}
export default ProductDB;
