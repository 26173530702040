import React, { Component } from "react";
import { Link } from "react-router-dom";
class OwnerShopList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
    };
  }
  handleEditShop = (shop) => {
    this.props.onEditShop(shop);
  };
  handleDeleteShop = (shop) => {
    this.props.onDeleteShop(shop);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  render() {
    let { error } = this.state;
    let { filteredShops } = this.props;
    let { searchText } = this.props;
    let { sectionNumber } = this.props;
    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        <div className="form-heading column col-12">LIST OF SHOPS</div>
        {/* row starts */}
        <div className="row smaller-text my-1 justify-content-center align-items-center column">
          <div className="col-3">
            <Link to="#" id="1" onClick={this.handleSectionNumberClick}>
              Type/Limit
            </Link>
          </div>
          <div className="col-3">
            <Link to="#" id="2" onClick={this.handleSectionNumberClick}>
              {" "}
              Lang/Version
            </Link>
          </div>
          <div className="col-3">
            <Link to="#" id="3" onClick={this.handleSectionNumberClick}>
              WApp/Mob
            </Link>
          </div>
          <div className="col-3">
            <Link to="#" id="4" onClick={this.handleSectionNumberClick}>
              Photo/Info
            </Link>
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="row">
          <div className="col-12 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={this.handleSearchTextBoxKeyUp}
              onChange={this.handleSearchTextChange} // This is contolled by parent
              placeholder="Search Products"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row p-0">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link to="#" onClick={this.handleHeaderClick} id="name">
                        Url
                      </Link>
                    </th>
                    {sectionNumber == 1 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="shopTypeId"
                        >
                          Type
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 1 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="productsLimit"
                        >
                          Limit
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 2 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="languageChoice"
                        >
                          language
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 2 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="versionTypeId"
                        >
                          Version
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 3 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="whatsApp"
                        >
                          WhatsApp
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 3 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="mobile"
                        >
                          Mobile
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 4 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="productsPhoto"
                        >
                          Photo
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 4 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="informationPlace"
                        >
                          Info place
                        </Link>
                      </th>
                    )}
                  </tr>
                </thead>

                {filteredShops.map((shop, index) => (
                  <tbody key={shop.shopId}>
                    <tr className="myborder-top">
                      <td>{index + 1}</td>
                      <td>{shop.shopUrl}</td>
                      {sectionNumber == 1 && <td>{shop.shopTypeId}</td>}
                      {sectionNumber == 1 && <td>{shop.productsLimit}</td>}
                      {sectionNumber == 2 && <td>{shop.languageChoice}</td>}
                      {sectionNumber == 2 && <td>{shop.versionTypeId}</td>}
                      {sectionNumber == 3 && <td>{shop.whatsApp}</td>}
                      {sectionNumber == 3 && <td>{shop.mobile}</td>}
                      {sectionNumber == 4 && <td>{shop.productsPhoto}</td>}
                      {sectionNumber == 4 && <td>{shop.informationPlace}</td>}
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <Link to="#" onClick={() => this.handleEditShop(shop)}>
                          {/* Edit */}
                          <i className="fas fa-edit admin-icons"></i>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                          to="#"
                          onClick={() => {
                            if (
                              window.confirm(
                                "You really want to delete " + shop.name + " ?"
                              )
                            )
                              this.handleDeleteShop(shop);
                          }}
                        >
                          {/* Delete */}
                          <i className="far fa-trash-alt admin-icons"></i>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving shops </div>;
    } else {
      return (
        // <div className="container-fluid bg-blue">
        // <div className="row">
        <div className="col-md-12">{content}</div>
        // </div>
        // </div>
      );
    } //else
  }
}
export default OwnerShopList;
