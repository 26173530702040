import React, { Component } from "react";
function ThanksPage(props) {
  function handleStartShoppingClick() {
    props.onStartShoppingClick();
  }
  return (
    <>
      <div className="text-center mt-4">
        <h4>Opening WhatsApp...Wait...</h4>
      </div>

      <div className="text-center pt-1">
        <div className="col-10 mx-auto column p-0">
          <div className="col-8 mx-auto column p-0">
            <img
              src={
                "/" +
                window.routerPrefix +
                "/files/downloadImageFile/meriyaadi_logo.jpg"
              }
              className="img-fluid"
            />
          </div>
          <div className="mt-5">
            Please like and subscribe our
            <h5>
              <a href="https://youtu.be/_s9wneJugNM">You-tube channel</a>
            </h5>
          </div>
          <div className="mt-5">
            Please like our
            <h5>
              <a href="https://www.facebook.com/meriYaadi">Facebook Page</a>
            </h5>
          </div>
        </div>
      </div>
    </>
  );
}
export default ThanksPage;
