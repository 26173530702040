import React, { useState } from "react";
import ProductMasterListSingle from "./product-master-list-single";
function ProductMasterList(props) {
  let { filteredMasterListProducts } = props;
  let { selectionOption } = props;
  let { languageChoice } = props;
  let { colorScheme } = props;
  let { shopUrl } = props;
  let i = 0;
  let content;
  function handleCrudAction(message, action, type) {
    props.onCrudAction(message, action, type);
  }
  content = (
    // This is enclosed in container-list-table
    <>
      {filteredMasterListProducts.map((product, index) => (
        <ProductMasterListSingle
          filteredMasterListProducts={filteredMasterListProducts}
          languageChoice={languageChoice}
          colorScheme={colorScheme}
          shopUrl={shopUrl}
          key={product.productId}
          product={product}
          onCrudAction={handleCrudAction}
        />
      ))}
    </>
  );
  return <div className="">{content}</div>;
}
export default ProductMasterList;
