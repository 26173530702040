import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminShopTypeListSingle from "./owner-shoptype-list-single";

class AdminShopTypeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
    };
  }
  handleEditShopType = (shopType) => {
    this.props.onEditShopType(shopType);
  };
  handleCrudAction = (message, action) => {
    this.props.onCrudAction(message, action);
  };
  handleDuplicateShopType = (shopType, selection) => {
    this.props.onDuplicateShopType(shopType, selection);
  };
  handleDeleteShopType = (shopType, result) => {
    this.props.onDeleteShopType(shopType, result);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  render() {
    let { error } = this.state;
    let { filteredShopTypes } = this.props;
    let { searchText } = this.props;
    let { sectionNumber } = this.props;
    let { languageChoice } = this.props;
    let { shopUrl } = this.props;
    let { shopTypesLimit } = this.props;
    let { currentSize } = this.props;

    let contentNoshopTypes = <div className="text-center">No shopTypes !</div>;
    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        <div className="form-heading column col-12">
          LIST OF ShopTypes ({filteredShopTypes.length})
        </div>
        {/* row starts */}
        <div className="row justify-content-center">
          <div className="col-10 col-md-6 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={this.handleSearchTextBoxKeyUp}
              onChange={this.handleSearchTextChange} // This is contolled by parent
              placeholder="Search ShopTypes"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row p-0 ">
          <div className="col-12 justify-content-center">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link to="#" onClick={this.handleHeaderClick} id="name">
                        Name
                      </Link>
                    </th>
                  </tr>
                </thead>
                {filteredShopTypes.map((shopType, index) => (
                  <tbody key={index}>
                    <AdminShopTypeListSingle
                      shopType={shopType}
                      index={index}
                      sectionNumber={sectionNumber}
                      languageChoice={languageChoice}
                      onDeleteShopType={this.handleDeleteShopType}
                      onDuplicateShopType={this.handleDuplicateShopType}
                      onEditShopType={this.handleEditShopType}
                      onCrudAction={this.handleCrudAction}
                      shopUrl={shopUrl}
                      shopTypesLimit={shopTypesLimit}
                      currentSize={currentSize}
                    />
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving shopTypes </div>;
    } else if (filteredShopTypes.length >= 0) {
      return (
       
        <div className="">{content}</div>
       
      );
    } //else
    else if (filteredShopTypes.length == 0) {
      return contentNoshopTypes;
    } //else
  }
}
export default AdminShopTypeList;
