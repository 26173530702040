import React, { useState } from "react";
import CartProduct from "./cart-product";
import SavedProduct from "./saved-product";
import NavBarYaadi from "./navbar-yaadi";

function ShoppingCartDisplay(props) {
  let [message, setMessage] = useState("");
  function handleShowAllClick() {
    props.onShowAllClick();
  }
  function handleDeleteFromCart(product) {
    props.onDeleteFromCart(product);
    setMessage(product.name + " deleted");
  }
  function handleDeleteAllItems() {
    props.onDeleteAllItems();
    setMessage("All items deleted !");
  }
  function handleDeleteFromSaved(product) {
    props.onDeleteFromCart(product);
    setMessage(product.name + " deleted from 'Saved-For-Later'.");
  }
  function handleSaveForLatter(by, product) {
    props.onChangeProductQuantity(by, product);
    setMessage(product.name + " saved for later.");
  }
  function handleChangeProductQuantity(by, product) {
    props.onChangeProductQuantity(by, product);
    setMessage("");
  }
  function handleSavedtoCart(product) {
    // Product is already in the cart, just its quantity is made 1
    props.onAddToCartItemList(product);
    let { shopTypeId } = props;
    let m;
    if (shopTypeId != 8) {
      m = product.name + " added to Yaadi.";
    } else {
      m = product.name + " added to Cart.";
    }
    setMessage(m);
  }
  function handlePlaceorderClick() {
    let { numberOfCartProducts } = props;
    let { shopTypeId } = props;
    let m;
    if (isBilling) {
      if (numberOfCartProducts == 0) {
        m = " Bill contains nothing";
        setMessage(m);
        return;
      }
      else
      {
        props.onPlaceorderClick();
        return;
      }
      
    }
    if (numberOfCartProducts == 0) {
      if (shopTypeId != 8) {
        m = " Yaadi is empty, cannot place order.";
      } else {
        m = " Cart is empty, cannot place order.";
      }
      setMessage(m);
      return;
    }

    if (userType == "GUEST") {
      m = "Guest is not allowed to place order. Refresh this page";
      setMessage(m);
      return;
    }
    if (
      settings.minimumCriteria == "amount" &&
      totalAmount < settings.minimumAmount
    ) {
      m = "Minimum shopping amount should be " + settings.minimumAmount;
      setMessage(m);
      return;
    } //if
    if (
      settings.minimumCriteria == 1 &&
      cartItems.length < settings.minimumAmount
    ) {
      m = "Minimum " + settings.minimumAmount + " items should be purchased";
      setMessage(m);
      return;
    }
    props.onPlaceorderClick();
  }
  function handleBackClick(event) {
    // event.preventDefault();// Not required
    props.onBackClick();
  }
  let { cartItems } = props;
  let { totalAmount } = props;
  let { settings } = props;
  let { shopDetails } = props;
  let { shopName } = props;
  let { city } = props;
  let { userType } = props;
  // let { dummyPrice } = props;
  // total products in actual cart
  let { numberOfCartProducts } = props;
  let { shopTypeId } = props;
  let { colorScheme } = props;
  let { isBilling } = props;

  let btnClassList = "btn-my" + colorScheme;
  let i = 0,
    j = 0;
  // total productes saved for later
  let numberOfProductsSaved = cartItems.length - numberOfCartProducts;
  return (
    <>
      <NavBarYaadi
        numberOfCartProducts={numberOfCartProducts}
        totalAmount={totalAmount}
        message={message}
        onBackClick={handleBackClick}
        onPlaceorderClick={handlePlaceorderClick}
        shopName={shopName}
        city={city}
        settings={settings}
        shopDetails={shopDetails}
        currentPage={"YAADI"}
        shopTypeId={props.shopTypeId}
        userType={userType}
        colorScheme={colorScheme}
        isBilling={isBilling}
        // dummyPrice={dummyPrice}
      />
      <div className="container-fluid container-md container-yaadi p-0">
        <div className="text-center mb-2 column">
          {numberOfCartProducts > 0 ? (
            <div>
              <div className="text-center text-thick bigger-text p-0 mb-3">
                {shopTypeId != 8 ? "Yaadi" : "Cart"}
              </div>
              <div className="text-center p-1">
                <button
                  // to="#"
                  className={btnClassList}
                  onClick={() => {
                    if (
                      window.confirm(
                        "You really want to delete all the items ?"
                      )
                    )
                      handleDeleteAllItems();
                  }}
                >
                  Delete all items
                </button>
              </div>
            </div>
          ) : (
            <div className="column text-center text-thick bigger-text p-0">
              {shopTypeId != 8 ? "Yaadi" : "Cart"} is empty
            </div>
          )}
        </div>
        <div className="container-fluid container-md column">
          {/* row starts */}
          {cartItems.map(
            (product, index) =>
              product.quantity > 0 && (
                <CartProduct
                  product={product}
                  productQty={product.quantity}
                  onSaveForLatter={handleSaveForLatter}
                  onChangeProductQuantity={handleChangeProductQuantity}
                  onDeleteFromCart={handleDeleteFromCart}
                  key={product.productId}
                  srNo={++i}
                  shopTypeId={props.shopTypeId}
                  languageChoice={props.languageChoice}
                  dummyPrice={props.dummyPrice}
                  colorScheme={colorScheme}
                />
              )
          )}
          {/* row ends */}
        </div>
        {numberOfProductsSaved > 0 && (
          <div className="heading-saved-products" id="saved">
            <div className="text-center mb-2 text-thick bigger-text">
              Saved for Later
            </div>
            <div className="container-fluid container-md column text-mygrey">
              {cartItems.map(
                (product, index) =>
                  product.quantity == 0 && (
                    <SavedProduct
                      product={product}
                      productQty={product.quantity}
                      onSavedtoCart={handleSavedtoCart}
                      onDeleteFromSaved={handleDeleteFromSaved}
                      key={product.productId}
                      srNo={++j}
                      shopTypeId={props.shopTypeId}
                    />
                  )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default ShoppingCartDisplay;
