import React from "react";
import { useState } from "react";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import axios from "axios";

function FinalYaadiHeader(props) {
  let defaultCustomer = {
    name: "",
    label: "Select Customer",
  };
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [salesType, setSalesType] = useState(0);
  let [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  let [customerList, setCustomerList] = useState([]);
  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  let { customer } = props;
  let { isBilling } = props;
  let { shopDetails } = props;
  let { colorScheme } = props;
  let date = props.yaadiDateTime; //new Date();
  function onSelectCustomer(e)
  {
    setSelectedCustomer(e);
    let customer={};
    customer.name=e.name;
    customer.whatsApp=e.whatsApp;
    customer.address=e.address;
    window.localStorage.setItem("customer", JSON.stringify(customer));
  }
  function handleRadioButtonClick(event) {
    if (event.target.value == 1) {
      //get customers from back-end
      getCustomerListFromDatabase();
    } else if (event.target.value == 0) {
      setSelectedCustomer(defaultCustomer);
    }
    setSalesType(event.target.value);
  }
  function getCustomerListFromDatabase() {
    setFlagLoad(true);
    axios
      .get(window.routerPrefix + "/customer/customers/" + shopDetails.shopUrl)
      .then((res) => {
        let customerList = res.data;
        setFlagLoad(false);
        setCustomerList(customerList);
      })
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  }
  let optionsCustomers = customerList.map((customer, index) => ({
    ...customer,
    value: customer.name,
    label: customer.name,
  }));
  if (!isBilling) {
    selectedCustomer = props.customer;
  }
  return (
    <>
      <div className={"row mb-2 p-2 border-my" + shopDetails.colorScheme}>
        <div className="bigger-text col-12 text-danger">{shopDetails.shopUrl}</div>
        {shopDetails.homePageFacility == 1 && (
          <div className="col-12  mb-2">
            {shopDetails.address} {shopDetails.city}-{shopDetails.pinCode}.
          </div>
        )}
        <div className="col-12   mb-2">WhatsApp: {shopDetails.whatsApp}</div>
      </div>
      {/* row ends */}
      {(!isBilling ||
        (isBilling && selectedCustomer.name != "")) && (
          <div className={"row mb-2 p-2 border-my" + shopDetails.colorScheme}>
            <div className="col-12  text-danger">
              {customer.name} &nbsp; ({customer.whatsApp})
            </div>
            <div className="col-12 ">{customer.address}</div>
            <div className="col-12 ">
              Dt.:
              {pad(date.getDate(), 2)}/{pad(date.getMonth() + 1, 2)}/
              {pad(date.getFullYear(), 2)} ({pad(date.getHours(), 2)}:
              {pad(date.getMinutes(), 2)})
            </div>
          </div>
        )}
      {isBilling && (
        <div className="form-group row  align-items-center">
          <div className="col-5 text-right">
            <label>Sale Type:</label>
          </div>
          <div className="col-7 px-0 input-group ">
            <input
              type="radio"
              className="form-control-inline"
              name="salesType"
              value="0"
              onClick={(event) => handleRadioButtonClick(event)}
              onChange={(event) => handleRadioButtonClick(event)}
              checked={salesType == 0}
            />
            &nbsp;Cash Sale&nbsp;
            <input
              type="radio"
              className="form-control-inline"
              name="minimumCriteria"
              value="1"
              onClick={(event) => handleRadioButtonClick(event)}
              onChange={(event) => handleRadioButtonClick(event)}
              checked={salesType == 1}
            />
            &nbsp;Select Customer
          </div>
        </div>
      )}
      {flagLoad && (
        <div className="div text-center">
          <BeatLoader size={16} color={colorScheme} flagLoad />
        </div>
      )}
      {!flagLoad && salesType == 1 && (
        <div className="form-group row  align-items-center myborder">
          <div className="col-5 text-right">
            <label>Select Customer:</label>
          </div>
          <div className="col-7 px-0 input-group text-left">
            <Select
              defaultValue={selectedCustomer}
              onChange={(e)=>{onSelectCustomer(e)}}
              options={optionsCustomers}
              // isDisabled={resultShopTypeSelection.isDone == "Completed"}
            />
          </div>
        </div>
      )}
    </>
  );
}
export default FinalYaadiHeader;
