import React from "react";
import { Link } from "react-router-dom";

function FooterPanel(props) {
  let btnClassList = "";

  let classList =
    "container container-md  fixed-bottom py-3 text-center text-white ";
  if (props.validAdmin) {
    // logged in, so use store's color-scheme
    classList += " bg-my" + props.colorScheme;
    btnClassList += "btn-my" + props.colorScheme;
  } else {
    // Not logged in, so use default color-scheme
    classList += " bg-mycyan";
    btnClassList += "btn-mycyan";
  }

  return (
    <div className={classList}>
      <div className="row">
        <div className="col-10 mx-auto column p-2">
          <div className="col-5 col-md-2 mx-auto p-0">
            <Link to={"/" + props.shopUrl} className={btnClassList}>
              VISIT THE STORE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FooterPanel;
