import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";

function AdminSettingsHomePage(props) {
  let [shop, setShop] = useState({
    shopId: "",
    name: "",
    owner: "",
    address: "",
    city: "",
    pinCode: "",
    state: "",
    // shopUrl: "",
    whatsApp: "",
    aboutUs: "",
    // imageLogo: "",
    status: "",
    // specialBrand: "",
    // specialBrandId: -1,
    // shopTypeId: "",
    // dummyPrice: 0,
    // colorScheme:""
  });
  let [errorShop, setErrorShop] = useState({
    name: {
      message: "",
      mxLen: 40,
      mnLen: 4,
      onlyDigits: false,
      allDigits: false,
      noSymbols: false,
    },
    owner: {
      message: "",
      mxLen: 40,
      mnLen: 6,
      onlyDigits: false,
      allDigits: false,
      noSymbols: false,
    },
    address: {
      message: "",
      mxLen: 80,
      mnLen: 10,
      onlyDigits: false,
      allDigits: false,
      noSymbols: false,
    },
    city: {
      message: "",
      mxLen: 25,
      mnLen: 2,
      onlyDigits: false,
      allDigits: false,
    },
    pinCode: {
      message: "",
      mxLen: 6,
      mnLen: 6,
      onlyDigits: true,
      allDigits: true,
      noSymbols: true,
    },
    state: {
      message: "",
      mxLen: 30,
      mnLen: 2,
      onlyDigits: false,
      allDigits: false,
      noSymbols: true,
    },
    whatsApp: {
      message: "",
      mxLen: 10,
      mnLen: 10,
      onlyDigits: true,
      allDigits: true,
      noSymbols: true,
    },
    aboutUs: {
      message: "",
      mxLen: 800,
      mnLen: 10,
      onlyDigits: false,
      allDigits: false,
      noSymbols: false,
    },
  });
  let emptyShop;
  let [flagLoad, setFlagLoad] = useState(false);
  let [formUpdateStatus, setFormUpdateStatus] = useState("no");
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let [imagePreview, setImagePreview] = useState("");
  let [file, setFile] = useState("");
  let [fileName, setFileName] = useState("");
  let [error, setError] = useState("");
  let [brandList, setBrandList] = useState([]);
  let [tandc, setTandc] = useState("");
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      setShop({ ...shop, [name]: event.target.value });
      return;
    }
    if (inputType == "file") {
      let file = event.target.files[0];
      if (!file) {
        return;
      }
      let fileType = file.type.substring(0, file.type.indexOf("/"));
      let errShop = { ...errorShop };
      let fileName = file.name;

      if (errorShop[name]["imageType"] != fileType) {
        errorShop[`${name}`].message = "Check type of file";
        setErrorShop(errShop);
      } else {
        errorShop[`${name}`].message = "";
        setErrorShop(errShop);
        setShop({ ...shop, [name]: event.target.value });
        let imagePreview = URL.createObjectURL(file);
        setImagePreview(imagePreview);
        setFile(file);
        setFileName(fileName);
      }
      return;
    }
    setShop({ ...shop, [name]: event.target.value });
    isValid(event);
  }
  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;

    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorShop[`${name}`].mnLen;
      mxLen = errorShop[`${name}`].mxLen;
      onlyDigits = errorShop[`${name}`].onlyDigits;
      allDigits = errorShop[`${name}`].allDigits;
      noSymbols = errorShop[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      // else if (!allDigits) {
      //   for (i = value.length - 1, flag = false; i >= 0; i--) {
      //     const d = value.charCodeAt(i);
      //     if (d < 48 || d > 57) {
      //       message = "";
      //       flag = true;
      //       break;
      //     } //if
      //   } //for
      //   if (flag == false) {
      //     message = "Only digits are not allowed, use some alphabets also";
      //   }
      // } //else if... allDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }

    // errorShop[`${name}`].message = message;
    let errShop = { ...errorShop };
    errorShop[`${name}`].message = message;
    setErrorShop(errShop);
    checkAllErrors();
  }
  function handleFocus(event) {
    let name = event.target.name;
    let errShop = { ...errorShop };
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorShop) {
      if (errorShop[field].message !== "") {
        // error is there
        // flagFormInvalid = true;
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleSelectBrandChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedBrandId = optionElement.getAttribute("id");
    let specialBrand = event.target.value.trim();
    let specialBrandId = selectedBrandId;
    setShop({
      ...shop,
      specialBrand: specialBrand,
      specialBrandId: specialBrandId,
    });
  }
  function getShopFromDatabase() {
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .all([
        axios.get(window.routerPrefix + "/shop/byShopUrl/" + shopUrl),
        axios.get(window.routerPrefix + "/admin/adminByShopUrl/" + shopUrl),
      ])
      .then(
        axios.spread((res, res1) => {
          if (!res) {
            setFlagLoad(false);
            return;
          }
          let ad = res1.data;
          let sh = res.data;
          if (sh.status == "not ready") {
            // first time, doing settings
            emptyShop.shopId = props.shopDetails.shopId; // form should contain empty fields
            emptyShop.shopUrl = shopUrl;
            emptyShop.shopTypeId = props.shopDetails.shopId;
            emptyShop.colorScheme = props.shopDetails.colorScheme; // IMP
            emptyShop.status = props.shopDetails.status;
            setFlagFormInvalid(true); // disable form submit button, as form is empty
            sh = emptyShop;
          } else {
            sh = res.data;
          }
          if (sh.brandwiseDownload) {
            // get brands of this particular shop/store
            axios
              // .get("/brands/" + shopUrl)
              .get(window.routerPrefix + "/brand/brands/" + shopUrl)
              .then((res) => {
                let brandList = res.data;
                setBrandList(brandList);
                setFlagLoad(false);
              })
              .catch((err) => {
                console.log(err);
                setError(err);
                setFlagLoad(false);
              });
          }
          setShop(sh);
          setFlagLoad(false);
        })
      )
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        setError(err);
      });
  }
  useEffect(() => {
    emptyShop = shop;
    getShopFromDatabase();
  }, []);
  function handleSubmit(event) {
    event.preventDefault();
    window.scroll(0, 0);
    setFlagLoad(true);
    setFormUpdateStatus("yes");
    // logo file should be <shopUrl>_logo.<extension>
    let extension = fileName.substring(fileName.indexOf("."), fileName.length);
    shop.imageLogo = shop.shopUrl + "_logo" + extension;
    //EDIT / PUT
    axios
      .put(window.routerPrefix + "/shop/updateHomePageSettings/", shop)
      .then((res) => {
        setFlagLoad(false);
        setFormUpdateStatus("tandcRemained"); //done
        if (shopDetails.status == "not ready") {
          // for is updated now show the t&c screen
          // get t&c from backend
          setFlagLoad(true);
          axios
            .get(window.routerPrefix + "/files/downloadTandcFile")
            .then((res) => {
              setTandc(res.data);
              setFlagLoad(false);
            })
            .catch((err) => {
              console.log(err);
              setFlagLoad(false);
            });
        } else {
          let message = "Home page settings updated successfully.";
          props.onCrudAction(message, "", "");
        }
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        let message = "Error... Could not update Home Page Settings";
        props.onCrudAction(message, "", "");
      });
  }

  function fileChangedHandler(event) {
    let file = event.target.files[0];
    if (!file) {
      this.setState({ message: "" });
      return;
    }
    let fileType = file.type.substring(0, file.type.indexOf("/"));
    if (fileType != "image") {
      this.setState({ message: "Invalid file..." });
    }
    //  else if (file.size > 5000) {
    //   this.setState({ message: "File-size should be below 5kb" });
    // }
    else {
      this.setState({ message: "" });
    }
    let previewImage = URL.createObjectURL(file);
    this.setState({ file: file });
    this.setState({ previewImage: previewImage });
  }
  function handleFormCancelClick() {
    props.onCancelFormClick();
  }
  let { action } = props;
  let { shopDetails } = props;
  let { colorScheme } = props;
  let optionsBrand;
  if (shopDetails.brandwiseDownload) {
    optionsBrand = brandList.map((brand, index) => (
      <option value={brand.name} key={index} id={brand.brandId}>
        {brand.name}
      </option>
    ));
  }
  if (shopDetails.status == "not ready" && formUpdateStatus == "yes") {
    return (
      <div className="container-fluid container-md container-list-table">
        <div className="form-heading">
          Please Wait... the settings are getting updated
        </div>
        {flagLoad && (
          <div className="text-center">
            <BeatLoader size={16} color={colorScheme} flagLoad />
          </div>
        )}
      </div>
    );
  } else if (shopDetails.status == "not ready" && formUpdateStatus == "done") {
    return (
      <div className="container-fluid container-md container-list-table">
        <div className="form-heading">
          Congratulations! Your store is activated!!
        </div>
        <div className="form-heading mb-5">
          You may <Link to={"/" + shopDetails.shopUrl}>visit</Link> your store
        </div>
      </div>
    );
  }
  function handleTandcCancelClick() {
    setFormUpdateStatus("no");
  }
  function handleTandcSubmit(event) {
    // here we will make status="ready" for this particular shop/store
    event.preventDefault();
    setFlagLoad(true);
    axios
      .put(window.routerPrefix + "/shop/updateTandcAgree/", shop)
      .then((res) => {
        setFlagLoad(false);
        setFormUpdateStatus("done"); //done
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
        let message = "Error... Try again";
      });
  }
  return (
    <>
      <div className="container-fluid container-md container-list-table">
        {shopDetails.status == "not ready" &&
          formUpdateStatus != "tandcRemained" && (
            <div className="text-center text-thick">
              Activate your online-store now.
            </div>
          )}
        {shopDetails.status == "not ready" &&
          formUpdateStatus != "tandcRemained" && (
            <div className="text-center mb-2">
              Provide following details for activation of the online-store.
            </div>
          )}

        {shopDetails.status != "not ready" && (
          <div className="form-heading col-12">Home Page Settings</div>
        )}
        {formUpdateStatus == "tandcRemained" && !flagLoad && (
          <div className="form-heading col-12">
            Read the Terms and Conditions
          </div>
        )}
        {formUpdateStatus == "tandcRemained" && flagLoad && (
          <div className="form-heading col-12">Please wait....</div>
        )}
        {formUpdateStatus != "tandcRemained" && (
          <form
            className={"text-thick p-4 bg-my" + colorScheme}
            onSubmit={handleSubmit}
          >
            {/* row starts */}
            {shopDetails.homePageFacility == 1 && (
              <div className="form-group row align-items-center">
                <div className="col-5 text-right">
                  <label>Shop Name</label>
                </div>
                <div className="col-7  px-0">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={shop.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Shop Name"
                    required
                  />
                </div>
                <div className="offset-5">
                  {errorShop.name.message ? (
                    <span className="error-text">{errorShop.name.message}</span>
                  ) : null}
                </div>
              </div>
            )}
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row  align-items-center">
              <div className="col-5 text-right">
                <label>Owner Name</label>
              </div>
              <div className="col-7  px-0">
                <input
                  type="text"
                  className="form-control"
                  name="owner"
                  value={shop.owner}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Store-owner's name"
                  required
                />
              </div>
              <div className="offset-5">
                {errorShop.owner.message ? (
                  <span className="error-text">{errorShop.owner.message}</span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            {shopDetails.homePageFacility == 1 && (
              <div className="form-group row column align-items-center">
                <div className="col-5 text-right">
                  <label>Address</label>
                </div>
                <div className="col-7 px-0">
                  <textarea
                    type="text"
                    className="form-control"
                    rows="3"
                    cols="50"
                    name="address"
                    value={shop.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Enter Detailed Address"
                    required
                  />
                </div>
                <div className="offset-5">
                  {errorShop.address.message ? (
                    <span className="error-text">
                      {errorShop.address.message}
                    </span>
                  ) : null}
                </div>
              </div>
            )}
            {/* row ends */}
            {/* row starts */}
            {shopDetails.homePageFacility == 1 && (
              <div className="form-group row column align-items-center">
                <div className="col-5 text-right">
                  <label>City</label>
                </div>
                <div className="col-7 px-0 input-group ">
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    value={shop.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Enter city name"
                    required
                  />
                </div>
                <div className="offset-5 column">
                  {errorShop.city.message ? (
                    <span className="error-text">{errorShop.city.message}</span>
                  ) : null}
                </div>
              </div>
            )}
            {/* row ends */}
            {/* row starts */}
            {shopDetails.homePageFacility == 1 && (
              <div className="form-group row column align-items-center">
                <div className="col-5 text-right">
                  <label>Pin Code</label>
                </div>
                <div className="col-7 px-0 input-group ">
                  <input
                    type="text"
                    className="form-control"
                    name="pinCode"
                    value={shop.pinCode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Pincode of the area  (optional)"
                  />
                </div>
                <div className="offset-5 column">
                  {errorShop.pinCode.message ? (
                    <span className="error-text">
                      {errorShop.pinCode.message}
                    </span>
                  ) : null}
                </div>
              </div>
            )}
            {/* row ends */}
            {/* row starts */}
            {shopDetails.homePageFacility == 1 && (
              <div className="form-group row column align-items-center">
                <div className="col-5 text-right">
                  <label>State</label>
                </div>
                <div className="col-7 px-0 input-group ">
                  <input
                    type="text"
                    className="form-control"
                    name="state"
                    value={shop.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Enter State"
                    required
                  />
                </div>
                <div className="offset-5 column">
                  {errorShop.state.message ? (
                    <span className="error-text">
                      {errorShop.state.message}
                    </span>
                  ) : null}
                </div>
              </div>
            )}
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row column align-items-center">
              <div className="col-5 text-right">
                <label>WhatsApp Number</label>
              </div>
              <div className="col-7 px-0 input-group ">
                <input
                  type="text"
                  className="form-control"
                  name="whatsApp"
                  value={shop.whatsApp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Enter whatsApp number"
                  required
                />
              </div>
              <div className="offset-5 column">
                {errorShop.whatsApp.message ? (
                  <span className="error-text">
                    {errorShop.whatsApp.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            {shop.status != "not ready" && shop.homePageFacility == 1 && (
              <div className="form-group row column align-items-center">
                <div className="col-5 text-right">
                  <label>Information about shop/store</label>
                </div>
                <div className="col-7 px-0">
                  <textarea
                    type="text"
                    className="form-control"
                    rows="8"
                    cols="50"
                    name="aboutUs"
                    value={shop.aboutUs}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    placeholder="Enter information about the shop/store, minimum 20 and maximum 800 characters. E.g. When this business is started, what is your speciality, etc. "
                    required
                  />
                </div>
                <div className="offset-5">
                  {errorShop.aboutUs.message ? (
                    <span className="error-text">
                      {errorShop.aboutUs.message}
                    </span>
                  ) : null}
                </div>
              </div>
            )}
            {/* row ends */}

            {/* row starts */}
            {!flagLoad && (
              <div className="form-group row offset-5 text-center mb-0">
                <button
                  className={"btn-my" + colorScheme + "-dark"}
                  type="submit"
                  disabled={flagFormInvalid}
                >
                  {shopDetails.status == "not ready" &&
                  formUpdateStatus != "tandcRemained"
                    ? "Activate the store"
                    : action}
                </button>
                &nbsp; &nbsp;
                {shopDetails.status != "not ready" && (
                  <button
                    className={"btn-my" + colorScheme + "-dark"}
                    onClick={handleFormCancelClick}
                    type="cancel"
                  >
                    Cancel
                  </button>
                )}
              </div>
            )}
            {flagLoad && (
              <div className="text-center">
                <BeatLoader size={16} color={colorScheme} flagLoading />
              </div>
            )}
            <input type="hidden" name="shopId" value={shop.shopId} />
          </form>
        )}
        {!flagLoad &&
          shopDetails.status == "not ready" &&
          formUpdateStatus == "tandcRemained" && (
            <div className="form-heading">
              <form
                className={"text-thick p-4 bg-my" + colorScheme}
                onSubmit={handleTandcSubmit}
              >
                {/* row starts */}
                <div className="form-group row column align-items-center">
                  <div className="col-12 text-right">
                    <label>Terms and Conditions</label>
                  </div>
                  <div className="col-12 px-0">
                    <textarea
                      type="text"
                      className="form-control"
                      rows="20"
                      cols="50"
                      name="tandc"
                      value={tandc}
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group row offset-5 text-center mb-0">
                  <button
                    className={"btn-my" + colorScheme + "-dark"}
                    type="submit"
                  >
                    Agree
                  </button>
                  &nbsp; &nbsp;
                  <button
                    className={"btn-my" + colorScheme + "-dark"}
                    onClick={handleTandcCancelClick}
                    type="cancel"
                  >
                    Not Agree
                  </button>
                </div>
              </form>
            </div>
          )}
        {flagLoad &&
          shopDetails.status == "not ready" &&
          formUpdateStatus == "tandcRemained" && (
            <div className="text-center">
              <BeatLoader size={16} color={colorScheme} flagLoad />
            </div>
          )}
      </div>
      
    </>
  );
}
export default AdminSettingsHomePage;
