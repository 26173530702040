import React, { Component } from "react";
import { Link } from "react-router-dom";
class SavedProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleDeleteFromSaved = () => {
    let { product } = this.props;
    this.props.onDeleteFromSaved(product);
  };
  handleSavedtoCart = (event) => {
    // saved-for-latter to cart
    let { product } = this.props;
    // just make its quantity =1
    if (product.unitId == 1) {
      // measurement in numbers
      product.quantity = 1;
    } else if (product.unitId == 2) {
      // measurement in 250 gms
      product.quantity = 0.25;
    } else if (product.unitId == 3) {
      // measurement in kg
      product.quantity = 1;
    } else if (product.unitId == 4) {
      // measurement in 500 gms
      product.quantity = 0.5;
    } else if (product.unitId == 5) {
      // measurement in 10 kg and above
      product.quantity = 10;
    } 

    this.props.onSavedtoCart(product);
  };
  render() {
    let { product } = this.props;
    let { srNo } = this.props;
    let {shopTypeId}=this.props;
    return (
      <React.Fragment>
        <div
          // a row starts..
          className="row column mb-2 pt-1 justify-content-center border-bottom"
          key={product.productId}
        >
          <div className="col-7 col-md-2 column p-0">
            <span>
              {srNo}. {product.name} &nbsp;&nbsp;
            </span>
            {product.information && (
              <span className=""> ({product.information})</span>
            )}
            <span className="">
              <i className="fas fa-rupee-sign"></i>
              {product.mrp - product.finalPrice == 0 ? (
                <span className="text-thick"> {product.mrp} </span>
              ) : (
                <span className="striked-text"> {product.mrp} </span>
              )}
              {product.mrp - product.finalPrice != 0 && (
                <span>&nbsp;{product.finalPrice}</span>
              )}
            </span>
          </div>
          <div className="column col-2 col-md-2 p-0 text-center bigger-text">
            <Link to="#" onClick={this.handleDeleteFromSaved}>
              {/* Delete */}
              <i className="fa fa-trash" aria-hidden="true"></i>
            </Link>
          </div>
          <div className="column col-3 col-md-2 p-0 text-center">
            <Link
              to="#"
              className="text-myblack"
              onClick={this.handleSavedtoCart}
              id={product.productId}
            >
              Add to {shopTypeId!=8?"Yaadi":"Cart"}
            </Link>
          </div>
          <div className="col-md-4">&nbsp; </div>
        </div>
        {/* row ends  */}
        {/* row starts */}

        {/* first row ends */}
      </React.Fragment>
    );
  }
}
export default SavedProduct;
