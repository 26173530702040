import React, { Component } from 'react';
class OrderSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <p>Order Success</p>
         );
    }
}
 
export default OrderSuccess;