import React, { useEffect, useState } from "react";
import fieldValidate from "./Formvalidations";
import DatePicker from "react-datepicker";
function AdminClientSheetAdd(props) {
  let emptyClientSheet;
  let [clientSheet, setClientSheet] = useState({
    clientSheetId: "",
    dietNumber: "",
    clientWeight: "",
    client: "",
    clientId: -1,
    clientSheetNumber: props.lastClientSheetNumber + 1,
    dateOfDelivery: new Date(),
  });
  let [errorClientSheet, setErrorClientSheet] = useState({
    name: { message: "", mxLen: 80, mnLen: 1, onlyDigits: false },
    dietNumber: { message: "", mxLen: 3, mnLen: 1 },
    clientWeight: { message: "", mxLen: 6, mnLen: 2 },
    client: { message: "" },
    clientId: { message: "" },
    dateOfDelivery: { message: "" },
  });
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
    init();
  }, []);
  function init() {
    emptyClientSheet = clientSheet;
    let { action } = props;
    if (action === "ADD") {
      setFlagFormInvalid(true);
      emptyClientSheet.client = props.selectedClient;
      emptyClientSheet.clientId = props.selectedClientId;
      emptyClientSheet.lastClientSheetNumber = props.lastClientSheetNumber + 1;
      setClientSheet(emptyClientSheet);
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      setFlagFormInvalid(false);
      setClientSheet(props.clientSheet);
    }
  }
  function handleTextFieldChange(event) {
    let name = event.target.name;
    setClientSheet({ ...clientSheet, [name]: event.target.value });
    // isValid(event);
    let message = fieldValidate(event, errorClientSheet);
    let errClientSheet = { ...errorClientSheet };
    errorClientSheet[`${name}`].message = message;
    setErrorClientSheet(errClientSheet);
  }
  function handleChange(event) {
    let name = event.target.name;
    setClientSheet({ ...clientSheet, [name]: event.target.value });
    // isValid(event);
    let message = fieldValidate(event, errorClientSheet);
    let errClientSheet = { ...errorClientSheet };
    errorClientSheet[`${name}`].message = message;
    setErrorClientSheet(errClientSheet);
  }
  function handleBlur(event) {
    let name = event.target.name;
    let message = fieldValidate(event, errorClientSheet);
    let errClientSheet = { ...errorClientSheet };
    errorClientSheet[`${name}`].message = message;
    setErrorClientSheet(errClientSheet);
  }
  function handleFocus(event) {
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorClientSheet) {
      if (errorClientSheet[field].message !== "") {
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleSelectClientChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedClientId = optionElement.getAttribute("id");
    let client = event.target.value;
    let clientId = selectedClientId;
    setClientSheet({ ...clientSheet, client: client, clientId: clientId });
  }
  function handleDateOfDeliverySelection(value) {
    setClientSheet({ ...clientSheet, isReady: value });
  }
  function handleSubmit(event) {
    event.preventDefault();
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onFormSubmit(clientSheet);
  }
  function handleDateOfDeliveryClick(date) {
    setClientSheet({ ...clientSheet, dateOfDelivery: date });
    
  }
  let { clientList } = props;
  let { selectedClient } = props;
  let { action } = props;
  let { colorScheme } = props;
  let optionsClient = clientList.map((client, index) => (
    <option value={client.name} key={index} id={client.clientId}>
      {client.name}
    </option>
  ));
  let heading;
  if (action === "ADD") {
    heading = "Add a ClientSheet (" + selectedClient + ")";
  } else if (action === "UPDATE") {
    heading = "Update the ClientSheet";
  }
  return (
    <>
      <div className="form-heading column col-12">{heading}</div>
      <form
        className={"text-thick p-4 bg-my" + colorScheme}
        onSubmit={handleSubmit}
      >
        {/* row starts */}
        {action === "UPDATE" && (
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Client</label>
            </div>
            <div className="col-7 px-0">
              <select
                className="form-control"
                name="client"
                value={clientSheet.client}
                onChange={handleSelectClientChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              >
                <option> Select Client </option>
                {optionsClient}
              </select>
            </div>
            <div className="offset-5">
              {errorClientSheet.client.message ? (
                <span className="error-text">
                  {errorClientSheet.client.message}
                </span>
              ) : null}
            </div>
          </div>
        )}
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Diet Number</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="dietNumber"
              value={clientSheet.dietNumber}
              onChange={handleTextFieldChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Diet Number"
              required
            />
          </div>
          <div className="offset-5">
            {errorClientSheet.dietNumber.message ? (
              <span className="error-text">
                {errorClientSheet.dietNumber.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Client Weight</label>
          </div>
          <div className="col-7 column px-0">
            <input
              type="text"
              className="form-control"
              name="clientWeight"
              value={clientSheet.clientWeight}
              onChange={handleTextFieldChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Client Weight"
              required
            />
          </div>
          <div className="offset-5">
            {errorClientSheet.clientWeight.message ? (
              <span className="error-text">
                {errorClientSheet.clientWeight.message}
              </span>
            ) : null}
          </div>
        </div>
        {/* row ends */}
        <div className="form-group row column align-items-center">
          <div className="col-5 text-right">
            <label>Date:</label>
          </div>
          <div className="col-7 column px-0">
            <DatePicker
              selected={clientSheet.dateOfDelivery}
              dateFormat="yyyy/MM/dd"
              // minDate={d.setDate(d.getDate() - 8)}
              onChange={(date) => handleDateOfDeliveryClick(date)}
            />
          </div>
        </div>

        {/* row starts */}
        <div className="form-group row offset-5 text-center mb-0">
          <button
            className="btn-mycyan"
            type="submit"
            disabled={flagFormInvalid}
          >
            {action}
          </button>
        </div>
        <input
          type="hidden"
          name="clientSheetId"
          value={clientSheet.clientSheetId}
        />
      </form>
    </>
  );
}
export default AdminClientSheetAdd;
