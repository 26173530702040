import React from "react";
import { FaStar } from "react-icons/fa";

class AdminDealerAdd extends React.Component {
  emptyDealer = {
    dealerId: "",
    name: "",
    whatsApp: "",
    link: "",
    password:""
  };
  errorDealer = {
    name: { message: "", mxLen: 40, mnLen: 5, onlyDigits: false },
    whatsApp: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    link: { message: "", mxLen: 20, mnLen: 5, onlyDigits: false },
    password: { message: "", mxLen: 10, mnLen: 4, onlyDigits: true },
  };
  constructor(props) {
    super(props);
    let dealer;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      dealer = this.emptyDealer;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      dealer = this.props.dealer;
    }
    this.state = {
      dealer: dealer,
      errorDealer: this.errorDealer,
      flagFormInvalid: flagFormInvalid,
    };
  }

  handleChange = (event) => {
    let { errorDealer } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        dealer: { ...this.state.dealer, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorDealer[`${name}`].mxLen;
    let onlyDigits = errorDealer[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    } //
    errorDealer[`${name}`].message = message;
    this.setState({
      errorDealer: errorDealer,
      dealer: { ...this.state.dealer, [name]: value },
    });
    this.checkAllErrors(errorDealer);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorDealer } = this.state;
    name = event.target.name;
    type = event.target.type;

    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorDealer[`${name}`].mnLen;
      onlyDigits = errorDealer[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    errorDealer[`${name}`].message = message;
    this.setState({ errorDealer: errorDealer });
    this.checkAllErrors(errorDealer);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorDealer = this.state.errorDealer;
    errorDealer[`${name}`].message = "";
    this.setState({ errorDealer: errorDealer });
    this.checkAllErrors(errorDealer);
  };
  checkAllErrors = (errorDealer) => {
    let flagFormInvalid = false;
    for (let field in errorDealer) {
      if (errorDealer[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleRatingClick = (value) => {
    this.setState({
      dealer: { ...this.state.dealer, rating: value },
    });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onFormSubmit(this.state.dealer);
    this.setState({ dealer: this.emptyDealer });
  };
  handleCancelClick = () => {
    this.props.onCancelClick();
  };
  render() {
    let { dealer } = this.state;
    let { errorDealer } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let { shopUrl } = this.props;
    
    let heading;
    if (action === "ADD") {
      heading = "Add a Dealer";
    } else if (action === "UPDATE") {
      heading = "Update the Dealer";
    }
    return (
      <React.Fragment>
        <div className="form-heading  col-12">{heading}</div>
        <form
          className="text-thick bg-mycyan p-4"
          onSubmit={this.handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row  align-items-center">
            <div className="col-5 text-right">
              <label>Name</label>
            </div>
            <div className="col-7  px-0">
              <input
                type="text"
                className="form-control"
                name="name"
                value={dealer.name}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Dealer name"
                required
              />
            </div>
            <div className="offset-5">
              {errorDealer.name.message ? (
                <span className="error-text">{errorDealer.name.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row  align-items-center">
            <div className="col-5 text-right">
              <label>WhatsApp Number</label>
            </div>
            <div className="col-7  px-0">
              <input
                type="text"
                className="form-control"
                name="whatsApp"
                value={dealer.whatsApp}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="WhatsApp Number"
                required
              />
            </div>
            <div className="offset-5">
              {errorDealer.whatsApp.message ? (
                <span className="error-text">
                  {errorDealer.whatsApp.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row  align-items-center">
            <div className="col-5 text-right">
              <label>Link </label>
            </div>
            <div className="col-7  px-0">
              <input
                type="text"
                className="form-control"
                name="link"
                value={dealer.link}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Link of the online store"
                required
              />
            </div>
            <div className="offset-5">
              {errorDealer.link.message ? (
                <span className="error-text">{errorDealer.link.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          <div className="form-group row  align-items-center">
          <div className="offset-5 text-center text-thick">
          .../{shopUrl}_{dealer.link}
          </div>
          </div>
                    {/* row starts */}
                    <div className="form-group row  align-items-center">
            <div className="col-5 text-right">
              <label>Password</label>
            </div>
            <div className="col-7  px-0">
              <input
                type="text"
                className="form-control"
                name="password"
                value={dealer.password}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Password (Only digits)"
                required
              />
            </div>
            <div className="offset-5">
              {errorDealer.password.message ? (
                <span className="error-text">
                  {errorDealer.password.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}

          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan-dark"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              className="btn-mycyan-dark"
              onClick={this.handleCancelClick}
            >
              CANCEL
            </button>

          </div>
          <input type="hidden" name="dealerId" value={dealer.dealerId} />
        </form>
      </React.Fragment>
    );
  }
}
export default AdminDealerAdd;
