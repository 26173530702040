import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function AdminFaq(props) {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  let [questionNumberClicked, setQuestionNumberClicked]=useState(-1);
  let {colorScheme}=props;
  let {questionAnswers}=props;
  let {topicNumber}=props;
  let {topicNumberClicked}=props;
  function handleFaqHeadingClick()
  {
    props.onFaqHeadingClick(topicNumber);
  }
  function handleQuestionClick(index)
  {
     // clicked active question
    if(questionNumberClicked==index)
    {
        setQuestionNumberClicked(-1);    
    }
    else
    {
        setQuestionNumberClicked(index);
    }
    
  }
  return (
    <>
        {/* row-begins */}
        <div className={"col-11 text-left mt-3 p-2 bg-my"+colorScheme} onClick={handleFaqHeadingClick}>
        {questionAnswers.heading}
        </div>
        {topicNumber==topicNumberClicked&&questionAnswers.questionAnswer.map((qa, index)=>
            <div className="col-12 text-left my-2" key={index}>
            <div className="text-left my-2" key={index}>
                <Link to="#" onClick={()=>{handleQuestionClick(index)}}>{qa.question} </Link>
                </div>        
             {questionNumberClicked==index&&<div>{qa.answer} </div>    }
            </div>
        )}
      {/* container-ends */}
    </>
  );
}
export default AdminFaq;
