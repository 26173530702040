import React, { Component } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { CustomerDB } from "../customerDB";
import AdminMakeYaadiCustomerForm from "./admin-make-yaadi-customer-form";
import AdminMakeYaadiCustomerList from "./admin-make-yaadi-customer-list";
class AdminMakeYaadiCustomerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagLoading: false,
      error: null,
      response: {},
      customerSelection: 0,
      flagCustomerSelected: false,
      customerInfo: { name: "Tatya", whatsappNumber: "", address: "" },
    };
  }
  handleCustomerSelection = (value) => {
    // this.getDataFromDatabase(value); // value is whichDate (today/yesterday)
    this.setState({
      customerSelection: value,
    });
  };
  handleCustomerSelectClick = (customer) => {
    // get customer details now
    this.setState({
      customerInfo: customer,
      flagCustomerSelected: true,
    });
  };
  render() {
    let { error } = this.state;
    let { customerSelection } = this.state;
    let { flagCustomerSelected } = this.state;
    let { customerInfo } = this.state;
    let { flagLoading } = this.state;
    let { shopUrl } = this.props;
    if (flagLoading)
      return (
        <div className="text-center">
          <BeatLoader size={16} color={"blue"} flagLoading />
        </div>
      );
    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        {/* row starts */}
        <div className="row">
          <div className=" col-12">
            <div className="form-heading">Customer Information</div>
          </div>

          <div className="col-12 my-2 text-center ">
            <input
              type="radio"
              className="form-control-inline"
              name="customerSelection"
              value="0"
              onClick={() => this.handleCustomerSelection(0)}
              onChange={() => this.handleCustomerSelection(0)}
              checked={customerSelection === 0}
            />
            &nbsp;Default
            <div className="col-12 my-2">
              <input
                type="radio"
                className="form-control-inline"
                name="customerSelection"
                value="1"
                onClick={() => this.handleCustomerSelection(1)}
                onChange={() => this.handleCustomerSelection(1)}
                checked={customerSelection === 1}
              />
              &nbsp;Enter Details
            </div>
          </div>
        </div>
        {/* row ends */}
        {customerSelection === 1 ? (
          <AdminMakeYaadiCustomerForm
            customerInfo={customerInfo}
            flagCustomerSelected={flagCustomerSelected}
          />
        ) : null}
        {customerSelection === 1 ? (
          <AdminMakeYaadiCustomerList
            shopUrl={shopUrl}
            onCustomerSelectClick={this.handleCustomerSelectClick}
          />
        ) : null}
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving Customers </div>;
    } else {
      return (
        // <div className="container-fluid bg-blue">
        //   <div className="row">
        <div className="col-md-12">{content}</div>
        //   </div>
        // </div>
      );
    }
  }
}
export default AdminMakeYaadiCustomerInfo;
