import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminClientSheetListSingle(props) {
  let [flagLoading, setFlagLoading] = useState(false);
  let [error, setError] = useState("");
  function handleEditClientSheet(clientSheet) {
    props.onEditClientSheet(clientSheet);
  }
  function handleDeleteClientSheet(clientSheet) {
    // Now call for delete api
    setFlagLoading(true);
    axios
      .delete(window.routerPrefix + "/clientSheet/delete/" + clientSheet.clientSheetId)
      .then((res) => {
        setFlagLoading(false);
        if (res.data) {
          props.onDeleteClientSheet(clientSheet);
        } else {
          //Error, something went wrong
        }
      })
      .catch((err) => {
        setFlagLoading(false);
        setError(err);
      });
  }
  let { clientSheet } = props;
  let { accessLevel } = props;
  let { adminType } = props;
  let { index } = props;

  return (
    <div className="row myborder-top justify-content-center mb-2">
      <div className="col-2 col-md-1">{index+1}.</div>
      <div className="col-2 col-md-1">{clientSheet.dietNumber}</div>
      <div className="col-2 col-md-4 ">{clientSheet.clientWeight}</div>
      <div className="col-2 col-md-1">
        {true && (
          <Link to="#" onClick={() => handleEditClientSheet(clientSheet)}>
            <i className="fas fa-edit admin-icons"></i>
          </Link>
        )}
      </div>
      <div className="col-2 col-md-1">
        {!flagLoading && true && (
          <Link
            to="#"
            onClick={() => {
              if (
                window.confirm(
                  "You really want to delete ClientSheet - " + clientSheet.name + " ?"
                )
              )
                handleDeleteClientSheet(clientSheet);
            }}
          >
            <i className="far fa-trash-alt admin-icons"></i>
          </Link>
        )}
        {flagLoading && <BeatLoader size={8} color={"blue"} flagLoading />}
      </div>
    </div>
  );
}
export default AdminClientSheetListSingle;
