import React, { useEffect, useState } from "react";
import AdminBrandList from "./admin-brand-list";
import AdminBrandAdd from "./admin-brand-add";
import { BeatLoader } from "react-spinners";
import { BrandDB } from "../BrandDB";
import axios from "axios";

function AdminBrand(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [searchText, setSearchText] = useState("");
  let [filteredBrands, setFilteredBrands] = useState([]);
  let [brandList, setBrandList] = useState([]);
  let [branddb, setBranddb] = useState([]);
  let [brand, setBrand] = useState(null);
  let [direction, setDirection] = useState("");
  let [sortedField, setSortedField] = useState("");
  let [shopTypeList, setshopTypeList] = useState([]);
  function getBrandListFromDatabase() {
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .get(window.routerPrefix + "/brand/brands/" + shopUrl)
      .then((res) => {
        let bList = res.data;
        setFlagLoad(false);
        // it may be masterlist, so brandShopType should be available 2023
        if (shopUrl == window.masterList) {
          setFlagLoad(true);
          axios
            .get(window.routerPrefix + "/shopType/shopTypes")
            .then((res) => {
              setFlagLoad(false);
              let stList = res.data;
              setshopTypeList(stList);
              // update category objects
              bList.map((b, index) => {
                for (let i = 0; i < stList.length; i++) {
                  if (b.brandShopTypeId === stList[i].shopTypeId) {
                    bList[index].brandShopType = stList[i].name;
                    break;
                  }
                }
              }); //map
            })
            .catch((err) => {
              setFlagLoad(false);
              setError(err);
            });
        }
        setFilteredBrands(bList);
        setBrandList(bList);
        setBranddb(new BrandDB(bList));
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
  }
  useEffect(() => {
    window.scroll(0, 0);
    getBrandListFromDatabase();
  }, []);

  function handleSearchTextBoxKeyUp(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function handleSearchTextChange(event) {
    let searchText = event.target.value;
    setSearchText(searchText);
    performSearchOperation(searchText);
  }
  function performSearchOperation(searchText) {
    let query = searchText.trim();
    let searchedBrands = [];
    searchedBrands = branddb.filterByName(query);
    setFilteredBrands(searchedBrands);
  }
  function handleCancelClick() {
    props.onCancelClick();
  }
  function onFormSubmit(brand) {
    let message;
    let { action } = props;
    let { shopUrl } = props;
    setFlagLoad(true);
    if (action === "UPDATE") {
      //EDIT / PUT
      axios
        .put(window.routerPrefix + "/brand/update/" + shopUrl, brand)
        .then((res) => {
          setFlagLoad(false);
          if (res.data == 0) {
            // error updating brand
            message = "Error... could not update brand.";
          } else {
            updateBrandList(brand);
            let message =
              "The brand '" + brand.name + "' updated successfully.";
            props.onCrudAction(message, "LIST", "Add a Brand");
          }
        })
        .catch((err) => {
          message = "Error... could not update brand.";
          props.onCrudAction(message, "ADD"); // Add again
          setFlagLoad(false);
          setError(err);
        });
    } else if (action === "ADD") {
      //ADD / POST
      setFlagLoad(true);
      axios
        .post(window.routerPrefix + "/brand/addBrand/" + props.shopUrl, brand, {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        })
        .then((res) => {
          setFlagLoad(false);
          if (res.data == 0) {
            message = "Something went wrong...";
            props.onCrudAction(message, "ADD"); // Add again
          } else {
            brand.brandId = res.data; // id of the entity is returned
            addToBrandList(brand);
            message = "Brand " + brand.name + " added successfully.";
            props.onCrudAction(message, "ADD"); // Add again
          }
        })
        .catch((err) => {
          message = "Error... could not add brand.";
          props.onCrudAction(message, "ADD"); // Add again
          setFlagLoad(false);
          setError(err);
        });
    } //else
  }
  function addToBrandList(brand) {
    let bList = [...brandList];
    bList.unshift(brand);
    let fBrands = [...filteredBrands];
    fBrands.unshift(brand);
    setFilteredBrands(fBrands);
    setBrandList(bList);
  }
  function updateBrandList(brand) {
    let bList = [...brandList];
    for (let i = 0; i < bList.length; i++) {
      if (bList[i].brandId == brand.brandId) {
        bList.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        bList.unshift(brand);
        break;
      }
    } //for
    // remove from filteredchapters also
    let fBrands = [...filteredBrands];
    for (let i = 0; i < fBrands.length; i++) {
      if (fBrands[i].brandId == brand.brandId) {
        fBrands.splice(i, 1); // remove 1 element from position i
        // and insert at the beginning as we want latest modified at the top
        fBrands.unshift(brand);
        break;
      }
    } //for
    //sorting
    setFilteredBrands(fBrands);
    setBrandList(bList);
  }
  function handleEditBrand(brand) {
    setBrand(brand);
    setSearchText("");
    props.onEditClick();
  }
  function handleDeleteBrand(brand, result) {
    let message;
    if (result == 0) {
      //Error... deleting brand
      message = "Error....could not delete the brand";
      props.onCrudAction(message, "LIST");
      return;
    }
    let bList = [...brandList];
    for (let i = 0; i < bList.length; i++) {
      if (bList[i].brandId == brand.brandId) {
        bList.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    // remove from filteredbrands also
    let fBrands = [...filteredBrands];
    for (let i = 0; i < fBrands.length; i++) {
      if (fBrands[i].brandId == brand.brandId) {
        fBrands.splice(i, 1); // remove 1 element from position i
        break;
      }
    } //for
    if (fBrands.length == 0) {
      // only one entity was searched and deleted, so search text should be removed, and all products should be shown
      setSearchText("");
      setFilteredBrands(bList);
    } else {
      setFilteredBrands(fBrands);
    }
    setBrandList(bList);
    setBranddb(new BrandDB(bList));
    message = brand.name + " deleted successfully";
    props.onCrudAction(message, "LIST");
  }
  function handleHeaderClick(event) {
    let field = event.target.id;
    let d = false;
    if (field === sortedField) {
      // same button clicked twice
      d = !direction;
    } else {
      // different field
      d = false;
    }
    setDirection(d);
    // let { filteredBrands } = state;
    let fBrands = [...filteredBrands];
    if (d == false) {
      //in ascending order
      fBrands = fBrands.sort((a, b) => {
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      });
    } else {
      //in descending order
      fBrands = fBrands.sort((a, b) => {
        if (a[field] < b[field]) {
          return 1;
        }
        if (a[field] > b[field]) {
          return -1;
        }
        return 0;
      });
    }
    setFilteredBrands(fBrands);
    setSortedField(field);
  }
  let { action } = props;
  let { colorScheme } = props;
  let { subject } = props;
  let { shopUrl } = props;
  let { shopDetails } = props;
  let content;
  if (flagLoad)
    content = (
      <div className="text-center">
        <BeatLoader size={16} color={colorScheme} flagLoad />
      </div>
    );
  else if (action === "LIST") {
    // First show list
    content = (
      <AdminBrandList
        onEditBrand={handleEditBrand}
        onDeleteBrand={handleDeleteBrand}
        onSearchTextChange={handleSearchTextChange}
        filteredBrands={filteredBrands}
        shopUrl={shopUrl}
        searchText={searchText}
        subject={subject}
        onSearchTextBoxKeyUp={handleSearchTextBoxKeyUp}
        onHeaderClick={handleHeaderClick}
        currentSize={brandList.length}
        colorScheme={colorScheme}
      />
    );
  } else if (action === "ADD" || action === "UPDATE") {
    // After pressing Add Button, show only form and not the list
    content = (
      <div>
        {/* content={" "} */}
        <AdminBrandAdd
          onFormSubmit={onFormSubmit}
          onCancelClick={handleCancelClick}
          brand={brand}
          action={action}
          colorScheme={colorScheme}
          shopUrl={shopDetails.shopUrl}
          shopTypeId={shopDetails.shopTypeId}
          shopTypeList={shopTypeList}
        />
      </div>
    );
  } else if (action === "DELETE") {
    content = <div>Delete action in Progress.</div>;
  }
  return (
    <div className="container container-md container-list-table">{content}</div>
  );
}
export default AdminBrand;
