import React, { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import FooterAdvt from "./footer-advt";
import { Link } from "react-router-dom";
function CustomerForm(props) {
  let emptyCustomer;

  let [customer, setCustomer] = useState({
    name: "",
    whatsApp: "",
    address: "",
  });
  let [errorCustomer, setErrorCustomer] = useState({
    name: { message: "", mxLen: 30, mnLen: 6, onlyDigits: false },
    whatsApp: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    address: { message: "", mxLen: 80, mnLen: 10, onlyDigits: false },
  });
  let [flagLoad, setFlagLoad] = useState(false);
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);
  let [minimumAmountMessage, setMinimumAmountMessage] = useState("");

  let { shopDetails } = props;
  let { userType } = props;
  let { colorScheme } = props;
  let { settings } = props;
  useEffect(() => {
    emptyCustomer = customer;
    window.scroll(0, 0);
    updateCustomer();
    prepareMinimumAmountMessage();
  }, []);
  function prepareMinimumAmountMessage() {
    let message;
    if (settings.minimumCriteria == "amount") {
      if (settings.deliverCharges) {
        message =
          "Minimum shopping required is Rs." + settings.minimumAmount + ".";
      }
    } else if (settings.minimumCriteria == "none") {
      if (settings.deliverCharges == 0 && settings.deliveryChargesBelow == 0) {
        message = "Shop for any amount. No delivery charges.";
      } else if (
        settings.deliverCharges != 0 &&
        settings.deliveryChargesBelow != 0
      ) {
        message =
          "Delivery charges of Rs. " +
          settings.deliveryCharges +
          " for shopping below " +
          settings.deliveryChargesBelow +
          ".";
      }
    }
    setMinimumAmountMessage(message);
  }

  function updateCustomer() {
    if (props.customer) {
      // Coming from home page, from local storage
      setCustomer(props.customer);
    } else {
      setCustomer(emptyCustomer);
    }
  }
  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      setCustomer({ ...customer, [name]: event.target.value });
      return;
    }
    // let value = event.target.value.trim();
    setCustomer({ ...customer, [name]: event.target.value });
    isValid(event);
  }

  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorCustomer[`${name}`].mnLen;
      mxLen = errorCustomer[`${name}`].mxLen;
      onlyDigits = errorCustomer[`${name}`].onlyDigits;
      allDigits = errorCustomer[`${name}`].allDigits;
      noSymbols = errorCustomer[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }
    // errorCustomer[`${name}`].message = message;
    let errCustomer = { ...errorCustomer };
    errorCustomer[`${name}`].message = message;
    setErrorCustomer(errCustomer);
    checkAllErrors();
  }
  function handleFocus(event) {
    let name = event.target.name;
    let errCustomer = { ...errorCustomer };
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorCustomer) {
      if (errorCustomer[field].message !== "") {
        // error is there
        // flagFormInvalid = true;
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleCustomerFormSubmit(event) {
    event.preventDefault();
    customer.shopUrl = shopDetails.shopUrl;
    onFormSubmit(customer);
  }
  function onFormSubmit(customer) {
    //ADD / POST
    // name: "",
    // whatsApp: "",
    // address: "",
    customer.name = customer.name.trim();
    customer.whatsApp = customer.whatsApp.trim();
    customer.address = customer.address.trim();
    let { shopDetails } = props;
    setFlagLoad(true);
    axios
      .post(window.routerPrefix + "/customerEntry/addCustomerEntry", customer, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        setFlagLoad(false);
        props.onCustomerFormSubmit(customer);
      })
      .catch((err) => {
        console.log("error!");
        setFlagLoad(false);
      });
  }
  function handleBackClick(event) {
    // event.preventDefault();// Not required
    props.onBackClick();
  }
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  function handleProceedAsGuestClick() {
    //ADD / POST
    props.onProceedAsGuestClick();
    return; // Guest counting cancelled 2023
  }
  let classMessage = "text-thick";
  if (flagLoad)
    return (
      <div className="text-center mt-5">
        <BeatLoader size={16} color={colorScheme} flagLoading />
      </div>
    );
  return (
    <>
      <div className="container container-md customer-form-shop-name fixed-top py-2 text-center">
        <div className={"row py-2 bg-my" + colorScheme + "-dark"}>
          <div className="col-12 text-center ">
            <h3 className="text-thick text-white">{shopDetails.shopUrl}</h3>
          </div>
          <h4 className="col-12 text-center text-white">
            WhatsApp: {shopDetails.whatsApp}
          </h4>
          {/* <h4 className="col-12 text-center text-white">
            {shopDetails.city}-{shopDetails.pinCode}. WhatsApp:{" "}
            {shopDetails.whatsApp}
          </h4> */}
        </div>
      </div>
      <div className="container container-md container-add-form">
        <div className="row justify-content-center">
          {settings.firstPageMessage && (
            <div
              className={
                "firstpage-message-text col-12 col-md-7 p-2 mb-5 text-center border-all-my" +
                colorScheme
              }
            >
              {/*Yes..achieved 2023 div-center for div occupying given text  */}
              <div className="div-center">{settings.firstPageMessage}</div>
            </div>
          )}
          {minimumAmountMessage && (
            <div
              className={
                "firstpage-message-text text-center text-thick col-12 col-md-7 mb-5 "
              }
            >
              <div className="div-center text-left">{minimumAmountMessage}</div>
            </div>
          )}

          {userType == "GUEST" && (
            <>
              <div className="col-8 text-center mb-2">
                <Link
                  to="#"
                  className={"btn-my" + colorScheme}
                  onClick={handleProceedAsGuestClick}
                >
                  Proceed as Guest!
                </Link>
              </div>
              <div className="col-8 text-center mb-2">
                (You won't be able to place order)
              </div>
              <div className="text-center col-12 mb-2">OR</div>
            </>
          )}
          {userType == "GUEST" && (
            <div className="col-12 my-2 text-center ">
              Enter your details and Proceed.
            </div>
          )}
        </div>

        <div className="form-customer-entry">
          <form
            className={"text-thick  py-3 border-all-my" + colorScheme}
            onSubmit={handleCustomerFormSubmit}
          >
            <div className="form-group row justify-content-center px-2">
              {/* row starts */}
              <div className="col-8  px-0">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={customer.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Enter Your Full Name"
                  required
                />
              </div>
            </div>
            <div className="form-group row justify-content-center  px-2">
              <div className="col-8  px-0">
                {errorCustomer.name.message ? (
                  <span className="error-text">
                    {errorCustomer.name.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}
            <div className="form-group row  justify-content-center px-2">
              <div className="col-8 px-0">
                <input
                  type="text"
                  className="form-control"
                  name="whatsApp"
                  value={customer.whatsApp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Enter your WhatsApp No."
                />
              </div>
            </div>
            <div className="form-group row  justify-content-center px-2">
              <div className="col-8 px-0">
                {errorCustomer.whatsApp.message ? (
                  <span className="error-text">
                    {errorCustomer.whatsApp.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row starts */}
            <div className="form-group row justify-content-center">
              <div className="col-8 px-0">
                <textarea
                  type="text"
                  className="form-control"
                  rows="3"
                  cols="50"
                  name="address"
                  value={customer.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  placeholder="Enter Your address. Flat No., Building Name"
                />
              </div>
            </div>
            <div className="form-group row  justify-content-center px-2">
              <div className="col-8 px-0">
                {errorCustomer.address.message ? (
                  <span className="error-text">
                    {errorCustomer.address.message}
                  </span>
                ) : null}
              </div>
            </div>
            {/* row ends */}
            {/* row starts */}

            <input
              type="hidden"
              name="customerId"
              value={customer.customerId}
            />

            <div className="form-group row justify-content-center text-center my-1">
              <button
                className={"btn-my" + colorScheme}
                type="submit"
                disabled={flagFormInvalid}
              >
                Proceed For Shopping
              </button>
            </div>
          </form>
        </div>
      </div>
      <FooterAdvt adminAsUser={props.adminAsUser} colorScheme={colorScheme} />
    </>
  );
}
export default CustomerForm; //EnterShopping; //CheckoutForm;
