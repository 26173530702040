import React, { Component } from "react";
import { Link } from "react-router-dom";

class AdminDealerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
    };
  }
  handleEditDealer = (dealer) => {
    this.props.onEditDealer(dealer);
  };
  handleDeleteDealer = (dealer) => {
    this.props.onDeleteDealer(dealer);
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  render() {
    let { error } = this.state;
    let { searchText } = this.props;
    let { sectionNumber } = this.props;
    let { filteredDealers } = this.props;
    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        <div className="form-heading  col-12">
          LIST OF Dealers ({filteredDealers.length})
        </div>
        {/* row starts */}
        <div className="row smaller-text my-1 justify-content-center align-items-center ">
          <div className="col-6 d-md-none text-center p-0">
            <Link to="#" id="1" onClick={this.handleSectionNumberClick}>
              WhatsApp
            </Link>
          </div>
          <div className="col-6 d-md-none text-center p-0">
            <Link to="#" id="2" onClick={this.handleSectionNumberClick}>
              {" "}
              Link
            </Link>
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="row justify-content-center">
          <div className="col-10 col-md-6">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={this.handleSearchTextBoxKeyUp}
              onChange={this.handleSearchTextChange} // This is contolled by parent
              placeholder="Search Dealers"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link to="#" onClick={this.handleHeaderClick} id="name">
                        Dealer
                      </Link>
                    </th>
                    {sectionNumber == 1 && (
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="whatsApp">
                          WhatsApp
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 2 && (
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="link">
                          Link
                        </Link>
                      </th>
                    )}
                    <th></th>
                  </tr>
                </thead>
                {filteredDealers.map((dealer, index) => (
                  <tbody key={index}>
                    <tr className="myborder-top">
                      <td>{index + 1}</td>
                      <td>{dealer.name}</td>
                      {sectionNumber == 1 && <td>{dealer.whatsApp}</td>}
                      {sectionNumber == 2 && <td>{dealer.link}</td>}
                      {/* <td>{dealer.whatsApp}</td> */}
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <Link
                          to="#"
                          onClick={() => this.handleEditDealer(dealer)}
                        >
                          {/* Edit */}
                          <i className="fas fa-edit admin-icons"></i>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                          to="#"
                          onClick={() => {
                            if (
                              window.confirm(
                                "You really want to delete " +
                                  dealer.name +
                                  " dealer ?"
                              )
                            )
                              this.handleDeleteDealer(dealer);
                          }}
                        >
                          {/* Delete */}
                          <i className="far fa-trash-alt admin-icons"></i>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving Dealers </div>;
    } else {
      return (
        // <div className="container-fluid bg-blue">
        //   <div className="row">
        <div className="col-md-12">{content}</div>
        //   </div>
        // </div>
      );
    }
  }
}
export default AdminDealerList;
