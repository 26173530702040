import React from "react";
import { Link } from "react-router-dom";
function DropDownAdminUtilities(props) {
  function handleAdminUtilityClick(event) {
    props.onAdminUtilityClick(event);
  }

  let { adminUtilityList } = props;
  let { title } = props;
  let { shortTitle } = props;
  let { colorScheme } = props;
  return (
    <React.Fragment>
      <div className="nav-item dropdown">
        <Link
          // className={"nav-link dropdown-toggle text-thick my" + colorScheme}
          className="nav-link dropdown-toggle text-thick text-black"
          to="#"
          id="dropdown01"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          // style={{ color: colorScheme }}
        >
          <span className="normal-text">{title}</span>
          <span className="short-text">{shortTitle}</span>
        </Link>
        <div className="dropdown-menu" aria-labelledby="dropdown01">
          {adminUtilityList.map((menu, index) => (
            <Link
              className="dropdown-item"
              to="#"
              key={index}
              id={index}
              onClick={handleAdminUtilityClick}
            >
              {menu.name}
            </Link>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}
export default DropDownAdminUtilities;
