import React, { useState } from "react";
import FormStartStore from "./form-start-store";
import HomePageInfo from "./home-page-info";
function HomePage(props) {
  function handleContactNowClick() {
    let whatsappNumber = "917083307568";
    let whatsAppMessage = "I am interested to start my online store";
    window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`;
  }
  function handleStartNowClick() {
    setView("form-home-page");
  }
  function handleBackClick() {
    setView("home-page-info");
  }
  let [view, setView] = useState("home-page-info");
  
  return (
    <>
      {view == "home-page-info" && (
        <HomePageInfo onStartNowClick={handleStartNowClick} />
      )}
      {view == "form-home-page" && (
        <FormStartStore onBackClick={handleBackClick} />
      )}
    </>
  );
}
export default HomePage;
