import React, { useEffect, useState } from "react";
import FinalYaadiProduct from "./final-yaadi-product";
import FinalYaadiHeader from "./final-yaadi-header";
import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import axios from "axios";
import FooterAdvt from "./footer-advt";

function FinalYaadiAnytime(props) {
  let [flagLoading, setFlagLoading] = useState(true);
  let [colorScheme, setColorScheme] = useState("");
  let [flagInvalidUrl, setFlagInvalidUrl] = useState(false);
  let [flagCancel, setFlagCancel] = useState(false);
  let [cartItems, setCartItems] = useState([]);
  let [customer, setCustomer] = useState({});
  let [totalAmount, setTotalAmount] = useState(0);
  let [yaadiDetails, setYaadiDetails] = useState("");
  let [shopDetails, setShopDetails] = useState("");
  let [settings, setSettings] = useState("");
  let [yaadiDateTime, setYaadiDateTime] = useState(new Date());
  let [adminAsUser, setAdminAsUser] = useState(false);

  let shopUrl = "";
  let yaadiId = "";
  let yaadiUrl = "";

  function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
  useEffect(() => {
    //Runs only on the first render

    init();
  }, []);
  function init() {
    // get shopUrl from the link, it is at the end, preceded by _
    // ................_<shopUrl>
    yaadiUrl = props.location.pathname.substring(11); // /yaadilist/
    let pos = yaadiUrl.indexOf("_");
    shopUrl = yaadiUrl.substring(pos + 1);
    // Get yaadi of this url
    let yaadiDetails;
    setFlagLoading(true);
    // First assign session
    axios
      .get("/" + window.routerPrefix + "/yaadi/welcome")
      .then((res) => {
        setFlagLoading(false);
        getYaadiDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function getYaadiDetails() {
    setFlagLoading(true);
    axios
      .get("/" + window.routerPrefix + "/yaadi/yaadiByUrl/" + yaadiUrl)
      .then((res) => {
        if (!res.data) {
          //No shop
          setFlagLoading(false);
          setFlagInvalidUrl(true);
          return; // don't go ahead
        } else {
          let yDetails = res.data; // only one yaadi
          yaadiId = yDetails.yaadiId;
          let customerInfo = new Object();
          customerInfo.name = yDetails.customerName;
          customerInfo.whatsApp = yDetails.customerWhatsApp;
          customerInfo.address = yDetails.customerAddress;
          setYaadiDateTime(new Date(yDetails.dateOfYaadi));
          setYaadiDetails(yDetails);
          setCustomer(customerInfo);
          getDataFromDatabase();
        } //else
      })
      .catch((err) => {
        console.log(err);
        setFlagLoading(false);
        setFlagInvalidUrl(true);
        // this.setState({ error: err }); // error is set
      });
  }
  function getDataFromDatabase() {
    let shopDetails, settings;
    let cartItems;
    setFlagLoading(true);
    axios
      .all([
        axios.get("/" + window.routerPrefix + "/shop/byShopUrl/" + shopUrl),
        axios.get(
          "/" +
            window.routerPrefix +
            "/yaadiProduct/yaadiProducts/" +
            shopUrl +
            "/" +
            yaadiId
        ),
        axios.get("/" + window.routerPrefix + "/setting/settings/" + shopUrl),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          shopDetails = res1.data;
          cartItems = res2.data;
          settings = res3.data;
          setColorScheme(shopDetails.colorScheme);
          // Also check whether this is admin or user, accordingly show content in the footer
          let adminAsUser = window.localStorage.getItem("admin");
          if (!adminAsUser) {
            setAdminAsUser(false);
          } else if (adminAsUser == shopDetails.shopUrl) {
            setAdminAsUser(true);
          } else {
            setAdminAsUser(false);
          }
          setShopDetails(shopDetails);
          setCartItems(cartItems);
          setSettings(settings);
          setFlagLoading(false);
          // now update title of page
          // document.title = "meriYaadi-" + shopDetails.title;
          calculateTotalAmount(cartItems);
        })
      )
      .catch((err) => {
        console.log(err);
        setFlagLoading(false);
        // this.setState({ error: err }); // error is set
      });
  }
  function calculateTotalAmount(cartItems) {
    let amount;
    let totalAmount = 0;
    for (let i = 0; i < cartItems.length; i++) {
      amount = cartItems[i].finalPrice * cartItems[i].quantity;
      totalAmount += amount;
    } //for
    setTotalAmount(totalAmount);
    // this.setState({ totalAmount: totalAmount });
  }
  function handleCancelOrder() {
    //  Cancel order of this.yaadiId
    setFlagLoading(true);
    axios
      .delete(window.routerPrefix + "/yaadies/" + shopUrl + "/" + yaadiId)
      .then((res) => {
        axios
          .delete(
            window.routerPrefix + "/yaadiProducts/" + shopUrl + "/" + yaadiId
          )
          .then((res) => {
            setFlagLoading(false);
            setFlagCancel(true);
          })
          .catch((err) => {
            console.log(err);
            setFlagLoading(false);
          });
      })
      .catch((err) => {
        setFlagLoading(false);
        console.log(err);
      });
  }
  let i = 0;
  if (flagInvalidUrl)
    return (
      <div>
        <h4 className="text-center mt-5">
          This link is either expired or invalid.
        </h4>
        <div className="text-center">
          <Link to={"/" + shopDetails.shopUrl} className="btn btn-success">
            Start shopping
          </Link>
        </div>
      </div>
    );
  if (flagLoading)
    return (
      <div className="text-center mt-5">
        <BeatLoader size={48} color={props.colorScheme} flagLoading />
      </div>
    );
  let amountToPay = totalAmount + yaadiDetails.deliveryCharges;
  let cancelSuccess = (
    <div className="mt-5 text-center">
      <h4>The yaadi is cancelled.</h4>
      <Link to={"/" + shopUrl} className="btn btn-success">
        Start shopping again
      </Link>
    </div>
  );
  let yaadiView = (
    <div
      className="container-fluid container-md bg-white container-final-yaadi text-center mt-2 justify-content-center"
      id="final-yaadi"
    >
      {/* row starts */}
      <FinalYaadiHeader
        customer={customer}
        shopDetails={shopDetails}
        settings={settings}
        yaadiDateTime={yaadiDateTime}
        isBilling={false}
      />

      {/* row ends */}
      {/* row starts */}
      <div className="text-thick">Yaadi No. {yaadiDetails.yaadiId}</div>
      <div className="row justify-content-center  text-thick text-center mb-2 text-danger">
        <div className="col-5 col-md-4">Description</div>
        <div className="col-3 col-md-2">Qty</div>
        <div className="col-2 col-md-2">Rate</div>
        <div className="col-2 col-md-2">Total</div>
      </div>
      {/* row ends */}
      {/* row starts */}
      {cartItems.map((product, index) =>
        product.quantity > 0.0 ? (
          <FinalYaadiProduct
            product={product}
            key={index}
            srNo={++i}
            languageChoice={shopDetails.languageChoice}
          />
        ) : null
      )}
      {/* row ends */}
      {/* row starts */}
      <div
        className={
          "row justify-content-center pb-3 text-danger border-my" +
          shopDetails.colorScheme
        }
      >
        <div className="col-5 col-md-4 p-0 pr-2">
          Total {cartItems.length} products
        </div>
        <div className="col-7 col-md-6 p-0 pr-2 text-right text-thick ">
          Grand Total = <i className="fas fa-rupee-sign"></i>
          &nbsp;{totalAmount.toFixed(2)}
        </div>
        {yaadiDetails.deliveryCharges > 0 && (
          <div className="offset-5 col-7 offset-md-0 col-md-10 p-0 pr-2 text-right text-thick">
            Delivery Charges = <i className="fas fa-rupee-sign"></i>
            &nbsp; {settings.deliveryCharges.toFixed(2)}
          </div>
        )}
        {yaadiDetails.deliveryCharges > 0 && (
          <div className="col-12 col-md-10 p-0 pr-2  text-right text-thick">
            Net payable amount = <i className="fas fa-rupee-sign"></i>
            &nbsp; {amountToPay.toFixed(2)}
          </div>
        )}
      </div>
      {/* row ends */}
    </div>
  );
  {
    /* container ends */
  }
  return (
    <>
      {!flagCancel ? yaadiView : cancelSuccess}
      <FooterAdvt adminAsUser={adminAsUser} colorScheme={colorScheme} />
    </>
  );
}
export default FinalYaadiAnytime;
