export class DealerDB {
  constructor(dealers) {
    this.dealers = dealers;
  }
  allDealers() {
    return this.dealers;
  }
  searchByDealerId(id) {
    for (let i = 0; i < this.dealers.length; i++) {
      if (this.dealers[i].dealerId == id) {
        return this.dealers[i];
      }
    }
    return null; // not found
  }
  filterByName(query) {
    let filteredDealers = [];
    for (let i = 0; i < this.dealers.length; i++) {
      if (this.dealers[i].name.toLowerCase().includes(query.toLowerCase())) {
        filteredDealers.push(this.dealers[i]);
      }
    } //for
    return filteredDealers;
  }
}
export default DealerDB;
