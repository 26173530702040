import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import SearchOptions from "./search-options";
import SearchList from "./search-list";
import ListCategory from "./list-category";
import ListBrand from "./list-brand";

function NavBar(props) {
  function handleCategoryPanelClose() {
    props.onCategoryPanelClose();
  }
  function handleBrandPanelClose() {
    props.onBrandPanelClose();
  }
  function handleLogoClick(event) {
    event.preventDefault();
    props.onLogoClick();
  }
  function handleCategoryClick(event) {
    props.onCategoryClick(event);
    window.scrollTo(0, 0); // moving scrollbar at the top
  }
  function handleCategoryLinkClick(event) {
    props.onCategoryLinkClick(event);
    window.scrollTo(0, 0); // moving scrollbar at the top
  }
  function handleCartClick() {
    props.onCartClick();
    window.scrollTo(0, 0); // moving scrollbar at the top
  }
  function handleBrandClick(event) {
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onBrandClick(event);
  }
  function handleBrandLinkClick(event) {
    props.onBrandLinkClick(event);
    window.scrollTo(0, 0); // moving scrollbar at the top
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(); //24062020
  }
  function handleSearchTextBlur(event) {
    props.onSearchTextBlur();
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleSearchTextEnter(event) {
    props.onSearchTextEnter(event);
  }
  function handleSearchButtonClick() {
    props.onSearchButtonClick();
  }
  function handleSearchTextFocus(event) {
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onSearchTextFocus(event);
  }
  function handleCancelClick() {
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onCancelClick();
  }
  function handleShowAllClick() {
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onShowAllClick();
  }
  function handleSpecialOffersClick() {
    window.scrollTo(0, 0); // moving scrollbar at the top
    props.onSpecialOffersClick();
  }
  function handleSearchBoxProductClick(productId) {
    props.onSearchBoxProductClick(productId);
  }
  function handleQuickModeSelection(event) {
    props.onQuickModeSelection(!props.flagQuickMode);
    window.scrollTo(0, 0); // moving scrollbar at the top
  }
  function handleAdminPanelClick() {}
  let { numberOfCartProducts } = props;
  let { status } = props;
  let { showItemCount } = props;
  let { categoryList } = props;
  let { specialOffersList } = props;
  let { brandList } = props;
  let { searchText } = props;
  let { searchBy } = props;
  let { flagSearch } = props;
  let { totalAmount } = props;
  let { matchedFlag } = props;
  let { filteredProducts } = props;
  let { selectedCategoryName } = props;
  let { selectedBrandName } = props;
  let { shopUrl } = props;
  let { shopTypeId } = props;
  let { flagQuickMode } = props;
  let { isBilling } = props;
  let heading;
  let { flagCartAdd } = props;
  let { flagCategoryLinkClick } = props;
  let { flagBrandLinkClick } = props;
  let { colorScheme } = props;
  if (searchBy === "DEFAULT") {
    if (showItemCount)
      heading = "Showing all " + filteredProducts.length + " items";
    else heading = "Showing all items";
  } else if (searchBy === "CATEGORY") {
    heading = "Showing -" + selectedCategoryName;
    if (selectedCategoryName.length <= 30) {
      heading += "-" + filteredProducts.length + " items ";
    }
  } else if (searchBy === "BRAND") {
    heading =
      "Showing Brand-" +
      selectedBrandName +
      "-" +
      filteredProducts.length +
      " items ";
  } else if (searchBy === "SPECIAL") {
    heading = "Showing " + specialOffersList.length + " special ";
    if (specialOffersList.length == 1) {
      heading += " offer";
    } else {
      heading += " offers";
    }
  } else if (searchBy === "CLICK") {
    heading = "Showing selected item";
  } else if (searchBy === "TYPING" && matchedFlag === true) {
    heading = "Showing total " + filteredProducts.length + " matched items";
  } else if (searchBy === "TYPING" && matchedFlag === false) {
    heading = "No matched item found. ";
  } else if (filteredProducts.length == 1) {
    heading = heading + " Similar item: (" + filteredProducts.length + ")";
  } else if (filteredProducts.length > 1) {
    heading = heading + " Similar items: (" + filteredProducts.length + ")";
  }
  let classList, quickModeText;
  quickModeText = "col-12 p-0 text-center text-my" + colorScheme;
  classList =
    "container-fluid container-md container-navbar fixed-top bg-my" +
    colorScheme;
  let btnClassList = "btn-my" + colorScheme + "-dark";

  if (status == "not ready") {
    return (
      <div className={classList + " text-center p-3"}>
        <h5>This store is not activated yet.</h5>
        <h5>Login to admin-panel for activation.</h5>
        <div className="mt-3 p-2">
          <Link to="/adminpanel" className={btnClassList}>
            ADMIN PANEL
          </Link>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={classList}>
        {/* First row begins */}
        {!flagSearch && !flagCategoryLinkClick && !flagBrandLinkClick && (
          <div className="row nav-bar-logo-row justify-content-around justify-content-md-center align-items-center bg-white px-2">
            {/* logo starts here*/}
            <div className="col-4 col-md-3  p-0 py-1 text-center ">
              {!isBilling && (
                <img
                  src={
                    status == "ready"
                      ? // ? "/images/" + shopUrl + "_logo.jpg"
                        window.routerPrefix +
                        "/files/downloadLogoImageFile/" +
                        shopUrl
                      : "/images/DemoLogo.jpg"
                  }
                  alt="No logo"
                  className="div-shop-logo"
                  onClick={handleLogoClick}
                />
              )}
              {isBilling && (
                <Link className={"btn-my" + colorScheme} to="/adminpanel">Admin Panel</Link>
              )}
              {/* </div> */}
            </div>
            {/* logo ends here */}
            {/* Advt. Image starts here */}
            <div className="col-3  text-center align-self-end">
              <div className="row justify-content-center smaller-text align-items-end p-0 m-0 ">
                <div className={quickModeText}>Quick Mode</div>
                <div className="col-12 p-1  form-group text-center">
                  <input
                    type="checkbox"
                    // className="form-check-input"
                    checked={flagQuickMode}
                    onChange={handleQuickModeSelection}
                  />
                  &nbsp;&nbsp; {flagQuickMode ? "ON" : "OFF"}
                </div>
              </div>
            </div>
            {/* Advt. Image ends here
              {/* shopping cart starts here */}
            {!flagCartAdd && (
              <div className="col-5 col-md-3 text-center  my-auto p-0">
                <button
                  to="#"
                  onClick={handleCartClick}
                  className={btnClassList}
                >
                  {!isBilling ? "Checkout" : "View Cart"}
                </button>
                {/*actual content (shopping cart) starts*/}
                <div className="p-0">
                  <Link
                    className={"smaller-text my" + colorScheme + "-dark"}
                    onClick={handleCartClick}
                    to="#"
                    //https://stackoverflow.com/questions/52238637/react-router-how-to-pass-data-between-pages-in-react
                    // Data can be sent from one page to another
                    // But large amount of data can not be sent??
                  >
                    <span className="yaadi-content">
                      {numberOfCartProducts}{" "}
                    </span>
                    {/* {!dummyPrice && ( */}
                    <span>
                      {" "}
                      (<i className="fas fa-rupee-sign"></i>
                      <span className="yaadi-content"> {totalAmount})</span>
                    </span>
                    {/* )} */}
                  </Link>
                </div>
              </div>
            )}
            {/* shopping cart ends here */}
            {flagCartAdd && (
              <div className="col-5 col-md-3 text-center column p-0 my-auto ">
                <Link to="#">
                  <img
                    src="/images/meriyaadi.jpg"
                    className="img-fluid"
                    // className="img-responsive"
                  />
                </Link>
              </div>
            )}
          </div>
        )}
        {/* Second row begins */}
        <div className="row column justify-content-center p-1 form">
          {/* search-by-text-starts */}
          {!flagCategoryLinkClick && !flagBrandLinkClick && (
            <div className="column col-9 col-md-4 p-0 ">
              <input
                value={searchText}
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search products"
                aria-label="Search"
                onKeyUp={handleSearchTextBoxKeyUp}
                onChange={handleSearchTextChange} // This is contolled by parent
                onFocus={handleSearchTextFocus}
                onBlur={handleSearchTextBlur}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSearchTextEnter(event);
                  }
                }}
              />
            </div>
          )}

          {flagSearch && !searchText && (
            <div className="col-3 col-md-1 p-0 column">
              <button to="#" className="btn" onClick={handleCancelClick}>
                Cancel <i className="far fa-window-close icon-3x"></i>
              </button>
            </div>
          )}
          {flagSearch && searchText && (
            <div className="col-3 col-md-2 p-0 column ">
              <button
                to="#"
                className="btn btn-block"
                onClick={handleSearchButtonClick}
              >
                Search{" "}
                <span>
                  <i className="fas fa-search size-3x"></i>
                </span>
              </button>
            </div>
          )}
          {searchBy !== "DEFAULT" &&
            !flagSearch &&
            !flagCategoryLinkClick &&
            !flagBrandLinkClick && (
              <div className="col-3 col-md-2 text-center p-0">
                <button
                  className={"btn-my" + colorScheme + "-dark"}
                  onClick={handleShowAllClick}
                >
                  {/* <i className="fas fa-sync-alt"></i> */}
                  Show All
                </button>
              </div>
            )}
          {/* search-by-text-ends */}
        </div>
        {/* Second row ends */}
        {!flagSearch && !flagCategoryLinkClick && !flagBrandLinkClick && (
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 text-center bg-white smaller-text">
              {heading}
            </div>
          </div>
        )}
        {!flagSearch && (
          <div className="row  justify-content-center justify-content-md-center align-items-center">
            <SearchOptions
              categoryList={categoryList}
              brandList={brandList}
              specialOffersList={specialOffersList}
              onCategoryClick={handleCategoryClick}
              onCategoryLinkClick={handleCategoryLinkClick}
              onBrandClick={handleBrandClick}
              onBrandLinkClick={handleBrandLinkClick}
              onSpecialOffersClick={handleSpecialOffersClick}
              flagCategoryLinkClick={flagCategoryLinkClick}
              flagBrandLinkClick={flagBrandLinkClick}
            />
          </div>
        )}
        {flagCategoryLinkClick && !flagBrandLinkClick && (
          <div className="row">
            <div className="col-12 text-center my-2">
              {/* close */}
              <button
                className="btn btn-panel"
                onClick={handleCategoryPanelClose}
              >
                <i className="fas fa-window-close"></i>
              </button>
            </div>
          </div>
        )}
        {flagBrandLinkClick && !flagCategoryLinkClick && (
          <div className="row">
            <div className="col-12 text-center my-2">
              {/* close */}
              <button className="btn" onClick={handleBrandPanelClose}>
                <i className="fas fa-window-close"></i>
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="container-fluid container-md container-search-list">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 bg-white div-search-list">
            {flagSearch && (
              <SearchList // 28.07.2020
                prdb={props.prdb}
                searchText={props.searchText}
                onSearchBoxProductClick={handleSearchBoxProductClick}
              />
            )}
          </div>
        </div>
      </div>
      {flagCategoryLinkClick && !flagBrandLinkClick ? (
        <div className="container-fluid container-md container-category-list">
          <div className="row justify-content-center">
            <ListCategory
              categoryList={categoryList}
              onCategoryClick={handleCategoryClick}
              title={"Search by Categories"}
              shortTitle={"Categories"}
              colorScheme={colorScheme}
            />
            {/* </div> */}
          </div>
        </div>
      ) : null}
      {flagBrandLinkClick && !flagCategoryLinkClick ? (
        <div className="container-fluid container-md container-category-list">
          <div className="row justify-content-center">
            <ListBrand
              brandList={brandList}
              onBrandClick={handleBrandClick}
              title={"Search by Brand"}
              shortTitle={"BRANDS"}
              colorScheme={colorScheme}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
export default NavBar;
