import React, { useState, useEffect } from "react";
import FooterAdvt from "./footer-advt";
import AboutUsImageSlider from "./about-us-image-slider";
import { Link } from "react-router-dom";
import axios from "axios";
import { BeatLoader } from "react-spinners";

function ShopInfo(props) {
  let whatsApp = "";
  let [flagLoading, setFlagLoading] = useState(false);
  function handleStartShoppingClick() {
    props.onStartShoppingClick();
  }
  function handleStoreCreateClick() {}
  function handleContactNowClick() {
    let { whatsAppStore } = props;
    let whatsappNumber = "91" + whatsAppStore;
    let whatsAppMessage = "Hello...";
    window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`;
  }
  useEffect(() => {
    //Runs only on the first render
    init();
  }, []);

  function init() {
    window.scroll(0, 0);
    // check whether about-us images are available
    //aboutUsImages
    let shopUrl = props.shopDetails.shopUrl;
    let { dealer } = props;
    if (!dealer) return;
    setFlagLoading(true);
    // get data of the dealer
    let { shopDetails } = props;
    axios
      .get(
        window.routerPrefix +
          "/dealers/byLink/" +
          shopDetails.shopUrl +
          "/" +
          dealer
      )
      .then((res) => {
        whatsApp = res.data[0].whatsApp;
        setFlagLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoading(false);
      });
  }
  let { shopDetails } = props;
  let { colorScheme } = props.shopDetails;
  let { settings } = props;
  let { whatsAppStore } = props;
  // let aboutUs = settings.aboutUs;
  let classList =
    "container container-md  fixed-top py-3 text-center text-white";
  classList += " bg-mygreen"; // + colorScheme;

  let btnClassList = "";
  btnClassList += "btn-mygreen"; // + props.shopDetails.colorScheme;

  if (flagLoading)
    return (
      <div className="text-center mt-5">
        <BeatLoader size={14} color={props.colorScheme} flagLoading />
      </div>
    );
  return (
    <>
      <div
        className={
          "col-10 col-md-8 my-1 h5 text-center border-my" + colorScheme
        }
      >
        <h2>{shopDetails.name}</h2>
        <h5>
          <pre
            className={"text-center "}
            style={{ fontSize: "12px", fontFamily: "Arial" }}
          >
            {shopDetails.address}
          </pre>
        </h5>

        <h5>
          <pre
            className={"text-center "}
            style={{ fontSize: "12px", fontFamily: "Arial" }}
          >
            {shopDetails.city}-{shopDetails.pinCode}, {shopDetails.state}.
          </pre>
        </h5>
        <h5>WhatsApp:{" " + shopDetails.whatsApp}</h5>
        <div className="my-3 h5 text-center">
          <Link to="#" className={"text-mygreen"} onClick={handleContactNowClick}>
            Contact Now <i className="fab fa-whatsapp"></i>
          </Link>
        </div>
      </div>
    </>
  );
}
export default ShopInfo;
