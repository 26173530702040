import React, { useEffect, useState } from "react";
import axios from "axios";
import { BeatLoader } from "react-spinners";

function AdminSettingsFirstPage(props) {
  let [settings, setSettings] = useState({
    firstPageMessage: "",
    minimumCriteria: "",
    minimumAmount: 0,
    deliveryCharges: 0,
    deliveryChargesBelow: 0,
  });
  let [errorSettings, setErrorSettings] = useState({
    firstPageMessage: { message: "", mxLen: 100, onlyDigits: false },
    minimumAmount: { message: "", mnLen: 1, onlyDigits: true },
    deliveryCharges: { message: "" },
    deliveryChargesBelow: { message: "" },
  });
  let [flagLoad, setFlagLoad] = useState(false);
  let [flagFormInvalid, setFlagFormInvalid] = useState(false);

  function handleChange(event) {
    let name = event.target.name;
    let userInput;
    let inputType = event.target.type;
    if (inputType == "checkbox") {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      setSettings({ ...settings, [name]: event.target.value });
      return;
    }
    // let value = event.target.value.trim();
    setSettings({ ...settings, [name]: event.target.value });
    isValid(event);
  }

  function handleBlur(event) {
    isValid(event);
  }
  function isValid(event) {
    let i,
      flag,
      name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      mxLen,
      onlyDigits,
      allDigits,
      noSymbols;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea" || type === "password") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorSettings[`${name}`].mnLen;
      mxLen = errorSettings[`${name}`].mxLen;
      onlyDigits = errorSettings[`${name}`].onlyDigits;
      allDigits = errorSettings[`${name}`].allDigits;
      noSymbols = errorSettings[`${name}`].noSymbols;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (value.length > mxLen) {
        if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
        else message = "Maximum " + mxLen + " digits allowed";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else if (noSymbols && !allDigits) {
        let d,
          count = 0;
        for (i = value.length - 1, flag = false; i >= 0; i--) {
          d = value.charCodeAt(i);
          if (d >= 48 && d <= 57) {
            count++; // digits are being counted
          } //if
          if (
            (d >= 97 && d <= 122) ||
            (d >= 65 && d <= 90) ||
            (d >= 48 && d <= 57) ||
            value.charAt(i) == "_"
          ) {
            continue;
          } //if
          else {
            // symbol is there, may be space
            flag = true;
            break;
          }
        } //for
        if (flag == true) {
          let q = String.fromCharCode(d);
          if (d == 32) {
            message = "space is not allowed";
          } else
            message =
              "Symbols other than underscore (_) are not allowed, you have used " +
              q;
        } else {
          if (count == value.length) {
            message = "Only digits are not allowed, use some alphabets also";
          }
        }
      } //else if... noSymbols
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else if (type === "email") {
      value = event.target.value.trim();
      if (/\S+@\S+\.\S+/.test(value) == 0) {
        message = "valid email-id " + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    else {
      message = "";
    }

    // errorSettings[`${name}`].message = message;
    let errSettings = { ...errorSettings };
    errorSettings[`${name}`].message = message;
    setErrorSettings(errSettings);
    checkAllErrors();
  }
  function handleFocus(event) {
    let name = event.target.name;
    let errSettings = { ...errorSettings };
    checkAllErrors();
  }
  function checkAllErrors() {
    for (let field in errorSettings) {
      if (errorSettings[field].message !== "") {
        // error is there
        // flagFormInvalid = true;
        setFlagFormInvalid(true);
        return;
      } //if
    } //for
    setFlagFormInvalid(false);
  }
  function handleFormCancelClick()
  {
    props.onCancelFormClick();
  }
  function handleRadioButtonClick(event) {
    setSettings({ ...settings, [event.target.name]: event.target.value });
  }
  function getSettingsFromDatabase() {
    let { shopDetails } = props;
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .get(window.routerPrefix + "/setting/settings/" + shopUrl)
      .then((res) => {
        let settings = res.data;
        setFlagLoad(false);
        setSettings(settings);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
      });
  }
  useEffect(() => {
    getSettingsFromDatabase();
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setFlagLoad(true);
    let { shopUrl } = props;
    //EDIT / PUT
    axios
      // .put("/settings/" + shopUrl, data)
      // .put("/setting/update/" + shopUrl, data)
      .put(window.routerPrefix + "/setting/update/" + shopUrl, settings)
      .then((res) => {
        setFlagLoad(false);
        let message = "First Page Settings updated successfully.";
        props.onCrudAction(message, "", "");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  let { action } = props;
  let criteriaPrefix;
  let { colorScheme } = props;
  //2023 --> minimum criteria
  if (settings.minimumCriteria == "amount") { 
    criteriaPrefix = <span className="input-group-text">Rs.</span>;
  } else if (settings.minimumCriteria == "none") {
    criteriaPrefix = "";
  } else if (settings.minimumCriteria == "qty") {
    criteriaPrefix = <span className="input-group-text">Kg</span>;
  }
  return (
    <>
      <div className="container-fluid container-md container-list-table">
        <div className="form-heading column col-12">First Page Settings</div>
        <form
          className={"text-thick p-4 bg-my" + colorScheme}
          onSubmit={handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Home Page Message</label>
            </div>
            <div className="col-7 column px-0">
              <textarea
                type="text"
                className="form-control"
                rows="8"
                cols="50"
                name="firstPageMessage"
                value={settings.firstPageMessage}
                onChange={handleChange}
                placeholder="First page message (max 200 letters)"
              />
            </div>
            <div className="offset-5">
              {errorSettings.firstPageMessage.message ? (
                <span className="error-text">
                  {errorSettings.firstPageMessage.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Minimum Shopping Criteria?</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <input
                type="radio"
                className="form-control-inline"
                name="minimumCriteria"
                value="none"
                onClick={(event) => handleRadioButtonClick(event)}
                onChange={(event) => handleRadioButtonClick(event)}
                checked={settings.minimumCriteria == "none"}
              />
              &nbsp;None&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="minimumCriteria"
                value="amount"
                onClick={(event) => handleRadioButtonClick(event)}
                onChange={(event) => handleRadioButtonClick(event)}
                checked={settings.minimumCriteria == "amount"}
              />
              &nbsp;Amount &nbsp;
              {/* <input
                  type="radio"
                  className="form-control-inline"
                  name="minimumCriteria"
                  value="quantity"
                  onClick={(event) => handleRadioButtonClick(event)}
                  onChange={(event) => handleRadioButtonClick(event)}
                  checked={settings.minimumCriteria == "quantity"}
                />
                &nbsp;Item nos.
                <input
                  type="radio"
                  className="form-control-inline"
                  name="minimumCriteria"
                  value="weight"
                  onClick={(event) => handleRadioButtonClick(event)}
                  onChange={(event) => handleRadioButtonClick(event)}
                  checked={settings.minimumCriteria == "weight"}
                />
                &nbsp;Total Wt. */}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          {settings.minimumCriteria != "none" && (
            <div className="form-group row column align-items-center">
              <div className="col-5 text-right">
                <label>Minimum {settings.minimumCriteria} required</label>
              </div>
              <div className="col-7 px-0 input-group ">
                <div className="input-group-prepend">
                  {/* <span className="input-group-text">Rs.</span> */}
                  {criteriaPrefix}
                </div>
                <input
                  type="text"
                  className="form-control"
                  name="minimumAmount"
                  value={settings.minimumAmount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                />
              </div>
              <div className="offset-5 column">
                {errorSettings.minimumAmount.message ? (
                  <span className="error-text">
                    {errorSettings.minimumAmount.message}
                  </span>
                ) : null}
              </div>
            </div>
          )}
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Delivery Charges</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <div className="input-group-prepend">
                <span className="input-group-text">Rs.</span>
              </div>
              <input
                type="text"
                className="form-control"
                name="deliveryCharges"
                value={settings.deliveryCharges}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </div>
            <div className="offset-5 column">
              {errorSettings.deliveryCharges.message ? (
                <span className="error-text">
                  {errorSettings.deliveryCharges.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Delivery Charges Applicable for Shopping below</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <div className="input-group-prepend">
                <span className="input-group-text">Rs.</span>
              </div>
              <input
                type="text"
                className="form-control"
                name="deliveryChargesBelow"
                value={settings.deliveryChargesBelow}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </div>
            <div className="offset-5 column">
              {errorSettings.deliveryChargesBelow.message ? (
                <span className="error-text">
                  {errorSettings.deliveryChargesBelow.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          {!flagLoad && (
            <div className="form-group row offset-5 text-center mb-0">
              <button
                className={"btn-my" + colorScheme + "-dark"}
                type="submit"
                disabled={flagFormInvalid}
              >
                {action}
              </button>
              &nbsp; &nbsp;
              <button
                className={"btn-my" + colorScheme+"-dark"}
                onClick={handleFormCancelClick}
                type="cancel"
              >
                Cancel
              </button>
            </div>
          )}
          {flagLoad && (
            <div className="text-center">
              <BeatLoader size={16} color={colorScheme} flagLoading />
            </div>
          )}
        </form>
      </div>
    </>
  );
}
export default AdminSettingsFirstPage;
