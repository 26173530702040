import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import axios from "axios";
import { BeatLoader } from "react-spinners";
function ProductMasterListSingle(props) {
  let [flagAdded, setFlagAdded] = useState(false);
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");

  function handleAddProductClick(product) {
    // // This product is to be added to shop's product-list 2023
    // if (shopProductList.length >= productsLimit) {
    //   window.alert("Maximum limit of " + productsLimit + " items is reached");
    //   return;
    // }
    setFlagLoad(true);
    let { shopUrl } = props;
    axios
      .post(window.routerPrefix + "/product/addProduct/" + shopUrl, product, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
        },
      })
      .then((res) => {
        let message = product.name + " added successfully";
        props.onCrudAction(message, "", "");
        setFlagLoad(false);
        setFlagAdded(true);
      })
      .catch((err) => {
        setError(err);
        setFlagLoad(false);
      });
  }
  let { filteredMasterListProducts } = props;
  let { selectionOption } = props;
  let { languageChoice } = props;
  let { colorScheme } = props;
  let { product } = props;
  let i = 0;
  let content;
  content = (
    // This is enclosed in container-list-table
    <>
      <div
        className={"row p-0 justify-content-center border-my" + colorScheme}
        key={product.productId}
      >
        <div className={"col-10 my-2 "}>
          {++i}. &nbsp;
          {product.name}
          {product.information ? <span> - {product.information}</span> : null}
          {languageChoice == 1 && product.nameMarathi ? (
            <span className="text-thick">/{product.nameMarathi}</span>
          ) : null}
        </div>
        {!flagLoad && !flagAdded && (
          <div className="col-6 text-center">
            <button
              className={"btn-my" + colorScheme}
              to="#"
              onClick={() => handleAddProductClick(product)}
            >
              Add
            </button>
          </div>
        )}
        {!flagLoad && flagAdded && (
          <div className={"col-6 text-center my"+colorScheme+"-dark"}>Added...</div>
        )}
        {flagLoad && (
          <div className="col-6 text-center">
            <BeatLoader size={8} color={colorScheme} flagLoad />
          </div>
        )}
        <div className="col-6 text-center">
          <LazyLoad>
            <img
              // className="masterlist-image"
              className="div-masterlist-image"
              src={
                window.routerPrefix +
                "/files/downloadImageFile/" +
                product.image
              }
              alt=""
            />
          </LazyLoad>
        </div>
      </div>
    </>
  );
  if (error) {
    return <div>Error retriving products </div>;
  } else {
    return <div className="">{content}</div>;
  } //else
}
export default ProductMasterListSingle;
