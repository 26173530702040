import React, { Component } from "react";
import { BeatLoader } from "react-spinners";

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      flagLoading: false,
      versionTypeId: 0,
    };
  }

  render() {
    let { flagLoading } = this.state;
    // let { versionTypeId } = this.state;
    let versionTypeId = this.props.shopDetails.versionTypeId;
    let { error } = this.state;
    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        {/* row starts */}
        <div className="row"></div>
        {versionTypeId == 3 && (
          <div className="row justify-content-center mb-3">
            <div className="col-6 text-center">
              <button
                className="btn-mycyan"
                onClick={this.handleExcelFileDownloadClick}
              >
                Create new Bill
              </button>
            </div>
            <div className="col-6 text-center">
              <button
                className="btn-mycyan"
                onClick={this.handleExcelFileDownloadClick}
              >
                Cancel this Bill
              </button>
            </div>
          </div>
        )}
        {!flagLoading && versionTypeId < 3 && (
          <div className="bigger-text text-thick text-center">
            This feature is available in Turbo version !
          </div>
        )}
        {flagLoading && (
          <div className="text-center mt-5">
            <BeatLoader size={16} color={"blue"} flagLoading />
          </div>
        )}
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving Yaadies </div>;
    } else {
      return (
        <div className="container container-list-table p-0">{content}</div>
      );
    } //else
  }
}
export default Billing;
