import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminMakeYaadiAutocomplete from "./admin-make-yaadi-autocomplete";
import { BeatLoader } from "react-spinners";
import { ProductDB } from "../ProductDB";
class AdminEditYaadiSingle extends Component {
  categoryList = [];
  brandList = [];
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      productdb: {},
      flagLoading: false,
      //  searchText: "",
      flagProductSelection: false,
      flagQuantitySelection: false,
      selectedProductId: -1,
      scrollPos: 0,
      quantity: 0,
      finalPrice:0,
      mrp:0
    };
  }
  componentDidMount = () => {
    let { srNo } = this.props;
    let { productCount } = this.props;
    let { product } = this.props;
    // let searchText;
    if (srNo <= productCount) {
      this.setState({
        quantity: product.quantity,
        finalPrice: product.finalPrice,
        mrp: product.mrp,
        // searchText: product.name,
      });
    }
  };
  handleDeleteProduct = (product) => {
    this.props.onDeleteProduct(product.productId);
    // search-text should change
  };
  handleQuantityChange = (event) => {
    // let name = event.target.name;
    let value = event.target.value;
    //check of max. length
    this.setState({
      quantity: value,
    });
  };
  handleQuantityBlur = (event) => {
    this.setState({
      flagQuantitySelection: true,
    });
    let scrollPos = this.state.scrollPos;
    scrollPos += 10;
    this.setState({
      scrollPos: scrollPos,
    });
    window.scroll(0, scrollPos);
    this.props.onSelectQuantity(
      this.props.srNo,
      this.props.product,
      this.state.quantity,
      this.state.finalPrice
    );
  };
  handleFinalPriceChange = (event) => {
    // let name = event.target.name;
    let value = event.target.value;
    //check of max. length
    this.setState({
      finalPrice: value,
    });
  };
  handleFinalPriceBlur = (event) => {
    this.props.onSelectFinalPrice(
      this.props.srNo,
      this.props.product,
      this.state.quantity,
      this.state.finalPrice,
    );
  };

  handleSelection = (product) => {
    let searchText;
    // if (product.information) {
    //   searchText = product.name + "-" + product.information;
    // } else {
    //   searchText = product.name;
    // }
    this.setState({
      flagProductSelection: true,
      selectedProductId: product.productId,
      // searchText: searchText,
    });
    // This may be edit action
    this.props.onSelectProduct(this.props.srNo, product, this.state.quantity);
  };
  handleSearchTextChange = (event) => {
    // this.setState({ searchText: event.target.value });
    // this.props.onSearchTextChange(event);
  };
  render() {
    let { flagLoading } = this.state;
    let { productList } = this.props;
    let { srNo } = this.props;
    let { productCount } = this.props;
    let { flagProductSelection } = this.state;
    let { flagQuantitySelection } = this.state;
    let { product } = this.props;
    let { quantity } = this.state;
    let { mrp } = this.state;
    let { finalPrice } = this.state;
    let { colorScheme } = this.props;
    let content;
    if (flagLoading) {
      content = (
        <div className="text-center">
          <BeatLoader size={16} color={colorScheme} flagLoading />
        </div>
      );
    } else {
      content = (
        // <div className="row justify-content-center p-2 form">
        <React.Fragment>
          {
            // action === "PREPARE"
            true && <div className="col-1 col-md-1 p-0 text-right">{srNo}</div>
          }
          {
            // action === "PREPARE"
            true && (
              <div className="col-10 col-md-5 p-0 my-2">
                <AdminMakeYaadiAutocomplete
                  productList={productList}
                  onSelection={this.handleSelection}
                  onSearchTextChange={this.handleSearchTextChange}
                  product={product}
                  description={product ? product.name : ""}
                />
              </div>
            )
          }
          {/* {srNo < productCount && (
            // || flagProductSelection
            <div className="col-4 col-md-2 p-0 text-center">
              Rs.
              {product.finalPrice === product.mrp ? (
                product.finalPrice
              ) : (
                <span>
                  <span className="striked-text">{product.mrp}</span>
                  <span className=""> {product.finalPrice}</span>
                </span>
              )}
              {product.unitId != 1 ? "/kg" : ""}
            </div>
          )} */}

          {srNo <= productCount && (
            // || flagProductSelection
            //
            <div className="col-6 col-md-1 p-0 text-center ">
              <input
                type="text"
                onChange={this.handleFinalPriceChange}
                onBlur={this.handleFinalPriceBlur}
                value={finalPrice}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.handleFinalPriceChange(event);
                  }
                }}
              />
            </div>
          )}
          {srNo <= productCount && (
            // || flagProductSelection
            //
            <div className="col-6 col-md-1 p-0 text-center make-yaadi-quantity">
              <input
                type="text"
                onChange={this.handleQuantityChange}
                onBlur={this.handleQuantityBlur}
                value={quantity}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    this.handleQuantityChange(event);
                  }
                }}
              />
            </div>
          )}
          {srNo <= productCount && (
            // || flagQuantitySelection
            <div className="col-3 col-md-1 p-0 text-right ">
              Rs. {quantity * finalPrice}
            </div>
          )}
          {
            // flagQuantitySelection
            true && (
              <div className="col-12 col-md-1 p-0 text-right">
                <Link
                  to="#"
                  onClick={() => {
                    if (window.confirm("You really want to delete ?"))
                      this.handleDeleteProduct(product);
                  }}
                >
                  {/* Delete */}
                  <i className="far fa-trash-alt admin-icons"></i>
                </Link>
              </div>
            )
          }
        </React.Fragment>
      );
    }
    return (
      // <div className="container-fluid container-md p-0">
      <div className="row  m-2 p-1 justify-content-between bg-mycyan ">{content}</div>
    );
  }
}
export default AdminEditYaadiSingle;
