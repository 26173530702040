import React from "react";
import { FaStar } from "react-icons/fa";
class OwnerShopType extends React.Component {
  emptyShopType = {
    shopTypeId: "",
    name: "",
    masterListName: "",
    colorScheme: "",
  };
  errorShopType = {
    name: { message: "", mxLen: 30, mnLen: 1, onlyDigits: false },
    masterListName: { message: "", mxLen: 30, mnLen: 1, onlyDigits: false },
    colorScheme: { message: "", mxLen: 30, mnLen: 1, onlyDigits: false },
  };
  constructor(props) {
    super(props);
    let shopType;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      shopType = this.emptyShopType;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      shopType = this.props.shopType;
    }
    this.state = {
      shopType: shopType,
      errorShopType: this.errorShopType,
      flagFormInvalid: flagFormInvalid,
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
  }
  handleChange = (event) => {
    let { errorShopType } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        shopType: { ...this.state.shopType, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorShopType[`${name}`].mxLen;
    let onlyDigits = errorShopType[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    } //
    errorShopType[`${name}`].message = message;
    this.setState({
      errorShopType: errorShopType,
      shopType: { ...this.state.shopType, [name]: value },
    });
    this.checkAllErrors(errorShopType);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorShopType } = this.state;
    name = event.target.name;
    type = event.target.type;

    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorShopType[`${name}`].mnLen;
      onlyDigits = errorShopType[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    errorShopType[`${name}`].message = message;
    this.setState({ errorShopType: errorShopType });
    this.checkAllErrors(errorShopType);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorShopType = this.state.errorShopType;
    errorShopType[`${name}`].message = "";
    this.setState({ errorShopType: errorShopType });
    this.checkAllErrors(errorShopType);
  };
  checkAllErrors = (errorShopType) => {
    // let { errorShopType } = this.state;
    let flagFormInvalid = false;
    for (let field in errorShopType) {
      if (errorShopType[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleRatingClick = (value) => {
    this.setState({
      shopType: { ...this.state.shopType, rating: value },
    });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onFormSubmit(this.state.shopType);
    this.setState({ shopType: this.emptyShopType });
  };
  handleCancelClick = () => {
    this.props.onCancelClick();
  };
  render() {
    let { shopType } = this.state;
    let { errorShopType } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let { colorScheme } = this.props;
    let heading;
    if (action === "ADD") {
      heading = "Add a ShopType";
    } else if (action === "UPDATE") {
      heading = "Update the ShopType";
    }
    return (
      <>
        <div className="form-heading column col-12">{heading}</div>
        <form
          className="text-thick bg-mycyan p-4"
          onSubmit={this.handleSubmit}
        >
          <div className="form-group row column align-items-center">
            {/* row starts */}
            <div className="col-5 text-right">
              <label>Type</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="name"
                value={shopType.name}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Type of Shop"
                required
              />
            </div>
            <div className="offset-5">
              {errorShopType.name.message ? (
                <span className="error-text">{errorShopType.name.message}</span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          <div className="form-group row column align-items-center">
            {/* row starts */}
            <div className="col-5 text-right">
              <label>Master List Name</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="masterListName"
                value={shopType.masterListName}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Master List Name"
                required
              />
            </div>
            <div className="offset-5">
              {errorShopType.masterListName.message ? (
                <span className="error-text">
                  {errorShopType.masterListName.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          <div className="form-group row column align-items-center">
            {/* row starts */}
            <div className="col-5 text-right">
              <label>Color Scheme</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="colorScheme"
                value={shopType.colorScheme}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Color Scheme"
                required
              />
            </div>
            <div className="offset-5">
              {errorShopType.colorScheme.message ? (
                <span className="error-text">
                  {errorShopType.colorScheme.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
            &nbsp;&nbsp;&nbsp;
            <button
              className={"btn-my" + colorScheme}
              onClick={this.handleCancelClick}
            >
              CANCEL
            </button>
          </div>
          <input type="hidden" name="shopTypeId" value={shopType.shopTypeId} />
        </form>
      </>
    );
  }
}
export default OwnerShopType;
