import React from "react";
function AboutUsImageSlider(props) {
  let { shopUrl } = props;

  return (
    <>
      {[...new Array(4)].map((e, index) => (
        <div className="col-12 col-md-7 text-center  mb-5 " key={index + 1}>
          <img
            className="div-aboutus-image"
            src={
              window.routerPrefix +
              "/files/downloadAboutUsImageFile/" +
              shopUrl +
              "/" +
              (index + 2)
            }
            alt="No Store Image"
          />
        </div>
      ))}
    </>
  );
}
export default AboutUsImageSlider;
