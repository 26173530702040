import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function AdminProductListSingle(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  function handleEditProduct(product) {
    props.onEditProduct(product);
  }
  function handleDuplicateProduct(product) {
    let selection = window.prompt(
      "How many duplicates of " + product.name + " to be created (1 to 10)"
    );
    if (selection == null) return;
    let { productsLimit } = props;
    let { currentSize } = props;
    if (/^[0-9]+$/.test(selection)) {
      if (selection > 10) {
        window.alert("Enter a number between 1 to 10.");
      } else if (currentSize == productsLimit) {
        window.alert(
          "The store has reached the maximum limit of " +
            currentSize +
            " products."
        );
      } else if (selection > productsLimit - currentSize) {
        window.alert(
          "The store has currently " +
            currentSize +
            " products. It can accomodate maximum " +
            productsLimit +
            "."
        );
      } else {
        //ok np
        props.onDuplicateProduct(product, selection);
      }
    } //if
    else {
      window.alert("You didn't enter a valid number.");
    }
  }
  function handleDeleteProduct(product) {
    // Now call for delete api
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      // .delete("/products/" + shopUrl + "/" + product.productId)
      .delete(
        window.routerPrefix +
          "/product/delete/" +
          shopUrl +
          "/" +
          product.productId
      )
      .then((res) => {
        setFlagLoad(false);
        if (res.data != 1) {
          // could not delete the product
          props.onDeleteProduct(product, 0);
        } else {
          props.onDeleteProduct(product, 1);
        }
      })
      .catch((err) => {
        setFlagLoad(false);
        setError(err);
      });
  }
  let { product } = props;
  let { index } = props;
  let { sectionNumber } = props;
  let { languageChoice } = props;
  let { colorScheme } = props;
  let unitList = [
    { unitId: 0, name: "Dummy" },
    { unitId: 1, name: "Number" },
    { unitId: 2, name: "Multiple of 250 gm" },
    { unitId: 3, name: "Multiple of 1 kg" },
    { unitId: 4, name: "Multiple of 500 gm" },
    { unitId: 5, name: "10 kg and above" },
  ];
  let content = (
    // This is enclosed in container-list-table
    <React.Fragment>
      <tr className="myborder-top">
        <td>{index + 1}</td>
        <td>
          {product.name} {product.unitId == 1 ? " " + product.information : ""}
        </td>
        {sectionNumber == 1 && <td>{product.mrp}</td>}
        {sectionNumber == 1 && <td>{product.finalPrice}</td>}
        {sectionNumber == 2 && <td>{product.instock ? "Yes" : "No"}</td>}
        {sectionNumber == 2 && <td>{product.rating}</td>}
        {sectionNumber == 12 && <td>{product.mrp}</td>}
        {sectionNumber == 12 && <td>{product.finalPrice}</td>}
        {sectionNumber == 12 && <td>{product.instock ? "Yes" : "No"}</td>}
        {sectionNumber == 12 && <td>{product.rating}</td>}
        {sectionNumber == 3 && <td>{product.category}</td>}
        {sectionNumber == 3 && <td>{product.brand}</td>}
        {sectionNumber == 4 && <td>{product.information}</td>}
        {sectionNumber == 4 && <td>{unitList[product.unitId].name}</td>}
        {sectionNumber == 34 && <td>{product.category}</td>}
        {sectionNumber == 34 && <td>{product.brand}</td>}
        {sectionNumber == 34 && <td>{product.information}</td>}
        {sectionNumber == 34 && <td>{unitList[product.unitId].name}</td>}
        {/* <td>{product.searchWords}</td> */}
      </tr>
      <tr>
        <td></td>
        {languageChoice ? <td>{product.nameMarathi}</td> : null}
        {languageChoice ? <td></td> : null}
        {languageChoice ? <td></td> : null}
      </tr>
      <tr>
        <td></td>
        <td>
          <div className="row">
            <div className="col-4">
              <Link to="#" onClick={() => handleEditProduct(product)}>
                {/* Edit */}
                <i className="fas fa-edit admin-icons"></i>
              </Link>
            </div>
            {!flagLoad && (
              <div className="col-4">
                <Link
                  to="#"
                  onClick={() => {
                    if (
                      window.confirm(
                        "You really want to delete " + product.name + " ?"
                      )
                    )
                      handleDeleteProduct(product);
                  }}
                >
                  {/* Delete */}
                  <i className="far fa-trash-alt admin-icons"></i>
                </Link>
              </div>
            )}

            {flagLoad && (
              <div className="col-4">
                <BeatLoader size={8} color={colorScheme} flagLoad />
              </div>
            )}

            <div className="col-4">
              <Link to="#" onClick={() => handleDuplicateProduct(product)}>
                {/* Duplicate/Clone */}
                <i className="fas fa-clone"></i>
              </Link>
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
  if (error) {
    return <div>Error retriving products </div>;
  } else {
    return content;
  } //else
}
export default AdminProductListSingle;
