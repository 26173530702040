import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";
function  AdminBrandListSingle (props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  function handleEditBrand  (brand)  {
    props.onEditBrand(brand);
  };
  function handleDeleteBrand  (brand)  {
    // Now call for delete api
    let { shopUrl } = props;
    setFlagLoad(true);
    axios
      .delete(
        window.routerPrefix + "/brand/delete/" + shopUrl + "/" + brand.brandId
      )
      .then((res) => {
        setFlagLoad(false);
        if (res.data != 1) {
          // could not delete the brand
          props.onDeleteBrand(brand, 0);
        } else {
          props.onDeleteBrand(brand, 1);
        }
      })
      .catch((err) => {
          setFlagLoad(false);
          setError(err);
      });
  };
    let { brand } = props;
    let { index } = props;
    let { colorScheme} = props;
    let content = (
      // This is enclosed in container-list-table
      <>
        <tr className="myborder-top">
          <td>{index + 1}</td>
          <td>{brand.name}</td>
          <td>{brand.rating}</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div className="row">
              <div className="col-4">
                <Link to="#" onClick={() => handleEditBrand(brand)}>
                  {/* Edit */}
                  <i className="fas fa-edit admin-icons"></i>
                </Link>
              </div>
              {!flagLoad && (
                <div className="col-4">
                  <Link
                    to="#"
                    onClick={() => {
                      if (
                        window.confirm(
                          "You really want to delete " + brand.name + " ?"
                        )
                      )
                        handleDeleteBrand(brand);
                    }}
                  >
                    {/* Delete */}
                    <i className="far fa-trash-alt admin-icons"></i>
                  </Link>
                </div>
              )}
              {flagLoad && (
                <div className="col-4">
                  <BeatLoader size={8} color={colorScheme} flagLoad />
                </div>
              )}
            </div>
          </td>
        </tr>
      </>
    );
    if (error) {
      return <div>Error retriving brands </div>;
    } else {
      return content;
    } //else
  }
export default AdminBrandListSingle;
