import React, { useEffect, useState } from "react";
import AdminFaq from "./admin-faq";
import { Link } from "react-router-dom";
import axios from "axios";

function AdminHomePageNew(props) {
  let [tandc, setTandc] = useState("");
  let [flagLoad, setFlagLoad] = useState(false);
  let [txtShowHide, setTxtShowHide] = useState("Show");
  let { selectedMenuIndex } = props;
  let { selectedMenuList } = props;
  let { selectedSubMenuIndex } = props;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  let [topicNumberClicked, setTopicNumberClicked] = useState(-1);
  let { colorScheme } = props;
  let faq = [
    {
      heading: "CREATE STORE",
      questionAnswer: [
        {
          question: "Which type of stores can be created using meriYaadi?",
          answer:
            "Grocery stores, vegetable and fruit shops, dry fruit and masala sellers, sweet mart etc. all types of shops who have various products to display to their customers can use the platform to build their own online store.",
        },
        {
          question: "How to set-up my own online store?",
          answer:
            "Click on the Start Now button on meriYaadi.com. Now in 3 simple steps add your store name, store type and email id. You will receive your store link, user id and password on the email id you entered. Go to your store link and click on the Admin Panel. Here you will have to enter your business details like owner name, mobile number and address. Tadaa !! Your online store set-up is done. Now add your products (with images, prices and discounts), share the store link with your customers and get orders instantly on your Whatsapp number. Please note that for a few store types, you will get a few products already added by us to help you in easy steps. You can delete any product by clicking on the Delete icon in the list of products.",
        },
        {
          question: "How do I add my shop's logo and images to my website?",
          answer:
            "Log into the admin panel. Go to Update section and click on Store Logo to add the logo and Store Images to add your store’s images on the website.",
        },
        {
          question:
            "Can I change my whatsapp number where I will receive the yaadis(shopping list)?",
          answer:
            "Yes, you can change any of the business details (other than Store URL and email). To change mobile number, go to the Update section on the admin panel. Click on home page settings and edit the mobile number",
        },
      ],
    },
    {
      heading: "ADD/EDIT PRODUCTS",
      questionAnswer: [
        {
          question:
            "How do I add my products with all the details like price, discount, image?",
          answer:
            "On the admin panel go to the Manage section. Click on Products. Now here you see all the products loaded in your store. You can add a new product by clicking on Add a Product. Now write product details in the form opened. All these details will help your customers have a better view of the products you are selling",
        },
        {
          question:
            "It takes time to add products one by one, can meriYaadi help me?",
          answer:
            "Yes to save your time, meriYaadi has prepared a ready made list of most common products across categories. Go to the Manage section, then click on Use Master List. You will find various categories and even products of other types. Add them as you like",
        },
        {
          question:
            "I want to change the price of some products frequently, is there any way?",
          answer:
            "Yes, you can simply change the price of a product by clicking on the Products-Price option under Manage section. MRP is the maximum price and Final Price is the discounted price. If you want to give a discount then uncheck the Keep Both Prices Same button and change Final Price’s value.",
        },
        {
          question: "How do I add or change the image of a product?",
          answer:
            "Under the Manage section, click on Products-Image. Here you will be shown a list of all products of your store. You can add or replace the product images. Click on Change image. Then choose a file- either click a photo or choose one from the gallery",
        },

        {
          question:
            "Can I remove a product from the online store without deleting it from my product list?",
          answer:
            "Yes, when you add or edit a product, there is a Rating option. As you increase the rating, the product will be viewed at the top of your store page. If you make the rating 1 star, the product will not be displayed",
        },
        {
          question: "How to add a new category or brand?",
          answer:
            "Under the Manage section, go to Categories or Brand and add them.",
        },
        {
          question:
            "Because I provide home delivery service to my customers, can I include the delivery charges too?",
          answer:
            "Yes you can. Under the Update section of Admin Panel, go to First Page settings. Here you can write the rules for how delivery charges will be applied. You have the option to set an amount only below which the charges will be applied. You can also apply the charges without any minimum shopping criteria. To communicate the charges to your customers it is better to write a message in the Home Page message.",
        },
      ],
    },
    {
      heading: "REPORTS",
      questionAnswer: [
        {
          question:
            "Do I get any details on my customers, the products that they bought and total sales?",
          answer:
            "Yes we at meriYaadi want to provide you with as many insights as possible so that you can use the data for your record purpose and to see patterns to improve your sales. In the Admin Panel, go to Utilities. There you can view and download Yaadi Summary (order list for a date range), you can view product wise sales and also you can see the customers who have visited your site in the past 2 days.",
        },
        {
          question: "Can I download the reports in excel format?",
          answer:
            "Go to Download Yaadies under Utilities section. Select date range and click on Download Yaadies or Download Challans to get excel reports.",
        },
      ],
    },
    {
      heading: "PAYMENT",
      questionAnswer: [
        {
          question: "How long will my free trial last?",
          answer: "Currently we provide a one month free trial per store.",
        },
        {
          question: "What are the charges of creating my online store?",
          answer:
            "After the free trial the charges depend on the number of products you have added in your store. If it is less than 150, then the charge is Rs 299 per month. If it lies between 150 to 300 then the charge is Rs 399 per month. For adding more than 300 products you can contact us. Note: The charges are inclusive of taxes, and all features will be provided in it.",
        },
        {
          question: "How to pay for the monthly subscription of meriYaadi?",
          answer:
            "You can use UPI methods like GPay, Phonepe, Paytm to pay the charges. You will find our account details under Your Account section of the Admin Panel. Once you make the payment do attach the screenshot in the same section.",
        },
        {
          question:
            "I want to use meriYaadi for my multiple shops, how do I do it?",
          answer:
            "Currently we do support multiple branches. You will have to individually create accounts for each of the shops.",
        },
      ],
    },
    {
      heading: "SUPPORT",
      questionAnswer: [
        {
          question:
            "If I have any queries or face any technical issue for my website how can I contact you?",
          answer:
            "You can Whatsapp us on 83559 15361 or mail us on admin@meriyaadi.com. We will be glad to assist you!",
        },
      ],
    },
  ];
  function handleFaqHeadingClick(topicNumber) {
    if (topicNumber == topicNumberClicked) {
      // clicked active heading
      setTopicNumberClicked(-1);
    } else {
      setTopicNumberClicked(topicNumber);
    }
  }
  function handleShowHideTandcCLick() {
    // get terms and conditions from back-end
    setFlagLoad(true);
    if (tandc) {
      setTxtShowHide("Show");
      setTandc("");
      return;
    }
    setTxtShowHide("Hide");
    axios
      .get(window.routerPrefix + "/files/downloadTandcFile")
      .then((res) => {
        setTandc(res.data);
        setFlagLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setFlagLoad(false);
      });
  }
  function menuClicked(index) {
    props.onMenuClicked(index);
  }
  function subMenuClicked(subMenuIndex) {
    props.onSubMenuClicked(selectedMenuIndex, subMenuIndex);
  }
  let { mainMenus } = props;
  let task, pos;
  if (selectedMenuIndex != -1) {
    task = mainMenus[selectedMenuIndex].menu;
    pos = task.indexOf(" ");
    task = task.substring(0, pos);
  }

  return (
    <>
      <div
        className={
          "container-fluid container-md container-admin-home-page border-my" +
          colorScheme
        }
      >
        <div className={"text-center text-bigger text-thick text-italic text-black"}>
          {" "}
          {selectedMenuIndex != -1 && task}
        </div>
        {selectedMenuIndex == -1 &&
          mainMenus.map((e, index) => (
            <div className="row  justify-content-center" key={index}>
              <div
                onClick={() => {
                  menuClicked(index);
                }}
                className={
                  "col-8 col-md-4 div-clickable text-center text-thick text-bigger mb-2 p-2 bg-my" +
                  colorScheme
                }
              >
                {e.menu.toUpperCase()}
              </div>
            </div>
          ))}
        {/* container-ends */}
        {selectedMenuIndex >= 0 &&
          mainMenus[selectedMenuIndex].list.map((e, index) => (
            <div className="row  justify-content-center" key={index}>
              <div
                className={
                  "col-8 col-md-4 div-clickable text-center text-thick text-bigger mb-2 p-2 " +
                  (e.subject != "NONE" ? "bg-my" + colorScheme : "")
                }
                onClick={() => {
                  subMenuClicked(index);
                }}
              >
                {e.subject != "NONE" ? e.name.toUpperCase() : ""}
              </div>
            </div>
          ))}
        {/* container-ends */}
      </div>
      {selectedMenuIndex == -1 && (
        <div className="container-fluid container-md mt-3 ">
          {/* row-begins */}
          <div className="row">
            <div className="col-6 col-md-4 text-center mx-auto homepage-image">
              <Link to="/">
                <img
                  className="img-fluid"
                  src="images/meriyaadi_logo.jpg"
                  alt=""
                />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-8 col-md-6 text-center mx-auto homepage-image">
              <Link to="/">
                <img className="img-fluid" src="images/shopkeeper.jpg" alt="" />
              </Link>
            </div>
          </div>
          {/* row-begins */}
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 text-center bigger-text">
              Click for{" "}
              <a href="https://www.youtube.com/watch?v=_s9wneJugNM">
                Introduction video
              </a>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 text-center bigger-text">
              Click for{" "}
              <a href="https://www.youtube.com/watch?v=gg14zAlOB80&t=73s">
                Video to Guide the customers
              </a>
            </div>
          </div>
          <div
            className={
              "row justify-content-center pb-3 border-my" + colorScheme
            }
          >
            <div className="col-12 col-md-6 text-center bigger-text">
              Please like our
              <a href="https://www.facebook.com/meriYaadi"> FaceBook Page</a>
            </div>
          </div>
          <div className={"row justify-content-center mt-3 p-2 "}>
            <h4 className="col-12 col-md-6 text-center ">
              Frequently Asked Questions
            </h4>
          </div>
          <div
            className={
              "row justify-content-center mb-3 pb-3 border-my" + colorScheme
            }
          >
            {faq.map((f, index) => (
              <AdminFaq
                key={index}
                topicNumber={index}
                topicNumberClicked={topicNumberClicked}
                questionAnswers={f}
                colorScheme={colorScheme}
                onFaqHeadingClick={handleFaqHeadingClick}
              />
            ))}
          </div>
          <div
            className={
              "row justify-content-center mt-3 border-my" + colorScheme
            }
          >
            <h4 className="col-12 col-md-7 text-center mt-3">
              Terms and Conditions
            </h4>
            <h5 className="col-12 col-md-6 text-center ">
              <Link to="#" onClick={handleShowHideTandcCLick}>
                {txtShowHide}
              </Link>
            </h5>
            {tandc && (
              <div className="col-10 px-0 text-justify">
                <textarea
                  type="text"
                  className="form-control"
                  rows="20"
                  cols="50"
                  name="tandc"
                  value={tandc}
                  readOnly
                />
              </div>
            )}
          </div>
        </div>
      )}
      {selectedMenuIndex == -1 && (
        <div className="container-md ">
          <div className="row justify-content-center text-center">
            <div className="col-12 my-3">www.meriYaadi.com &#169; 2020</div>
          </div>
        </div>
      )}
    </>
  );
}
export default AdminHomePageNew;
