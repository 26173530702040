import React from "react";
import { Link } from "react-router-dom";
import AdminBrandListSingle from "./admin-brand-list-single";

function AdminBrandList(props) {
  function handleEditBrand(brand) {
    props.onEditBrand(brand);
  }
  function handleCrudAction(message, action) {
    props.onCrudAction(message, action);
  }
  function handleDuplicateBrand(brand, selection) {
    props.onDuplicateBrand(brand, selection);
  }
  function handleDeleteBrand(brand, result) {
    props.onDeleteBrand(brand, result);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  let { filteredBrands } = props;
  let { searchText } = props;
  let { sectionNumber } = props;
  let { languageChoice } = props;
  let { shopUrl } = props;
  let { brandsLimit } = props;
  let { currentSize } = props;
  let { subject } = props;
  let { colorScheme } = props;
  
  let contentNobrands = <div className="text-center">NO {subject}</div>;
  return (
    // This is enclosed in container-list-table
    <>
      {filteredBrands.length != 0 && (
        <div className="form-heading column col-12">
          LIST OF {subject} ({filteredBrands.length})
        </div>
      )}
      {filteredBrands.length == 0 && (
        <div className="form-heading column col-12">NO {subject}</div>
      )}
      {/* row starts */}
      {/* row ends */}
      {/* row starts */}
      {/* no enity at all, so don't provide search facility  */}
      {currentSize != 0 && (
        <div className="row justify-content-center">
          <div className="col-10 col-md-6 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={handleSearchTextBoxKeyUp}
              onChange={handleSearchTextChange} // This is contolled by parent
              placeholder="Search Brands"
              id=""
            />
          </div>
        </div>
      )}
      {/* row ends */}
      {filteredBrands.length != 0 && (
        <div className="row p-0 ">
          <div className="col-12 justify-content-center">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="name">
                        Name
                      </Link>
                    </th>
                  </tr>
                </thead>
                {filteredBrands.map((brand, index) => (
                  <tbody key={index}>
                    <AdminBrandListSingle
                      brand={brand}
                      index={index}
                      sectionNumber={sectionNumber}
                      languageChoice={languageChoice}
                      onDeleteBrand={handleDeleteBrand}
                      onDuplicateBrand={handleDuplicateBrand}
                      onEditBrand={handleEditBrand}
                      onCrudAction={handleCrudAction}
                      shopUrl={shopUrl}
                      brandsLimit={brandsLimit}
                      currentSize={currentSize}
                      colorScheme={colorScheme}
                    />
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default AdminBrandList;
