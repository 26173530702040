import React from "react";
import { Link } from "react-router-dom";
import AdminCategoryListSingle from "./admin-category-list-single";
function AdminCategoryList(props) {
  function handleEditCategory(category) {
    props.onEditCategory(category);
  }
  function handleCrudAction(message, action) {
    props.onCrudAction(message, action);
  }
  function handleDuplicateCategory(category, selection) {
    props.onDuplicateCategory(category, selection);
  }
  function handleDeleteCategory(category, result) {
    props.onDeleteCategory(category, result);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  let { filteredCategories } = props;
  let { searchText } = props;
  let { sectionNumber } = props;
  let { languageChoice } = props;
  let { shopUrl } = props;
  let { categoriesLimit } = props;
  let { currentSize } = props;
  let { subject } = props;
  let contentNocategories = <div className="text-center">NO {subject}</div>;
  return (
    // This is enclosed in container-list-table
    <>
      {filteredCategories.length != 0 && (
        <div className="form-heading column col-12">
          LIST OF {subject} ({filteredCategories.length})
        </div>
      )}
      {filteredCategories.length == 0 && (
        <div className="form-heading column col-12">NO {subject}</div>
      )}
      {/* row starts */}
      {/* {filteredCategories.length !=0 && (
        <div className="row smaller-text my-1 justify-content-center align-items-center column">
          <div className="col-2 d-md-none text-center">
            <Link to="#" id="1" onClick={handleSectionNumberClick}>
              Category
            </Link>
          </div>
        </div>
      )} */}
      {/* row ends */}
      {/* row starts */}
      {/* no enity at all, so don't provide search facility  */}
      {currentSize !=0 && (
        <div className="row justify-content-center">
          <div className="col-10 col-md-6 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={handleSearchTextBoxKeyUp}
              onChange={handleSearchTextChange} // This is contolled by parent
              placeholder="Search Categories"
              id=""
            />
          </div>
        </div>
      )}
      {/* row ends */}
      {filteredCategories.length !=0 && (
        <div className="row p-0 ">
          <div className="col-12 justify-content-center">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="name">
                        Name
                      </Link>
                    </th>
                  </tr>
                </thead>
                {filteredCategories.map((category, index) => (
                  <tbody key={index}>
                    <AdminCategoryListSingle
                      category={category}
                      index={index}
                      sectionNumber={sectionNumber}
                      languageChoice={languageChoice}
                      onDeleteCategory={handleDeleteCategory}
                      onDuplicateCategory={handleDuplicateCategory}
                      onEditCategory={handleEditCategory}
                      onCrudAction={handleCrudAction}
                      shopUrl={shopUrl}
                      categoriesLimit={categoriesLimit}
                      currentSize={currentSize}
                    />
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default AdminCategoryList;
