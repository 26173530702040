import React from "react";

class OwnerAdminPasswordAdd extends React.Component {
  emptyAdminPassword = {
    adminId: "",
    userName: "",
    password: "",
    shopUrl: "",
    flagFirstTimeLogin: "",
  };
  errorAdminPassword = {
    userName: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    password: { message: "", mxLen: 15, mnLen: 4, onlyDigits: false },
    shopUrl: { message: "", mxLen: 20, mnLen: 5, onlyDigits: false },
  };
  constructor(props) {
    super(props);
    let adminPassword;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      adminPassword = this.emptyAdminPassword;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      adminPassword = this.props.adminPassword;
    }
    this.state = {
      adminPassword: adminPassword,
      errorAdminPassword: this.errorAdminPassword,
      flagFormInvalid: flagFormInvalid,
    };
  }

  handleChange = (event) => {
    let { errorAdminPassword } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        adminPassword: { ...this.state.adminPassword, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorAdminPassword[`${name}`].mxLen;
    let onlyDigits = errorAdminPassword[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen + 1);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorAdminPassword[`${name}`].message = message;
    this.setState({
      errorAdminPassword: errorAdminPassword,
      adminPassword: { ...this.state.adminPassword, [name]: value },
    });
    this.checkAllErrors(errorAdminPassword);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorAdminPassword } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorAdminPassword[`${name}`].mnLen;
      onlyDigits = errorAdminPassword[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      // Currently no checking
      value = event.target.selectedIndex;
      if (value == 0) {
        // message = name + requiredMessage;
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorAdminPassword[`${name}`].message = message;
    this.setState({ errorAdminPassword: errorAdminPassword });
    this.checkAllErrors(errorAdminPassword);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorAdminPassword = this.state.errorAdminPassword;
    errorAdminPassword[`${name}`].message = "";
    this.setState({ errorAdminPassword: errorAdminPassword });
    this.checkAllErrors(errorAdminPassword);
  };
  checkAllErrors = (errorAdminPassword) => {
    let flagFormInvalid = false;
    for (let field in errorAdminPassword) {
      if (errorAdminPassword[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onFormSubmit(this.state.adminPassword); //this.state
  };
  render() {
    let { adminPassword } = this.state;
    let { errorAdminPassword } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let heading;
    if (action === "ADD") {
      heading = "Add a Shop";
    } else if (action === "UPDATE") {
      heading = "Update the Admin Password";
    }
    return (
      <React.Fragment>
        <div className="form-heading column col-12">{heading}</div>
        <form

          className="text-thick bg-myblue p-4"
          onSubmit={this.handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>User Name</label>
            </div>
            <div className="col-7 px-0">
              <input
                type="text"
                className="form-control"
                name="userName"
                value={adminPassword.userName}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter user-name"
                required
              />
            </div>
            <div className="offset-5">
              {errorAdminPassword.userName.message ? (
                <span className="error-text">
                  {errorAdminPassword.userName.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Password</label>
            </div>
            <div className="col-7 px-0 input-group">
              <input
                type="text"
                className="form-control"
                name="password"
                value={adminPassword.password}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter password"
                required
              />
            </div>
            <div className="offset-5">
              {errorAdminPassword.password.message ? (
                <span className="error-text">
                  {errorAdminPassword.password.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>ShopUrl</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <input
                type="text"
                className="form-control"
                name="shopUrl"
                value={adminPassword.shopUrl}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Shop Url"
              />
            </div>
            <div className="offset-5 column">
              {errorAdminPassword.shopUrl.message ? (
                <span className="error-text">
                  {errorAdminPassword.shopUrl.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right column">
              <label className="form-check-label">First time logged In ?</label>
            </div>
            <div className="col-7 px-0 column">
              <input
                className="form-control"
                type="checkbox"
                name="flagFirstTimeLogin"
                value=""
                onChange={this.handleChange}
                checked={Boolean(adminPassword.flagFirstTimeLogin)}
              />
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
          </div>
          <input type="hidden" name="adminId" value={adminPassword.adminId} />
        </form>
      </React.Fragment>
    );
  }
}
export default OwnerAdminPasswordAdd;