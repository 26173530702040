import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import axios from "axios";

class OwnerAdminPasswordList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      flagLoading: false,
    };
  }
  handleEditAdminPassword = (adminPassword) => {
    this.props.onEditAdminPassword(adminPassword);
  };
  handleDeleteAdminPassword = (adminPassword) => {
    // Now call for delete api
        this.setState({ flagLoading: true });
    axios
      .delete(window.routerPrefix + "/admin/delete/" + adminPassword.adminId)
      .then((res) => {
        this.setState({ flagLoading: false });
        if (res.data != 1) {
          // could not delete the adminPassword
          this.props.onDeleteAdminPassword(adminPassword, 0);
        } else {
          this.props.onDeleteAdminPassword(adminPassword, 1);
        }
      })
      .catch((err) => {
        this.setState({ flagLoading: false, error: err });
      });
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  render() {
    let { error } = this.state;
    let { filteredAdminPasswords } = this.props;
    let { searchText } = this.props;
    let { sectionNumber } = this.props;

    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        <div className="form-heading column col-12">LIST OF Admins</div>
        {/* row starts */}
        <div className="row smaller-text my-1 justify-content-center align-items-center column">
          <div className="col-3">
            <Link to="#" id="1" onClick={this.handleSectionNumberClick}>
              User Name/Password
            </Link>
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        <div className="row">
          <div className="col-12 my-2 ">
            <input
              type="search"
              className="container-fluid form-control"
              value={searchText}
              onKeyUp={this.handleSearchTextBoxKeyUp}
              onChange={this.handleSearchTextChange} // This is contolled by parent
              placeholder="Search Admins"
              id=""
            />
          </div>
        </div>
        {/* row ends */}
        <div className="row p-0">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Link
                        to="#"
                        onClick={this.handleHeaderClick}
                        id="shopUrl"
                      >
                        Url
                      </Link>
                    </th>
                    {sectionNumber == 1 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="userName"
                        >
                          Type
                        </Link>
                      </th>
                    )}
                    {sectionNumber == 1 && (
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="password"
                        >
                          Password
                        </Link>
                      </th>
                    )}
                  </tr>
                </thead>
                {filteredAdminPasswords.map((adminPassword, index) => (
                  <tbody key={adminPassword.adminId}>
                    <tr className="myborder-top">
                      <td>{index + 1}</td>
                      <td>{adminPassword.shopUrl}</td>
                      {sectionNumber == 1 && <td>{adminPassword.userName}</td>}
                      {sectionNumber == 1 && <td>{adminPassword.password}</td>}
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        <Link
                          to="#"
                          onClick={() =>
                            this.handleEditAdminPassword(adminPassword)
                          }
                        >
                          {/* Edit */}
                          <i className="fas fa-edit admin-icons"></i>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link
                          to="#"
                          onClick={() => {
                            if (
                              window.confirm(
                                "You really want to delete " +
                                  adminPassword.shopUrl +
                                  " ?"
                              )
                            )
                              this.handleDeleteAdminPassword(adminPassword);
                          }}
                        >
                          {/* Delete */}
                          <i className="far fa-trash-alt admin-icons"></i>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving shops </div>;
    } else {
      return (
      
        <div className="col-md-12">{content}</div>
     
      );
    } //else
  }
}
export default OwnerAdminPasswordList;
