import React, { Component } from "react";
import { Link } from "react-router-dom";
class AdminReportCumulativeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      response: {},
      //   It is not advisable to store anything in a state that can be derived
      //from props at any point in time
      //https://blog.bitsrc.io/managing-derived-state-from-props-in-react-f26b5b15069
    };
  }
  pad = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  };
  componentDidMount = () => {};
  handleShowHereClick = (event) => {
    event.preventDefault();
    this.props.onShowHereClick();
  };
  handleExcelFileDownloadClick = () => {
    this.props.onExcelFileDownloadClick();
  };
  handleSearchTextBoxKeyUp = (event) => {
    this.props.onSearchTextBoxKeyUp(event);
  };
  handleSearchTextChange = (event) => {
    this.props.onSearchTextChange(event);
  };
  handleHeaderClick = (event) => {
    this.props.onHeaderClick(event);
  };
  handleSectionNumberClick = (event) => {
    this.props.onSectionNumberClick(event);
  };
  render() {
    let { error } = this.state;
    let { flagDisplay } = this.props;
    let { whichDate } = this.props;

    let { filteredProducts } = this.props;
    let { searchText } = this.props;
    let unitList = [
      { unitId: 0, name: "" },
      { unitId: 1, name: "" },
      { unitId: 2, name: " kg" },
      { unitId: 3, name: " kg" },
      { unitId: 4, name: " kg" },
      { unitId: 5, name: " kg" },
    ];
    let content = (
      // This is enclosed in container-list-table
      <React.Fragment>
        <div className="row justify-content-center my-3">
          <div className="col-12 text-center my-2">
            <Link className="" to="#" onClick={this.handleShowHereClick}>
              Show Here
            </Link>
          </div>
        </div>
        {/* row ends */}
        {/* row starts */}
        {flagDisplay && (
          <div className="row justify-content-center">
            <div className="col-10 text-center my-2">
              <button
                className="btn-mycyan"
                onClick={this.handleExcelFileDownloadClick}
              >
                Download {whichDate}'s Excel File
              </button>
            </div>
            <div className="text-center thick-red-text">
              Total items: {filteredProducts.length}
            </div>
            <div className="col-10 col-md-6 my-2 ">
              <input
                type="search"
                className="container-fluid form-control"
                value={searchText}
                onKeyUp={this.handleSearchTextBoxKeyUp}
                onChange={this.handleSearchTextChange} // This is contolled by parent
                placeholder="Search an item"
                id=""
              />
            </div>
          </div>
        )}
        {/* row ends */}

        {flagDisplay && (
          <div className="row p-0">
            <div className="col-12 col-md-6 mx-auto">
              <div className="table-responsive">
                <table className="table table-sm table-hover table-borderless bigger-text">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>
                        <Link to="#" onClick={this.handleHeaderClick} id="name">
                          Description
                        </Link>
                      </th>
                      <th>
                        <Link
                          to="#"
                          onClick={this.handleHeaderClick}
                          id="quantity"
                        >
                          Quantity
                        </Link>
                      </th>
                    </tr>
                  </thead>
                  {filteredProducts.map((product, index) => (
                    <tbody key={product.productId}>
                      <tr className="myborder-top">
                        <td>{index + 1}</td>
                        <td>{product.name}</td>
                        <td>
                          {product.quantity}
                          {unitList[product.unitId].name}
                        </td>
                      </tr>
                      <tr>
                        <td>{product.nameMarathi}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
    if (error) {
      return <div>Error retriving Products </div>;
    } else {
      return <div className="container-fluid p-0">{content}</div>;
    } //else
  }
}
export default AdminReportCumulativeList;
