import React from "react";

class AdminSpecialOfferAdd extends React.Component {
  emptySpecialOffer = {
    specialOfferId: "",
    name: "",
    description: "",
    criteria: 0,
    discountIn: 0,
    discountValue: 0,
    status: "",
  };
  errorSpecialOffer = {
    name: { message: "", mxLen: 30, mnLen: 4, onlyDigits: false },
    description: { message: "", mxLen: 200, mnLen: 10, onlyDigits: false },
    criteria: { message: "" },
    discountIn: { message: "" },
    discountValue: { message: "" },
    status: { message: "" },
  };
  constructor(props) {
    super(props);
    let specialOffer;
    let flagFormInvalid;
    let { action } = this.props;
    if (action === "ADD") {
      flagFormInvalid = true;
      specialOffer = this.emptySpecialOffer;
    } else if (action === "UPDATE") {
      // in edit mode, keep the update button enabled at the beginning
      flagFormInvalid = false;
      specialOffer = this.props.specialOffer;
    }
    this.state = {
      specialOffer: specialOffer,
      errorSpecialOffer: this.errorSpecialOffer,
      flagFormInvalid: flagFormInvalid,
    };
  }

  handleChange = (event) => {
    let { errorSpecialOffer } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        specialOffer: { ...this.state.specialOffer, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorSpecialOffer[`${name}`].mxLen;
    let onlyDigits = errorSpecialOffer[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    } //
    errorSpecialOffer[`${name}`].message = message;
    this.setState({
      errorSpecialOffer: errorSpecialOffer,
      specialOffer: { ...this.state.specialOffer, [name]: value },
    });
    this.checkAllErrors(errorSpecialOffer);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorSpecialOffer } = this.state;
    name = event.target.name;
    type = event.target.type;

    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorSpecialOffer[`${name}`].mnLen;
      onlyDigits = errorSpecialOffer[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;
      if (value == 0) {
        message = name + requiredMessage;
      } else {
        message = "";
      }
    } //else if
    errorSpecialOffer[`${name}`].message = message;
    this.setState({ errorSpecialOffer: errorSpecialOffer });
    this.checkAllErrors(errorSpecialOffer);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorSpecialOffer = this.state.errorSpecialOffer;
    errorSpecialOffer[`${name}`].message = "";
    this.setState({ errorSpecialOffer: errorSpecialOffer });
    this.checkAllErrors(errorSpecialOffer);
  };
  checkAllErrors = (errorSpecialOffer) => {
    // let { errorSpecialOffer } = this.state;
    let flagFormInvalid = false;
    for (let field in errorSpecialOffer) {
      if (errorSpecialOffer[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleUpload = (event) => {
    this.setState({
      imageName: event.target.files[0],
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onFormSubmit(this.state.specialOffer);
    this.setState({ specialOffer: this.emptySpecialOffer });
  };
  render() {
    let { specialOffer } = this.state;
    let { errorSpecialOffer } = this.state;
    let { flagFormInvalid } = this.state;
    let { action } = this.props;
    let { colorScheme } = this.props;
    let heading;
    if (action === "ADD") {
      heading = "Add a Special Offer ";
    } else if (action === "UPDATE") {
      heading = "Update the Special Offer";
    }
    return (
      <React.Fragment>
        <div className="form-heading column col-12">{heading}</div>
        <form
          className={"text-thick p-4 bg-my" + colorScheme}
          onSubmit={this.handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Special Offer</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="name"
                value={specialOffer.name}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Special-Offer name"
                required
              />
            </div>
            <div className="offset-5">
              {errorSpecialOffer.name.message ? (
                <span className="error-text">
                  {errorSpecialOffer.name.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Description</label>
            </div>
            <div className="col-7 column px-0">
              <textarea
                type="text"
                className="form-control"
                rows="8"
                cols="50"
                name="description"
                value={specialOffer.description}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Description in max 200 letters"
              />
            </div>
            <div className="offset-5">
              {errorSpecialOffer.description.message ? (
                <span className="error-text">
                  {errorSpecialOffer.description.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Offer Criteria</label>
            </div>
            <div className="col-7 px-0 input-group ">
              <input
                type="radio"
                className="form-control-inline"
                name="criteria"
                value="amount"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={specialOffer.criteria == "amount"}
              />
              &nbsp;Amount&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="criteria"
                value="delivery"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={specialOffer.criteria == "delivery"}
              />
              &nbsp;Delivery Charges&nbsp;
              <input
                type="radio"
                className="form-control-inline"
                name="criteria"
                value="other"
                onClick={(event) => this.handleRadioButtonClick(event)}
                onChange={(event) => this.handleRadioButtonClick(event)}
                checked={specialOffer.criteria == "other"}
              />
              &nbsp;Other&nbsp;
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan-dark"
              type="submit"
              disabled={flagFormInvalid}
            >
              {action}
            </button>
          </div>
          <input
            type="hidden"
            name="specialOfferId"
            value={specialOffer.specialOfferId}
          />
        </form>
      </React.Fragment>
    );
  }
}
export default AdminSpecialOfferAdd;
