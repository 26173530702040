import React from "react";
import axios from "axios";
class Registration extends React.Component {
  emptyRegistration = {
    registrationId: "",
    shopName: "",
    ownerName: "",
    address: "",
    city: "",
    pincode: "",
    whatsApp: "",
    mobile: "",
    emailid: "",
  };
  errorRegistration = {
    shopName: { message: "", mxLen: 40, mnLen: 4, onlyDigits: false },
    ownerName: { message: "", mxLen: 40, mnLen: 4, onlyDigits: false },
    address: { message: "", mxLen: 80, mnLen: 10, onlyDigits: false },
    city: { message: "", mxLen: 30, mnLen: 4, onlyDigits: false },
    pincode: { message: "", mxLen: 6, mnLen: 6, onlyDigits: true },
    whatsApp: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    mobile: { message: "", mxLen: 10, mnLen: 10, onlyDigits: true },
    emailid: { message: "", mxLen: 50, mnLen: 0, onlyDigits: false },
  };
  constructor(props) {
    super(props);
    let flagFormInvalid;
    flagFormInvalid = true;
    let registration = this.emptyRegistration;

    this.state = {
      registration: registration,
      errorRegistration: this.errorRegistration,
      flagFormInvalid: flagFormInvalid,
    };
  }

  handleChange = (event) => {
    let { errorRegistration } = this.state;
    let name = event.target.name;
    let message;
    let userInput;
    const isCheckbox = event.target.type === "checkbox";
    if (isCheckbox) {
      // for checkbox, no checking of validations.
      userInput = event.target.checked;
      this.setState({
        registration: { ...this.state.registration, [name]: userInput },
      });
      return;
    }
    let value = event.target.value;
    //check of max. length
    let mxLen = errorRegistration[`${name}`].mxLen;
    let onlyDigits = errorRegistration[`${name}`].onlyDigits;
    if (value.length > mxLen) {
      value = value.slice(0, mxLen + 1);
      if (!onlyDigits) message = "Maximum " + mxLen + " characters allowed";
      else message = "Maximum " + mxLen + " digits allowed";
    } //if...
    else {
      message = "";
    }
    errorRegistration[`${name}`].message = message;
    this.setState({
      errorRegistration: errorRegistration,
      registration: { ...this.state.registration, [name]: value },
    });
    this.checkAllErrors(errorRegistration);
  };
  handleBlur = (event) => {
    let name,
      value,
      requiredMessage = " is required",
      type,
      message = "",
      mnLen,
      onlyDigits;
    let { errorRegistration } = this.state;
    name = event.target.name;
    type = event.target.type;
    if (type === "text" || type === "textarea") {
      // check if 'required', also check min no. of characters
      value = event.target.value.trim();
      mnLen = errorRegistration[`${name}`].mnLen;
      onlyDigits = errorRegistration[`${name}`].onlyDigits;
      if (value.length === 0) {
        message = name + requiredMessage;
      } else if (value.length < mnLen) {
        if (!onlyDigits) message = "Min " + mnLen + " characters required";
        else message = "Min " + mnLen + " digits required";
      } //else
      else if (onlyDigits) {
        for (let i = value.length - 1; i >= 0; i--) {
          const d = value.charCodeAt(i);
          if (d < 48 || d > 57) {
            message = "Enter only digits";
            break;
          } //if
        } //for
      } //else if... onlyDigits
      else {
        message = "";
      }
    } //if... text || textarea
    else if (event.target.type === "select-one") {
      value = event.target.selectedIndex;

      if (value === 0) {
        message = "";
      } else {
        message = "";
      }
    } //else if
    errorRegistration[`${name}`].message = message;
    this.setState({ errorRegistration: errorRegistration });
    this.checkAllErrors(errorRegistration);
  };
  handleFocus = (event) => {
    let name = event.target.name;
    let errorRegistration = this.state.errorRegistration;
    errorRegistration[`${name}`].message = "";
    this.setState({ errorRegistration: errorRegistration });
    this.checkAllErrors(errorRegistration);
  };
  checkAllErrors = (errorRegistration) => {
    let flagFormInvalid = false;
    for (let field in errorRegistration) {
      if (errorRegistration[field].message !== "") {
        // error is there
        flagFormInvalid = true;
        break;
      } //if
    } //for
    this.setState({ flagFormInvalid: flagFormInvalid });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.onFormSubmit(this.state.registration);
  };
  onFormSubmit(data) {
    let message;
    this.setState({
      flagLoading: true,
    });
    axios
      .post(window.routerPrefix +"/register", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          // "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((res) => {
        this.setState({
          flagLoading: false,
          response: res.data,
        });
        let whatsappNumber = "91" + "7083307568";
        let whatsAppMessage = `Hello, I am ${this.state.registration.ownerName}, owner of 
        ${this.state.registration.shopName}. I have submitted the registration form`;
        window.location = `https://wa.me/${whatsappNumber}?text=${whatsAppMessage}`;
      })
      .catch((err) => {
        console.log("error!");
        this.setState({
          flagLoading: false,
        });
      });
  }
  render() {
    let { registration } = this.state;
    let { errorRegistration } = this.state;
    let { flagFormInvalid } = this.state;
    let heading;
    heading = "Registration form";

    // prepare options
    return (
        <div className="container-fluid container-md container-list-table p-0">
        <div className="form-heading column col-12 my-2">{heading}</div>
        <div className="column col-12 text-center text-danger my-2 bigger-text">
          (After you click to submit button, WhatsApp will be opened up in your mobile,
          with our number (7083307568). In WhatsApp, you need to just tap to enter button.)
        </div>
        <form
          className="text-thick bg-mycyan p-4"
          onSubmit={this.handleSubmit}
        >
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Shop/Business Name</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="shopName"
                value={registration.shopName}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter name of Shop/Business"
                required
              />
            </div>
            <div className="offset-5">
              {errorRegistration.shopName.message ? (
                <span className="error-text">
                  {errorRegistration.shopName.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Name of owner</label>
            </div>
            <div className="col-7 px-0">
              <input
                type="text"
                className="form-control"
                name="ownerName"
                value={registration.ownerName}
                onChange={this.handleChange}
                placeholder="Owner's full name"
              />
            </div>
            <div className="offset-5">
              {errorRegistration.ownerName.message ? (
                <span className="error-text">
                  {errorRegistration.ownerName.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Address</label>
            </div>
            <div className="col-7 px-0">
              <textarea
                type="text"
                className="form-control"
                rows="3"
                cols="50"
                name="address"
                value={registration.address}
                onChange={this.handleChange}
                placeholder="Enter postal address"
              />
            </div>
            <div className="offset-5">
              {errorRegistration.address.message ? (
                <span className="error-text">
                  {errorRegistration.address.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>City</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="city"
                value={registration.city}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="City name"
                required
              />
            </div>
            <div className="offset-5">
              {errorRegistration.city.message ? (
                <span className="error-text">
                  {errorRegistration.city.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Pincode</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="pincode"
                value={registration.pincode}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Pincode Number"
                required
              />
            </div>
            <div className="offset-5">
              {errorRegistration.pincode.message ? (
                <span className="error-text">
                  {errorRegistration.pincode.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>WhatsApp Number</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="whatsApp"
                value={registration.whatsApp}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter WhatsApp Number"
                required
              />
            </div>
            <div className="offset-5">
              {errorRegistration.whatsApp.message ? (
                <span className="error-text">
                  {errorRegistration.whatsApp.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Mobile Number</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="mobile"
                value={registration.mobile}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Mobile Number"
                required
              />
            </div>
            <div className="offset-5">
              {errorRegistration.mobile.message ? (
                <span className="error-text">
                  {errorRegistration.mobile.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row column align-items-center">
            <div className="col-5 text-right">
              <label>Email-id</label>
            </div>
            <div className="col-7 column px-0">
              <input
                type="text"
                className="form-control"
                name="emailid"
                value={registration.emailid}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                placeholder="Enter Emailid"
                required
              />
            </div>
            <div className="offset-5">
              {errorRegistration.emailid.message ? (
                <span className="error-text">
                  {errorRegistration.emailid.message}
                </span>
              ) : null}
            </div>
          </div>
          {/* row ends */}
          {/* row starts */}
          <div className="form-group row offset-5 text-center mb-0">
            <button
              className="btn-mycyan"
              type="submit"
              disabled={flagFormInvalid}
            >
              Submit
            </button>
          </div>
          <input
            type="hidden"
            name="registrationId"
            value={registration.registrationId}
          />
        </form>
        <div className="container-fluid container-md customer-form-contact-details text-white pt-1 mt-5">
          <div className="row justify-content-center column">
            <div className="col-10 mx-auto column p-0 text-center ">
              <div>
                <div>Easy way to get orders from customers</div>
                <div className="col-5 col-md-2 mx-auto column p-0">
                  <img
                    src="/images/meriyaadi_logo_aboutus.jpg"
                    className="img-fluid"
                    // className="img-responsive"
                  />
                </div>
                <div>
                  {/* <b>Rs. 399/month</b> */}
                  <b>Mob.:</b> 70833 07568 (Pune)
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    );
  }
}
export default Registration;
