import React, { useState } from "react";
import axios from "axios";
import AdminStoreImage from "./admin-store-image";

function AdminStoreImages(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");
  let [previewImage, setPreviewImage] = useState(false);
  let [flagImageChange, setFlagImageChange] = useState(false);
  let [flagImageUploaded, setFlagImageUploaded] = useState(false);
  let [message, setMessage] = useState("");
  let [file, setFile] = useState("");
  function handleChangeImageClick() {
    setFlagImageChange(true);
  }
  function handleCrudAction(message, action, menu) {
    props.onCrudAction(message, "", "");
  }

  let { shopUrl } = props;
  let { colorScheme } = props;
  return (
    <div className="container-fluid container-md container-list-table">
      {[...new Array(5)].map((e, index) => (
        <AdminStoreImage
          key={index}
          imageNumber={index + 1}
          shopUrl={shopUrl}
          onCrudAction={handleCrudAction}
          colorScheme={colorScheme}
        />
        
      ))}
    </div>
  );
}
export default AdminStoreImages;
