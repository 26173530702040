import React, { Component } from "react";

function RepeatOrderProduct(props) {
  let { product } = props;
  let { srNo } = props;
  let unitDisplay;
  let { shopTypeId } = props;
  let { languageChoice } = props;
  let { otherInfo } = props;
  if (product.unitId == 1) {
    // unit is numbers
    unitDisplay = product.quantity;
  } else if (product.unitId == 2 || product.unitId == 4) {
    // unit is gms
    unitDisplay = product.quantity * 1000;
    if (unitDisplay >= 1000) {
      unitDisplay /= 1000;
      unitDisplay = unitDisplay + " kg";
    } else {
      unitDisplay = unitDisplay + " gms";
    }
  } //else if product.unitId == 1
  else if (product.unitId == 3 || product.unitId == 5) {
    // unit is kg
    unitDisplay = product.quantity * 1 + " kg";
  }
  return (
    <>
      <div className="product-name bigger-text column pl-2 px-1 text-left">
        <span>
          {srNo}. {product.name}
          {languageChoice && product.nameMarathi ? (
            <span className="text-thick">/{product.nameMarathi}</span>
          ) : null}
        </span>
        {product.information && (
          <span className="product-information "> ({product.information})</span>
        )}
        : {unitDisplay}
        {otherInfo ? <span className="error-text">-{otherInfo}</span> : ""}
      </div>
      <div className="col-4 col-md-2 text-left p-0 column mb-2 pr-2"></div>
    </>
  );
}

export default RepeatOrderProduct;
