import React from "react";
import { Link } from "react-router-dom";
import AdminClientSheetListSingle from "./admin-clientsheet-list-single";
function AdminClientSheetList(props) {
  function handleEditClientSheet(clientSheet) {
    props.onEditClientSheet(clientSheet);
  }
  function handleCrudAction(message, action) {
    props.onCrudAction(message, action);
  }
  function handleDeleteClientSheet(clientSheet) {
    props.onDeleteClientSheet(clientSheet);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  function handleSelectClientChange(event) {
    let index = event.target.selectedIndex; // get selected index, instad of selected value
    var optionElement = event.target.childNodes[index];
    var selectedClientId = optionElement.getAttribute("id");
    let selectedClient = event.target.value;
    props.onSelectClientChange(selectedClient, selectedClientId);
  }
  let { selectedClient } = props;
  let { filteredClientSheets } = props;
  let { clientList } = props;
  let { searchText } = props;
  let { currentSize } = props;
  let { accessLevel } = props;
  let { adminType } = props;

  let contentNoclientSheets = <div className="text-center">No clientSheets !</div>;
  // prepare options
  let optionsClient = clientList.map((client, index) => (
    <option value={client.name} key={index} id={client.clientId}>
      {client.name}
    </option>
  ));
  return (
    <>
      {selectedClient && filteredClientSheets.length != 0 && (
        <>
          <div className="row p-0 justify-content-center ">
            <div className="col-2 col-md-4">
              <Link to="#" onClick={handleHeaderClick} id="clientSheetNumber">
                Sn
              </Link>
            </div>
            <div className="col-2 col-md-4">
              <Link to="#" onClick={handleHeaderClick} id="dietNumber">
                Diet Number
              </Link>
            </div>
            <div className="col-2 col-md-4">
              <Link to="#" onClick={handleHeaderClick} id="clientWeight">
                Weight
              </Link>
            </div>
            <div className="col-4">&nbsp;</div>
          </div>
          {filteredClientSheets.map((clientSheet, index) => (
            <AdminClientSheetListSingle
              key={index}
              clientSheet={clientSheet}
              index={index}
              onDeleteClientSheet={handleDeleteClientSheet}
              onEditClientSheet={handleEditClientSheet}
              onCrudAction={handleCrudAction}
              currentSize={currentSize}
              accessLevel={accessLevel}
              adminType={adminType}
            />
          ))}
        </>
      )}
    </>
  );
}
export default AdminClientSheetList;
