import React, { useState } from "react";
import { Link } from "react-router-dom";
import AdminProductListSingle from "./admin-product-list-single";

function AdminProductList(props) {
  let [flagLoad, setFlagLoad] = useState(false);
  let [error, setError] = useState("");

  function handleEditProduct(product) {
    props.onEditProduct(product);
  }
  function handleCrudAction(message, action) {
    props.onCrudAction(message, action);
  }
  function handleDuplicateProduct(product, selection) {
    props.onDuplicateProduct(product, selection);
  }
  function handleDeleteProduct(product, result) {
    props.onDeleteProduct(product, result);
  }
  function handleSearchTextBoxKeyUp(event) {
    props.onSearchTextBoxKeyUp(event);
  }
  function handleSearchTextChange(event) {
    props.onSearchTextChange(event);
  }
  function handleHeaderClick(event) {
    props.onHeaderClick(event);
  }
  function handleSectionNumberClick(event) {
    props.onSectionNumberClick(event);
  }
  let { filteredProducts } = props;
  let { searchText } = props;
  let { sectionNumber } = props;
  let { languageChoice } = props;
  let { shopUrl } = props;
  let { subject } = props;
  let { productsLimit } = props;
  let { currentSize } = props;
  let { colorScheme } = props;
  
  let contentNoproducts = <div className="text-center">NO {subject}</div>;
  let content = (
    // This is enclosed in container-list-table
    <>
      <div className="form-heading col-12">
        LIST OF {subject} ({filteredProducts.length})
      </div>
      {/* row starts */}
      <div className="row smaller-text my-1 justify-content-center align-items-center ">
        <div className="col-2 d-md-none text-center">
          <Link to="#" id="1" onClick={handleSectionNumberClick}>
            Price
          </Link>
        </div>
        <div className="col-3 d-md-none text-center">
          <Link to="#" id="2" onClick={handleSectionNumberClick}>
            {" "}
            Stock/Rating
          </Link>
        </div>
        <div className="col-md-4 d-none d-md-flex  mx-auto">
          <Link to="#" id="12" onClick={handleSectionNumberClick}>
            Price and Stock/Rating
          </Link>
        </div>
        <div className="col-4 d-md-none text-center">
          <Link to="#" id="3" onClick={handleSectionNumberClick}>
            Category/Brand
          </Link>
        </div>
        <div className="col-2 d-md-none text-center">
          <Link to="#" id="4" onClick={handleSectionNumberClick}>
            Unit/Info
          </Link>
        </div>

        <div className="col-md-4 d-none d-md-flex  text-center">
          <Link to="#" id="34" onClick={handleSectionNumberClick}>
            Category/Brand & Unit/Info
          </Link>
        </div>
      </div>
      {/* row ends */}
      {/* row starts */}
      <div className="row justify-content-center">
        <div className="col-10 col-md-6 my-2 ">
          <input
            type="search"
            className="container-fluid form-control"
            value={searchText}
            onKeyUp={handleSearchTextBoxKeyUp}
            onChange={handleSearchTextChange} // This is contolled by parent
            placeholder="Search Products"
            id=""
          />
        </div>
      </div>
      {/* row ends */}
      <div className="row p-0 ">
        <div className="col-12 justify-content-center">
          <div className="table-responsive">
            <table className="table mx-auto w-auto table-sm table-hover table-borderless bigger-text">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <Link to="#" onClick={handleHeaderClick} id="name">
                      Name
                    </Link>
                  </th>
                  {sectionNumber == 1 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="mrp">
                        MRP
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 1 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="finalPrice">
                        F.Price
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 2 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="instock">
                        In stock
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 2 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="rating">
                        Rating
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 12 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="mrp">
                        MRP
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 12 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="finalPrice">
                        F.Price
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 12 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="instock">
                        In stock
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 12 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="rating">
                        Rating
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 3 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="category">
                        Category
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 3 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="brand">
                        Brand
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 4 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="information">
                        Info.
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 4 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="unit">
                        Unit
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 34 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="category">
                        Category
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 34 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="brand">
                        Brand
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 34 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="information">
                        Info.
                      </Link>
                    </th>
                  )}
                  {sectionNumber == 34 && (
                    <th>
                      <Link to="#" onClick={handleHeaderClick} id="unit">
                        Unit
                      </Link>
                    </th>
                  )}
                </tr>
              </thead>
              {filteredProducts.map((product, index) => (
                <tbody key={index}>
                  <AdminProductListSingle
                    product={product}
                    index={index}
                    sectionNumber={sectionNumber}
                    languageChoice={languageChoice}
                    onDeleteProduct={handleDeleteProduct}
                    onDuplicateProduct={handleDuplicateProduct}
                    onEditProduct={handleEditProduct}
                    onCrudAction={handleCrudAction}
                    shopUrl={shopUrl}
                    productsLimit={productsLimit}
                    currentSize={currentSize}
                    colorScheme={colorScheme}
                  />
                </tbody>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
  if (filteredProducts.length >= 0) {
    return <div className="">{content}</div>;
  } //else
  else if (filteredProducts.length == 0) {
    return contentNoproducts;
  } //else
}
export default AdminProductList;
